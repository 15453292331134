import VideoPath from "../../../lib/VideoPath";

let ResourcePageVideosConfig = [
    {
        name: "Awiros Automatic Number Plate Recognition",
        type: "youtube", // youtube/link
        videoID: VideoPath.autoNPR,
        // link: 'http://google.com',
        thumbnail: "/assets/images/resource/video-thumbnails-resources/anpr.png"
    },

    {
        name: "Awiros FRAM",
        type: "youtube", // youtube/link
        videoID: VideoPath.FRAM,
        // link: 'http://google.com',
        thumbnail:
            "/assets/images/resource/video-thumbnails-resources/awiros-fram.png"
    },

    {
        name: "Awiros Fire and Smoke Detection",
        type: "youtube", // youtube/link
        videoID: VideoPath.fireAndSmokeDetection,
        // link: 'http://google.com',
        thumbnail:
            "/assets/images/resource/video-thumbnails-resources/fire-and-smoke.png"
    },

    {
        name: "Awiros Face Clustering Application",
        type: "youtube", // youtube/link
        videoID: VideoPath.faceClustering,
        // link: 'http://google.com',
        thumbnail:
            "/assets/images/resource/video-thumbnails-resources/face-clustering.png"
    },

    {
        name: "AI-based Crowd Estimation & Management",
        type: "youtube", // youtube/link
        videoID: VideoPath.crowdDash,
        // link: 'http://google.com',
        thumbnail:
            "/assets/images/resource/video-thumbnails-resources/crowd.png"
    },

    {
        name: "Awiros Vehicle Counting & Classification",
        type: "youtube", // youtube/link
        videoID: VideoPath.vcc,
        // link: 'http://google.com',
        thumbnail: "/assets/images/resource/video-thumbnails-resources/vcc.png"
    }
];

export default ResourcePageVideosConfig;
