import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section3CardStyles from "./Section3CardStyles";
import Grid from "@material-ui/core/Grid";

let styles = Section3CardStyles;

class Section3Card extends Component {
    static defaultProps = {
        appName: "Advanced Intrusion Detection",
        textContent:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur consectetur possimus quo ratione ullam. Asperiores, cupiditate deleniti ducimus facere facilis ipsa itaque optio quasi quisquam reiciendis rem sequi velit vitae!",
        logo: "/assets/images/resource/ownership.png"
    };

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid container direction={"column"} spacing={2}>
                    <Grid item>
                        <div className={classes.appName}>
                            {this.props.appName}
                        </div>
                    </Grid>
                    <Grid
                        container
                        direction={"row"}
                        justify={"center"}
                        spacing={3}
                        alignItems={"center"}
                        item
                    >
                        <Grid item xs={7} className={classes.textContent}>
                            {this.props.textContent}
                        </Grid>

                        <Grid item xs={5}>
                            <img
                                src={this.props.logo}
                                alt="app logo"
                                className={classes.appIcon}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Section3Card);
