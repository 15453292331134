class StyleConfig {
   static mainThemeColor = '#ffcc00';
   static secondaryThemeColor = '#222222';
   static invertedSecondaryThemeColor = '#ffffff';
   static tertiaryThemeColor = '#333333';

   static backgroundColor = '#ffffff';

   static fadeTabColor = '#51718c';
   static selectedTabColor = '#000000';
   static notSelectedTabColor = '#efefef';
   static hoverIndicationColor = 'rgba(0, 0, 0, 0.04)';

   static headingColor = '#3b566e';

   static appStackCardColor = '#fff2bd';
   static appStackButtonColor = 'rgb(96,96,138)';
   static appStackButtonHoverColor = 'rgba(96,96,138,.9)';

   static borderOutline = '#d9d9d9';
   static darkButtonBackground = 'rgb(64,64,64)';
   static darkButtonBackgroundHover = 'rgba(64,64,64,.9)';

   static fadeTextColor= 'rgb(89,89,89)'

   static videoLabels = 'rgba(127,127,127)';

   //static carouselHeadings = '#336799';
   static carouselHeadings = '#20497f';

   static barterColor= '#f5f5f7';
   static barterHighlight= 'rgb(91,155,213)';
}


class ProcessorColors {
   static "i-GPU" = "#839bc7";
   static CPU = "#d79467";
   static GPU = 'rgb(168,197,148)';
   static VPU = '#0071c5';
   static processorTextColor = 'white'
}

export {ProcessorColors};

export default StyleConfig;
