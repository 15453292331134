import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import TestimonialCardStyles from "./TestimonialCardStyles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import {WithStore} from "pure-react-carousel";

let styles = TestimonialCardStyles;

class TestimonialCard extends Component {
   static defaultProps = {
      name: '',
      designation: '',
      logo: '',
   };

   handleClick = (e) => {
      e.preventDefault();
      // this.props.carouselStore.setStoreState({isPlaying: true});
   }

   render() {
      let {classes} = this.props;
      return (
         <Grid container direction={'column'} justify={'space-between'} className={classes.root}
               onClick={this.handleClick}>
            <Grid item>
               <i className={`fas fa-quote-left ${classes.quoteSign}`}/>
               <Typography className={classes.textContent}>
                  {this.props.cardData.content}
               </Typography>
            </Grid>

            <Grid item container direction={'row'} justify={'center'} alignItems={'center'}>
               <Grid item xs={3}>
                  <img src={this.props.cardData.logo} alt="" className={classes.logoImage}/>
               </Grid>
               <Grid item xs={1}/>
               <Grid item xs={8}>
                  <Typography>{this.props.cardData.name}</Typography>
                  <Typography>{this.props.cardData.designation}</Typography>
               </Grid>
            </Grid>
         </Grid>
      );
   }
}

export default withStyles(styles)(TestimonialCard);