let CaseStudyStyles = {
  caseStudy: {
    margin: "0 auto",
    maxWidth: 1500,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  heading: {
    color: "#FFCC00",
    fontSize: "36px",
    fontWeight: "800",
    textAlign: "center",
    padding: "0px 100px 0px 100px",
    marginTop: "10px",
    marginBottom: "20px",
    "@media (max-width: 1024px)": {
      fontSize: "24px",
    },
    "@media (max-width: 768px)": {
      fontSize: "20px",
      padding: "0px 20px 0px 20px",
    },
  },
  description: {
    fontSize: "16px",
    textAlign: "center",
    padding: "10px 100px 10px 100px",
    fontWeight: "300",
    "@media (max-width: 1024px)": {
      fontSize: "14px",
    },
    "@media (max-width: 768px)": {
      fontSize: "12px",
      padding: "10px 20px 10px 20px",
    },
  },
  readMoreButton: {
    backgroundColor: "#0D5377",
    boxShadow: "0px 4px 8px rgba(13, 83, 119, 0.2)",
    borderRadius: "10px",
    color: "white",
    padding: "12px 32px 12px 32px",
    border: "none",
    fontSize: "20px",
    fontWeight: "400",
    marginTop: "16px",
    width: "max-content",
    marginBottom: "40px",
    cursor: "pointer",
    "@media (max-width: 768px)": {
      fontSize: "16px",
      padding: "10px 20px 10px 20px",
    },
  },
};

export default CaseStudyStyles;
