import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import CardSetStyles from "./CardSetStyles";
import Grid from "@material-ui/core/Grid";
import IndustryPageCard from "./IndustryPageCard/IndustryPageCard";
import Hidden from "@material-ui/core/Hidden";

let styles = CardSetStyles;

class CardSet extends Component {
   render() {
      let {classes, thumbnailImage} = this.props;
      return (
         <div className={classes.root}>
            <Grid container direction={'row'} spacing={4} alignItems={'center'} className={classes.viewContainer}>
               <Hidden xsDown>
                  <Grid item xs={4}>
                     <img src={thumbnailImage} alt={"tab thumbnail"} className={classes.thumbnailImage}/>
                  </Grid>
               </Hidden>

               <Grid container direction={'row'} spacing={3} item xs={12} sm={8} className={classes.tilesContainer}>
                  {this.props.cardsData.map((cardData, index) => {
                     return (
                        <Grid item key={index} xs={6} sm={12} md={6}>
                           <IndustryPageCard cardData={cardData}/>
                        </Grid>
                     )
                  })}
               </Grid>
            </Grid>
         </div>
      );
   }
}

export default withStyles(styles)(CardSet);