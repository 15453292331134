let NewsCarouselCardStyles = {
   root: {
      // border: `1px solid ${StyleConfig.mainThemeColor}`,
      height: '100%',
      position: 'relative',
      // padding: '20px 0'
      //
   },
   card: {
      padding: '20px 16px',
      // width: '85%',
      // height:'85%',
      // height: '100%',
      minHeight: 350,
   },
   title: {
      fontSize: 16,
      padding: '0 10px'
   },
   thumbnailImage: {
      width: '100%'
   },
   textContent: {
      fontSize: 16,
      fontWeight: 300
   },
   h3heading: {
      color: '#3b566e',
      fontSize: 20,
      fontWeight: 300,
   }
};

export default NewsCarouselCardStyles;