import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section1 from "./Sections/Section1/Section1";
import Section2 from "./Sections/Section2/Section2";
import HomePageStyles from "./HomePageStyles";
import Section3 from "./Sections/Section3/Section3";
import Section4 from "./Sections/Section4/Section4";
import Section6 from "./Sections/Section6/Section6";
import Section5 from "./Sections/Section5/Section5";
import Section7 from "./Sections/Section7/Section7";
import IndustriesBanner from "./IndustriesBanner";

let styles = HomePageStyles;

class HomePage extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                {/* <IndustriesBanner></IndustriesBanner> */}
                <Section1 history={this.props.history} /> {/*1*/}
                <Section2 history={this.props.history} /> {/*2*/}
                <Section3 history={this.props.history} /> {/*3*/}
                <Section4 history={this.props.history} /> {/*4*/}
                <Section5 history={this.props.history} /> {/*5*/}
                <Section6 history={this.props.history} /> {/*6*/}
                <Section7 history={this.props.history} /> {/*7*/}
            </div>
        );
    }
}

export default withStyles(styles)(HomePage);
