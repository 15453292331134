let GuidelineStyles = {
  guidelineContainer: {
    padding: "64px",
    "@media (max-width:768px)": {
      padding: "32px",
    },
    "@media (max-width:500px)": {
      padding: "20px",
    },
  },
  guidelineHeading: {
    color: "#FC0",
    fontSize: "48px",
    fontWeight: "600",
    margin: "auto",
    marginBottom: "40px",
    "@media (max-width:768px)": {
      textAlign: "center",
      marginBottom: "25px",
      fontSize: "32px",
    },
  },
  guidelineContent: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    "@media (max-width:768px)": {
      gap: "10px",
    },
  },
  guideline: {
    display: "flex",
    alignItems: "center",
    gap: "30px",
    border: "1px solid #FC0",
    borderRadius: "8px",
    padding: "5px 20px",
    "@media (max-width:768px)": {
      padding: "5px 10px",
    },
  },
  guidelineNumber: {
    color: "#FC0",
    fontSize: "48px",
    fontWeight: "700",
    "@media (max-width:768px)": {
      fontSize: "32px",
    },
  },
  guidelineText: {
    color: "white",
    fontSize: "18px",
    fontWeight: "500",
    "@media (max-width:768px)": {
      fontSize: "16px",
    },
  },
};

export default GuidelineStyles;
