import StyleConfig from "../../lib/StyleConfig";

let NavbarStyles = {
  root: {
    position: "fixed",
    bottom: 16,
    right: 16,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: 16,
  },
  appBar: {
    background: "white",
    transition: "all 200ms ease",
    borderBottom: ".3px solid lightgrey",
    padding: "1vh 4vh",
    maxWidth: "1920px",
    margin: "0 auto",
    top: 0,
    left: 0,
  },
  appBarExpanded: {
    padding: "4vh 4vh",
    borderBottom: "0",
    // marginBottom: '10vh',
    "@media (max-width:600px)": {
      padding: "2vh 3vh",
    },
  },
  navbarContentContainer: {
    marginTop: "8vh",
    backgroundColor: "white",
    "@media (max-width:600px)": {
      marginTop: "11vh",
    },
  },
  navbarExpandedContentContainer: {
    marginTop: "16vh",
    "@media (max-width:600px)": {
      marginTop: "11vh",
    },
  },
  scrollToTopButton: {
    color: StyleConfig.mainThemeColor,
    backgroundColor: StyleConfig.secondaryThemeColor,
    "&:hover": {
      backgroundColor: StyleConfig.mainThemeColor,
      color: StyleConfig.invertedSecondaryThemeColor,
    },
  },
  title: {
    // flexGrow: 1,
  },
  buttons: {
    textDecoration: "none",
    margin: 16,
    fontSize: 19.2,
    transition: "all 500ms ease",
    color: StyleConfig.fadeTabColor,

    position: "relative",
    display: "inline",
    "&:after": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: -8,
      display: "inline-block",
      width: 0,
      borderBottom: `2px solid ${StyleConfig.mainThemeColor}`,

      transition: "all 500ms ease",
      "-moz-transition": "all 500ms ease",
      "-webkit-transition": "all 500ms ease",
      "-ms-transition": "all 500ms ease",
      "-o-transition": "all 500ms ease",
    },
    "&:hover": {
      color: StyleConfig.selectedTabColor,
      "&:after": {
        width: "1.3em",
        height: "2px",
      },
    },
  },
  portalButton: {
    textDecoration: "none",
    marginLeft: 8,
    padding: 8,
    fontSize: 19.2,
    transition: "all 500ms ease",
    border: "1px solid black",
    borderRadius: "4px",
    color: StyleConfig.secondaryThemeColor,
    "&:hover": {
      backgroundColor: StyleConfig.mainThemeColor,
      color: StyleConfig.invertedSecondaryThemeColor,
    },
  },
  activeLink: {
    color: `${StyleConfig.selectedTabColor}!important`,
    "&:after": {
      content: "''",
      position: "absolute",
      left: 0,
      bottom: -8,
      display: "inline-block",
      width: "1.3em!important",
      height: "2px!important",
      borderBottom: `2px solid ${StyleConfig.mainThemeColor}`,
    },
  },
  sectionDesktop: {
    display: "none",
    "@media (min-width: 1280px)": {
      display: "flex",
    },
  },

  sectionMobile: {
    display: "flex",
    "@media (min-width: 1280px)": {
      display: "none",
    },
  },

  mobileMenuContainer: {
    "& ul": {
      width: "50vw",
      margin: "0px!important",
      padding: "0px!important",
    },
    // backgroundColor:StyleConfig.mainThemeColor
  },
  mobileButtons: {
    textDecoration: "none",
    padding: 16,
    fontSize: 19.2,
    // transition: 'all 500ms ease',
    color: StyleConfig.fadeTabColor,
    backgroundColor: StyleConfig.mainThemeColor,
    display: "block",
    position: "relative",
    border: 0,
    width: "100%",
    textAlign: "left",
    "&:hover": {
      color: StyleConfig.mainThemeColor,
      backgroundColor: StyleConfig.tertiaryThemeColor,
    },
  },
  mobilePortalButton: {
    textDecoration: "none",
    padding: 16,
    margin: 5,
    fontSize: 19.2,
    color: StyleConfig.fadeTabColor,
    backgroundColor: StyleConfig.mainThemeColor,
    display: "block",
    position: "relative",
    border: "1px solid black",
    borderRadius: "4px",
    "&:hover": {
      color: StyleConfig.mainThemeColor,
      backgroundColor: StyleConfig.tertiaryThemeColor,
    },
  },
  mobileActiveLink: {
    color: `${StyleConfig.mainThemeColor}!important`,
    backgroundColor: StyleConfig.secondaryThemeColor,
  },

  dropdownMenuContainer: {
    zIndex: 1300,
  },
  dropdownMenu: {
    marginTop: 25,
    borderTop: `3px solid ${StyleConfig.mainThemeColor}`,
    "& ul": {
      // minWidth:240,
      margin: "0px!important",
      padding: "0px!important",
    },
    "@media (max-width: 1280px)": {
      border: `1px solid ${StyleConfig.secondaryThemeColor}`,
      marginTop: 0,
    },
  },
  dropdownMenuItem: {
    borderBottom: `1px solid lightgrey`,
    padding: 0,
  },
  dropdownMenuLink: {
    textDecoration: "none",
    color: StyleConfig.selectedTabColor,
    padding: 16,
    fontSize: 19.2,
    display: "block",
    position: "relative",
    border: 0,
    width: "100%",
    minWidth: 240,
    textAlign: "left",
    "&:hover": {
      backgroundColor: StyleConfig.mainThemeColor,
      transition: "all 500ms ease",
      // '& a': {
      color: StyleConfig.invertedSecondaryThemeColor,
      // }
    },
  },
};

export default NavbarStyles;
