export const faqs = [
  {
    question: "Who can participate in the hackathon?",
    answer:
      "If you are a working professional, start-up based, freelancer, research scholar or College student, you are eligible to participate in this App-a-thon.",
  },
  {
    question: "Are there any fees to participate in the App-a-thon?",
    answer: "No, there are no fees to participate in this App-a-thon.",
  },
  {
    question: "How do I submit my app for the App-a-thon?",
    answer:
      "Each team will receive login credentials for Awiros Appstack to build and submit their apps.",
  },
  {
    question: "Do we need to have the entire idea fully working?",
    answer:
      "Not needed, we will evaluate the entire idea based on problem statement, approach, accuracy and porting of app.",
  },
  {
    question:
      "Do I have to be online and available for the entire duration of the App-a-thon?",
    answer: "No, not required.",
  },
  {
    question: "Do I need to give a demo for the solution I have built?",
    answer:
      "Yes, the shortlisted apps of the teams/individual will give 5-10 minutes of presentation and demo.",
  },
  {
    question:
      "If it is a team submission, does that mean all the team members will have access to work at the same time?",
    answer:
      "Each team will get login credentials of Awiros Appstack to build and submit their applications",
  },
  {
    question:
      "Who will own intellectual property rights to the solution I have built?",
    answer:
      "You will own the intellectual property right for your code only. However, all the code needs to be in the public domain (open source) so that it can be evaluated by the judges.",
  },
  {
    question: "How many people can register in one team?",
    answer:
      "One team can have a maximum of 4 individuals. Each team should have their name, even if there is only one individual registering.",
  },
  {
    question: "Can I register as an individual developer?",
    answer: "Yes, You can register as an individual developer.",
  },
  {
    question:
      "How will a team be chosen to participate in-person for the Bangalore event?",
    answer:
      "Each team will submit their Computer Vision Apps by 15th October 2023. The judges will shortlist the submitted Apps and only those teams will be eligible to participate in-person at Bangalore event.",
  },
  {
    question: "How will access to Awiros SDK be provided?",
    answer:
      "Each team will be cloud instance with all the necessary installed software.",
  },
  {
    question: "How will the cash prize be distributed among the team members?",
    answer:
      "The registered team will be counted as one participant irrespective to the number of members in team(1-4). The cash prize will be consolidated amount offered to the team to be then shared amongst the team equally.",
  },
  {
    question:
      "Will the Goodies be given to all the members of the shortlisted team?",
    answer:
      "Yes, goodies will be given to all the members of the shortlisted team.",
  },
];
