import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import ContactPageStyles from "./ContactPageStyles";
import Grid from "@material-ui/core/Grid";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import ReCAPTCHA from "react-google-recaptcha";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/styles";
import UserService from "../../services/UserService";
import UserValidator from "../../lib/validators/UserValidator";
import clsx from "clsx";
import primaryTheme from "../../util/PrimaryTheme";
import { toast } from "material-react-toastify";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";

let styles = ContactPageStyles;

let TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
let SITE_KEY = "6LfO2dkUAAAAAA4jcdjwOXkk-98PuBFdy_jEaLlN";

let siteKeyToggle = process.env.NODE_ENV === "production";

// let siteKeyToggle = false

let SELECTED_SITE_KEY = siteKeyToggle ? SITE_KEY : TEST_SITE_KEY;

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class ContactPage extends Component {
  constructor(props) {
    super(props);
    let formData = {
      name: "",
      email: "",
      contact: "",
      city: "",
      message: "",
    };

    if (process.env.NODE_ENV === "development") {
      formData = {
        name: "",
        email: "",
        contact: "",
        city: "",
        message: "",
      };
    }
    this.state = {
      ...formData,
      captchaValue: null,
      load: true,
      captchaExpired: false,

      snackbarOpenFlag: false,
      snackBarProperties: {
        alertSeverity: "success",
        alertMessage: "",
      },
    };
    this._reCaptchaRef = React.createRef();
  }

  handleCaptchaChange = (captchaValue) => {
    this.setState({ captchaValue });
    // if value is null recaptcha expired
    if (captchaValue === null) this.setState({ captchaExpired: true });
  };

  // asyncScriptOnLoad = () => {
  //    console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
  // };

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    // debugger;
    if (this.state.captchaValue) {
      let { name, email, contact, city, message } = this.state;
      let validationResponse = await UserValidator.isValidEmail(email);
      console.log(validationResponse);
      if (validationResponse.success) {
        let data = {
          name,
          e_mail: email,
          phone: contact,
          city,
          company: message,
        };
        let response = await UserService.sendMessage(data);
        if (response.success) {
          // this.handleSnackbarOpen({
          //   alertMessage:
          //     'You will shortly receive the Datasheet on your Email-ID',
          //   alertSeverity: 'success',
          // });
          toast.success(
            "You will shortly receive the Datasheet on your Email-ID"
          );
          // toast.success()
        } else {
          toast.error("Error submitting form, Please try again later...");
          // this.handleSnackbarOpen({
          //   alertMessage: 'Error submitting form, Please try again later...',
          //   alertSeverity: 'error',
          // });
        }
      } else {
        toast.error(validationResponse.message || "Please enter a valid Email");
      }
    } else {
      toast.error("Captcha not completed");
      // this.handleSnackbarOpen({
      //   alertMessage: 'Captcha not completed',
      //   alertSeverity: 'error',
      // });
    }
  };

  handleSnackbarClose = (callback) => (e) => {
    console.log(callback);
    this.setState({ snackbarOpenFlag: false }, callback);
  };

  handleSnackbarOpen = (snackBarProperties) => {
    this.handleSnackbarClose(() => {
      this.setState({ snackbarOpenFlag: true, snackBarProperties });
    })();
  };

  render() {
    let { classes, width } = this.props;
    // const {load} = this.state || {};
    let { snackBarProperties, snackbarOpenFlag, load } = this.state;
    let { alertMessage, alertSeverity } = snackBarProperties;

    return (

      <ThemeProvider theme={primaryTheme}>
        <div className={classes.root}>
          <h1 className={classes.heading}>Contact Us</h1>

          <Grid
            container
            direction={"row"}
            spacing={3}
            className={classes.mapAndFormContainer}
          >
            <Grid item xs={12} md={6} className={classes.formContainer}>
              <div className={classes.subheadingContainer}>
                <h2 className={clsx("bottom-left-dash", classes.subheading)}>
                  Send a Message
                </h2>
              </div>
              <form onSubmit={this.handleSubmit}>
                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="outlined"
                  label="Full Name"
                  name={"name"}
                  onChange={this.handleChange}
                  value={this.state.name}
                />
                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="outlined"
                  label="Your Email"
                  name={"email"}
                  onChange={this.handleChange}
                  value={this.state.email}
                />
                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="outlined"
                  label="Your Contact"
                  name={"contact"}
                  onChange={this.handleChange}
                  value={this.state.contact}
                />
                <TextField
                  required
                  fullWidth
                  type="text"
                  variant="outlined"
                  label="City"
                  name={"city"}
                  onChange={this.handleChange}
                  value={this.state.city}
                />
                <TextField
                  required
                  fullWidth
                  multiline
                  rows={4}
                  type="text"
                  variant="outlined"
                  label="Message"
                  name={"message"}
                  onChange={this.handleChange}
                  value={this.state.message}
                />

                <div className={classes.captcha}>
                  {load && (
                    <ReCAPTCHA
                      style={{ display: "inline-block" }}
                      ref={this._reCaptchaRef}
                      sitekey={SELECTED_SITE_KEY}
                      onChange={this.handleCaptchaChange}
                    // asyncScriptOnLoad={this.asyncScriptOnLoad}
                    />
                  )}
                </div>

                <Grid item>
                  <Button
                    type={"submit"}
                    variant="contained"
                    className={classes.submitButton}
                  >
                    Submit
                  </Button>
                </Grid>
              </form>
            </Grid>

            <Portal>
              <Snackbar
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                open={snackbarOpenFlag}
                autoHideDuration={6000}
                onClose={this.handleSnackbarClose()}
              >
                <Alert
                  onClose={this.handleSnackbarClose()}
                  severity={alertSeverity}
                >
                  {alertMessage}
                </Alert>
              </Snackbar>
            </Portal>

            <Grid item xs={12} md={6}>
              <iframe
                width={"100%"}
                height={
                  this.props.width === "xs" || this.props.width === "sm"
                    ? "450"
                    : "100%"
                }
                frameBorder={"0"}
                style={{ border: 0 }}
                allowFullScreen=""
                aria-hidden="false"
                handleImageChange
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.4790371108343!2d77.07219965077915!3d28.465116782398244!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d199bc43000c5%3A0xa7c5b30358f1d30a!2sAwiros%20(Awidit%20Systems%20Pvt.%20Ltd.)!5e0!3m2!1sen!2sin!4v1589434365772!5m2!1sen!2sin"
                tabIndex="0"
              />
            </Grid>
          </Grid>

          {width !== "xs" ? (
            <div className={classes.main_container}>
              <Grid
                container
                direction="row"
                spacing={2}
                justify={"space-around"}
              >
                <Grid item>
                  <i
                    className={clsx("fas fa-paper-plane", classes.detailsLogo)}
                  />
                </Grid>
                <Grid item xs={11} container direction="row" spacing={2}>
                  <Grid item xs={12} sm={4}>
                    <div className={classes.detailsHeading}>Office Address</div>
                    <div className={classes.detailsTextContent}>
                      11, Vatika City Point,
                    </div>
                    <div className={classes.detailsTextContent}>
                      Mehrauli-Gurgaon Rd,
                    </div>
                    <div className={classes.detailsTextContent}>
                      Gurugram, Haryana 122002,
                    </div>
                    <div className={classes.detailsTextContent}>India</div>
                  </Grid>

                  {/* <Grid item xs={3}>
                <div className={classes.detailsHeading}>Registered Office</div>
                <div className={classes.detailsTextContent}>
                  802-804, Vijaya Building, Barakhamba Road, New Delhi 110001
                </div>
              </Grid> */}
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                spacing={2}
                justify={"space-around"}
              >
                <Grid item>
                  <i
                    className={clsx("fas fa-phone-alt", classes.detailsLogo)}
                  />{" "}
                </Grid>
                <Grid item xs={11} container direction="row" spacing={2}>
                  <Grid item xs={4}>
                    <div className={classes.detailsHeading}>Phone</div>
                    <a
                      href={"tel:+911244263789"}
                      className={classes.detailsLink}
                    >
                      (+91) 124 426 3789
                    </a>
                  </Grid>

                  <Grid item xs={4}></Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                spacing={2}
                justify={"space-around"}
              >
                <Grid item>
                  <i className={clsx("fas fa-envelope", classes.detailsLogo)} />
                </Grid>
                <Grid item xs={11} container direction="row" spacing={2}>
                  <Grid item xs={4}>
                    <div className={classes.detailsHeading}>Email</div>
                    <a
                      className={classes.detailsLink}
                      href="https://mail.google.com/mail/?view=cm&fs=1&to=info@awiros.com"
                      target="_blank"
                    >
                      info@awiros.com
                    </a>
                  </Grid>

                  <Grid item xs={4}></Grid>

                  <Grid item xs={4}></Grid>
                </Grid>
              </Grid>

              <Grid
                container
                direction="row"
                spacing={2}
                justify={"space-around"}
              >
                <Grid item>
                  <img
                    src="/assets/youtubeContactUS.svg"
                    width="35px"
                    height="30px"
                  ></img>
                  {/* <i className={clsx("fas fa-envelope", classes.detailsLogo)} /> */}
                </Grid>
                <Grid item xs={11} container direction="row" spacing={2}>
                  <Grid item xs={4}>
                    <div className={classes.detailsHeading}>YouTube</div>
                    <a
                      className={classes.detailsLink}
                      href="https://www.youtube.com/watch?v=-DwThHADbLE&t=1s"
                      target="_blank"
                    >
                      Our directional video
                    </a>
                  </Grid>

                  <Grid item xs={4}></Grid>

                  <Grid item xs={4}></Grid>
                </Grid>
              </Grid>
            </div>
          ) : (
            <div>
              <Grid
                container
                direction="column"
                spacing={6}
                justify={"space-around"}
              >
                <Grid
                  item
                  container
                  direction="column"
                  className={classes.contact_container}
                >
                  <Grid item container direction="row">
                    <Grid item xs={2}>
                      <i
                        className={clsx(
                          "fas fa-paper-plane",
                          classes.detailsLogo
                        )}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <div className={classes.detailsHeading}>
                        Office Address
                      </div>
                      <div className={classes.detailsTextContent}>
                        11, Vatika City Point,
                      </div>
                      <div className={classes.detailsTextContent}>
                        Mehrauli-Gurgaon Rd,
                      </div>
                      <div className={classes.detailsTextContent}>
                        Gurugram, Haryana 122002,
                      </div>
                      <div className={classes.detailsTextContent}>India</div>
                    </Grid>
                  </Grid>

                  <Grid item container direction="row">
                    <Grid item xs={2}>
                      <i
                        className={clsx("fas fa-envelope", classes.detailsLogo)}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <div className={classes.detailsHeading}>Email</div>
                      <a
                        className={classes.detailsLink}
                        href="https://mail.google.com/mail/?view=cm&fs=1&to=info@awiros.com"
                        target="_blank"
                      >
                        info@awiros.com
                      </a>
                    </Grid>
                  </Grid>

                  <Grid item container direction="row">
                    <Grid item xs={2}>
                      <i
                        className={clsx(
                          "fas fa-phone-alt",
                          classes.detailsLogo
                        )}
                      />
                    </Grid>
                    <Grid item xs={10}>
                      <div className={classes.detailsHeading}>Phone</div>
                      <a
                        href={"tel:+911244263789"}
                        className={classes.detailsLink}
                      >
                        <div>(+91) 124 426 3789</div>
                      </a>
                    </Grid>
                  </Grid>
                  <Grid item container direction="row">
                    <Grid item xs={2}>
                      <img
                        src="/assets/youtubeContactUS.svg"
                        width="35px"
                        height="30px"
                      ></img>
                    </Grid>
                    <Grid item xs={10}>
                      <div className={classes.detailsHeading}>YouTube</div>
                      <a
                        className={classes.detailsLink}
                        href="https://www.youtube.com/watch?v=-DwThHADbLE&t=1s"
                        target="_blank"
                      >
                        Our directional video
                      </a>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item container direction="column"></Grid>
              </Grid>
            </div>
          )}
        </div>
      </ThemeProvider>
    );
  }
}

export default withWidth()(withStyles(styles)(ContactPage));
