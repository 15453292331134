import React, { Component } from "react";

import Button from "@material-ui/core/Button";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import CardHeader from "@material-ui/core/CardHeader";
import DataSheetForm from "./DataSheetForm/DataSheetForm";
import Modal from "@material-ui/core/Modal";
import Section1Styles from "./Section1Styles";
import withStyles from "@material-ui/core/styles/withStyles";

let styles = Section1Styles;

class Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSheetModalFlag: false
        };
    }

    handleDataSheetModalOpen = () => {
        // window.open(RoutePath.dataSheetPath,'_blank')
        this.setState({ dataSheetModalFlag: true });
    };

    handleDataSheetModalClose = () => {
        // window.open(RoutePath.dataSheetPath,'_blank')
        this.setState({ dataSheetModalFlag: false });
    };

    render() {
        let { classes } = this.props;
        let { dataSheetModalFlag } = this.state;
        return (
            <div className={classes.root}>
                <div className={classes.heading}>
                    Deploy, Scale and Manage Video AI Like Never Before
                </div>
                {/*<div className={classes.subtitle}>Now, YOU do not need to re-invent the wheel!</div>*/}
                <div className={classes.textContent}>
                    Awiros allows you to deploy Video AI apps at any scale with
                    an unmatched level of flexibility
                </div>
                {/*<img className={classes.image} src={require('../../../../assets/images/resource/cycle.png')} alt="cycle"/>*/}
                <video
                    autoPlay
                    loop
                    controls={false}
                    className={classes.image}
                    playsinline
                    src={
                        "/assets/images/resource/scaling-and-orchestration.mp4"
                    }
                />

                {/* <Button variant="contained" color="primary" className={classes.button}
                    onClick={this.handleDataSheetModalOpen}>
               Get the Awiros OS Datasheet
            </Button> */}

                <Modal
                    open={dataSheetModalFlag}
                    disableEnforceFocus
                    disableAutoFocus
                    onClose={this.handleDataSheetModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}
                >
                    <Card className={classes.dataSheetCardContainer}>
                        <CardHeader title="Please fill this form to receive the Awiros OS Datasheet on your email" />

                        <CardContent>
                            <DataSheetForm />
                        </CardContent>
                    </Card>
                </Modal>
            </div>
        );
    }
}

export default withStyles(styles)(Section1);
