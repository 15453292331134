export const problems = [
  {
    iconUrl: "retail.png",
    title: "Retail",
    heading: "Revolutionize retail with computer vision solutions.",
    points: [
      "Detects and indicates the current occupancy",
      "Detects Queue for queue management",
      "Footfall analytics with heat map",
      "Automatic billing at retail store or warehouse",
      "Inventory Management",
    ],
  },
  {
    iconUrl: "automation.png",
    title: "Automation",
    heading: "Revolutionize retail with computer vision solutions.",
    points: [
      "Defected item detection at supply chain",
      "Dimension calculation at supply chain",
    ],
  },
  {
    iconUrl: "smartCity.png",
    title: "Smart City",
    heading: "Build smarter cities with computer vision.",
    points: [
      "People count within the user defined region in the camera Field-of-View (FoV)",
      "Number of people moving from one region to another",
    ],
  },
  {
    iconUrl: "security.png",
    title: "Security",
    heading: "Elevate security measures with computer vision.",
    points: [
      "Accident Detection on Factory floor",
      "Forklift speeding in Warehouse",
      "Fire and Smoke Detection",
      "Detects if employees are wearing certain protective gear",
      "Spillage Detection- liquid",
      "Electrical Spark detection",
    ],
  },

  {
    iconUrl: "sportsAndMedia.png",
    title: "Sports & Media",
    heading: "Enhance the sports experience with computer vision.",
    points: [
      "Calculate ball possession by players and also by team",
      "Heat map of players on field",
      "Detecting appearance of ads in a video stream",
      "Estimating the number of fans supporting a team in a match based on jersey color",
    ],
  },
  {
    iconUrl: "education.png",
    title: "Education",
    heading: "Empower education through computer vision technologies.",
    points: ["Students Attention Detection "],
  },
];
