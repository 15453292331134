import { Card, CardContent, CardHeader, Modal } from "@material-ui/core";
import React, { Component } from "react";

import BrochureFormStyles from "./BrochureFormStyles";
import Button from "@material-ui/core/Button";
import MuiAlert from "@material-ui/lab/Alert";
import Portal from "@material-ui/core/Portal";
import ReCAPTCHA from "react-google-recaptcha";
import Snackbar from "@material-ui/core/Snackbar";
import TextField from "@material-ui/core/TextField";
import { ThemeProvider } from "@material-ui/styles";
import UserService from "../../../../services/UserService";
import UserValidator from "../../../../lib/validators/UserValidator";
import primaryTheme from "../../../../util/PrimaryTheme";
import { toast } from "material-react-toastify";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";

let styles = BrochureFormStyles;

let TEST_SITE_KEY = "6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI";
let SITE_KEY = "6LfO2dkUAAAAAA4jcdjwOXkk-98PuBFdy_jEaLlN";
// let siteKeyProductionFlag = true;
let siteKeyProductionFlag = process.env.NODE_ENV === "production";

let SELECTED_SITE_KEY = siteKeyProductionFlag ? SITE_KEY : TEST_SITE_KEY;

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class BrochureForm extends Component {
    static defaultProps = {
        open: false,
        onClose: () => null,
        brochureFormData: {
            name: "Brochure Name",
            type: "link", // youtube/link
            // videoID: 'f0O8gQgaJq4',
            link: "http://google.com"
            // thumbnail: require('./../../../..//assets/images/resource/temp-thumbnail.png'),
        }
    };

    constructor(props) {
        super(props);
        let formData = {
            name: "",
            email: "",
            contact: "",
            city: "",
            message: `${this.props.brochureFormData.name} - Brochure Request`
        };

        if (process.env.NODE_ENV === "development") {
            formData = {
                name: "Shivansh Testing",
                email: "testing@awiros.com",
                contact: "test@awiros.com",
                city: "gurugram",
                message: `${this.props.brochureFormData.name} - Brochure Request`
            };
        }
        this.state = {
            ...formData,
            captchaValue: null,
            load: true,
            captchaExpired: false,
            snackbarOpenFlag: false,
            snackBarProperties: {
                alertSeverity: "success",
                alertMessage: ""
            }
        };
        this._reCaptchaRef = React.createRef();
    }

    handleCaptchaChange = (captchaValue) => {
        this.setState({ captchaValue });
        // if value is null recaptcha expired
        if (captchaValue === null) this.setState({ captchaExpired: true });
    };

    // asyncScriptOnLoad = () => {
    //    console.log("scriptLoad - reCaptcha Ref-", this._reCaptchaRef);
    // };

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };

    handleSubmit = async (e) => {
        e.preventDefault();
        // debugger;
        if (this.state.captchaValue) {
            let { name, email, contact, city, message } = this.state;
            let validationResponse = await UserValidator.isValidEmail(email);
            console.log(validationResponse);
            if (validationResponse.success) {
                let data = {
                    name,
                    e_mail: email,
                    phone: contact,
                    city,
                    company: message
                };
                let response = await UserService.sendMessage(data);
                if (response.success) {
                    // this.handleSnackbarOpen({
                    //   alertMessage: 'Your download will start shortly...',
                    //   alertSeverity: 'success',
                    // });
                    toast.success("Your download will start shortly...");
                    setTimeout(() => {
                        window.open(
                            this.props.brochureFormData.link,
                            "_blank",
                            "noopener noreferrer"
                        );
                    }, 2000);
                    // toast.success()
                } else {
                    // this.handleSnackbarOpen({
                    //   alertMessage: '',
                    //   alertSeverity: 'error',
                    // });
                    toast.error(
                        "Error submitting form, Please try again later..."
                    );
                }
            } else {
                toast.error("Please enter a valid Email");
            }
        } else {
            toast.error("Captcha not completed");
            // this.handleSnackbarOpen({
            //   alertMessage: 'Captcha not completed',
            //   alertSeverity: 'error',
            // });
        }
    };

    handleSnackbarClose = (callback) => (e) => {
        console.log(callback);
        this.setState({ snackbarOpenFlag: false }, callback);
    };

    handleSnackbarOpen = (snackBarProperties) => {
        this.handleSnackbarClose(() => {
            this.setState({ snackbarOpenFlag: true, snackBarProperties });
        })();
    };

    // handleSnackbarReopen = (snackBarProperties) => {
    //    this.handleSnackbarClose(this.handleSnackbarOpen(snackBarProperties));
    // }

    render() {
        let { classes, open, onClose } = this.props;
        let { snackBarProperties, snackbarOpenFlag, load } = this.state;
        let { alertMessage, alertSeverity } = snackBarProperties;

        console.log(this.state.captchaValue);
        return (
            <Modal
                open={open}
                disableEnforceFocus
                disableAutoFocus
                onClose={onClose}
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
                className={classes.modal}
            >
                <Card className={classes.dataSheetCardContainer}>
                    <CardHeader
                        title={`Please fill this form to download the requested Resource - ${this.props.brochureFormData.name}`}
                    />

                    <CardContent>
                        <ThemeProvider theme={primaryTheme}>
                            <div className={classes.root}>
                                <form onSubmit={this.handleSubmit}>
                                    <TextField
                                        required
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Full Name"
                                        name={"name"}
                                        onChange={this.handleChange}
                                        value={this.state.name}
                                        className={"smallMb"}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Your Email"
                                        name={"email"}
                                        onChange={this.handleChange}
                                        value={this.state.email}
                                        className={"smallMb"}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="Your Organisation"
                                        name={"contact"}
                                        onChange={this.handleChange}
                                        value={this.state.contact}
                                        className={"smallMb"}
                                    />
                                    <TextField
                                        required
                                        fullWidth
                                        type="text"
                                        variant="outlined"
                                        label="City"
                                        name={"city"}
                                        onChange={this.handleChange}
                                        value={this.state.city}
                                        className={"smallMb"}
                                    />
                                    {/*<TextField*/}
                                    {/*   required*/}
                                    {/*   fullWidth*/}
                                    {/*   multiline*/}
                                    {/*   rows={4}*/}
                                    {/*   type="text"*/}
                                    {/*   variant="outlined"*/}
                                    {/*   label="Message"*/}
                                    {/*   name={'message'}*/}
                                    {/*   onChange={this.handleChange}*/}
                                    {/*   value={this.state.message}*/}
                                    {/*   className={'smallMb'}*/}

                                    {/*/>*/}

                                    <div className={classes.captcha}>
                                        {load && (
                                            <ReCAPTCHA
                                                style={{
                                                    display: "inline-block"
                                                }}
                                                ref={this._reCaptchaRef}
                                                sitekey={SELECTED_SITE_KEY}
                                                onChange={
                                                    this.handleCaptchaChange
                                                }
                                                className={"smallMb"}
                                                // asyncScriptOnLoad={this.asyncScriptOnLoad}
                                            />
                                        )}
                                    </div>

                                    <Button
                                        type={"submit"}
                                        variant="contained"
                                        className={classes.submitButton}
                                    >
                                        Submit
                                    </Button>
                                </form>
                            </div>

                            <Portal>
                                <Snackbar
                                    anchorOrigin={{
                                        vertical: "bottom",
                                        horizontal: "left"
                                    }}
                                    open={snackbarOpenFlag}
                                    autoHideDuration={6000}
                                    onClose={this.handleSnackbarClose()}
                                >
                                    <Alert
                                        onClose={this.handleSnackbarClose()}
                                        severity={alertSeverity}
                                    >
                                        {alertMessage}
                                    </Alert>
                                </Snackbar>
                            </Portal>
                        </ThemeProvider>
                    </CardContent>
                </Card>
            </Modal>
        );
    }
}

export default withWidth()(withStyles(styles)(BrochureForm));
