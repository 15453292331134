import { Link, NavLink, withRouter } from "react-router-dom";
import { useEffect } from 'react'

import AppBar from "@material-ui/core/AppBar";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import CssBaseline from "@material-ui/core/CssBaseline";
import Fab from "@material-ui/core/Fab";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import MoreIcon from "@material-ui/icons/MoreVert";
import NavbarStyles from "./NavbarStyles";
import Paper from "@material-ui/core/Paper";
import Popper from "@material-ui/core/Popper";
import React from "react";
import RoutePath from "../../lib/RoutePath";
import Toolbar from "@material-ui/core/Toolbar";
import Zoom from "@material-ui/core/Zoom";
import clsx from "clsx";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import withStyles from "@material-ui/core/styles/withStyles";

const styles = NavbarStyles;

function ScrollTop(props) {
    const { children, window } = props;
    const { classes } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 50
    });

    const handleClick = (event) => {
        const anchor = (event.target.ownerDocument || document).querySelector(
            "#back-to-top-anchor"
        );

        if (anchor) {
            anchor.scrollIntoView({ behavior: "smooth", block: "center" });
        }
    };

    return (
        // <Zoom in={trigger}>
        //   <div onClick={handleClick} role="presentation" className={classes.root}>
        //     {children}
        //   </div>
        // </Zoom>
        <div></div>
    );
}

function Navbar(props) {
    const { classes, window } = props;

    const trigger = useScrollTrigger({
        target: window ? window() : undefined,
        disableHysteresis: true,
        threshold: 50,
        classes: classes
    });

    let isAnyMenuOpen = () => {
        return !(
            isCompanyMenuOpen ||
            isIndustryMenuOpen ||
            isTechnologyMenuOpen
        );
    };

    // company menu related
    const [companyMenuAnchor, setCompanyMenuAnchor] = React.useState(null);
    const isCompanyMenuOpen = Boolean(companyMenuAnchor);

    const handleCompanyMenuOpen = (event) => {
        event.preventDefault();
        setCompanyMenuAnchor(event.currentTarget);
    };

    const handleCompanyMenuClose = (event) => {
        setCompanyMenuAnchor(null);
        isAnyMenuOpen() && handleMobileMenuClose();
    };

    const handleCompanyLinkClick = () => {
        setCompanyMenuAnchor(null);
        handleMobileMenuClose();
    };

    // industry menu related
    const [industryMenuAnchor, setIndustryMenuAnchor] = React.useState(null);
    const isIndustryMenuOpen = Boolean(industryMenuAnchor);

    const handleIndustryMenuOpen = (event) => {
        event.preventDefault();
        setIndustryMenuAnchor(event.currentTarget);
    };

    const handleIndustryMenuClose = (event) => {
        setIndustryMenuAnchor(null);
        isAnyMenuOpen() && handleMobileMenuClose();
    };

    const handleIndustryLinkClick = () => {
        setIndustryMenuAnchor(null);
        handleMobileMenuClose();
    };

    // technology menu related
    const [technologyMenuAnchor, setTechnologyMenuAnchor] =
        React.useState(null);
    const isTechnologyMenuOpen = Boolean(technologyMenuAnchor);

    const handleTechnologyMenuOpen = (event) => {
        event.preventDefault();
        setTechnologyMenuAnchor(event.currentTarget);
    };

    const handleTechnologyMenuClose = (event) => {
        setTechnologyMenuAnchor(null);
        isAnyMenuOpen() && handleMobileMenuClose();
    };

    const handleTechnologyLinkClick = () => {
        setTechnologyMenuAnchor(null);
        handleMobileMenuClose();
    };

    const renderTechnologyMenu = (
        <>
            <Popper
                open={isTechnologyMenuOpen}
                id={"company-dropdown-menu"}
                anchorEl={technologyMenuAnchor}
                placement={"bottom"}
                // role={undefined}
                className={classes.dropdownMenuContainer}
            >
                <Paper className={classes.dropdownMenu}>
                    <ClickAwayListener onClickAway={handleTechnologyMenuClose}>
                        <MenuList>
                            <MenuItem
                                onClick={handleTechnologyLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <Link
                                    className={classes.dropdownMenuLink}
                                    to={RoutePath.awirosOS}
                                >
                                    Awiros OS
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={handleTechnologyLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <Link
                                    className={classes.dropdownMenuLink}
                                    to={RoutePath.appStack}
                                >
                                    App Stack
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={handleTechnologyLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <Link
                                    className={classes.dropdownMenuLink}
                                    to={RoutePath.developers}
                                >
                                    Developers
                                </Link>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );

    const renderCompanyMenu = (
        <>
            <Popper
                open={isCompanyMenuOpen}
                id={"company-dropdown-menu"}
                anchorEl={companyMenuAnchor}
                placement={"bottom"}
                // role={undefined}
                className={classes.dropdownMenuContainer}
            >
                <Paper className={classes.dropdownMenu}>
                    <ClickAwayListener onClickAway={handleCompanyMenuClose}>
                        <MenuList>
                            <MenuItem
                                onClick={handleCompanyLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <Link
                                    className={classes.dropdownMenuLink}
                                    to={RoutePath.aboutUs}
                                >
                                    About Us
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={handleCompanyLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <a href="/careers" className={classes.dropdownMenuLink}>
                                    Careers
                                </a>
                                {/* <Link
                                    className={classes.dropdownMenuLink}
                                    to={RoutePath.newContactUs}
                                >
                                    Contact Us
                                </Link> */}
                            </MenuItem>
                            <MenuItem
                                onClick={handleCompanyLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <Link
                                    className={classes.dropdownMenuLink}
                                    to={RoutePath.contactUs}
                                >
                                    Contact Us
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={handleCompanyLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <Link
                                    className={classes.dropdownMenuLink}
                                    to={RoutePath.support}
                                >
                                    Support
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={handleCompanyLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <a
                                    className={classes.dropdownMenuLink}
                                    href={RoutePath.blog}
                                >
                                    Blog
                                </a>
                            </MenuItem>
                            {/* <MenuItem
                                onClick={handleCompanyLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <a
                                    className={classes.dropdownMenuLink}
                                    target="_blank"
                                    href={RoutePath.careers}
                                >
                                    Join Us
                                </a>
                            </MenuItem> */}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );

    const renderIndustryMenu = (
        <>
            <Popper
                open={isIndustryMenuOpen}
                id={"company-dropdown-menu"}
                anchorEl={industryMenuAnchor}
                placement={"bottom"}
                // role={undefined}
                className={classes.dropdownMenuContainer}
            >
                <Paper className={classes.dropdownMenu}>
                    <ClickAwayListener onClickAway={handleIndustryMenuClose}>
                        <MenuList>
                            <MenuItem
                                onClick={handleIndustryLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <Link
                                    className={classes.dropdownMenuLink}
                                    to={RoutePath.industriesMatching}
                                >
                                    Smart Cities
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={handleIndustryLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <Link
                                    className={classes.dropdownMenuLink}
                                    to={RoutePath.industriesPageTwo}
                                >
                                    Warehouses
                                </Link>
                            </MenuItem>
                            <MenuItem
                                onClick={handleIndustryLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <Link
                                    className={classes.dropdownMenuLink}
                                    to={RoutePath.industriesPageThree}
                                >
                                    Manufacturing
                                </Link>
                            </MenuItem>
                            {/* <MenuItem
                onClick={handleIndustryLinkClick}
                className={classes.dropdownMenuItem}
              >
                <Link
                  className={classes.dropdownMenuLink}
                  to={RoutePath.industriesPageFour}
                >
                  Smart Campuses
                </Link>
              </MenuItem> */}
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );

    const [collateralsMenuAnchor, setCollateralsMenuAnchor] =
        React.useState(null);
    const isCollateralsMenuOpen = Boolean(collateralsMenuAnchor);

    const handleCollateralsMenuOpen = (event) => {
        event.preventDefault();
        setCollateralsMenuAnchor(event.currentTarget);
    };

    const handleCollateralsMenuClose = (event) => {
        setCollateralsMenuAnchor(null);
        isAnyMenuOpen() && handleMobileMenuClose();
    };

    const handleCollateralsLinkClick = () => {
        setCollateralsMenuAnchor(null);
        handleMobileMenuClose();
    };

    const renderCollateralsMenu = (
        <>
            <Popper
                open={isCollateralsMenuOpen}
                id={"collaterals-dropdown-menu"}
                anchorEl={collateralsMenuAnchor}
                placement={"bottom"}
                // role={undefined}
                className={classes.dropdownMenuContainer}
            >
                <Paper className={classes.dropdownMenu}>
                    <ClickAwayListener onClickAway={handleCollateralsMenuClose}>
                        <MenuList>
                            <MenuItem
                                onClick={handleCollateralsLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <a
                                    target="_blank"
                                    className={classes.dropdownMenuLink}
                                    href={RoutePath.awirosOS_DataSheetPath}
                                >
                                    Awiros OS Datasheet
                                </a>
                            </MenuItem>
                            <MenuItem
                                onClick={handleCollateralsLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <a
                                    target="_blank"
                                    className={classes.dropdownMenuLink}
                                    href={RoutePath.appstackOfferingsPath}
                                >
                                    Awiros Appstack Offerings
                                </a>
                            </MenuItem>
                            <MenuItem
                                onClick={handleCollateralsLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <a
                                    target="_blank"
                                    className={classes.dropdownMenuLink}
                                    href={RoutePath.ANPR_SpecDocPath}
                                >
                                    Awiros ANPR
                                </a>
                            </MenuItem>
                            <MenuItem
                                onClick={handleCollateralsLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <a
                                    target="_blank"
                                    className={classes.dropdownMenuLink}
                                    href={RoutePath.FRS_SpecDocPath}
                                >
                                    Awiros FRS
                                </a>
                            </MenuItem>
                            <MenuItem
                                onClick={handleCollateralsLinkClick}
                                className={classes.dropdownMenuItem}
                            >
                                <a
                                    target="_blank"
                                    className={classes.dropdownMenuLink}
                                    href={RoutePath.AI_ITMS_Path}
                                >
                                    Awiros AI-based ITMS
                                </a>
                            </MenuItem>
                        </MenuList>
                    </ClickAwayListener>
                </Paper>
            </Popper>
        </>
    );

    const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

    const handleMobileMenuClose = () => {
        setMobileMoreAnchorEl(null);
    };

    const handleMobileMenuOpen = (event) => {
        setMobileMoreAnchorEl(event.currentTarget);
    };

    const renderMobileMenu = (
        <Menu
            className={classes.mobileMenuContainer}
            anchorEl={mobileMoreAnchorEl}
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            id={"mobile-menu"}
            keepMounted
            transformOrigin={{ vertical: "top", horizontal: "right" }}
            open={isMobileMenuOpen}
            onClose={handleMobileMenuClose}
        >
            <NavLink
                exact
                activeClassName={classes.mobileActiveLink}
                to={RoutePath.homePath}
                className={classes.mobileButtons}
                onClick={handleMobileMenuClose}
            >
                Home
            </NavLink>

            {/* <NavLink
        activeClassName={classes.mobileActiveLink}
        to={RoutePath.awirosOS}
        className={classes.mobileButtons}
        onClick={handleMobileMenuClose}
      >
        Awiros OS
      </NavLink>

      <NavLink
        activeClassName={classes.mobileActiveLink}
        to={RoutePath.appStack}
        className={classes.mobileButtons}
        onClick={handleMobileMenuClose}
      >
        AppStack
      </NavLink>

      <NavLink
        activeClassName={classes.mobileActiveLink}
        to={RoutePath.developers}
        className={classes.mobileButtons}
        onClick={handleMobileMenuClose}
      >
        Developers
      </NavLink> */}
            <NavLink
                activeClassName={classes.mobileActiveLink}
                to={RoutePath.awirosOS}
                className={classes.mobileButtons}
                onClick={handleTechnologyMenuOpen}
            >
                Technology
            </NavLink>

            <NavLink
                activeClassName={classes.mobileActiveLink}
                to={RoutePath.products}
                className={classes.mobileButtons}
                onClick={handleMobileMenuClose}
            >
                Appliances
            </NavLink>

            {/*<NavLink*/}
            {/*   activeClassName={classes.mobileActiveLink}*/}
            {/*   to={RoutePath.industriesMatching}*/}
            {/*   className={classes.mobileButtons}*/}
            {/*   onClick={handleIndustryMenuOpen}*/}
            {/*>*/}
            {/*   Industries*/}
            {/*</NavLink>*/}

            <NavLink
                activeClassName={classes.mobileActiveLink}
                to={RoutePath.industriesMatching}
                className={classes.mobileButtons}
                onClick={handleIndustryMenuOpen}
            >
                Industries
            </NavLink>

            <NavLink
                activeClassName={classes.mobileActiveLink}
                to={RoutePath.resourcesPage}
                className={classes.mobileButtons}
                onClick={handleMobileMenuClose}
            >
                Resources
            </NavLink>

            <NavLink
                activeClassName={classes.mobileActiveLink}
                to={RoutePath.aboutUs}
                className={classes.mobileButtons}
                onClick={handleCompanyMenuOpen}
            >
                Company
            </NavLink>

            <a
                href={RoutePath.dashboardPath}
                className={classes.mobilePortalButton}
                target="_blank"
                rel="noopener noreferrer"
            >
                Portal Login
            </a>
        </Menu>
    );

    return (
        <>
            <CssBaseline />
            <AppBar
                color={"default"}
                elevation={0}
                position={"fixed"}
                className={clsx(
                    classes.appBar,
                    !trigger ? classes.appBarExpanded : undefined
                )}
            >
                <Toolbar>
                    <img
                        src={"/assets/images/logo.svg"}
                        alt="Awiros Logo"
                        className={[classes.title]}
                    />
                    <div className={classes.grow} />

                    <div className={classes.sectionDesktop}>
                        <div className={classes.linkContainer}>
                            <NavLink
                                exact
                                activeClassName={classes.activeLink}
                                to={RoutePath.homePath}
                                className={classes.buttons}
                            >
                                Home
                            </NavLink>

                            {/* <NavLink
                activeClassName={classes.activeLink}
                to={RoutePath.awirosOS}
                className={classes.buttons}
              >
                Awiros OS
              </NavLink>

              <NavLink
                activeClassName={classes.activeLink}
                to={RoutePath.appStack}
                className={classes.buttons}
              >
                AppStack
              </NavLink>

              <NavLink
                activeClassName={classes.activeLink}
                to={RoutePath.developers}
                className={classes.buttons}
              >
                Developers
              </NavLink> */}
                            <NavLink
                                activeClassName={classes.activeLink}
                                to={RoutePath.aboutUs}
                                className={classes.buttons}
                                onClick={handleTechnologyMenuOpen}
                            >
                                Technology
                            </NavLink>

                            <NavLink
                                activeClassName={classes.activeLink}
                                to={RoutePath.products}
                                className={classes.buttons}
                            // onClick={handleProductsMenuOpen}
                            >
                                Appliances
                            </NavLink>

                            <NavLink
                                activeClassName={classes.activeLink}
                                to={RoutePath.industriesMatching}
                                className={classes.buttons}
                                onClick={handleIndustryMenuOpen}
                            >
                                Industries
                            </NavLink>

                            <NavLink
                                activeClassName={classes.activeLink}
                                to={RoutePath.resourcesPage}
                                className={classes.buttons}
                            // onClick={handleCollateralsMenuOpen}
                            >
                                Resources
                            </NavLink>

                            <NavLink
                                activeClassName={classes.activeLink}
                                to={RoutePath.aboutUs}
                                className={classes.buttons}
                                onClick={handleCompanyMenuOpen}
                            >
                                Company
                            </NavLink>

                            <a
                                href={RoutePath.dashboardPath}
                                className={classes.portalButton}
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                Portal Login
                            </a>
                        </div>
                    </div>

                    <div className={classes.sectionMobile}>
                        <IconButton
                            aria-label="show more"
                            aria-controls={"mobile-menu"}
                            aria-haspopup="true"
                            onClick={handleMobileMenuOpen}
                            color="inherit"
                        >
                            <MoreIcon />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            {renderTechnologyMenu}
            {renderCompanyMenu}
            {renderCollateralsMenu}
            {renderIndustryMenu}
            {renderMobileMenu}

            {/*<Toolbar id="back-to-top-anchor"/>*/}

            <div
                className={clsx(
                    classes.navbarContentContainer,
                    !trigger
                        ? classes.navbarExpandedContentContainer
                        : undefined
                )}
            >
                {props.children}
            </div>
            <ScrollTop {...props}>
                <Fab
                    color="default"
                    size="large"
                    aria-label="scroll back to top"
                    className={classes.scrollToTopButton}
                >
                    <KeyboardArrowUpIcon />
                </Fab>
            </ScrollTop>
        </>
    );
}

export default withRouter(withStyles(styles)(Navbar));
