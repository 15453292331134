import RoutePath from "../../../lib/RoutePath";
import Pulse from "./Pages/Pulse/Pulse";
import React from "react";

let ProductDescriptionConfig = {
   defaultRoute: RoutePath.pulse,
   [RoutePath.pulse]: {
      tabs: [
         {tabId: 'heading', tabName: 'Heading'},
         {tabId: 'description', tabName: 'Description'},
         {tabId: 'images', tabName: 'Images'},
         {tabId: 'details', tabName: 'Details'},
      ],
      content: (
         <Pulse/>
      )
   },
   [RoutePath.onePlus]: {
      tabs: [
         {tabId: 'heading', tabName: 'Heading'},
         {tabId: 'description', tabName: 'Description'},
         {tabId: 'images', tabName: 'Images'},
         {tabId: 'details', tabName: 'Details'},
      ],
      content: (
         <Pulse/>
      )
   },
   [RoutePath.enterprise]: {
      tabs: [
         {tabId: 'heading', tabName: 'Heading'},
         {tabId: 'description', tabName: 'Description'},
         {tabId: 'images', tabName: 'Images'},
         {tabId: 'details', tabName: 'Details'},
      ],
      content: (
         <Pulse/>
      )
   },

};

export default ProductDescriptionConfig;