import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section5Styles from "./Section5Styles";
import clsx from "clsx";

let styles = Section5Styles;

class Section5 extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <h2 className={"h2heading center"}>
                    Awiros helps make Video AI Apps enterprise-grade, quicker
                    and cheaper
                </h2>
                <h3 className={clsx("h3heading center", classes.subheading)}>
                    The developer only writes and pushes the core algorithm,
                    remaining modules requiring diverse skillsets of more than
                    90% complexity is handled by Awiros
                </h3>
                <div className={classes.headerImage}>
                    <img
                        src={"/assets/images/resource/dev-page-1.png"}
                        className={classes.headerImage}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Section5);
