import StyleConfig from "../../../lib/StyleConfig";

let ResourcesPageStyles = {
  card: {
    width: "350px",
    borderRadius: "4px",
    padding: "10px 0px 10px 0px",
    "@media (max-width: 1280px)": {
      width: "350px",
    },
    "@media (max-width: 600px)": {
      width: "100%",
    },
  },
  link: {
    textDecoration: "none",
  },
  cardTitle: {
    fontSize: 13,
    fontWeight: 400,
    textAlign: "start",
    fontColor: StyleConfig.videoLabels,
    padding: "0px 0px 0px 15px",
    color: "black",
  },
};

export default ResourcesPageStyles;
