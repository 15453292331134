import StyleConfig from "../../../../../lib/StyleConfig";

let Section3CardStyles = {
   root: {
      // minHeight: 260,
      // width:'100%',
      // height:'100%',
      border: '1px solid',
      borderColor: StyleConfig.mainThemeColor,
      padding: 20,
   },
   appName: {
      fontSize: 18,
      fontWeight: 500,
      color: StyleConfig.headingColor
   },
   textContent: {
      fontSize: 14,
      fontWeight: 400,
      textAlign: 'left'
   },
   appIcon: {
      width: '100%',
   }
};

export default Section3CardStyles;