import React, { Component } from "react";
import Section2Styles from "./Section2Styles";
import withStyles from "@material-ui/core/styles/withStyles";

let styles = Section2Styles;

class Section2 extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <p className={classes.heading}>
                    Video + AI + Operating System = Awiros Video Intelligence
                </p>
                <div>
                    <p className={classes.textContent}>
                        Video is the most pervasive content type today. It forms
                        more than 80% of the entire internet traffic.
                    </p>
                    <p className={classes.textContent}>
                        Awiros' Video Intelligence is the ideal mechanism to
                        leverage state-of-the-art Deep Learning Frameworks right
                        from training to development to deployment at scale.
                        Awiros lets you leverage the science of Computer Vision
                        and Artificial Intelligence to analyze and interpret
                        videos, converting them into actionable data for various
                        use cases.
                    </p>
                </div>
                <img
                    src={"../../../../assets/images/resource/vi.png"}
                    alt="Awiros Logo"
                    className={classes.largeImage}
                />
            </div>
        );
    }
}

export default withStyles(styles)(Section2);
