let CommonStyling = {
   root: {
      padding: 0
   },
   heading: {
      fontSize: 36,
      fontWeight: 400,
      margin: 0,
   },
   subtitle: {
      fontSize: 20,
      fontWeight: 400,
      margin: 0,
   },
   textContent: {
      fontSize: 18,
      fontWeight: 300,
      textAlign: 'justify'
   },
   thumbnailImage: {
      width: '100%'
   },
   listHeading: {
      fontSize: 18,
      fontWeight: 400
   },
   listTextContainer: {
      fontSize: 16,
      fontWeight: 100,
      '& li': {
         lineHeight: '1.6em'
      }
   }
};
;

export default CommonStyling;