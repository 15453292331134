import React, { Component } from "react";

import AppCard from "./AppCard/AppCard";
import AppStackService from "../../../../../../services/AppStackService";
import AppStackStyles from "./AppStackStyles";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import ListItemText from "@material-ui/core/ListItemText";
import MenuItem from "@material-ui/core/MenuItem";
import PrimaryTheme from "../../../../../../util/PrimaryTheme";
import RoutePath from "../../../../../../lib/RoutePath";
import Select from "@material-ui/core/Select";
import StyleConfig from "../../../../../../lib/StyleConfig";
import { ThemeProvider } from "@material-ui/styles";
import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import { toast } from "material-react-toastify";
import withStyles from "@material-ui/core/styles/withStyles";
import withWidth from "@material-ui/core/withWidth";

const styles = AppStackStyles;

const MainThemeCheckbox = withStyles({
    root: {
        "&$checked": {
            color: StyleConfig.mainThemeColor
        }
    },
    checked: {}
})((props) => <Checkbox color="default" {...props} />);

class AppStack extends Component {
    constructor(props) {
        super(props);
        this.state = {
            appList: [],
            targetIndustriesList: [],
            selectedTargetIndustries: [],
            processorList: [
                {
                    id: 0,
                    name: "CPU",
                    nameText: "x86 CPU",
                    selectElement: (
                        <div className={props.classes.menuItem}>
                            <img
                                src={
                                    "/assets/images/icons/hardware-platforms/CPU.png"
                                }
                                alt="CPU icon"
                                className={props.classes.dropdownIcons}
                            />
                            &nbsp; &nbsp;x86 Processors
                        </div>
                    )
                },

                {
                    id: 1,
                    name: "GPU",
                    nameText: "GPU",
                    selectElement: (
                        <div className={props.classes.menuItem}>
                            <img
                                src={
                                    "/assets/images/icons/hardware-platforms/nvidia-gpu.png"
                                }
                                alt="GPU icon"
                                className={props.classes.dropdownIcons}
                            />
                            &nbsp; &nbsp;NVIDIA GPUs
                        </div>
                    )
                },
                {
                    id: 2,
                    name: "VPU",
                    nameText: "Intel Movidius VPU",
                    selectElement: (
                        <div className={props.classes.menuItem}>
                            <img
                                src={
                                    "/assets/images/icons/hardware-platforms/movidius.png"
                                }
                                alt="Movidius icon"
                                className={props.classes.dropdownIcons}
                            />
                            &nbsp; &nbsp;Intel Movidius VPUs
                        </div>
                    )
                },
                {
                    id: 3,
                    name: "i-GPU",
                    nameText: "Integrated GPU(i-GPU)",
                    selectElement: (
                        <div className={props.classes.menuItem}>
                            <img
                                src={
                                    "/assets/images/icons/hardware-platforms/igpu.png"
                                }
                                alt="i-GPU icon"
                                className={props.classes.dropdownIcons}
                            />
                            &nbsp; &nbsp;Integrated GPUs (i-GPU)
                        </div>
                    )
                }
            ],
            selectedProcessors: []
        };
    }

    componentDidMount = async () => {
        try {
            await this.getGridHeaders();
            await this.getListOfTargetIndustries();
        } catch (e) {
            console.log(e);
        }
    };

    getGridHeaders = async () => {
        let response = await AppStackService.getGridHeaders();
        if (response.success) {
            this.setState({ appList: response.data });
        }
    };

    getListOfTargetIndustries = async () => {
        let response = await AppStackService.getListOfTargetIndustries();
        if (response.success) {
            this.setState({ targetIndustriesList: response.data });
        }
    };

    handleMultiselectChange = (e) => {
        this.setState({ [e.target.name]: e.target.value }, async () => {
            await this.getFilterAwirosAppStack(this.createFilterData());
        });
    };

    createFilterData = () => {
        let selectedTargetIndustriesData =
            this.state.selectedTargetIndustries.map((targetIndustries) => {
                return { "industries.id": parseInt(targetIndustries) };
            });
        let selectedProcessorsData = this.state.selectedProcessors.map(
            (processorId) => {
                return {
                    processor: this.state.processorList[processorId].name
                };
            }
        );
        // let selectedAppStackDevelopersData = this.state.selectedAppStackDevelopers.map(developerId => {
        //    return {awi_developer: this.state.appStackDeveloperList[developerId].name};
        // });
        let data = [
            selectedTargetIndustriesData,
            // selectedAppStackDevelopersData,
            selectedProcessorsData
        ];
        return {
            app_stack_filters: data
        };
    };

    getFilterAwirosAppStack = async (data) => {
        let response = await AppStackService.getFilterAwirosAppStack(data);
        if (response.success) {
            this.setState({ appList: response.data });
        } else {
            toast.error(response.message);
        }
    };

    handleClick = (url) => () => {
        window.open(url, "_blank");
    };

    render() {
        let { classes, scrolledFlag, width } = this.props;
        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction={"row"}
                    alignItems={"center"}
                    justify={
                        width === "xs" || width === "sm"
                            ? "center"
                            : "space-between"
                    }
                    className={classes.topBarContainer}
                    style={
                        scrolledFlag
                            ? { borderBottom: "1px solid lightgrey" }
                            : null
                    }
                    spacing={width === "xs" || width === "sm" ? 3 : undefined}
                >
                    <ThemeProvider theme={PrimaryTheme}>
                        <Grid
                            container
                            direction={"row"}
                            spacing={3}
                            item
                            xs={12}
                            md={7}
                        >
                            <Grid item xs={12} md={6}>
                                <FormControl
                                    variant="outlined"
                                    size={"small"}
                                    fullWidth
                                >
                                    <InputLabel id="xAxisDropdown">
                                        Select Target Industries
                                    </InputLabel>
                                    <Select
                                        multiple
                                        fullWidth
                                        labelId="targetIndustries"
                                        id="targetIndustries"
                                        value={
                                            this.state.selectedTargetIndustries
                                        }
                                        name={"selectedTargetIndustries"}
                                        onChange={this.handleMultiselectChange}
                                        label="Select Target Industries"
                                        renderValue={(selected) => {
                                            let names = selected.map(
                                                (selectedId) => {
                                                    return this.state
                                                        .targetIndustriesList[
                                                        this.state.targetIndustriesList.findIndex(
                                                            (
                                                                targetIndustry
                                                            ) => {
                                                                return (
                                                                    targetIndustry.id ===
                                                                    selectedId
                                                                );
                                                            }
                                                        )
                                                    ].awi_name;
                                                }
                                            );
                                            return names.join(", ");
                                        }}
                                    >
                                        {this.state.targetIndustriesList.map(
                                            (industry, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={industry.id}
                                                    >
                                                        <MainThemeCheckbox
                                                            checked={
                                                                this.state.selectedTargetIndustries.indexOf(
                                                                    industry.id
                                                                ) > -1
                                                            }
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                industry.awi_name
                                                            }
                                                        />
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={6}>
                                <FormControl
                                    variant="outlined"
                                    fullWidth
                                    size={"small"}
                                    className={classes.formControl}
                                >
                                    <InputLabel id="xAxisDropdown">
                                        Select Hardware Platforms
                                    </InputLabel>
                                    <Select
                                        multiple
                                        fullWidth
                                        labelId="processor"
                                        id="processor"
                                        value={this.state.selectedProcessors}
                                        name={"selectedProcessors"}
                                        onChange={this.handleMultiselectChange}
                                        label="Select Hardware Platforms"
                                        renderValue={(selected) => {
                                            let names = selected.map(
                                                (selectedId) => {
                                                    return this.state
                                                        .processorList[
                                                        this.state.processorList.findIndex(
                                                            (processor) => {
                                                                return (
                                                                    processor.id ===
                                                                    selectedId
                                                                );
                                                            }
                                                        )
                                                    ].nameText;
                                                }
                                            );
                                            return names.join(", ");
                                        }}
                                    >
                                        {this.state.processorList.map(
                                            (processor, index) => {
                                                return (
                                                    <MenuItem
                                                        key={index}
                                                        value={processor.id}
                                                    >
                                                        <MainThemeCheckbox
                                                            checked={
                                                                this.state.selectedProcessors.indexOf(
                                                                    processor.id
                                                                ) > -1
                                                            }
                                                        />
                                                        <ListItemText
                                                            primary={
                                                                processor.selectElement
                                                            }
                                                        />
                                                    </MenuItem>
                                                );
                                            }
                                        )}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </ThemeProvider>

                    <Grid item>
                        {/*<Button variant={'outlined'} onClick={this.handleClick('https://www.google.com')} className={'classes.guidedTourButton}>*/}
                        <Button
                            variant={"outlined"}
                            onClick={this.handleClick(RoutePath.dashboardPath)}
                            className={classes.guidedTourButton}
                        >
                            Take a Tour
                        </Button>
                    </Grid>
                </Grid>

                <div className={classes.appListContainer}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={1}
                        className={classes.appList}
                    >
                        {this.state.appList.map((app, index) => {
                            return (
                                <Grid item key={index} xs={12} sm={6} md={4}>
                                    <AppCard
                                        cardData={app}
                                        switchScreen={this.props.switchScreen}
                                    />
                                </Grid>
                            );
                        })}
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withWidth()(withStyles(styles)(AppStack));
