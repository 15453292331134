import StyleConfig from "../../../lib/StyleConfig";

let ProductDescriptionPageStyles = {
   root: {
      margin: '0 auto',
      maxWidth: 1200,
      padding: '0 20px 50px',
      flex: 1,
      // width:'100vw',
      backgroundColor: StyleConfig.backgroundColor,
      '@media (min-width: 960px)': {
         padding: '0 20px 50px',
         paddingTop: 25
      },
      '@media (min-width: 990px)': {
         padding: '0 10px 50px',
         paddingTop: 25
      },
   },
   navButtonsContainer: {
      position: 'sticky',
      top: 110
   },
   navButton: {
      borderRadius: 5,
      justifyContent: 'unset',
      padding: '13px 25px',
      width: '100%',
      backgroundColor: StyleConfig.notSelectedTabColor,
      '&:hover': {
         backgroundColor: StyleConfig.mainThemeColor,
         color: StyleConfig.invertedSecondaryThemeColor
      },
   },
   navButtonLabel: {
      lineHeight: '1.5em', /* Sets line height to 1.5 times text size */
      height: '1.5em', /* Sets the div height to 2x line-height (3 times text size) */
      lineClamp: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'box',
      boxOrient: 'vertical',

      fontSize: 18,
      fontWeight: 400,
      textTransform: 'capitalize',
      textAlign: 'left',
      alignItems: 'flex-start'
   },
   selectedNavButton: {
      backgroundColor: StyleConfig.mainThemeColor,
      color: StyleConfig.invertedSecondaryThemeColor,
   },

   contentContainer: {
      scrollMarginTop: '13vh'
   }
};

export default ProductDescriptionPageStyles;