import React, { Component } from 'react';

import { Grid } from '@material-ui/core';
import ResourceCard from '../ResourceCard/ResourceCard';
import ResourcePageVideosConfig from './ResourcePageVideosConfig';
import ResourcesPageVideosSectionStyles from './ResourcesPageVideosSectionStyles';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = ResourcesPageVideosSectionStyles;

class ResourcesPageVideosSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      videoList: ResourcePageVideosConfig,
    };
  }

  render() {
    let { classes } = this.props;
    let { videoList } = this.state;
    return (
      <div className={classes.root}>
        {videoList.length ? (
          <>
            <div className={'h2heading'}>Product Videos</div>
            <Grid container direction="row" spacing={3}>
              {videoList.map((videoData,index) => {
                return (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <ResourceCard cardData={videoData} />
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}
      </div>
    );
  }
}

export default withStyles(styles)(ResourcesPageVideosSection);
