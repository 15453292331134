import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import NewsCarouselCardStyles from "./NewsCarouselCardStyles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Grid from "@material-ui/core/Grid";

let styles = NewsCarouselCardStyles;

class NewsCarouselCard extends Component {
   static defaultProps = {
      title: '',
      icon: '',
      content: '',
      heading: '',
      link: ''
   };

   handleClick = (e) => {
      e.preventDefault();
      // this.props.carouselStore.setStoreState({isPlaying: true});
      if (this.props.cardData.link) {
         window.open(this.props.cardData.link, '_blank');
      }
   }

   render() {
      let {classes, cardData} = this.props;

      return (
         <Card className={classes.root}>
            <CardActionArea className={classes.card} onClick={this.handleClick}>
               <Grid container direction={'row'} justify={"space-around"} spacing={2}>
                  <Grid item md={7} sm={7} xs={12}>
                     <img src={cardData.thumbnail} alt="thumbnail" className={classes.thumbnailImage}/>
                  </Grid>

                  <Grid item md={5} sm={5} xs={12} container direction={'column'} justify={'center'} spacing={2}>
                     <Grid item className={classes.h3heading}>
                        {cardData.heading}
                     </Grid>
                     <Grid item className={classes.textContent}>
                        {cardData.subheading}
                     </Grid>
                  </Grid>
               </Grid>
            </CardActionArea>
         </Card>
      );
   }
}

export default withStyles(styles)(NewsCarouselCard);
