import StyleConfig from "../../../lib/StyleConfig";

let TestimonialsStyles = {
   headingContainer: {
      marginBottom: 20,
   },
   heading: {
      color: StyleConfig.headingColor,
      textAlign: 'center',
      fontSize: 30,
      fontWeight: 300,
   },
};

export default TestimonialsStyles;