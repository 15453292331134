import StyleConfig from "../../../../../lib/StyleConfig";

let ProductCardStyles = {
   root: {
      marginBottom: 50
   },
   heading: {
      fontSize: 30,
      fontWeight: 400,
      color: StyleConfig.headingColor,
      '@media (max-width:600px)': {
         textAlign: 'center'
      }
   },
   subheading: {
      fontSize: 20,
      fontWeight: 400,
      color: StyleConfig.headingColor,
      '@media (max-width:600px)': {
         textAlign: 'center',
         marginTop: 10,
      }
   },
   body: {
      fontSize: 17,
      fontWeight: 300,
      textAlign: 'justify'
   },
   productImage: {
      width: "100%"
   },
   learnMoreButtonContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      '@media (max-width: 600px)': {
         justifyContent: 'center'
      }
   },
   learnMoreButton: {},
   listTextContainer: {
      fontSize: 16,
      fontWeight: 100,
      '& li': {
         lineHeight: '1.6em'
      }
   }
};

export default ProductCardStyles;
