import StyleConfig from "./lib/StyleConfig";

let AppStyles = {
  root: {
    backgroundColor: StyleConfig.backgroundColor,
    maxWidth: 1920,
    margin: "0 auto",
  },
  scrollToTop: {
    // display:"none",
    position: "fixed",
    bottom: "16px",
    right: "16px",
    height: "50px",
    width: "50px",
    borderRadius: "50%",
    cursor: "pointer",
    zIndex: "10",
    display: "none",
  },

  scrollToTopButton: {
    color: StyleConfig.mainThemeColor,
    backgroundColor: StyleConfig.secondaryThemeColor,
    "&:hover": {
      backgroundColor: StyleConfig.mainThemeColor,
      color: StyleConfig.invertedSecondaryThemeColor,
    },
    border: "none",
    zIndex:501,
  },
  "@global": {
    body: {
      backgroundColor: StyleConfig.backgroundColor,
    },
    ".bottom-dash": {
      position: "relative",
      // display: 'inline',
      marginBottom: 50,
      "&:after": {
        content: "''",
        position: "absolute",
        left: "calc(50% - 25px)",
        bottom: -12,
        display: "inline-block",
        width: "50px",
        height: "50px!important",
        borderBottom: `2px solid ${StyleConfig.mainThemeColor}`,
      },
    },
    ".bottom-dash-thick": {
      position: "relative",
      display: "inline",
      marginBottom: 50,
      "&:after": {
        content: "''",
        position: "absolute",
        left: "calc(50% - 25px)",
        bottom: -20,
        display: "inline-block",
        height: ".35em",
        width: "50px",
        borderBottom: `5px solid ${StyleConfig.mainThemeColor}`,
      },
    },
    ".bottom-left-dash": {
      position: "relative",
      display: "inline",
      marginBottom: 50,
      "&:after": {
        content: "''",
        position: "absolute",
        left: 0,
        bottom: -12,
        display: "inline-block",
        width: 100,
        height: 3,
        borderBottom: `3px solid ${StyleConfig.mainThemeColor}`,
      },
    },

    ".h2heading": {
      fontSize: 30,
      fontWeight: 300,
      color: StyleConfig.headingColor,
      marginBottom: 30,
    },

    ".mbZero": {
      marginBottom: 0,
    },

    ".h3heading": {
      fontSize: 20,
      fontWeight: 300,
      color: StyleConfig.headingColor,
    },

    ".textContent": {
      fontSize: 18,
      fontWeight: 300,
    },
    ".center": {
      textAlign: "center",
    },
    ".justify": {
      textAlign: "justify",
    },
    ".uppercase": {
      textTransform: "uppercase",
    },
    ".bold": {
      fontWeight: "bold",
    },
    ".fullWidth": {
      width: "100%",
    },

    ".smallMb": {
      marginBottom: 10,
    },

    ".mediumMb": {
      marginBottom: 30,
    },

    ".scrollMargin": {
      scrollMarginTop: "22vh",
    },
    ".textDecorationNone": {
      textDecoration: "none",
    },
    ".ml10": {
      marginLeft: 10,
    },
    ".ml40": {
      marginLeft: 40,
    },
  },
};

export default AppStyles;
