import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section5Styles from "./Section5Styles";
import TableContainer from "@material-ui/core/TableContainer";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import Paper from "@material-ui/core/Paper";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import RoutePath from "../../../../lib/RoutePath";
import {withRouter} from "react-router-dom";

let styles = Section5Styles;

function createData(models, subscription, perpetual, floating, payPerUse) {
   return {models, subscription, perpetual, floating, payPerUse};
}

let tick = <i className="fas fa-check"/>;

const rows = [
   createData('Billing', 'Monthly', 'Life Time', 'Time Bound', 'Metered'),

   createData('Frequency', 'Monthly / Quarterly', 'One Time', 'Yearly', 'Usage Based'),

   createData('Awiros OS', tick, tick, tick, tick),

   createData('Awiros Apps', tick, tick, tick, tick),

   createData('Awiros Appliance', 'NA / Customer Sourced', tick, tick, 'NA / Customer Sourced'),

   createData('Support', 'Remote Support', 'On Field / Remote', 'On Field / Remote', 'On Field / Remote'),
];

class Section5 extends Component {

   handleClick = targetRoute => () => {
      RoutePath.navigateTo(this.props, RoutePath.contactUs)
   }

   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <h2 className={'h2heading center'}>Awiros Pricing Models</h2>
            <div className={'textContent justify'}>

            </div>

            <div>
               <TableContainer component={Paper}>
                  <Table className={classes.table} aria-label="simple table">
                     <TableHead>
                        <TableRow>
                           <TableCell align="center"
                                      className={clsx(classes.column1HeaderCell, classes.columnHeaderCell)}>Models</TableCell>
                           <TableCell align="center"
                                      className={clsx(classes.column2HeaderCell, classes.columnHeaderCell)}>Subscription</TableCell>
                           <TableCell align="center"
                                      className={clsx(classes.column3HeaderCell, classes.columnHeaderCell)}>Perpetual</TableCell>
                           <TableCell align="center"
                                      className={clsx(classes.column4HeaderCell, classes.columnHeaderCell)}>
                              Floating - Time Based
                           </TableCell>
                           <TableCell align="center"
                                      className={clsx(classes.column5HeaderCell, classes.columnHeaderCell)}>Pay Per
                              Use</TableCell>
                        </TableRow>
                     </TableHead>
                     <TableBody>
                        {rows.map((row) => (
                           <TableRow key={row.models} className={classes.tableContentRow}>
                              <TableCell
                                 align="center" component="th" scope="row"
                                 className={clsx(classes.tableContentCell, classes.column1BodyCell, 'uppercase')}>
                                 {row.models}
                              </TableCell>
                              <TableCell
                                 align="center"
                                 className={clsx(classes.tableContentCell, classes.column2BodyCell)}>
                                 {row.subscription}
                              </TableCell>
                              <TableCell
                                 align="center"
                                 className={clsx(classes.tableContentCell, classes.column3BodyCell)}>
                                 {row.perpetual}
                              </TableCell>
                              <TableCell
                                 align="center"
                                 className={clsx(classes.tableContentCell, classes.column4BodyCell)}>
                                 {row.floating}
                              </TableCell>
                              <TableCell
                                 align="center"
                                 className={clsx(classes.tableContentCell, classes.column5BodyCell)}>
                                 {row.payPerUse}
                              </TableCell>
                           </TableRow>
                        ))}


                        <TableRow className={classes.tableContentRow}>
                           <TableCell
                              align="center"
                              className={clsx(classes.tableContentCell, classes.column1BodyCell, 'uppercase bold')}>
                              {'Pricing'}
                           </TableCell>

                           <TableCell
                              align="center"
                              className={clsx(classes.tableContentCell, classes.column2BodyCell)}
                           >
                              <Button
                                 variant="contained"
                                 className={classes.column2ContactButton}
                                 onClick={this.handleClick()}
                              >
                                 Contact
                              </Button>
                           </TableCell>

                           <TableCell
                              align="center"
                              className={clsx(classes.tableContentCell, classes.column3BodyCell)}
                           >
                              <Button
                                 variant="contained"
                                 className={classes.column3ContactButton}
                                 onClick={this.handleClick()}
                              >
                                 Contact
                              </Button>
                           </TableCell>

                           <TableCell
                              align="center"
                              className={clsx(classes.tableContentCell, classes.column4BodyCell)}
                           >
                              <Button
                                 variant="contained"
                                 className={classes.column4ContactButton}
                                 onClick={this.handleClick()}

                              >
                                 Contact
                              </Button>
                           </TableCell>

                           <TableCell
                              align="center"
                              className={clsx(classes.tableContentCell, classes.column5BodyCell)}
                           >
                              <Button
                                 variant="contained"
                                 className={classes.column5ContactButton}
                                 onClick={this.handleClick()}
                              >
                                 Contact
                              </Button>
                           </TableCell>
                        </TableRow>
                     </TableBody>
                  </Table>
               </TableContainer>
            </div>
         </div>
      );
   }
}

export default withRouter(withStyles(styles)(Section5));