import { makeStyles } from "@material-ui/styles";
import React from "react";
import "@splidejs/splide/dist/css/splide.min.css";
import { Link } from "react-router-dom";
import RoutePath from "../../lib/RoutePath";

const useStyles = makeStyles({
  banner: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    height: "100%",
    gap: "50px",
    justifyContent: "center",
    "@media (max-width: 1024px)": {
      gap: "30px",
    },
    "@media (max-width: 768px)": {
      gap: "10px",
    },
    "@media (max-width: 600px)": {
      flexDirection: "column-reverse",
    },
  },

  bannerContent: {
    padding: "20px",
    maxWidth: "500px",
    "@media (max-width: 1240px)": {
      maxWidth: "400px",
    },
    "@media (max-width: 1024px)": {
      maxWidth: "350px",
    },
    "@media (max-width: 600px)": {
      display: "flex",
      flexDirection: "column",
      textAlign: "center",
    },
    "@media (max-width: 500px)": {
      padding: "20px",
    },
  },
  bannerContentComvision: {
    fontSize: "56px",
    fontWeight: "700",
    marginBottom: "0px",
    "@media (max-width: 1240px)": {
      fontSize: "44px",
    },
    "@media (max-width: 1024px)": {
      fontSize: "32px",
    },
    "@media (max-width: 1024px)": {
      fontSize: "24px",
    },
    "@media (max-width: 600px)": {
      fontSize: "28px",
    },
  },
  bannerContentAppathon: {
    color: "#FFCC00",
    display: "flex",
    fontSize: "70px",
    alignItems: "center",
    fontWeight: "700",
    gap: "15px",
    "@media (max-width: 1240px)": {
      fontSize: "56px",
    },
    "@media (max-width: 1024px)": {
      fontSize: "44px",
    },
    "@media (max-width: 768px)": {
      fontSize: "32px",
    },
    "@media (max-width: 600px)": {
      display: "flex",
      justifyContent: "center",
      //   fontSize: "48px",
    },
  },

  bannerContentUnveil: {
    fontSize: "48px",
    fontWeight: "600",
    "@media (max-width: 1240px)": {
      fontSize: "36px",
    },
    "@media (max-width: 1024px)": {
      fontSize: "28px",
    },
    "@media (max-width: 1024px)": {
      fontSize: "24px",
    },
    "@media (max-width: 600px)": {
      fontSize: "28px",
    },
  },
  bannerDetails: {
    fontSize: "24px",
    fontWeight: "300",
    marginTop: "16px",
    "@media (max-width: 1240px)": {
      fontSize: "20px",
    },

    "@media (max-width: 1024px)": {
      fontSize: "14px",
    },
    "@media (max-width: 600px)": {
      fontSize: "16px",
      width: "315px",
      marginTop: "32px",
      marginBottom: "32px",
    },
    "@media (max-width: 500px)": {
      width: "300px",
    },
  },
  bannerContentButton: {
    backgroundColor: "#FFCC00",
    borderRadius: "94px",
    border: "none",
    width: "316px",
    cursor: "pointer",
    fontSize: "24px",
    marginTop: "40px",
    padding: "12px 24px",
    "@media (max-width: 1240px)": {
      width: "300px",
    },
    "@media (max-width: 1024px)": {
      width: "200px",
      borderRadius: "4px",
      padding: "8px 16px",
    },
    "@media (max-width: 768px)": {
      margin: "auto",
      marginTop: "24px",
      marginBottom: "40px",
      fontWeight: "400",
      fontSize: "16px",
      borderRadius: "48px",
    },
  },
  imageContainer: {},
  image: {
    width: "600px",
    "@media (max-width: 1240px)": {
      width: "500px",
    },
    "@media (max-width: 1024px)": {
      width: "350px",
    },
    "@media (max-width: 600px)": {
      width: "340px",
    },
    "@media (max-width: 500px)": {
      width: "320px",
    },
    "@media (max-width: 400px)": {
      width: "280px",
    },
  },
});
function AppathonBanner() {
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      <div className={classes.bannerContent}>
        <div className={classes.bannerContentComvision}>Computer Vision</div>
        <div className={classes.bannerContentAppathon}>APP-A-THON</div>
        <div className={classes.bannerContentUnveil}>Unveil The Unseen</div>
        <div className={classes.bannerDetails}>
          Participate in the 2nd Computer Vision App-a-thon organized by Awiros
          in collaboration with Intel®
        </div>
        <Link to={RoutePath.appathon}>
          <button className={classes.bannerContentButton}>Learn more</button>
        </Link>
      </div>
      <div className={classes.imageContainer}>
        <img
          className={classes.image}
          src="/assets/appathon/homeBannerImage.png"
        ></img>
      </div>
    </div>
  );
}

export default AppathonBanner;
