let GuidelineStyles = {
  form: {
    display: "flex",
    minHeight: "800px",
    marginTop: "2px",
    marginBottom: "2px",
  },
  formProgress: {
    backgroundColor: "#2C2C2C",
    color: "white",
    width: "500px",
    textAlign: "center",
    display: "flex",
    alignItems: "center",
    "@media (max-width: 1440px)": {
      display: "none",
    },
  },
  mobileViewHeading: {
    display: "none",
    fontSize: "48px",
    fontWeight: "600",
    color: "#FC0",

    "@media (max-width: 1440px)": {
      display: "block",
      fontSize: "32px",
    },
    "@media (max-width: 768px)": {
      textAlign: "center",
    },
  },
  formProgressContent: {
    display: "flex",
    flexDirection: "column",
    gap: "180px",
  },
  formProgressHeading: {
    fontSize: "48px",
    fontWeight: "600",
    color: "#FC0",
    display: "none",
    margin: "115px auto",
    "@media (max-width: 1440px)": {
      fontSize: "32px",
    },
  },

  oneStepContainer: {
    margin: "auto",
  },
  stepSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "24px",
    width: "350px",
  },
  stepContainer: {
    width: "180px",
    textAlign: "end",
    display: "flex",
    flexDirection: "column",
  },
  stepLabel: {
    fontSize: "32px",
    fontWeight: "500",
  },
  stepDescription: {
    fontSize: "18px",
    fontWeight: "500",
  },
  circle: {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: "1px solid white",
  },
  details: {
    margin: "auto 80px",
    width: "max-content",

    "@media (max-width: 1600px)": {
      margin: "40px auto",
    },
    "@media (max-width: 768px)": {
      width: "400px",
      padding: "10px",
    },
  },
  successfulTeamRegistrationMessage: {
    color: "#048C1A",
    fontSize: "16px",
  },
  captchaContainer: {
    marginTop: "20px",
    display: "flex",
    justifyContent: "center",
  },
  detailsHeading: {
    fontSize: "48px",
    fontWeight: "500",
    "@media (max-width: 1440px)": {
      fontSize: "32px",
    },
    "@media (max-width: 768px)": {
      textAlign: "center",
      fontSize: "32px",
    },
  },
  interestedStatement: {
    fontSize: "16px",
    "@media (max-width: 768px)": {
      textAlign: "center",
    },
  },
  detailsNote: {
    fontSize: "24px",
    marginBottom: "18px",
    "@media (max-width: 1440px)": {
      marginBottom: "24px",
      fontSize: "20px",
    },
    "@media (max-width: 768px)": {
      textAlign: "center",
      fontSize: "16px",
    },
  },
  detailsRow: {
    display: "flex",
    gap: "40px",
    marginBottom: "24px",
    "@media (max-width: 1440px)": {
      flexDirection: "column",
      marginBottom: "24px",
      gap: "20px",
    },
    "@media (max-width: 768px)": {
      alignItems: "center",
    },
  },
  inputField: {
    width: "300px",
    height: "40px",
    backgroundColor: "#DDDDDD",
    border: "none",
    padding: "10px",
    fontSize: "14px",
    "@media (max-width: 1440px)": {
      width: "500px",
    },
    "@media (max-width: 768px)": {
      width: "100%",
    },
  },
  interestedComponent: {
    display: "flex",
    gap: "20px",
    marginTop: "10px",
    "@media (max-width: 768px)": {
      justifyContent: "center",
    },
  },
  detailsContinueButton: {
    marginTop: "64px",
    width: "100%",
    backgroundColor: "#FFCC00",
    padding: "12px 16px",
    border: "none",
    fontSize: "24px",
    fontWeight: "800",
    "@media (max-width: 1440px)": {
      padding: "6px 12px",
      marginTop: "32px",
    },
    "@media (max-width: 768px)": {
      width: "350px",
      marginLeft: "auto",
      fontWeight: "500",
      fontSize: "20px",
    },
  },
  detailsContinueButtonContainer: {
    "@media (max-width: 768px)": {
      textAlign: "center",
    },
  },

  detailsActions: {
    display: "flex",
    justifyContent: "space-between",
    "@media (max-width: 768px)": {
      gap: "10px",
    },
  },
  detailsPreviousButton: {
    marginTop: "32px",
    width: "320px",
    padding: "10px 14px",
    border: "2px solid #FFCC00",
    cursor: "pointer",
    fontSize: "24px",
    fontWeight: "500",
    "@media (max-width: 1440px)": {
      width: "180px",
      marginTop: "44px",
      padding: "8px 12px",
    },
    "@media (max-width: 768px)": {
      width: "120px",
      fontSize: "16px",
    },
  },
  detailsSubmitButton: {
    marginTop: "32px",
    width: "320px",
    backgroundColor: "#FFCC00",
    padding: "12px 16px",
    border: "none",
    cursor: "pointer",
    fontSize: "24px",
    fontWeight: "500",
    "@media (max-width: 1440px)": {
      width: "180px",
      marginTop: "44px",
      padding: "8px 12px",
    },
    "@media (max-width: 768px)": {
      width: "120px",
      fontSize: "16px",
    },
  },
  secondStepContainer: {
    "@media (max-width: 768px)": {
      padding: "20px",
    },
  },
  teamInfo: {
    display: "flex",
    gap: "40px",
    marginBottom: "28px",
    justifyContent: "space-between",
    "@media (max-width: 1440px)": {
      flexDirection: "column",
      gap: "20px",
      marginBottom: "12px",
    },
  },
  teamInfoField: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  },
  detailsContactNote: {
    marginTop: "28px",
    textAlign: "center",
    fontSize: "16px",
    "@media (max-width: 1440px)": {
      marginTop: "20px",
    },
    "@media (max-width: 768px)": {
      maxWidth: "90%",
    },
  },
  tabSection: {
    display: "flex",
    gap: "12px",
    marginTop: "8px",
    "@media (max-width: 1440px)": {},
  },
  participantsDetails: {
    display: "flex",
    justifyContent: "space-between",
    gap: "48px",
  },
  policies: {
    fontWeight: "700",
    color: "black",
  },
  detailsVerticalRow: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "100%",
    "@media (max-width: 1440px)": {
      gap: "12px",
    },
    "@media (max-width: 768px)": {
      minWidth: "300px",
    },
  },
  detailsVerticalRowHeading: {
    marginBottom: "12px",
  },
};

export default GuidelineStyles;
