import StyleConfig from "../../lib/StyleConfig";

let DevelopersPageStyles = {
   root: {
      margin: '0 auto',
      maxWidth: 1200,
      padding: '0 20px 50px',
      flex: 1,
      // width:'100vw',
      backgroundColor: StyleConfig.backgroundColor,
      '@media (min-width: 960px)': {
         padding: '0 20px 50px'
      },
      '@media (min-width: 990px)': {
         padding: '0 10px 50px'
      },
   },
};

export default DevelopersPageStyles;