import StyleConfig from "../../../../../../lib/StyleConfig";

let IndustryPageCardStyles = {
   root: {
      padding: '0 12px',
      height: 110,
      // backgroundColor: StyleConfig.appStackCardColor
      border: '1px solid',
      borderColor: StyleConfig.mainThemeColor,
      '@media (max-width: 600px)': {
         minHeight: 180,
      },
   },
   appIcon: {
      width: 60,
      height: 60,
      // margin:'0 auto'
   },
   appName: {
      fontSize: 15,
      fontWeight: 500,
      '@media (max-width: 600px)': {
         textAlign: 'center',
         overflow: 'hidden',
         textOverflow: 'ellipsis'
      },
   },
   developerName: {
      fontSize: 12,
      '@media (max-width: 600px)': {
         textAlign: 'center'
      },
   },
   descriptionText: {
      fontSize: 13,
      lineClamp: 2,
      // whiteSpace: 'normal', /* Wrap lines of text */
      overflow: 'hidden', /* Hide text that goes beyond the boundaries of the div */
      textOverflow: 'ellipsis', /* Ellipses (cross-browser) */
      // '-o-text-overflow': 'ellipsis', /* Ellipses (cross-browser) */
      display: 'box',
      boxOrient: 'vertical',
      '@media (max-width: 600px)': {
         textAlign: 'center',
         display: 'none'
      },
   },
};

export default IndustryPageCardStyles;