import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section4CardStyles from "./Section4CardStyles";

let styles = Section4CardStyles;

class Section4Card extends Component {
   static defaultProps = {}

   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <img className={classes.thumbnailImage} src={this.props.thumbnailImage}
                 alt="cloud"/>
            <p>{this.props.children}</p>
            <ul>
               {this.props.dotPoints.map((point, index) => {
                  return <li key={index}>{point}</li>
               })}
            </ul>
         </div>
      );
   }
}

export default withStyles(styles)(Section4Card);