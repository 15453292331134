import StyleConfig from "../../../../lib/StyleConfig";

let Section1Styles = {
   root: {
      paddingTop: 30,
      display: 'flex',
      flexDirection: 'column',
      marginBottom: 40

   },
   heading: {
      color: StyleConfig.headingColor,
      fontSize: 30,
      fontWeight: 300,
      textAlign: 'center',
      marginBottom: 10,
   },
   subtitle: {
      color: 'black',
      fontWeight: 400,
      fontSize: 18,
      textAlign: 'center',
      marginBottom: 10,
   },
   textContent: {
      fontSize: 18,
      fontWeight: 300,
      textAlign: 'center',
      marginBottom: 10,
   },
   image: {
      width: '100%'
   },
   button: {
      margin: '10px auto',
      backgroundColor: StyleConfig.darkButtonBackground,
      '&:hover': {
         backgroundColor: StyleConfig.darkButtonBackgroundHover,
      }
   },
   dataSheetCardContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) !important',
      width: '50%',
      padding: 10,
      maxHeight: '95%',
      // overflowX:'auto',
      border: 0,
      outline: 0,
      overflowY: 'auto',
      '@media (max-width:960px)': {
         width: '75%',
      },
      '@media (max-width:700px)': {
         width: '95%',
      }

   },
};

export default Section1Styles;
