export const mainBannerData = [
  {
    line1: "Automate",
    line2: "Traffic Management",
    line3: "Eliminate manual operations",
    line4: "and enhance traffic control",
    line5: "with Awiros ITMS",
    backUrl: "/banner5/Traffic_management.jpg",
  },
  {
    line1: "Optimize",
    line2: "Transport Networks",
    line3: "Adpot Video AI to get",
    line4: "real-time traffic updates and",
    line5: "implement corrective actions",
    backUrl: "/banner5/transport_networks.jpg",
  },
  {
    line1: "Improve",
    line2: "Road Safety",
    line3: "Detect traffic violations",
    line4: "and generate instant alerts",
    line5: "with Video AI",
    backUrl: "/banner5/Road_safety.jpg",
  },
];

export const bannerData = {
  heading: "Transform Smart Campuses with Video AI",
  metricData: [
    { title: "Apps", count: "20" },
    { title: "People Impacted", count: "50000" },
    { title: "Facility Management Costs", count: "30" },
  ],
  subHeading:
    "Awiros with it's diverse marketplace and dynamic deployment offerings has become the go-to Video AI solution for smart campuses. Check out our tailored use-cases that can revolutionize your smart campus unit.",
  mobileViewSubHeading:
    "Check out our tailored use-cases that can revolutionize your smart campus unit.",
  trustedBy: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/dell.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/honeywell.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/cisco.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/siemens.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/nvidia.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/mercedez.png",
  ],
};

export const whyBetterData = [
  {
    icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps.png",
    video:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/1-diverse-marketplace.mp4",
    title: "Diverse Marketplace",
    description: "Offers 60+ Video AI apps on our Appstack",
  },
  {
    icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/dynamic-deployment.png",
    video:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/2-dynamic-deployment.mp4",
    title: "Dynamic Deployment",
    description:
      "Deploy Video AI apps at any scale with an unmatched level of flexibility",
  },
  {
    icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/hardware.png",
    video:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/5-hardware-agnostic.mp4",
    title: "Hardware Agnostic",
    description:
      "Awiros apps run smoothly without the need for specialized cameras or hardware",
  },
  {
    icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/scale.png",
    video:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/3-scale-on-demand.mp4",
    title: "Scale on Demand",
    description: "Scale up to thousands of cameras with ease",
  },
  {
    icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/real-time.png",
    video:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/4-real-time-alerts-and-data.mp4",
    title: "Real-time Data & Alerts",
    description:
      "Monitor and get instant alerts from multiple apps in real time",
  },
  {
    icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/easy-to-integrate.png",
    video:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/6-easy-to-integrate.mp4",
    title: "Easy to Integrate",
    description:
      "Quick & easy integration into existing systems by 3 different deployment architectures",
  },
];

export const appSelection = [
  {
    title: "Our top Apps for Smart Campuses",
    placeholder: "Search for Smart Campus apps",
    apps: [
      {
        name: "Automatic Number Plate Recognition",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Number Plate Recognition.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/licence-plate-single.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "ANPR",
          "Number Plate",
          "Vehicle",
          "Car",
          "Bike",
          "4 wheeler",
          "Registration",
        ],
        description:
          "Detect number plates without any specialised cameras, and manage traffic with ease",
      },
      {
        name: "Vehicle Counting & Classification",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Vehicle Counting.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/vehicle-count.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "vehicle count",
          "car count",
          "vehicle identification",
          "car details",
          "vehicle details",
        ],
        description:
          "Count vehicles of different types and identify attributes such as type, color and make",
      },
      {
        name: "Helmet Violation Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_helmet_violation.svg",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/helmet-violation.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "helmet violation",
          "2 wheeler",
          "bike",
          "motorcycle",
          "helmet detection",
        ],
        description:
          "Identify riders who violate helmet rules and generate an instant alert",
      },
      {
        name: "Wrong Way Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_helmet_violation.svg",
        imageUrl: "./assets/apps/Wrong_way.png",
        tags: [
          "wrong way",
          "wrong direction",
          "vehicles",
          "traffic",
          "smart traffic",
          "smart city",
        ],
        description:
          "Identify vehicles moving in the wrong direction and generate an alert",
      },
      {
        name: "Triple Riding",
        iconUrl: "./assets/app_logo/LP-4 logos/triple_riding.png",
        imageUrl: "./assets/apps/triple_riding.jpg",
        tags: [
          "Smart City",
          "Safe City",
          "triple",
          "motorbike",
          "2 wheeler",
          "scooter",
        ],
        description:
          "Generate alerts when triple riding is detected on  two wheelers",
      },
      {
        name: "Zebra Crossing Violation",
        iconUrl: "./assets/app_logo/LP-4 logos/triple_riding.png",
        imageUrl: "./assets/apps/Zebra_crossing.png",
        tags: [
          "smart city",
          "smart traffic",
          "red light",
          "traffic rules",
          "zebra",
          "crossing",
          "zebra crossing",
        ],
        description:
          "Identify the vehicles overstepping the zebra crossing at a red light",
      },
      {
        name: "Parking Violation",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Parking Violation.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/ParkingViolation.png",
        tags: [
          "Smart City",
          "Safe City",
          "no parking zone",
          "no parking",
          "wrong parking",
        ],
        description:
          "Generate alerts when vehicles are parked in no-parking zones",
      },
      {
        name: "Speed Violation Detection",
        iconUrl: "./assets/app_logo/LP-4 logos/speed_violation.png",
        imageUrl: "./assets/apps/speed_violation.png",
        tags: [
          "Speed violation",
          "detection",
          "smart city",
          "smart campus",
          "speed limit",
          "vehicle",
          "traffic",
          "e-challan",
          "transport",
          "safety",
        ],
        description: "Identify vehicles violating the speed limit.",
      },
      {
        name: "Red Light Violation Detection",
        iconUrl: "./assets/app_logo/LP-4 logos/speed_violation.png",
        imageUrl: "./assets/apps/speed_violation.png",
        tags: [
          "red light",
          "traffic",
          "traffic rules",
          "smart traffic",
          "smart city",
          "traffic management",
          "safety",
        ],
        description: "Get instant alerts for vehicles violating the red lights",
      },
      {
        name: "Pothole detection",
        iconUrl: "./assets/app_logo/LP-4 logos/speed_violation.png",
        imageUrl: "./assets/apps/pothole-detection.JPG",
        tags: ["Smart City", "Safe City", "hole in ground", "pothole detect"],
        description:
          "Detect potholes on roads and send alerts to the designated authority",
      },
      {
        name: "Garbage Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Garbage Detection.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/Garbage-labeled.jpg",
        tags: [
          "Smart City",
          "Safe City",
          "detect garbage",
          "garbage, littering",
        ],
        description:
          "Detect garbage in a premise and send alerts to the designated authority",
      },
      {
        name: "Animal detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/Animal-detection.JPG",
        imageUrl: "./assets/apps/Animal-detection.JPG",
        tags: ["Smart City", "Safe City", "detect animal", "stray animal"],
        description:
          "Detect animals on roads and other prohibited areas and generate alerts",
      },
    ],
  },
];

// Camera: Awiros Apps can be deployed on any standard ONVIF compliant source.
// AppStack: 60+ Video AI Apps available for a variety of industries
// Engyn: Proprietary Hardware Abstraction Library for scaling Video AI apps
// Awiros OS UI: A consolidated UI to view and manage all aspects of deployment at a single place.
// VMS:  Easily Integrate with any VMS platform to store and read video feeds instantly.
// CCC: A control and command centre can manage and view deployment across servers and sites seamlessly.
export const integrationData = [
  {
    name: "cloud",
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/cloud.png",
    hoverCoordinates: [
      {
        x: 9.16,
        y: 62.25,
        text: "A consolidated UI to view and manage all aspects of deployment at a single place",
      },
      {
        x: 16.67,
        y: 14.03,
        text: "Proprietary Hardware Abstraction Library for scaling Video AI apps",
      },
      {
        x: 43.14,
        y: 11.65,
        text: "60+ Video AI Apps available for a variety of industries",
      },
      // { x: 59.10, y: 15.04, text: "near Public/ Private Cloud" },
      {
        x: 73.81,
        y: 71.79,
        text: "Awiros Apps can be deployed on any standard ONVIF compliant source",
      },
    ],
  },
  {
    name: "premise",
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/on-premise.png",
    hoverCoordinates: [
      {
        x: 4.8,
        y: 47.81,
        text: "Easily Integrate with any VMS platform to store and read video feeds instantly",
      },
      {
        x: 16.54,
        y: 72.05,
        text: "Manage and view deployment across servers and sites seamlessly",
      },
      {
        x: 27.7,
        y: 91.0,
        text: "A consolidated UI to view and manage all aspects of deployment at a single place",
      },
      {
        x: 36.25,
        y: 18.54,
        text: "Proprietary Hardware Abstraction Library for scaling Video AI apps",
      },
      // { x: 37.11, y: 36.42, text: "near on premise server" },
      {
        x: 61.26,
        y: 13.38,
        text: "60+ Video AI Apps available for a variety of industries",
      },
      {
        x: 82.22,
        y: 61.72,
        text: "Awiros Apps can be deployed on any standard ONVIF compliant source",
      },
    ],
  },
  {
    name: "edge",
    imageUrl: "/assets/edge+central.png",
    hoverCoordinates: [
      {
        x: 0.07,
        y: 57.22,
        text: "Easily Integrate with any VMS platform to store and read video feeds instantly",
      },
      {
        x: 9.71,
        y: 81.19,
        text: "Manage and view deployment across servers and sites seamlessly",
      },
      {
        x: 19.37,
        y: 90.63,
        text: "A consolidated UI to view and manage all aspects of deployment at a single place",
      },
      {
        x: 16.67,
        y: 16.29,
        text: "Proprietary Hardware Abstraction Library for scaling Video AI apps",
      },
      {
        x: 46.79,
        y: 5.96,
        text: "60+ Video AI Apps available for a variety of industries",
      },
      // { x: 72.77, y: 74.17, text: "near Awiros Edge Server" },
      // { x: 92.89, y: 83.04, text: "near on Site n" },
    ],
  },
];

export const ourSuccessStoriesData = [
  {
    imageUrl: "/assets/industries/carousel1.svg",
  },
  {
    imageUrl: "/assets/industries/carousel2.svg",
  },
];

export const customers1 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/20cube.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bareily-smart-city.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bengaluru-safe-city.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/biocon.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/upl.png",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dr-reddy-labs.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dxc-tech.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/govt-of-telangana.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/hindustan-petroleum.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/wani.png",
  ],
};

export const customers2 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/indiabulls.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Jaipur.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/mercedez.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrb.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/yotta.png",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrtc.png",
    // "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/raytheon-tech.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/sicm.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/thane-smart-city.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/maharashtra.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Kdisc.jpeg",
  ],
};

export const partners1 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Cisco.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Dell.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Genetec.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Hitachi.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Honeywell.png",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Intel.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Milestone.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Nvidia.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Qognify.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Trinity.png",
  ],
};

export const partners2 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/AGS Transact Technologies.jpg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Amnex.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Hewlett Packard Enterprsie.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Honeywell.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/ICD Technologies.jpeg",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Johnsons Control.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/L&T.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/NEC.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/Xilinx.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/QualcommLogo.svg",
  ],
};
