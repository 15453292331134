import APIServices from './serviceHelpers/APIServices';
import { getHost } from '../lib/Constants';
import APIPath from '../lib/APIPath';
import GenUtil from '../util/GenUtil';

let tempLoginData = {
  email: 'integration@awiros.com',
  password: 'awiros-int-!',
};

class UserService {
  static async sendMessage(data) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.bookDemo}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: {
        'Content-Type': 'application/json',
      },
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    let sr = await APIServices.request(axiosConfig);
    return sr;
  }

  static async login(data = tempLoginData) {
    const axiosConfig = {
      url: `${getHost()}${APIPath.loginPath}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: GenUtil.getLoginHeaders(),
      cache: false,
      processData: false,
      data: data,
    };
    let sr = await APIServices.request(axiosConfig);
    if (sr.success) {
      GenUtil.setAccessToken(sr.data);
    }
    return sr;
  }
}

export default UserService;
