import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import GuidelineStyles from "./GuidelineStyles";
import { guidelines } from "../utils/Guidelines";

const Guideline = (props) => {
  const { classes } = props;

  return (
    <div
      className={classes.guidelineContainer}
      style={{
        background: `url('/assets/appathon/guidelinesBG.png')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#2C2C2C",
      }}
    >
      <div className={classes.guidelineHeading}>PARTICIPATION GUIDELINES</div>
      <div className={classes.guidelineContent}>
        {guidelines.map((guideline, index) => (
          <div className={classes.guideline} key={index}>
            <span className={classes.guidelineNumber}>{`0${index + 1}`}</span>
            <span className={classes.guidelineText}>{guideline}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(GuidelineStyles, { withTheme: true })(Guideline)
);
