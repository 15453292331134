import React, {useState} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";
import ScrollIntoView from "react-scroll-into-view";
import ProductDescriptionPageStyles from "./ProductDescriptionPageStyles";
import {withRouter} from "react-router-dom";
import clsx from "clsx";
import ProductDescriptionConfig from "./ProductDescriptionConfig";
import RoutePath from "../../../lib/RoutePath";

let styles = ProductDescriptionPageStyles;

function ScrollTop(props) {
   const {children, window} = props;
   const {classes} = props;
   // Note that you normally won't need to set the window ref as useScrollTrigger
   // will default to window.
   // This is only being set here because the demo is in an iframe.
   const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 10
   });

   const handleClick = event => {
      const anchor = (event.target.ownerDocument || document).querySelector(
         "#back-to-top-anchor"
      );

      if (anchor) {
         anchor.scrollIntoView({behavior: "smooth", block: "center"});
      }
   };

   return (
      <Zoom in={trigger}>
         <div onClick={handleClick} role="presentation" className={classes.root}>
            {children}
         </div>
      </Zoom>
   );
}

function ProductDescriptionPage(props) {
   let {classes, window} = props;

   let productData = ProductDescriptionConfig[props.match.params.page];

   if (!productData) {
      RoutePath.navigateTo(props, RoutePath.productDetailed(ProductDescriptionConfig.defaultRoute));
      productData = ProductDescriptionConfig[ProductDescriptionConfig.defaultRoute]
   }

   let defaultSelectedTab = productData.tabs.length ? productData.tabs[0].tabId : '';

   let [selectedTab, setSelectedTab] = useState(defaultSelectedTab)

   const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 10
   });

   let handleChangeSelectedTab = (tabURI) => () => {
      setSelectedTab(tabURI)
   }

   return (
      <div className={classes.root}>
         <Grid container direction={'row'} justify={'center'} spacing={2}>
            <Grid item xs={12} sm={3}>
               <Grid container direction={"row"} spacing={2} justify={'center'} className={classes.navButtonsContainer}>
                  {productData.tabs.map((tab, index) => {
                     return (
                        <Grid item xs={11} key={index}>
                           <ScrollIntoView selector={`#${tab.tabId}`}>
                              <Button
                                 variant={'contained'}
                                 className={clsx(classes.navButton, selectedTab === tab.tabId ? classes.selectedNavButton : null)}
                                 onClick={handleChangeSelectedTab(tab.tabId)}
                                 classes={{label: classes.navButtonLabel}}
                              >
                                 {tab.tabName}
                              </Button>
                           </ScrollIntoView>
                        </Grid>
                     )
                  })}
               </Grid>
            </Grid>

            <Grid item xs={12} sm={8} container direction={'column'} spacing={4}>
               <Grid item className={classes.contentContainer}
                     style={{scrollMarginTop: !trigger ? '22vh' : '13vh'}}>
                  {productData.content}
               </Grid>
            </Grid>
         </Grid>

      </div>
   );
}

export default withRouter(withStyles(styles)(ProductDescriptionPage));
