import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section5CardStyles from "./Section5CardStyles";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import {WithStore} from "pure-react-carousel";

let styles = Section5CardStyles;

class Section5Card extends Component {
   static defaultProps = {
      title: '',
      icon: '',
      content: '',
      link: ''
   };

   constructor(props) {
      super(props);
   }

   handleClick = () => {
      // this.props.carouselStore.setStoreState({isPlaying: true});
      if (this.props.cardData.link) {
         window.open(this.props.cardData.link, '_blank');
      }
   }

   render() {
      let {classes, cardData} = this.props;

      return (
         <div className={classes.root}>
            <Card className={classes.card} elevation={0}>
               <CardActionArea className={classes.cardClickableArea} onClick={this.handleClick}>
                  <img src={cardData.thumbnail} alt="thumbnail" className={classes.thumbnailImage}/>
                  <div className={classes.title}>{cardData.heading}</div>
               </CardActionArea>
            </Card>
         </div>
      );
   }
}

export default withStyles(styles)(Section5Card);
