let Section5Config = [
    {
        heading: "",
        content: "",
        link: "",
        thumbnail: "./../../../../assets/images/resource/partner_logos/axis.png"
    },

    {
        heading: "",
        content: "",
        link: "https://www.cisco.com/c/dam/en/us/products/collateral/servers-unified-computing/whitepaper-c22-742389.pdf",
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/cisco.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/dell-emc.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail: "./../../../../assets/images/resource/partner_logos/ey.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail: "./../../../../assets/images/resource/partner_logos/flir.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/genetec.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/hexagon.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/hitachi.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/honeywell.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail: "./../../../../assets/images/resource/partner_logos/hpe.png"
    },

    {
        heading: "",
        content: "",
        // link: 'https://marketplace.intel.com',
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/intel.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/johnson-controls.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail: "./../../../../assets/images/resource/partner_logos/l&t.png"
    },

    {
        heading: "",
        content: "",
        link: "",
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/milestone.png"
    },

    {
        heading: "",
        content: "",
        // link: 'https://www.nvidia.com/en-in/',
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/nvidia.png"
    },

    {
        heading: "",
        content: "",
        // link: 'https://www.qognify.com/indias-smart-cities-a-nation-of-growth/',
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/qognify.png"
    },

    {
        heading: "",
        content: "",
        // link: 'https://trinitymobility.com/Smart-cities/',
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/trinity.png"
    },

    {
        heading: "",
        content: "",
        // link: 'http://www.tycosecurityproducts.in/',
        thumbnail: "./../../../../assets/images/resource/partner_logos/tyco.png"
    },

    {
        heading: "",
        content: "",
        // link: 'https://www.veracityglobal.com/',
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/veracity.png"
    },

    {
        heading: "",
        content: "",
        // link: 'https://www.vmware.com/in/company/office_locations/office_india.html',
        thumbnail:
            "./../../../../assets/images/resource/partner_logos/vmware.png"
    }
];

export default Section5Config;
