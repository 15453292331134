import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section4Styles from "./Section4Styles";
import CarouselComponentWithDots from "../../../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";
import withWidth from "@material-ui/core/withWidth";
import Section3Card from "../Section3/Section3Card/Section3Card";
import Section4Card from "./Section4Card/Section4Card";

let styles = Section4Styles;

class Section4 extends Component {
    constructor(props) {
        super(props);
        this.contentArray = [
            <Section4Card
                appName={
                    "Development, Training and Benchmarking tools to take you from Concept to Production 10x Faster!"
                }
                textContent={
                    "If you have an AI algorithm then we have all of the tools you need to make it into a deployable product. Hope to train your AI models? You have a dedicated tool for creating image datasets, annotating images and training your models. Hope to know how good your models are? Benchmark your models against the best of models from across the world? Hope to productize your algorithm? Convert it into an app on the AppStack in 50 lines of code or less!"
                }
                logo={"/assets/images/resource/developer-tools.png"}
            />,

            <Section4Card
                appName={
                    'Extensive Partnerships and Integrations, so you don\'t have to worry about "Compatibility Issues"'
                }
                textContent={
                    "Deployment of Video AI often calls for integration with a host of other software and utilities. Awiros acts as a standardized delivery platform which has already been integrated with all of the major software and utilities, including all of the leading VMSs and Command and Control Center Softwares. It therefore ensures the seamless as well as effortless deployment for every individual solution on the AppStack in any kind of scenario."
                }
                logo={"/assets/images/resource/integrations.png"}
            />,

            <Section4Card
                appName={
                    "Gain Access to New Opportunities, with the Awiros Development Community by your Side"
                }
                textContent={
                    "With the AppStack acting as an aggregator of the best of Video AI solutions developers can bundle their solutions with other best-in-class apps on the AppStack and reach out to a larger customer base. Not just that, but as the number of AppStack users increases the outreach of every individual solution increases manifold without any kind of effort from the developer’s end."
                }
                logo={"/assets/images/resource/opportunities.png"}
            />
        ];
    }

    render() {
        let { classes, width } = this.props;
        return (
            <div className={classes.root}>
                <div className={"h2heading center"}>The Awiros Advantage</div>
                <CarouselComponentWithDots
                    contentArray={this.contentArray}
                    visibleSlides={1}
                    interval={7000}
                />
            </div>
        );
    }
}

export default withWidth()(withStyles(styles)(Section4));
