import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import AppStackContainerStyles from "./AppStackContainerStyles";
import AppStack from "./AppStack/AppStack";
import AppDescription from "./AppDescription/AppDescription";

let styles = AppStackContainerStyles;

class AppStackContainer extends Component {
   constructor(props) {
      super(props);
      this.state = {
         descriptionPageOpenedFlag: false,
      }
   }

   switchScreen = (appData, pageStatus) => {
      this.setState({appData: appData, descriptionPageOpenedFlag: pageStatus})
   }

   render() {
      let {classes} = this.props;
      let {descriptionPageOpenedFlag} = this.state;
      return (
         <div className={classes.root}>
            {descriptionPageOpenedFlag ? (
               <AppStack switchScreen={this.switchScreen} scrolledFlag={this.props.scrolledFlag}/>
            ) : (
               <AppDescription appData={this.state.appData} switchScreen={this.switchScreen}
                               scrolledFlag={this.props.scrolledFlag}/>
            )}
         </div>
      );
   }
}

export default withStyles(styles)(AppStackContainer);