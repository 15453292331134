import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let AppathonPageStyles = {
  rootContainer: {
    color: "#606069",
  },
  linkContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "44px",
    marginTop: "44px",
    fontSize: "24px",
    "@media (max-width: 1440px)": {
      fontSize: "20px",
      gap: "32px",
    },
    "@media (max-width: 1024px)": {
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    "@media (max-width: 400px)": {
      fontSize: "16px",
    },
  },

  linkContainerLink: {
    textDecoration: "none",
    textDecorationLine: "underline",
    color: "blue",
  },

  banner: {
    paddingTop: "90px",
  },
  bannerContent: {
    marginLeft: "70px",
    "@media (max-width: 768px)": {
      textAlign: "center",
      marginLeft: "0",
    },
  },
  bannerContentLogo: {
    width: "700px",
    marginBottom: "20px",
    "@media (max-width: 768px)": {
      width: "300px",
    },
  },
  bannerContentComvision: {
    fontSize: "32px",
    color: "white",
    fontWeight: "600",
    marginBottom: "0px",
  },
  bannerContentAppathonHeading: {
    color: "#FFCC00",
    display: "flex",
    alignItems: "center",
    fontWeight: "900",
    gap: "15px",
    "@media (max-width: 768px)": {
      justifyContent: "center",
    },
  },
  bannerContentAppathon: {
    fontSize: "96px",
    "@media (max-width: 768px)": {
      fontSize: "44px",
    },
  },
  bannerContentVersion: {
    fontSize: "56px",
    marginTop: "15px",
    "@media (max-width: 768px)": {
      fontSize: "24px",
      marginTop: "12px",
    },
  },
  bannerContentUnveil: {
    fontSize: "48px",
    color: "white",
    fontWeight: "300",
    "@media (max-width: 768px)": {
      fontSize: "32px",
    },
  },
  bannerContentRegisterButton: {
    backgroundColor: "#FFCC00",
    padding: "12px 56px 12px 56px",
    borderRadius: "4px",
    border: "none",
    width: "316px",
    cursor: "pointer",
    height: "60px",
    fontWeight: "800",
    fontSize: "24px",
    marginTop: "40px",
    "@media (max-width: 768px)": {
      marginTop: "56px",
      width: "240px",
      fontWeight: "700",
      padding: "12px 0px 12px 0px",
    },
  },
  bannerPblStatement: {
    display: "flex",
    justifyContent: "center",
    margin: "50px auto",
    "@media (max-width: 768px)": {
      margin: "100px auto",
    },
  },
  bannerPblStatementImage: {
    "@media (max-width: 768px)": {
      width: "100px",
    },
  },
  sessionContainer: {
    padding: "96px 64px",
  },
  sessionHeading: {
    fontSize: "48px",
    fontWeight: "700",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "32px",
    },
  },
};

export default AppathonPageStyles;
