class GenUtil {
    static accessToken = '';

    static getAccessToken() {
        return localStorage.getItem('token') || this.accessToken || '';
    }

    static setAccessToken(token) {
        localStorage.setItem('token', token);
        this.accessToken = token;
    }

    static removeAccessToken(token) {
        localStorage.setItem('token', token);
        this.accessToken = token;
    }

    static getHeaders() {
        return {
            token: GenUtil.getAccessToken()
        };
    }

    static getLoginHeaders = async () => {
        return {
            "Content-Type": "application/json",
        };
    };

    static getAPIHeaders = async () => {
        return {
            "Content-Type": "application/json",
            Authorization: await this.getAccessToken()
        };
    };

}
export default GenUtil;
