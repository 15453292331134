import React, { Component } from "react";

import SocialBoxStyles from "./SocialBoxStyles";
import clsx from "clsx";
import withStyles from "@material-ui/core/styles/withStyles";

let styles = SocialBoxStyles;

class SocialBox extends Component {
    static defaultProps = {
        name: "Vikram Gupta",
        thumbnailImage: "/assets/images/resource/vikram.jpg",
        socialLinks: [
            {
                profileIcon: <i className="fab fa-linkedin-in" />,
                profileLink: "https://www.linkedin.com/in/vkrmgpta/"
            }
        ]
    };

    constructor(props) {
        super(props);
        this.state = {
            hovering: false
        };
    }

    handleHoverStart = () => {
        this.setState({ hovering: true });
    };
    handleHoverEnd = () => {
        this.setState({ hovering: false });
    };

    render() {
        let { classes } = this.props;
        return (
            <>
                <div className={classes.root}>
                    <div className={classes.cardContainer}>
                        <div
                            className={classes.thumbnailContainer}
                            onMouseEnter={this.handleHoverStart}
                            onMouseLeave={this.handleHoverEnd}
                        >
                            <img
                                className={`${classes.thumbnailImage} ${classes.removeGrayscale}`}
                                src={this.props.thumbnailImage}
                                alt="display picture"
                            />
                            <div
                                className={clsx(
                                    this.state.hovering
                                        ? classes.socialLinksContainerShow
                                        : classes.socialLinksContainerHide
                                )}
                            >
                                {this.props.socialLinks.map(
                                    (profileData, index) => {
                                        return (
                                            <a
                                                target="_blank"
                                                className={classes.socialLink}
                                                href={profileData.profileLink}
                                                key={index}
                                            >
                                                <div
                                                    className={classes.linkText}
                                                >
                                                    {profileData.profileIcon}
                                                </div>
                                            </a>
                                        );
                                    }
                                )}
                            </div>
                        </div>
                    </div>
                    <h3 className={classes.name}>{this.props.name}</h3>
                </div>
            </>
        );
    }
}

export default withStyles(styles)(SocialBox);
