import StyleConfig from "../../lib/StyleConfig";

let AboutUsPageStyles = {
  root: {
    margin: "0 auto",
    maxWidth: 1200,
    padding: "0 20px 50px",
    flex: 1,
    backgroundColor: StyleConfig.backgroundColor,
    "@media (min-width: 960px)": {
      padding: "0 20px 50px",
    },
  },

  cardContainer: {
    display: "flex",
    justifyContent: "center",

    gap: "150px",
    "@media (max-width: 1024px)": {
      gap: "50px",
    },
    "@media (max-width: 500px)": {
      flexDirection: "column",
      alignItems: "center",
      gap: "20px",
    },
  },
  heading: {
    fontSize: 36,
    fontWeight: 400,
    color: StyleConfig.mainThemeColor,
    textAlign: "center",
    marginBottom: 15,
  },
  textContent: {
    fontSize: 18,
    fontWeight: 300,
    color: StyleConfig.headingColor,
    textAlign: "center",
    maxWidth: 1080,
    margin: "0 auto",
    lineHeight: "1.7em",
  },
  subtitle: {
    fontSize: 30,
    fontWeight: 300,
    color: StyleConfig.headingColor,
    textAlign: "center",
    margin: "100px auto 50px",
  },
  linkedInContainer: {
    textAlign: "center",
    marginTop: "20px",
    fontSize: "24px",
    display: "flex",
    gap: "10px",
    justifyContent: "center",
    alignItems: "center",
    color: "#3b566e",
    fontWeight: "300",
    marginBottom: "30px",
  },
  linkedInLogo: {
    backgroundColor: "#ffcc00",
    width: "30px",
    height: "30px",
    borderRadius: "50%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  mainText: {
    color: "#3b566e",
  },
  text: {
    textDecoration: "none",
    "@media (max-width:500px)": {
      fontSize: "20px",
    },
  },
  profilesContainer: {
    marginBottom: 100,
  },
};

export default AboutUsPageStyles;
