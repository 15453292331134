import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import AwardsStyles from "./AwardsStyles";
import { awards, mobileViewAwards } from "../utils/awards";
import CarouselComponentWithDots from "../../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";

const Awards = (props) => {
  const { classes } = props;

  return (
    <div
      className={classes.awards}
      style={{
        background: `url('/assets/appathon/awardsBG.png')`,
        // backgroundSize: "cover",
        border: "1px solid black",
        backgroundSize: "100% 50%",
        // backgroundColor: "#2C2C2C",
        backgroundRepeat: "no-repeat",
      }}
    >
      <span className={classes.awardsHeading}>AWARDS</span>
      <span className={classes.awardsDescription}>App-a-thon 2.0</span>
      <div className={classes.awardsContainer}>
        {awards.map((award, index) => (
          <div className={classes.singleAward} key={index}>
            <div className={classes.rankHeader}>
              <span className={classes.numberHeading}>{award.number}</span>
              <span className={classes.text}>{award.text}</span>
            </div>
            <div className={classes.priceHeading}>CASH PRIZE</div>
            <div className={classes.price}>₹{award.price}</div>
          </div>
        ))}
      </div>
      <div className={classes.carouselComp}>
        <CarouselComponentWithDots
          contentArray={mobileViewAwards.map((award) => (
            <div className={classes.singleAward}>
              <div className={classes.rankHeader}>
                <span className={classes.numberHeading}>{award.number}</span>
                <span className={classes.text}>{award.text}</span>
              </div>
              <div className={classes.priceHeading}>CASH PRIZE</div>
              <div className={classes.price}>₹{award.price}</div>
            </div>
          ))}
          aspectRatioWidth={700}
          visibleSlides={1}
          interval={7000}
          isIntrinsicHeight={true}
        />
      </div>
      <ul className={classes.list}>
        <li>Swag Bags for top 20 teams!</li>
        <li>Certificate of Participation for all submissions.</li>
        <li>
          Chance to be considered for job/internship offers at Awiros for top 5
          teams!
        </li>
      </ul>
      <div className={classes.note}>
        *Cash prizes are subject to tax deductions as per the govt guidelines.
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(AwardsStyles, { withTheme: true })(Awards)
);
