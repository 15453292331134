import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section3Styles from "./Section3Styles";
import Section3Card from "./Section3Card/Section3Card";
import InfiniteCarousel from "../../../CommonComponents/InfiniteCarousel/InfiniteCarousel";
import Section3Config from "./Section3Config";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";

let styles = Section3Styles;

class Section3 extends Component {
   constructor(props) {
      super(props);
      this.carouselContent = Section3Config
   }

   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <div className={classes.heading}>Awiros in Videos</div>

            <InfiniteCarousel carouselContent={this.carouselContent} Component={Section3Card}/>

            <Grid container direction={'row'} justify={'flex-end'} className={classes.ytButtonContainer}>
               <Grid item>
                  <div className={clsx("g-ytsubscribe", classes.ytButton)} data-channelid="UCauADFRElXLhspX_uqoSTmQ"
                       data-layout="full"
                       data-count="hidden"/>
               </Grid>
            </Grid>


         </div>
      );
   }
}

export default withStyles(styles)(Section3);
