import StyleConfig from "../../../../lib/StyleConfig";

let Section2Styles = {
   root: {
      marginTop: 0,
      marginBottom: 80,
   },
   button: {
      backgroundColor: StyleConfig.darkButtonBackground,
      '&:hover': {
         backgroundColor: StyleConfig.darkButtonBackgroundHover,
      }
   },
   fontAwesomeIcon: {
      fontSize: 18
   }
};

export default Section2Styles;