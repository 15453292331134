import { withStyles } from "@material-ui/styles";
import PolicyStyles from "./PolicyStyles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import PolicyPage from "./Policy";
import { eula } from "./utils";

const EULAPage = (props) => {
  const { classes } = props;

  return (
    <div>
      <PolicyPage data={eula} />
    </div>
  );
};

export default withRouter(
  withStyles(PolicyStyles, { withTheme: true })(EULAPage)
);
