let DashboardStyles = {
  DashboardContainer: {
    height: "100%",
  },

  dashboard: {
    padding: "40px",
    display: "flex",
    gap: "132px",
    justifyContent: "center",
    "@media (max-width: 1440px)": {
      gap: "40px",
    },
    "@media (max-width: 768px)": {
      flexDirection: "column",
      alignItems: "center",
    },
  },
  dashboardHeading: {
    color: "#FC0",
    fontSize: "70px",
    fontWeight: "700",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "44px",
    },
    "@media (max-width: 500px)": {
      fontSize: "32px",
    },
  },
  dashboardTotalRegistrationContainer: {
    textAlign: "center",
    width: "max-content",
  },
  dashboardDetailsHeading: {
    fontSize: "48px",
    marginBottom: "16px",
    "@media (max-width: 1440px)": {
      fontSize: "32px",
    },
    "@media (max-width: 768px)": {
      fontSize: "24px",
    },
  },
  dashboardCard: {
    fontSize: "158px",
    fontWeight: "700",
    borderRadius: "8px",
    borderBottom: "3px solid #FC0",
    background: "#FFF",
    boxShadow: "0px 2px 3px 1px rgba(0, 0, 0, 0.10)",
    width: "550px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 1440px)": {
      width: "250px",
      fontSize: "76px",
    },
  },
  dashboardTeams: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  dashboardTeam: {
    display: "flex",
    justifyContent: "space-between",
    fontSize: "16px",
    border: "1px solid #FC0",
    padding: "12px",
    width: "600px",
    "@media (max-width: 1440px)": {
      width: "450px",
      fontSize: "14px",
    },
    "@media (max-width: 768px)": {
      width: "350px",
      fontSize: "12px",
    },
  },
  login: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    margin: "200px auto",
    gap: "24px",
    "@media (max-width: 768px)": {
      flexDirection: "column",
    },
  },
  input: {
    padding: "6px",
  },
  inputLabel: {
    fontSize: "32px",
    fontWeight: "600",
  },
  submit: {
    width: "320px",
    padding: "6px",
    background: "#FFCC00",
    border: "none",
    cursor: "pointer",
  },
};

export default DashboardStyles;
