import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section1Styles from "./Section1Styles";
import AppStackContainer from "./AppStackContainer/AppStackContainer";
import clsx from "clsx";

let styles = Section1Styles;

class Section1 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      scrolledFlag: false,
    };
    this.appStackRef = React.createRef();
  }

  updateScrollStatus = () => {
    if (this.appStackRef.current.scrollTop > 1) {
      this.setState({ scrolledFlag: true });
    } else {
      this.setState({ scrolledFlag: false });
    }
  };

  handleScroll = () => {
    if (this.debounce_timer) {
      window.clearTimeout(this.debounce_timer);
    }
    this.debounce_timer = window.setTimeout(this.updateScrollStatus, 100);
  };

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <div className={"h2heading center smallMb heading"}>
          The World's First Video Intelligence Marketplace
        </div>
        <div className={"h3heading center mediumMb"}>
          Video AI apps for multiple industry segments and multiple hardware
          platforms
        </div>
        {/* <div
               ref={this.appStackRef}
               className={clsx(classes.appStack, 'appStack')}
               onScroll={this.handleScroll}
            >
               <AppStackContainer scrolledFlag={this.state.scrolledFlag}/>
            </div> */}
        <div className={classes.videoContainer}>
          <video
            className={classes.appStackVideo}
            autoPlay
            loop
            muted
            controls={false}
            playsInline={true}
            src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/appStackVideo.mp4"
            type="video/mp4"
          ></video>
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Section1);
