import React from "react";

let ProfileData = [
  {
    name: "Vikram Gupta",
    thumbnailImage: "assets/images/resource/vikram.jpg",
    socialLinks: [
      {
        profileIcon: <i className="fab fa-linkedin-in" />,
        profileLink: "https://www.linkedin.com/in/vkrmgpta/",
      },
    ],
  },
];

export default ProfileData;
