import React, { useEffect, useState } from "react";
import NotableDeploymentsFirstStyles from "./NotableDeploymentsFirstStyles";
import { makeStyles, withStyles } from "@material-ui/styles";
import { notableDeployments } from "../utils/index";
import clsx from "clsx";
import styles from "../styles.module.css";

const useStyles = makeStyles(NotableDeploymentsFirstStyles);

function NotableDeploymentsFirst() {
  const classes = useStyles();
  const [selectedIndex, setSelectedIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSelectedIndex((prev) => {
        if (prev === notableDeployments.length - 1) {
          return 0;
        }
        return prev + 1;
      });
    }, 5000);
    // To Update animation time also upadate in styles.module.css file,NotableDeploymentsFirstStyles.js,

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className={classes.deployments}>
      <div className={classes.headings}>Our Success stories</div>
      <div style={{ position: "relative" }}>
        <div className={`${classes.deploymentBannerContainer} `}>
          <div className={classes.deploymentImage}>
            <img
              style={{ objectFit: "contain" }}
              width="100%"
              height="100%"
              src={notableDeployments[selectedIndex].imageUrl}
            />
          </div>
          <div className={classes.deploymentMetrics}>
            <div className={classes.deploymentCells}>
              <div
                className={clsx(
                  classes.iconImage,
                  classes.deploymentMetricCells
                )}
              >
                <img
                  width="100%"
                  height="100%"
                  src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/icons/cam-white.png"
                />
              </div>
              <div className={clsx(classes.metricsData, classes.gradientText)}>
                {notableDeployments[selectedIndex].licenses}{" "}
                <span>Cameras</span>
              </div>
            </div>

            {/* gutter */}
            <div className={clsx(classes.deploymentCells, classes.gutterSpace)}>
              <div className={classes.deploymentMetricCells}></div>
              <div></div>
            </div>

            {/* area covered */}
            <div className={classes.deploymentCells}>
              <div
                className={clsx(
                  classes.iconImage,
                  classes.deploymentMetricCells
                )}
              >
                <img
                  width="100%"
                  height="100%"
                  src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/icons/area-white.png"
                />
              </div>
              <div className={clsx(classes.metricsData, classes.gradientText)}>
                {notableDeployments[selectedIndex].areaCovered} <span></span>
              </div>
            </div>

            {/* gutter */}
            <div className={clsx(classes.deploymentCells, classes.gutterSpace)}>
              <div className={classes.deploymentMetricCells}></div>
              <div></div>
            </div>

            {/* gutter */}
            <div className={clsx(classes.deploymentCells, classes.gutterSpace)}>
              <div></div>
              <div className={classes.deploymentMetricCells}></div>
            </div>

            {/* Apps */}
            <div className={classes.deploymentCells}>
              <div className={classes.iconImage}>
                <img width="100%" height="100%" src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/icons/apps.svg" />
              </div>
              <div
                className={clsx(
                  classes.deploymentMetricCells,
                  classes.metricsData
                )}
              >
                {notableDeployments[selectedIndex].application_types.length}
                {"+"}
                <span> Apps</span>
              </div>
            </div>

            {/* gutter */}
            <div className={clsx(classes.deploymentCells, classes.gutterSpace)}>
              <div></div>
              <div className={classes.deploymentMetricCells}></div>
            </div>
            <div className={classes.deploymentCells}>
              {/* lives impacted */}
              <div className={classes.iconImage}>
                <img
                  width="100%"
                  height="100%"
                  src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/icons/people.png"
                />
              </div>
              <div
                className={clsx(
                  classes.deploymentMetricCells,
                  classes.metricsData
                )}
              >
                {notableDeployments[selectedIndex].livesImpacted}{" "}
                <span>People</span>
              </div>
            </div>
          </div>
        </div>

        {/* <div className={clsx(classes.backdrop, styles.backdropAnimation)}></div> */}
      </div>
    </div>
  );
}

export default NotableDeploymentsFirst;
