import StyleConfig from "../../../../lib/StyleConfig";

let TestimonialCardStyles = {
   root: {
      // width:'100%',
      height: '100%',
      minHeight: 500,
      // border:'1px solid black',
      borderRadius: 5,
      padding: '45px 32px 20px',
      '-webkit-box-shadow': `0px 0px 7px 0px ${StyleConfig.mainThemeColor}`,
      '-moz-box-shadow': `0px 0px 7px 0px ${StyleConfig.mainThemeColor}`,
      boxShadow: `0px 0px 7px 0px ${StyleConfig.mainThemeColor}`,
   },
   quoteSign: {
      fontSize: 40,
      color: StyleConfig.mainThemeColor
   },
   textContent: {
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '1.6em'
   },
   logoImage: {
      width: '100%'
   }
};

export default TestimonialCardStyles;