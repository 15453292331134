let TestimonialConfig = [
    {
        name: "Rohan Malhotra",
        designation: "CEO, Roadzen",
        logo: "/assets/images/resource/roadzen.png",
        content:
            "The Awiros' team has enabled us to train, test and deploy our AI models at scale in different geographies across the world. The platform is unique and a must use for teams building computer vision algorithms based on Artificial Intelligence and Deep Learning."
    },
    {
        name: "Samar Singla",
        designation: "CEO, Jungleworks",
        logo: "/assets/images/resource/jungleworks.png",
        content:
            "We are impressed with the OS that Awiros embodies. It helps us as a user to manage the security of our campus through cameras and gives us flexibility to run various use-cases on-demand. We are now packaging Awiros products with Jungleworks portfolio and offering it to our customers globally."
    },
    {
        name: "Ms Nitika Gahlaut",
        designation: "IPS, DCP, Haryana Police",
        logo: "/assets/images/resource/haryana-police-logo.png",
        content:
            "It gives me great pleasure to convey my deep appreciation for outstanding work done by Awidit Systems Pvt. Ltd. by installing Facial Recognition System on 250 cameras at the 33rd Surajkund Fair. I hope Awidit Systems will continue to work with the same zeal and devotion."
    }
];

export default TestimonialConfig;
