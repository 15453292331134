import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section6Styles from "./Section6Styles";

let styles = Section6Styles;

class Section6 extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <h2 className={"h2heading center"}>
                    Awiros is a unified abstraction between hardware
                    infrastructure and Video AI “Apps”
                </h2>
                <h3 className={"h3heading center"}>
                    Awiros is functionally built as a Distributed Operating
                    System (OS) for developing, deploying and delivering Video
                    Intelligence “Apps”, helping both the customers and
                    developers through the comprehensive technology platform.
                </h3>
                <div className={classes.headerImage}>
                    <img
                        src={"/assets/images/resource/dev-page-2.png"}
                        className={classes.headerImage}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Section6);
