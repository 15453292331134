import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import AppDescriptionStyles from "./AppDescriptionStyles";
import Grid from "@material-ui/core/Grid";
import Chip from "@material-ui/core/Chip";
import IconButton from "@material-ui/core/IconButton";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Button from "@material-ui/core/Button";
import RoutePath from "../../../../../../lib/RoutePath";
import {ReplaceUrlHostname} from '../../../../../../util/commonUtils';

let styles = AppDescriptionStyles;

class AppDescription extends Component {
   componentDidMount = () => {
      if (!this.props.appData) {
         this.props.switchScreen(undefined, true)
      }
   }

   handleBackButton = () => {
      this.props.switchScreen(undefined, true)
   };

   handleClick = url => () => {
      window.open(url, '_blank')
   }

   render() {
      let {classes, appData, scrolledFlag} = this.props;
      if (appData) {
         return (
            <div className={classes.root}>
               <div className={classes.topBarContainer}
                    style={scrolledFlag ? {borderBottom: '1px solid lightgrey'} : null}
               >
                  <Grid container direction={'row'} justify={'space-between'} alignItems={'center'}
                        className={classes.topBar}>
                     <Grid item>
                        <IconButton aria-label="delete" className={classes.margin} size="medium"
                                    onClick={this.handleBackButton}>
                           <ArrowBackIcon fontSize="inherit"/>
                        </IconButton>
                     </Grid>

                     <Grid item className={classes.guidedTour}>
                        {/*<Button variant={'outlined'} onClick={this.handleClick('https://www.google.com')} className={classes.guidedTourButton}>*/}
                        <Button variant={'outlined'} onClick={this.handleClick(RoutePath.dashboardPath)}
                                className={classes.guidedTourButton}>
                           Take a Tour
                        </Button>
                     </Grid>
                  </Grid>
               </div>

               <div className={classes.contentContainer}>
                  <div className={classes.headerContainer}>
                     <img src={ReplaceUrlHostname(appData.icon)} alt={appData.awi_name} className={classes.appIcon}/>
                     <div className={classes.appNameContainer}>
                        <div className={classes.appName}>{appData.awi_name}</div>
                        <div className={classes.appDescription}>{appData.description}</div>
                     </div>
                  </div>

                  <Grid container direction="row" spacing={2}>
                     {appData.screenshot_image.map((image, index) => {
                        return (
                           <Grid item xs={3} key={index}>
                              <img src={ReplaceUrlHostname(image)} alt={appData.awi_name}
                                   className={classes.screenshotImage}/>
                           </Grid>
                        )
                     })}
                  </Grid>


                  <Grid container direction="row" spacing={2} className={classes.section3Container}>
                     <Grid item xs={4}>
                        <div className={classes.detailsSubheading}>Developer Name</div>
                        <div className={classes.detailsValue}>{appData.awi_developer}</div>
                     </Grid>
                     <Grid item xs={4}>
                        <div className={classes.detailsSubheading}>RAM Usage</div>
                        <div className={classes.detailsValue}>{appData.RAM} GB</div>
                     </Grid>
                     <Grid item xs={4}>
                        <div className={classes.detailsSubheading}>App Credits</div>
                        <div className={classes.detailsValue}>{appData.awi_app_credits}</div>
                     </Grid>
                     <Grid container direction={'row'} item xs={8} sm={4} spacing={1}>
                        <Grid item xs={12} className={classes.detailsSubheading}>Target Industries</Grid>
                        <Grid item xs={12} container direction={'row'} spacing={1}>
                           {appData.industries.map((industry, index) => {
                              return (
                                 <Grid item key={index}>
                                    <Chip
                                       label={industry.awi_name}
                                    />
                                 </Grid>
                              )
                           })}
                        </Grid>
                     </Grid>
                     <Grid item xs={4}>
                        <div className={classes.detailsSubheading}>Processor</div>
                        <div className={classes.detailsValue}>{appData.processor}</div>
                     </Grid>
                  </Grid>

                  <div className={classes.detailsSubheading}>Description</div>
                  <div className={classes.detailsValue}>{appData.industry_description}</div>
               </div>
            </div>
         );
      } else {
         return null
      }

   }
}

export default withStyles(styles)(AppDescription);