import React, {Component} from 'react';

import Card from "@material-ui/core/Card";
import Grid from "@material-ui/core/Grid";
import Section7TwitterFeedStyles from "./Section7TwitterFeedStyles";
import {v4 as uuid} from "uuid";
import withStyles from "@material-ui/core/styles/withStyles";

let styles = Section7TwitterFeedStyles;

class Section7TwitterFeed extends Component {
   constructor(props) {
      super(props);
      this.state = {
         
      }
   }

   render() {
      let {classes} = this.props;

      return (
         <div className={classes.root}>

            <Card key={uuid()} className={classes.tweetsContainer} elevation={3}>
               <a
                  className="twitter-timeline"
                  data-height="390"
                  // data-width="600"
                  data-theme="light"
                  href="https://twitter.com/awirosweb?ref_src=twsrc%5Etfw"
                  key={uuid()}
               >
                  Tweets by awirosweb
               </a>
            </Card>

            <Grid container direction={'row'} justify={'flex-end'} className={classes.followButtonContainer}>
               <Grid item key={uuid()}
               >
                  <a
                     href={"https://twitter.com/awirosweb?ref_src=twsrc%5Etfw"}
                     className={`twitter-follow-button ${classes.followButton}`}
                     data-size="large"
                     data-show-count="false"
                     key={uuid()}
                  >
                     Follow @awirosweb
                  </a>
               </Grid>
            </Grid>
         </div>
      );
   }
}

export default withStyles(styles)(Section7TwitterFeed)
