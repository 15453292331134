export const awards = [
  {
    number: 2,
    text: "nd",
    price: "1,50,000",
  },
  {
    number: 1,
    text: "st",
    price: "2,00,000",
  },
  {
    number: 3,
    text: "rd",
    price: "1,00,000",
  },
];

export const mobileViewAwards = [
  {
    number: 1,
    text: "st",
    price: "2,00,000",
  },
  {
    number: 2,
    text: "nd",
    price: "1,50,000",
  },
  {
    number: 3,
    text: "rd",
    price: "1,00,000",
  },
];
