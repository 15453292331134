let FaqsStyles = {
  faqsContainer: {
    padding: "64px",
    "@media (max-width:768px)": {
      padding: "40px",
    },
    "@media (max-width:500px)": {
      padding: "24px",
    },
  },
  faqsHeading: {
    fontSize: "48px",
    fontWeight: "600",
    marginBottom: "40px",
    "@media (max-width:1024px)": {
      fontSize: "32px",
      textAlign: "center",
    },
  },
  faqs: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    padding: "5px 20px",
    gap: "16px",
    bordeRadius: "8px",
    borderBottom: "3px solid #FC0",
    boxShadow: "0px 2px 3px 1px rgba(0, 0, 0, 0.10)",

    "@media (max-width:768)": {
      padding: "5px 10px",
    },
  },
  faqHeader: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  faqsQuestion: {
    marginLeft: "34px",
    fontWeight: "500",
    fontSize: "18px",
  },
  faqsAnswer: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    paddingLeft: "90px",
    fontSize: "18px",
    marginTop: "-32px",

    "@media (max-width:768px)": {
      paddingLeft: "70px",
      marginTop: "-16px",
    },
  },
  faqHeaderArrow: {
    marginLeft: "auto",
    cursor: "pointer",
  },
  faqsContent: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
  },
  faqsNumber: {
    color: "#FC0",
    fontSize: "48px",
    fontWeight: "700",
    "@media (max-width:768px)": {
      fontSize: "32px",
    },
  },
  faqsText: {
    color: "white",
    fontSize: "18px",
    fontWeight: "500",
  },
};

export default FaqsStyles;
