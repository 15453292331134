import StyleConfig from "../../../../lib/StyleConfig";

let Section2Styles = {
   root: {
      paddingTop: 30,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      marginBottom: 40
   },
   heading: {
      color: StyleConfig.headingColor,
      fontSize: 30,
      fontWeight: 300,
      textAlign: 'center'
   },
   // subtitle:{
   //    color:'black',
   //    fontWeight: 400,
   //    fontSize: 18,
   //    textAlign: 'center',
   // },
   textContent: {
      fontSize: 18,
      fontWeight: 300,
      textAlign: 'center'
   },
   image: {
      width: '100%',
      maxWidth: 980
   }
};

export default Section2Styles;
