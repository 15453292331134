class VideoPath {
  // static addUserPath = '/user/add';
  // static editUserPath = (id = ':id') => `/user/edit/${id}`;

  static intro = 'f0O8gQgaJq4';
  static autoNPR = 'meuBfCsDKf0';
  static FRAM = 'tEs4tR0L_9E';
  static fireAndSmokeDetection = 'RdLTZD74wuQ';
  static faceClustering = 'NIRzQwE7L-c';
  static vcc = '9Le_Ut0_aG0'
  static crowdDash = '0swfcPSVQs8'
}

export default VideoPath;
