import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section1Styles from "./Section1Styles";
import withWidth from "@material-ui/core/withWidth";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import DataSheetForm from "../../../AwirosOSPage/Sections/Section1/DataSheetForm/DataSheetForm";
import Modal from "@material-ui/core/Modal";
import SupportForm from "./SupportForm/SupportForm";

let styles = Section1Styles;

class Section1 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dataSheetModalFlag: false
        };
    }

    handleDataSheetModalOpen = () => {
        // window.open(RoutePath.dataSheetPath,'_blank')
        this.setState({ dataSheetModalFlag: true });
    };

    handleDataSheetModalClose = () => {
        // window.open(RoutePath.dataSheetPath,'_blank')
        this.setState({ dataSheetModalFlag: false });
    };

    render() {
        let { classes, width } = this.props;
        let { dataSheetModalFlag } = this.state;
        return (
            <div className={classes.root}>
                <div className={classes.headerImage}>
                    <img
                        src={"/assets/images/resource/support-cover.png"}
                        className={clsx(classes.headerImage, "mediumMb")}
                    />
                </div>

                <div className={"textContent mediumMb "}>
                    For technical assistance regarding your installation or
                    apps, contact us on
                </div>
                <div className={"mediumMb ml40"}>
                    <span className={`textContent `}>
                        <i className={`far fa-envelope ${classes.linkIcons}`} />
                        <a
                            className={"textDecorationNone ml10"}
                            href={"mailto:support@awiros.com"}
                        >
                            support@awiros.com
                        </a>
                    </span>
                    <span className={`textContent ml40`}>
                        <i className={`fas fa-phone ${classes.linkIcons}`} />
                        <a
                            className={"textDecorationNone ml10"}
                            href={"tel:+911244263789"}
                        >
                            +911244263789
                        </a>
                    </span>
                </div>

                <div className={"textContent bold mediumMb "}>
                    Raise a support request by clicking{" "}
                    <span
                        className={classes.clickHereLink}
                        onClick={this.handleDataSheetModalOpen}
                    >
                        here
                    </span>
                </div>

                <Modal
                    open={dataSheetModalFlag}
                    disableEnforceFocus
                    disableAutoFocus
                    onClose={this.handleDataSheetModalClose}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                    className={classes.modal}
                >
                    <Card className={classes.supportFormCardContainer}>
                        <CardHeader title="Please fill your Query in this form" />
                        <CardContent>
                            <SupportForm />
                        </CardContent>
                    </Card>
                </Modal>
            </div>
        );
    }
}

export default withWidth()(withStyles(styles)(Section1));
