import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let NotableDeploymentStyles = {
  NotableDeploymentContainer: {
    // aspectRatio:'1920/1050',
    "@media (max-width:768px)": {
      aspectRatio: "1920/1650",
      marginBottom: "30px",
    },
  },
  heading: {
    fontSize: "55px",
    fontWeight: "600",
    textAlign: "center",
    marginTop: "8%",
    marginBottom: "5%",
    "@media (max-width:1440px)": {
      fontSize: "36px",
    },
    "@media (max-width:1024px)": {
      fontSize: "24px",
    },
    // '@media (max-width:768px)':{
    //     fontSize:'42px',
    // },
    "@media (max-width:768px)": {
      fontSize: "24px",
    },
  },
  cardContainer: {
    display: "flex",
    justifyContent: "space-around",
    // paddingLeft:'10%',
    // paddingRight:'10%'
  },
  tp: {
    width: "100%",
  },
  DesktopViewContainer: {
    "@media (max-width:768.9px)": {
      display: "none",
    },
  },
  MobileViewContainer: {
    "@media (min-width:768.9px)": {
      display: "none",
    },
  },
  NDCard: {
    // height:'100%',
    width: "23%",
    height: "650px",
    position: "relative",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
    borderRadius: "10px",
    marginBottom: "5%",
    boxShadow: "rgba(60, 93, 148, 1) 0px 5px 15px",
    // height:'max-content',
    // boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    // boxShadow: '0px 2px 2px 0px rgba(99,89,99,0.5)',

    "@media (max-width:1600px)": {
      height: "500px",
    },
    "@media (max-width:1600px)": {
      width: "26%",
      height: "450px",
    },
    "@media (max-width:1080px)": {
      height: "400px",
      width: "28%",
    },
    "@media (max-width:768px)": {
      width: "45%",
      margin: "0 auto",
    },
    "@media (max-width:600px)": {
      width: "65%",
      margin: "0 auto",
    },
    // '@media (max-width:500px)':{
    //     width:'60%',
    //     margin:'0 auto'
    // },
  },
  image: {
    width: "100%",
    height: "50%",
    // aspectRatio:'1/1',
    // borderRadius:"2%",

    // backgroundColor:"black"
    // boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
  },
  detailContainer: {
    height: "55%",
    width: "100%",
    textAlign: "center",
    // position:'absolute',
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    backgroundColor: "white",
    borderRadius: "2%",
    // border:"1px solid black",
    // top:'45%',
    zIndex: "5",
    marginTop: "-20px",

    padding: "10% 3% 0% 3%",
    boxShadow: "rgba(0, 0, 0, 0.35) 0px 0px 15px 0px",
    "@media (max-width:1440px)": {
      // paddingTop:'5%',
    },
    // '@media (max-width:1080px)':{
    //     padding:'10% 2% 0% 2% ',
    //     height:'62%',
    //     marginTop:'-20%',
    //     marginBottom:'30%'
    // },
    // '@media (max-width:768px)':{

    //     marginTop:'-35%',
    //    paddingBottom:'10%'
    // },
  },
  detailHeading: {
    textAlign: "center",
    fontSize: "28px",
    color: "black",
    marginBottom: "2%",
    // border:'1px solid black',
    height: "20%",
    fontWeight: "500",
    "@media (max-width:1600px)": {
      fontSize: "24px",
    },
    "@media (max-width:1440px)": {
      marginBottom: "1%",
      fontSize: "20px",
    },
    "@media (max-width:1024px)": {
      fontSize: "20px",
      marginBottom: "5%",
    },
    "@media (max-width:768px)": {
      fontSize: "20px",
    },
  },
  detailDescription: {
    // textAlign:'center',
    padding: "5%",
    fontSize: "24px",
    color: "black",
    fontWeight: "300",
    // border:'1px solid black',
    height: "70%",
    marginTop: "3%",
    "@media (max-width:1600px)": {
      fontSize: "20px",
    },
    "@media (max-width:1440px)": {
      padding: "3%",
      fontSize: "18px",
    },
    "@media (max-width:1080px)": {
      fontSize: "14px",
    },
    "@media (max-width:768px)": {
      fontSize: "16px",
    },
  },
};

export default NotableDeploymentStyles;
