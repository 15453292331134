import StyleConfig from "../../../../../lib/StyleConfig";

let Section4CardStyles = {
   root: {
      // width:'100%',
      // height:'100%',
      // border:'1px solid',
      // borderColor:StyleConfig.mainThemeColor,
      // padding:20,
   },
   appName: {
      fontSize: 25,
      fontWeight: 380,
      color: StyleConfig.headingColor,
      textAlign: 'left'
   },
   textContent: {
      fontSize: 20,
      fontWeight: 300,
      textAlign: 'justify'
   },
   appIcon: {
      width: '80%',
      // border:'1px solid lightgrey'
   }
};

export default Section4CardStyles;