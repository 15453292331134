import StyleConfig from "../../../lib/StyleConfig";

let CarouselComponentWithDotsStyles = {
   
   root: {
      // border:'1px solid black'
   },
   imageContainer: {
      '-webkit-box-shadow': '0px 0px 18px -4px rgba(119,119,119,1)',
      '-moz-box-shadow': '0px 0px 18px -4px rgba(119,119,119,1)',
      boxShadow: '0px 0px 18px -4px rgba(119,119,119,1)',
   },
   dotGroup: {
      marginTop: 40,
      height:"20px",
      // border:"1px solid black",
     
      '& button': {
         fontSize: 11.2,
         color: StyleConfig.mainThemeColor,
         borderRadius: 50,
         border: 0,
         backgroundColor: 'transparent',
         transition: 'all 500ms ease'
      },
      '& .carousel__dot--selected': {
         fontSize: 16,
      },
      '@media (max-width:600px)': {
         marginTop: 20,
      }
   },
   componentCard: {
      margin: 10,
      // height: 548
   }
};

export default CarouselComponentWithDotsStyles;