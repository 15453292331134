import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import DashboardStyles from "./dashboardStyles";
import { toast } from "material-react-toastify";
import { env } from "../../../env";

const AppathonDashboard = (props) => {
  const { classes } = props;
  const [userValidated, setUserValidated] = useState(false);
  const [password, setPassword] = useState("");

  const [dashBoardData, setDashBoardData] = useState({});

  const handleSubmit = () => {
    const fetchData = async () => {
      await axios
        .post(
          `${env.REACT_APP_BACKEND_URL}/awiros/website/team/appathon_dashboard`,
          {
            password,
          }
        )
        .then((res) => {
          if (res.data.success) {
            setDashBoardData(res.data);
            setUserValidated(true);
          } else {
            toast.error(res.data.message || "Failed to fetch data");
          }
        })
        .catch(function (error) {
          if (error?.response?.status === 429) {
            toast.error("Too may request, Please try after some time");
            return;
          }
          console.log(error, "error");
        });
    };

    fetchData();
  };
  // useEffect(() => {
  //   const fetchData = async () => {
  //     await axios
  //       .get(
  //         `${env.REACT_APP_BACKEND_URL}/awiros/website/team/appathon_dashboard`
  //       )
  //       .then((res) => {
  //         if (res.data.success) {
  //           setDashBoardData(res.data);
  //         } else {
  //           toast.error("Failed to fetch data");
  //         }
  //       })
  //       .catch(function (error) {
  //         if (error?.response?.status === 429) {
  //           toast.error("Too may request, Please try after some time");
  //           return;
  //         }
  //         console.log(error, "error");
  //       });
  //   };

  //   fetchData();
  // }, []);

  return (
    <div>
      {userValidated ? (
        <div className={classes.DashboardContainer}>
          <div className={classes.dashboardHeading}>APP-A-THON 2.0</div>
          <div className={classes.dashboard}>
            <div
              style={{ display: "flex", gap: "20px", flexDirection: "column" }}
            >
              <div className={classes.dashboardTotalRegistrationContainer}>
                <div className={classes.dashboardDetailsHeading}>
                  Total Registrations
                </div>
                <div className={classes.dashboardCard}>
                  {dashBoardData?.totalRegisteredTeams}
                </div>
              </div>
              {/* <div className={classes.dashboardTotalRegistrationContainer}>
                <div className={classes.dashboardDetailsHeading}>
                  Registrations from website
                </div>
                <div className={classes.dashboardCard}>
                  {dashBoardData?.totalRegisteredTeams}
                </div>
              </div> */}
            </div>
            <div className={classes.dashboardTotalRegistrationContainer}>
              <div className={classes.dashboardDetailsHeading}>
                Recent Registrations
              </div>
              <div className={classes.dashboardTeams}>
                {(dashBoardData?.recentRegisteredTeams || []).map(
                  (team, index) => (
                    <div className={classes.dashboardTeam} key={index}>
                      <span>
                        {team.team_name} ({team.team_size} Participants)
                      </span>
                      <span>
                        {new Date(team.awi_creation).toLocaleString()}
                      </span>
                    </div>
                  )
                )}
                {(dashBoardData?.recentRegisteredTeams || []).map(
                  (team, index) => (
                    <div className={classes.dashboardTeam} key={index}>
                      <span>
                        {team.team_name} ({team.team_size} Participants)
                      </span>
                      <span>
                        {new Date(team.awi_creation).toLocaleString()}
                      </span>
                    </div>
                  )
                )}
                {(dashBoardData?.recentRegisteredTeams || []).map(
                  (team, index) => (
                    <div className={classes.dashboardTeam} key={index}>
                      <span>
                        {team.team_name} ({team.team_size} Participants)
                      </span>
                      <span>
                        {new Date(team.awi_creation).toLocaleString()}
                      </span>
                    </div>
                  )
                )}
                {(dashBoardData?.recentRegisteredTeams || []).map(
                  (team, index) => (
                    <div className={classes.dashboardTeam} key={index}>
                      <span>
                        {team.team_name} ({team.team_size} Participants)
                      </span>
                      <span>
                        {new Date(team.awi_creation).toLocaleString()}
                      </span>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className={classes.login}>
            <spanc className={classes.inputLabel}>Password : </spanc>
            <input
              type="password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              name="password"
              className={classes.input}
            ></input>
            <button className={classes.submit} onClick={handleSubmit}>
              Submit
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default withRouter(
  withStyles(DashboardStyles, { withTheme: true })(AppathonDashboard)
);
