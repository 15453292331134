import StyleConfig from "../../../../../lib/StyleConfig";

let Section4CardStyles = {
   root: {
      fontSize: 18,
      fontWeight: 300,
      color: StyleConfig.headingColor
   },
   thumbnailImage: {
      width: '100%'
   }
};

export default Section4CardStyles;