import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import InfiniteCarouselStyles from "./InfiniteCarouselStyles";
import {ButtonPlay, CarouselProvider, Slide, Slider,} from "pure-react-carousel";
import withWidth from "@material-ui/core/withWidth";

let styles = InfiniteCarouselStyles;

class InfiniteCarousel extends Component {
   static defaultProps = {
      isPlaying: true,
      interval: 5000,
      step: 1,
      aspectRatioWidth: 358,
      aspectRatioHeight: 600,
      carouselContent: [],
      visibleSlidesXl: 3,
      visibleSlidesLg: 3,
      visibleSlidesMd: 3,
      visibleSlidesSm: 2,
      visibleSlidesXs: 1,
   }

   render() {
      let {classes, width, carouselContent} = this.props;
      //let carouselDataToUse = [...carouselContent, ...carouselContent, ...carouselContent, ...carouselContent, ...carouselContent, ...carouselContent, ...carouselContent, ...carouselContent, ...carouselContent, ...carouselContent, ...carouselContent];
      let carouselDataToUse = [...carouselContent, ...carouselContent];
      let visibleSlides = 3;

      if (width === 'xl') {
         visibleSlides = this.props.visibleSlidesXl
      }
      if (width === 'lg') {
         visibleSlides = this.props.visibleSlidesLg
      } else if (width === 'md') {
         visibleSlides = this.props.visibleSlidesMd
      } else if (width === 'sm') {
         visibleSlides = this.props.visibleSlidesSm
      } else if (width === 'xs') {
         visibleSlides = this.props.visibleSlidesXs
      }
      // console.log(ButtonPlay);
      return (
         <div className={classes.root}>
            <CarouselProvider
               infinite
               isPlaying={true}
               interval={this.props.interval}
               lockOnWindowScroll={true}
               isIntrinsicHeight
               step={this.props.step}
               // dragEnabled={carouselDataToUse.length > 3}
               visibleSlides={visibleSlides}
               totalSlides={carouselDataToUse.length}
               currentSlide={Math.floor(carouselDataToUse.length / 2)}
               naturalSlideWidth={this.props.aspectRatioWidth}
               naturalSlideHeight={this.props.aspectRatioHeight}
               className={classes.carouselProvider}
            >
               <Slider
                  privateUnDisableAnimation
                  isPlaying={this.props.isPlaying}
                  className={classes.slider}
               >
                  {carouselDataToUse.map((cardData, index) => {
                     return (
                        <Slide index={index} key={index} className={classes.testimonialCard}>
                           <this.props.Component index={index} cardData={cardData}/>
                        </Slide>
                     )
                  })}
               </Slider>
            </CarouselProvider>
         </div>
      );
   }
}

export default withWidth()(withStyles(styles)(InfiniteCarousel));
