import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import DevelopersPageStyles from "./DevelopersPageStyles";
import Section1 from "./Sections/Section1/Section1";
import Section2 from "./Sections/Section2/Section2";
import Section5 from "./Sections/Section5/Section5";
import Section5_1 from "./Sections/Section6/Section6";
import Section3 from "./Sections/Section3/Section3";
import Section4 from "./Sections/Section4/Section4";

let styles = DevelopersPageStyles;

class DevelopersPage extends Component {
   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <Section1/>

            <Section2/>

            <Section5_1/>

            <Section5/>

            <Section4/>

            <Section3/>

         </div>
      );
   }
}

export default withStyles(styles)(DevelopersPage)