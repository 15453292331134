let NewsCarouselConfig = [
    {
        heading:
            "Awiros: An Intuitive Delivery Mechanism for Video Intelligence and Analytics",
        subheading:
            "Today, companies at the vanguard of AI (Artificial Intelligence), are using the power of video intelligence analytics to exploit the massive volume of data generated by the technology. Vikram Gupta, founder, Awiros, was also drawn to this area after the completion of his Ph.D. at Carnegie Mellon University...",
        content: "",
        link: "https://blog.t-hub.co/awiros-an-intuitive-delivery-mechanism-for-video-intelligence-and-analytics-37e93a67e29d",
        thumbnail:
            "../../../../../assets/images/resource/news-thumbnails/t-hub.png"
    },
    {
        heading:
            "Telangana government takes help of artificial intelligence projects",
        subheading:
            "The Telangana government, as part of its ‘Year of AI’ initiative, launched two new projects which use artificial intelligence (AI). Both projects are part of the state government’s efforts to make government services more efficient with emerging technologies such as AI and blockchain.",
        link: "https://www.deccanchronicle.com/nation/current-affairs/030120/telangana-government-takes-help-of-artificial-intelligence-projects.html",
        thumbnail:
            "../../../../../assets/images/resource/news-thumbnails/deccan-chronicle.jpeg"
    },
    {
        heading:
            "Telangana Police to tap into artificial intelligence for crowd estimation",
        subheading:
            "HYDERABAD: Telangana police’s intelligence wing on Thursday tied up with a Gurugram-based start-up to provide solutions for crowd management, especially in mega events like Sammakka-Saralamma Jatara at Medaram, considered one of the biggest tribal fairs in Asia...",
        link: "https://timesofindia.indiatimes.com/city/hyderabad/telangana-police-to-tap-into-artificial-intelligence-for-crowd-management/articleshow/73079647.cms",
        thumbnail:
            "../../../../../assets/images/resource/news-thumbnails/toi.png"
    },
    {
        heading:
            "Telangan's AI solution for crowd management wins Tech Sabha Award 2020",
        subheading:
            "Telangana Government's Crowd Management solution won the Indian Express Technology Sabha Award 2020. Technology Sabha Awards are given to Government departments that demonstrate innovative use of technology for distinct benefits to stakeholders.",
        link: "http://www.uniindia.com/telangana-s-ai-solution-for-crowd-management-wins-tech-sabha-award-2020/south/news/2138694.html",
        thumbnail:
            "../../../../../assets/images/resource/news-thumbnails/uniindia.png"
    },
    {
        heading: "Telangana bags two gold awards, a silver at SKOCH",
        subheading:
            "HYDERABAD: Telangana’s efforts in adoption of emerging technologies for transforming governance and citizen service delivery paid rich dividends when it won two gold and one silver awards at the 66th SKOCH Summit on Thursday. The State won a gold and a silver in Governance Category...",
        link: "https://www.newindianexpress.com/states/telangana/2020/jul/31/telangana-bags-two-gold-awards-a-silver-at-skoch-2177093.html",
        thumbnail:
            "../../../../../assets/images/resource/news-thumbnails/express.jpg"
    },
    {
        heading:
            "T-Hub and Raytheon select 3 startups as finalists for the RTX Innovation Challenge",
        subheading:
            "Startups across the globe were invited to compete. Out of the 70 startups, T-Hub and Raytheon Technologies shortlisted three for the Computer Vision challenge. Each received a grant funding from RTX to build a proof of concept (PoC).",
        link: "https://t-hub.co/raytheon-t-hub-rtxinnovationchallenge/?utm_source=SMP&utm_medium=PRPage&utm_campaign=RTXIC",
        thumbnail:
            "../../../../../assets/images/resource/news-thumbnails/t-hub-raytheon.jpg"
    },
    {
        heading:
            "T-Hub and Raytheon select 3 startups as finalists for the RTX Innovation Challenge",
        subheading:
            "T-Hub and Raytheon Technologies has announced three finalists for a challenge the companies are running to develop a tool to improve how Raytheon Technologies assesses required aircraft engine repairs.",
        link: "https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/t-hub-raytheon-technologies-select-three-startups-as-finalists-for-rtx-innovation-challenge/articleshow/77906609.cms",
        thumbnail:
            "../../../../../assets/images/resource/news-thumbnails/et.jpg"
    },
    {
        heading:
            "Funding Galore: Indian Startup Funding Of The Week [August 31- September 5]",
        subheading:
            "T-Hub and global aerospace and defence company Raytheon Technologies has shortlisted three startups for its RTX Innovation Challenge. The three finalists are Awiros, Utvyakta Solutions and PRENAV.",
        link: "https://inc42.com/buzz/funding-galore-indian-startup-funding-of-the-week-august-31-september-5/",
        thumbnail:
            "../../../../../assets/images/resource/news-thumbnails/inc42.png"
    }
];

export default NewsCarouselConfig;
