import { makeStyles, withStyles } from "@material-ui/styles";
import AppSection from "./AppSection/indexTwo";
import CarouselComponentWithDots from "../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import IndustriesPageFiveStyles from "./IndustriesPageFiveStyles";
import {
  appSelection,
  customers1,
  customers2,
  partners1,
  partners2,
  mainBannerData,
  bannerData,
} from "./utils/indexFive";

import CustomersAndPartnersMobileViewComp from "./componentsForTwoPage/CustomersAndPartnersMobileViewComp";
import CustomersAndPartnersDesktopViewComponent from "./componentsForTwoPage/CustomersAndPartnerDesktopViewComponent";

import { useEffect } from "react";
import Banner from "./ComponentsForThreePage/Banner";

import MainBanner from "./ComponentsForThreePage/MainBanner";
import IntegratingAwirosComp from "./ComponentsForThreePage/IntegratingAwirosComp";
import WhyBetterNew from "./CommonComponents/WhyBetterNew";
import OurSuccessStories from "./ComponentsForFourthPage/OurSuccessStories";
import NotableDeployment from "./ComponentsForThreePage/NotableDeployment";

const IndustriesPageFive = (props) => {
  const { classes } = props;
  let dimensions = useWindowDimensions();

  // for current window size
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  return (
    <section className={classes.rootContainer}>
      <MainBanner
        mainBannerData={mainBannerData}
        increaseLeft={true}
      ></MainBanner>

      {/* Banner */}
      <Banner bannerData={bannerData}></Banner>

      {/* Apps Section */}
      <AppSection>{appSelection}</AppSection>

      {/* Awiros in your city */}

      <IntegratingAwirosComp heading="Awiros Deployment Architecture"></IntegratingAwirosComp>

      {/* Why better */}
      {/* <WhyBetter></WhyBetter> */}
      <WhyBetterNew></WhyBetterNew>

      {/* Notable deployments */}
      {/* <OurSuccessStories></OurSuccessStories> */}
      <NotableDeployment></NotableDeployment>

      <div className={classes.deployments}></div>

      {dimensions.width <= 768 ? (
        <CarouselComponentWithDots
          visibleSlides={1}
          showDots={false}
          contentArray={[
            <CustomersAndPartnersMobileViewComp
              data={customers1}
              heading="Customers"
              subHeading=""
            ></CustomersAndPartnersMobileViewComp>,
            <CustomersAndPartnersMobileViewComp
              data={customers2}
              heading="Customers"
              subHeading=""
            ></CustomersAndPartnersMobileViewComp>,
            <CustomersAndPartnersMobileViewComp
              data={partners1}
              heading="Partners"
              subHeading="OEMs"
            ></CustomersAndPartnersMobileViewComp>,
            <CustomersAndPartnersMobileViewComp
              data={partners2}
              heading="Partners"
              subHeading="System Integrators"
            ></CustomersAndPartnersMobileViewComp>,
          ]}
        />
      ) : (
        <CarouselComponentWithDots
          visibleSlides={1}
          showDots={false}
          contentArray={[
            <CustomersAndPartnersDesktopViewComponent
              data={{
                heading: "Customers",
                allColumns: [
                  { subHeading: "", bothColumns: customers1 },
                  { subHeading: "", bothColumns: customers2 },
                ],
              }}
            ></CustomersAndPartnersDesktopViewComponent>,
            <CustomersAndPartnersDesktopViewComponent
              data={{
                heading: "Partners",
                allColumns: [
                  { subHeading: `OEMs`, bothColumns: partners1 },
                  { subHeading: "System Integrators", bothColumns: partners2 },
                ],
              }}
            ></CustomersAndPartnersDesktopViewComponent>,
          ]}
        />
      )}
      <div className={classes.deployments}></div>
    </section>
  );
};

export default withRouter(
  withStyles(IndustriesPageFiveStyles, { withTheme: true })(IndustriesPageFive)
);
