import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section4Styles from "./Section4Styles";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import Section4Card from "./Section4Card/Section4Card";
import HorizontalTabs from "../../../CommonComponents/HorizontalTabs/HorizontalTabs";

let styles = Section4Styles;

class Section4 extends Component {
    constructor(props) {
        super(props);
        this.tabData = [
            {
                name: "Cloud Deployment",
                content: (
                    <Section4Card
                        thumbnailImage={
                            "/assets/images/resource/cloud-deployment.png"
                        }
                        dotPoints={[
                            "Tailored to work with any cloud service provider",
                            "Easy to setup and use",
                            "Easy access to data & alerts",
                            "Cost-effective",
                            "Scales on demand",
                            "Platform as a service (PaaS)",
                            "On-Demand compute and storage availability"
                        ]}
                    >
                        Awiros Software Platform can be easily deployed and
                        accessed over any public or private cloud. Awiros
                        utilizes the orchestration capabilities of the cloud and
                        provides unmatched flexibility to cater to your Video
                        Intelligence needs. Ideally suited for enterprises,
                        SME’s and Smart Homes.
                    </Section4Card>
                )
            },
            {
                name: "On-Premise Deployment",
                content: (
                    <Section4Card
                        thumbnailImage={
                            "/assets/images/resource/on-premise.png"
                        }
                        dotPoints={[
                            "Data Security and Privacy",
                            "100% control over the software and hardware",
                            "Low Total Cost of Ownership (TCO)"
                        ]}
                    >
                        Awiros Software platform and Awiros Enterprise hardware
                        both can be deployed on-premise. The Awiros platform
                        capabilities can be utilized by creating a cloud like
                        service layer on-premise, hence reducing the cost and
                        increasing the efficiency. Ideal for Smart City,
                        Surveillance, Law Enforcement, Municipal, Traffic and
                        deployments where data security and control is primary.
                    </Section4Card>
                )
            },
            {
                name: "Edge+Central Deployment",
                content: (
                    <Section4Card
                        thumbnailImage={
                            "/assets/images/resource/edge+cloud.png"
                        }
                        dotPoints={[]}
                    >
                        Awiros also supports Edge+Central deployments in a
                        hybrid model. This architecture is best suited for Smart
                        City, Surveillance, Law Enforcement, Municipal, Traffic
                        and deployments with distributed/scattered geographic
                        locations, where there is an immediate need of
                        edge-based analytics. Using a combination of Awiros
                        Platform and Awiros One+, an agile and responsive
                        infrastructure can be created to address the diverse
                        needs of the clients.
                    </Section4Card>
                )
            }
        ];
    }

    render() {
        let { classes } = this.props;
        return (
            <Grid
                container
                direction="column"
                spacing={3}
                justify="center"
                alignItems="center"
                className={classes.root}
            >
                <Grid item>
                    <Typography
                        className={clsx(classes.heading, "bottom-dash")}
                        variant="h5"
                        component="h2"
                        gutterBottom
                    >
                        Awiros Deployment Architectures
                    </Typography>
                </Grid>

                <Grid item container direction={"row"} justify={"center"}>
                    <Grid item xs={12} sm={12} md={10} lg={8}>
                        <HorizontalTabs tabData={this.tabData} />
                    </Grid>
                </Grid>
            </Grid>
        );
    }
}

export default withStyles(styles)(Section4);
