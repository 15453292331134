import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section1Styles from "./Section1Styles";

let styles = Section1Styles;

class Section1 extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                {/*<h2 className={'h2heading center'}>From Concept to Production in 50 lines of code or less</h2>*/}
                <div className={classes.headerImage}>
                    <img
                        src={"/assets/images/resource/developers-cover.png"}
                        className={classes.headerImage}
                    />
                </div>
                <div className={"textContent justify"}>
                    The scope of application of Video AI is so wide that it
                    cannot be catered to by a single entity alone, which is why
                    Awiros is building the world’s first community of Video AI
                    developers. In the form of Awiros Spira, we are stitching
                    together an ecosystem of talent, resources, products and
                    business opportunities for the entire community to benefit
                    mutually. It is a comprehensive program for onboarding apps
                    from Computer Vision and AI developers onto the Awiros
                    AppStack where they can productize, market as well as
                    monetize their Video AI algorithms.
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(Section1);
