import React from "react";
import CardSet from "./CardSet/CardSet";

let TabConfig = [
    {
        name: "Smart & Safe Cities",
        content: (
            <CardSet
                thumbnailImage={
                    "../../../../assets/images/resource/smart-city.png"
                }
                cardsData={[
                    {
                        awi_name: "Facial Recognition",
                        icon: "./../../../../assets/images/resource/app-icons/frs.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Identify and track faces across multiple locations in real-time."
                    },
                    {
                        awi_name: "Number Plate Recognition",
                        icon: "./../../../../assets/images/resource/app-icons/npr.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "High accuracy vehicle NPR without any specialized cameras."
                    },
                    {
                        awi_name: "Crowd Counting",
                        icon: "./../../../../assets/images/resource/app-icons/crowd-counting.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Estimate crowd densities of any scale in real time using cameras"
                    },

                    {
                        awi_name: "Advanced Intrusion Detection",
                        icon: "./../../../../assets/images/resource/app-icons/advanced-intrusion.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Detect movement across a user-defined virtual line or perimeter."
                    },

                    {
                        awi_name: "Camera Health Monitoring",
                        icon: "./../../../../assets/images/resource/app-icons/tampering.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified about changes in the status of your cameras"
                    },
                    {
                        awi_name: "Helmet Violation Detection",
                        icon: "./../../../../assets/images/resource/app-icons/helmet.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Two-wheeler riders who do not have a helmet on can be identified."
                    },

                    {
                        awi_name: "Vehicle Counting",
                        icon: "./../../../../assets/images/resource/app-icons/vehicle-counting.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Count vehicles of different types and track variations in traffic movement"
                    },

                    {
                        awi_name: "Garbage Detection",
                        icon: "./../../../../assets/images/resource/app-icons/garbage.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Garbage accumulated in a region gets automatically identified"
                    }
                ]}
            />
        )
    },

    {
        name: "Smart Traffic",
        content: (
            <CardSet
                thumbnailImage={
                    "../../../../assets/images/resource/traffic.png"
                }
                cardsData={[
                    {
                        awi_name: "Number Plate Recognition",
                        icon: "./../../../../assets/images/resource/app-icons/npr.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "High accuracy vehicle NPR without any specialized cameras."
                    },

                    {
                        awi_name: "Vehicle Classification",
                        icon: "./../../../../assets/images/resource/app-icons/vehicle-classification.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Identify and search vehicles based on attributes like type, color and model."
                    },

                    {
                        awi_name: "Helmet Violation Detection",
                        icon: "./../../../../assets/images/resource/app-icons/helmet.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Two-wheeler riders who do not have a helmet on can be identified."
                    },

                    {
                        awi_name: "Zebra Crossing Violation",
                        icon: "./../../../../assets/images/resource/app-icons/zebra-crossing.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Identify vehicles overstepping the Zebra Crossing at a red light."
                    },

                    {
                        awi_name: "Wrong-Way Detection",
                        icon: "./../../../../assets/images/resource/app-icons/wrong-way.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Identify vehicles moving in the wrong direction"
                    },

                    {
                        awi_name: "Parking Violation Detection",
                        icon: "./../../../../assets/images/resource/app-icons/parking.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Wrongly parked vehicles can easily be identified"
                    },

                    {
                        awi_name: "Speed Violation Detection",
                        icon: "./../../../../assets/images/resource/app-icons/speeding.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Identify vehicles violating the speed limit."
                    },

                    {
                        awi_name: "Red Light Violation Detection",
                        icon: "./../../../../assets/images/resource/app-icons/rlvd.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get automatic alerts for vehicles violating the red light."
                    }
                ]}
            />
        )
    },

    {
        name: "Forensics",
        content: (
            <CardSet
                thumbnailImage={
                    "../../../../assets/images/resource/forensics.png"
                }
                cardsData={[
                    {
                        awi_name: "Vehicle Search",
                        icon: "./../../../../assets/images/resource/app-icons/vehicle-classification.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Identify and search vehicles based on attributes like type, color and model."
                    },

                    {
                        awi_name: "Attribute based Person Search",
                        icon: "./../../../../assets/images/resource/app-icons/person-search.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Search for persons using various attributes."
                    },

                    {
                        awi_name: "Person Tracking using Face",
                        icon: "./../../../../assets/images/resource/app-icons/frs.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Track the movement of a person of interest across multiple locations"
                    },

                    {
                        awi_name: "Video Summarization & Synopsis",
                        icon: "./../../../../assets/images/resource/app-icons/video-summarization-and-synopsis.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "View the contents of long surveillance videos in just a few minutes."
                    }
                ]}
            />
        )
    },

    {
        name: "Manufacturing",
        content: (
            <CardSet
                thumbnailImage={
                    "../../../../assets/images/resource/manufacturing.png"
                }
                cardsData={[
                    {
                        awi_name: "PPE Detection",
                        icon: "./../../../../assets/images/resource/app-icons/ppe.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Be notified if workers are not wearing proper protective equipment."
                    },

                    {
                        awi_name: "Fire Detection",
                        icon: "./../../../../assets/images/resource/app-icons/fire.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Detects the presence of fire in a camera’s view"
                    },

                    {
                        awi_name: "Smoke Detection",
                        icon: "./../../../../assets/images/resource/app-icons/smoke.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "The app automatically detects the presence of smoke in a camera’s view"
                    },

                    {
                        awi_name: "Bag/Object Counting",
                        icon: "./../../../../assets/images/resource/app-icons/cement-bag.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified if a camera is being tampered"
                    },
                    {
                        awi_name: "FR based Attendance",
                        icon: "./../../../../assets/images/resource/app-icons/attendance.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Complete attendance management using Facial Recognition"
                    },
                    {
                        awi_name: "Face Mask Detection",
                        icon: "./../../../../assets/images/resource/app-icons/mask-ppe.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified if an individual is not wearing a face mask"
                    },
                    {
                        awi_name: "Thermal Scanning",
                        icon: "./../../../../assets/images/resource/app-icons/temperature.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified if the body temperature of an individual is high"
                    },
                    {
                        awi_name: "Social Distancing Detection",
                        icon: "./../../../../assets/images/resource/app-icons/social-distancing.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified if social distancing norms are being violated"
                    }
                ]}
            />
        )
    },

    {
        name: "Retail",
        content: (
            <CardSet
                thumbnailImage={"../../../../assets/images/resource/retail.png"}
                cardsData={[
                    {
                        awi_name: "Footfall Analytics",
                        icon: "./../../../../assets/images/resource/app-icons/footfall.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Know the footfall of a store/section for every hour, day, week, or month"
                    },

                    {
                        awi_name: "Dwell Time Estimation",
                        icon: "./../../../../assets/images/resource/app-icons/dwell-time.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Know the average time an average customer spends in a section/aisle"
                    },

                    {
                        awi_name: "Customer Journey Tracking",
                        icon: "./../../../../assets/images/resource/app-icons/customer-journey.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Identify shopping paths followed by customers inside the store."
                    },

                    {
                        awi_name: "Visual Inventory Management",
                        icon: "./../../../../assets/images/resource/app-icons/visual-inventory.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Helps you keep track of the on-shelf inventory."
                    },
                    {
                        awi_name: "FR based Attendance",
                        icon: "./../../../../assets/images/resource/app-icons/attendance.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Complete attendance management using Facial Recognition"
                    },
                    {
                        awi_name: "Face Mask Detection",
                        icon: "./../../../../assets/images/resource/app-icons/mask-ppe.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified if an individual is not wearing a face mask"
                    },
                    {
                        awi_name: "Thermal Scanning",
                        icon: "./../../../../assets/images/resource/app-icons/temperature.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified if the body temperature of an individual is high"
                    },
                    {
                        awi_name: "Social Distancing Detection",
                        icon: "./../../../../assets/images/resource/app-icons/social-distancing.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified if social distancing norms are being violated"
                    }
                ]}
            />
        )
    },

    // {
    //    name: 'Enterprises',
    //    content: (
    //       <CardSet
    //          thumbnailImage={'../../../../assets/images/resource/enterprises.png')}
    //          cardsData={[
    //             {
    //                awi_name: 'Camera Health Check',
    //                icon: './../../../../assets/images/resource/app-icons/ppe.png'),
    //                awi_developer: 'Awidit Systems',
    //                description:'Get notified if a camera is being tampered',
    //             },
    //             {
    //                awi_name: 'Camera Health Check',
    //                icon: './../../../../assets/images/resource/app-icons/ppe.png'),
    //                awi_developer: 'Awidit Systems',
    //                description:'Get notified if a camera is being tampered',
    //             },
    //             {
    //                awi_name: 'Camera Health Check',
    //                icon: './../../../../assets/images/resource/app-icons/ppe.png'),
    //                awi_developer: 'Awidit Systems',
    //                description:'Get notified if a camera is being tampered',
    //             },
    //             {
    //                awi_name: 'Camera Health Check',
    //                icon: './../../../../assets/images/resource/app-icons/ppe.png'),
    //                awi_developer: 'Awidit Systems',
    //                description:'Get notified if a camera is being tampered',
    //             },
    //             {
    //                awi_name: 'Camera Health Check',
    //                icon: './../../../../assets/images/resource/app-icons/ppe.png'),
    //                awi_developer: 'Awidit Systems',
    //                description:'Get notified if a camera is being tampered',
    //             },
    //       ]}/>
    //    )
    // },

    {
        name: "Smart Homes",
        content: (
            <CardSet
                thumbnailImage={
                    "../../../../assets/images/resource/smart-home.png"
                }
                cardsData={[
                    {
                        awi_name: "Facial Recognition",
                        icon: "./../../../../assets/images/resource/app-icons/frs.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Know about every individual who walks into your perimter."
                    },
                    {
                        awi_name: "Advanced Intrusion Detection",
                        icon: "./../../../../assets/images/resource/app-icons/advanced-intrusion.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Check the entry of intruders into your perimeter"
                    },
                    {
                        awi_name: "Camera Health Monitoring",
                        icon: "./../../../../assets/images/resource/app-icons/tampering.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Know if your cameras are being tampered with"
                    },
                    {
                        awi_name: "Illegal Parking Detection",
                        icon: "./../../../../assets/images/resource/app-icons/parking.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Know if a vehicle is improperly parked in and around your property"
                    }
                ]}
            />
        )
    },

    {
        name: "Warehousing",
        content: (
            <CardSet
                thumbnailImage={
                    "../../../../assets/images/resource/warehousing.png"
                }
                cardsData={[
                    {
                        awi_name: "SKU Counting",
                        icon: "./../../../../assets/images/resource/app-icons/box-counting.png",
                        description:
                            "Automatically count the no. of SKUs as they move on the conveyor belt"
                    },

                    {
                        awi_name: "Visual Inventory Management",
                        icon: "./../../../../assets/images/resource/app-icons/visual-inventory.png",
                        description:
                            "Helps you keep track of the on-shelf inventory."
                    },
                    {
                        awi_name: "FR based Attendance",
                        icon: "./../../../../assets/images/resource/app-icons/attendance.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Complete attendance management using Facial Recognition"
                    },
                    {
                        awi_name: "Face Mask Detection",
                        icon: "./../../../../assets/images/resource/app-icons/mask-ppe.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified if an individual is not wearing a face mask"
                    },
                    {
                        awi_name: "Thermal Scanning",
                        icon: "./../../../../assets/images/resource/app-icons/temperature.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified if the body temperature of an individual is high"
                    },
                    {
                        awi_name: "Social Distancing Detection",
                        icon: "./../../../../assets/images/resource/app-icons/social-distancing.png",
                        // awi_developer: 'Awidit Systems',
                        description:
                            "Get notified if social distancing norms are being violated"
                    }
                ]}
            />
        )
    }
];

export default TabConfig;
