import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import CarouselComponentWithDots from "../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";
import IndustriesPageStyles from "./IndustriesPageStyles";
import {
  bannerData,
  appSelection,
  customers1,
  customers2,
  partners1,
  partners2,
} from "./utils/index";
import AppSection from "./AppSection/indexTwo";
import { useEffect } from "react";
import CustomersAndPartnersFirst from "./ComponentsForFirstPage/CustomersAndPartnersFirst";
import NotableDeploymentsFirst from "./ComponentsForFirstPage/NotableDeploymentsFirst";
import IntegratingAwirosComp from "./ComponentsForThreePage/IntegratingAwirosComp";
import WhyBetterNew from "./CommonComponents/WhyBetterNew";
import CustomersAndPartnersMobileViewComp from "./componentsForTwoPage/CustomersAndPartnersMobileViewComp";
import CustomersAndPartnersDesktopViewComponent from "./componentsForTwoPage/CustomersAndPartnerDesktopViewComponent";

const IndustriesPage = (props) => {
  const { classes } = props;

  // current window dimensions
  let dimensions = useWindowDimensions();

  // for current window size
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const renderBannerCards = () => {
    return bannerData.map((banner, index) => {
      const {
        title,
        apps,
        areaCovered,
        cameraCount,
        bartnote,
        bartnoteUrl,
        imageUrl,
        livesImpacted,
        subTitle,
        videoUrl,
      } = banner;
      return (
        <div className={classes.carousel} key={index}>
          <div
            className={classes.carouselDetailsConatiner}
            style={{
              backgroundImage: `linear-gradient(to left, transparent 0%, black 100%),url("${imageUrl}")`,
              backgroundSize: "cover",
            }}
          >
            <div className={classes.carouselDetails}>
              <h1 className={classes.bannerTitle}>{title}</h1>
              <p className={classes.bannerSubtitle}>{subTitle}</p>
              <div className={classes.carouselMetrics}>
                <div className={classes.metrics}>
                  <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/cctv.svg" />
                  <div className={classes.metricData}>
                    <span>{cameraCount}</span>
                    <span>Cameras</span>
                  </div>
                </div>
                <div className={classes.metrics}>
                  <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/carbon_area.svg" />
                  <div className={classes.metricData}>
                    <span>{areaCovered}</span>
                    <span>Area Covered</span>
                  </div>
                </div>
                <div className={classes.metrics}>
                  <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps.svg" />
                  <div className={classes.metricData}>
                    <span>{apps}</span>
                    <span>Apps</span>
                  </div>
                </div>
                <div className={classes.metrics}>
                  <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/people.svg" />
                  <div className={classes.metricData}>
                    <span>{livesImpacted}</span>
                    <span>Lives Impacted</span>
                  </div>
                </div>
              </div>
              <div className={classes.contactContainer}>
                <button className={classes.contact}>
                  <a href="/contact">
                    <span className={classes.contactUSButton}>Contact Us</span>
                    <img
                      className={classes.carretRight}
                      src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/carret--right.svg"
                      alt="Carret right"
                    />
                  </a>
                </button>
              </div>
            </div>
            <div className={classes.carouselVideo}>
              <video
                autoPlay
                loop
                muted
                controls={false}
                playsInline={true}
                src={videoUrl}
                type="video/mp4"
              ></video>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <section className={classes.rootContainer}>
        {/* Banner Carousel */}

        <CarouselComponentWithDots
          cardComponentClasses={classes.cardComponent}
          contentArray={renderBannerCards()}
          interval={6000}
        />

        {/* Hero section */}

        <div className={classes.heroSection}>
          <div>
            <p className={classes.heroSectionTitle}>
              Transforming Smart Cities with Video AI
            </p>
          </div>
          {dimensions.width <= 768 ? (
            <p className={classes.heroSectionSummary}>
              Check out our tailored use-cases that can revolutionize safety and
              traffic management in your city.
            </p>
          ) : (
            <p className={classes.heroSectionSummary}>
              Awiros with its diverse marketplace and dynamic deployment
              offerings has become the go-to Video AI solution for smart cities.
              Check out our tailored use-cases that can revolutionize safety and
              traffic management in your city.
            </p>
          )}
          <a href="/appstack" className={classes.heroButton}>
            <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/hero_apps.svg" />
            VIEW AWIROS APPSTACK
          </a>
          <div
            className={classes.heroSectionUseCase}
            style={{ marginTop: "60px" }}
          >
            <h1>An App for every Use-Case</h1>
          </div>
        </div>

        {/* Apps Section */}

        <AppSection>{appSelection}</AppSection>

        {/* Awiros in your city */}

        {/* <IntegratingAwiros heading='Integrating Awiros In Your Smart City'></IntegratingAwiros> */}
        <IntegratingAwirosComp heading="Awiros Deployment Architecture"></IntegratingAwirosComp>

        {/* why better */}

        {/* <WhyBetter></WhyBetter> */}
        <WhyBetterNew></WhyBetterNew>

        {/* Notable deployments */}

        <NotableDeploymentsFirst></NotableDeploymentsFirst>

        {/* Customers and partners */}
        {/* <CustomersAndPartnersFirst></CustomersAndPartnersFirst> */}
        <div className={classes.deployments}></div>

        {dimensions.width <= 768 ? (
          <CarouselComponentWithDots
            visibleSlides={1}
            showDots={false}
            contentArray={[
              <CustomersAndPartnersMobileViewComp
                data={customers1}
                heading="Customers"
                subHeading=""
              ></CustomersAndPartnersMobileViewComp>,
              <CustomersAndPartnersMobileViewComp
                data={customers2}
                heading="Customers"
                subHeading=""
              ></CustomersAndPartnersMobileViewComp>,
              <CustomersAndPartnersMobileViewComp
                data={partners1}
                heading="Partners"
                subHeading="OEMs"
              ></CustomersAndPartnersMobileViewComp>,
              <CustomersAndPartnersMobileViewComp
                data={partners2}
                heading="Partners"
                subHeading="System Integrators"
              ></CustomersAndPartnersMobileViewComp>,
            ]}
          />
        ) : (
          <CarouselComponentWithDots
            visibleSlides={1}
            showDots={false}
            contentArray={[
              <CustomersAndPartnersDesktopViewComponent
                data={{
                  heading: "Customers",
                  allColumns: [
                    { subHeading: "", bothColumns: customers1 },
                    { subHeading: "", bothColumns: customers2 },
                  ],
                }}
              ></CustomersAndPartnersDesktopViewComponent>,
              <CustomersAndPartnersDesktopViewComponent
                data={{
                  heading: "Partners",
                  allColumns: [
                    { subHeading: `OEMs`, bothColumns: partners1 },
                    {
                      subHeading: "System Integrators",
                      bothColumns: partners2,
                    },
                  ],
                }}
              ></CustomersAndPartnersDesktopViewComponent>,
            ]}
          />
        )}
        {/* <div className={classes.deployments}></div> */}
      </section>
    </div>
  );
};

export default withRouter(
  withStyles(IndustriesPageStyles, { withTheme: true })(IndustriesPage)
);
