import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import AppathonPageStyles from "./AppathonPageStyles";

const AppathonBanner = (props) => {
  const { classes, setRedirectToRegister } = props;
  // current window dimensions
  let dimensions = useWindowDimensions();

  // for current window size
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }
  return (
    <div
      style={{
        background: `url('/assets/appathon/${
          dimensions.width <= 768
            ? "APPATHON_BANNER_BG_MOBILE.webp"
            : "APPATHON_BANNER_BG.webp"
        }')`,
        border: "1px solid black",
        backgroundSize: "cover",
      }}
      className={classes.banner}
    >
      <div className={classes.bannerContent}>
        <img
          className={classes.bannerContentLogo}
          src="/assets/appathon/logo.webp"
        ></img>
        <div className={classes.bannerContentComvision}>COMPUTER VISION</div>
        <div className={classes.bannerContentAppathonHeading}>
          <div className={classes.bannerContentAppathon}>APP-A-THON</div>
          <div className={classes.bannerContentVersion}>2.0</div>
        </div>
        <div className={classes.bannerContentUnveil}>UNVEIL THE UNSEEN</div>

        {/* <button
          className={classes.bannerContentRegisterButton}
          onClick={() => setRedirectToRegister(true)}
        >
          Register Now!
        </button> */}
      </div>
      <div className={classes.bannerPblStatement}>
        <img
          className={classes.bannerPblStatementImage}
          src="/assets/appathon/pblStatementLogo.png"
        ></img>
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(AppathonPageStyles, { withTheme: true })(AppathonBanner)
);
