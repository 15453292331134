import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import SaveDatesStyles from "./SaveDatesStyles";
import { saveTheDates } from "../utils/saveTheDates";
import RoutePath from "../../../lib/RoutePath";

const SaveDates = (props) => {
  const { classes } = props;

  return (
    <div
      className={classes.SaveDates}
      style={{
        background: `url('/assets/appathon/guidelinesBG.png')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#2C2C2C",
      }}
    >
      <div className={classes.saveDatesDesktopContainer}>
        <img
          className={classes.SaveDatesImage}
          src="/assets/appathon/SaveDates.webp"
        ></img>
        <div className={classes.detailedPlan}>
          Read the detailed plan{" "}
          <a
            target="_blank"
            href={RoutePath.appathonSchedule}
            style={{
              textDecoration: "none",
              borderBottom: "1px solid #FC0",
              color: "white",
            }}
          >
            <span style={{ color: "#FC0" }}>HERE</span>
          </a>
        </div>
      </div>
      <div className={classes.mobileViewContainer}>
        <div className={classes.saveDatesContainer}>
          <div className={classes.saveDatesHeading}>SAVES THE DATES</div>
        </div>
        <div className={classes.cardContainer}>
          {saveTheDates.map((ele, index) => (
            <div className={classes.singleCard} key={index}>
              <img
                src={`/assets/appathon/saveTheDates/${ele.imageUrl}`}
                className={classes.imageContainer}
              ></img>
              <div className={classes.singleCardHeading}>{ele.heading}</div>
              <div className={classes.list}>
                {(ele.points || []).map((point, index) => (
                  <li key={index}>{point}</li>
                ))}
              </div>
              <div style={{ color: "#FC0" }}>
                {ele.range ? `${ele.range}` : `${ele.date}`}
              </div>
            </div>
          ))}
        </div>
        <div className={classes.detailedPlan}>
          Read the detailed plan{" "}
          <a
            target="_blank"
            href={RoutePath.appathonSchedule}
            style={{
              textDecoration: "none",
              borderBottom: "1px solid #FC0",
              color: "white",
            }}
          >
            <span style={{ color: "#FC0" }}>HERE</span>
          </a>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(SaveDatesStyles, { withTheme: true })(SaveDates)
);
