import Typography from "@material-ui/core/Typography";
import React, {Component} from 'react';

let Section2Config = [
   {
      heading: 'How can I add more app channels to my subscription?',
      content: (
         <Typography>
            To add more app channels to your subscription you can get in touch with the Awiros Support Team and list the
            apps as well the number of channels of each app you wish to activate. If your processing hardware (server)
            is computationally capable to process the new app channels then you can simply pay for the additional app
            channels based on a subscription plan of your choice and the apps will be made available on your AppStack
            within a day's time. If your hardware is not computationally capable to run the new app channels, then you
            would have to first either upgrade your existing hardware or add an additional hardware resource.
         </Typography>
      )
   },
   {
      heading: 'How can I add new processing hardware resources (servers) to expand my deployment?',
      content: (
         <Typography>
            To add new processing hardware resources you can get in touch with the Awiros Support team and request for a
            remote installation of the Awiros OS on top of your Linux based server. With your server connected to the
            internet the support team will remotely install the Awiros OS. You will then be provided with a a unique
            'terminal id' using which you can validate your installation. You can now use this same terminal id to map
            your server to a site in your existing deployment using the 'Sites' table on the Resources page.
         </Typography>
      )
   },

   {
      heading: 'How can I install software patches for the Awiros OS and my apps?',
      content: (
         <Typography>
            In case of Cloud based and Edge+Cloud based deployments updates for the Awiros OS as well as for individual
            apps are delivered over the air. In case of an offline deployments updates can be installed by raising an
            update request with the support team and connecting the requred server(s) to the internet for the duration
            of the update.
         </Typography>
      )
   },

];

export default Section2Config;
