import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";

const useStyles = makeStyles({
  main: {
    width: "100%",
    aspectRatio: "1920/510",
    position: "relative",
    "@media (max-width:768px)": {
      aspectRatio: "unset",
      display: "flex",
      flexDirection: "column",
      marginTop: "25%",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  box: {
    border: "1px solid #3C5D94",
    borderRadius: "30px",
    position: "absolute",
    bottom: "1px",
    width: "72.76%",
    height: "98%",
    left: "18%",
    "@media (max-width:768px)": {
      display: "flex",
      flexDirection: "column",
      left: "0",
      width: "85%",
      display: "none",
    },
  },
  cubebox: {
    position: "absolute",
    height: "67.28%",
    aspectRatio: "1/1",
    left: "9.6%",
    bottom: "15.6%",
    border: "2px solid #3C5D94",
    borderRadius: "30px",
    backgroundSize: "100% 100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width:768px)": {
      position: "absolute",
      width: "20%",
      height: "auto",
      top: "-20%",
      left: "40%",
      zIndex: "1",
    },
    "@media (max-width:550px)": {
      width: "25%",
      height: "auto",
      top: "-20%",
    },
    "@media (max-width:490px)": {
      width: "25%",
      height: "auto",
      top: "-20%",
    },
  },
  CubeLogo: {
    width: "80%",
  },
  innerContent: {
    position: "absolute",
    left: "31.77%",
    bottom: "25.78%",
    width: "54.73%",
    height: "47.15%",

    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media (max-width:1440px)": {
      left: "auto",
      right: "17%",
      width: "50%",
    },
    "@media (max-width:768px)": {
      flexWrap: "wrap",
      position: "relative",
      left: "0px",
      width: "60%",
      paddingTop: "10%",
      border: "2px solid #3C5D94",
      borderRadius: "30px",
      justifyContent: "space-around",
    },
    "@media (max-width:550px)": {
      flexWrap: "wrap",
      position: "relative",
      left: "0px",
      width: "80%",
      paddingTop: "10%",
      border: "2px solid #3C5D94",
      borderRadius: "30px",
      justifyContent: "space-around",
    },
  },

  eachContent: {
    height: "100%",
    border: "1px solid #3C5D94",
    borderRadius: "30px",
    width: "19.03%",
    display: "flex",
    position: "relative",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width:1080px)": {
      width: "20%",
      borderRadius: "10px",
    },
    "@media (max-width:1280px)": {
      borderRadius: "20px",
    },
    "@media (max-width:768px)": {
      width: "35%",
      height: "auto",
      aspectRatio: "1/1",
      marginBottom: "5%",
      marginTop: "2%",
    },
  },
  circle: {
    position: "absolute",
    backgroundColor: "green",
    height: "45px",
    width: "45px",
    borderRadius: "50%",
    right: "-10px",
    top: "-10px",
    "@media (max-width:1440px)": {
      height: "40px",
      width: "40px",
    },
    "@media (max-width:1080px)": {
      height: "35px",
      width: "35px",
    },
  },
  tickmark: {
    position: "absolute",
    right: "0px",
    top: "0px",
    color: "white",
    "@media (max-width:1440px)": {
      right: "-3px",
      top: "-5px",
    },
  },
  enteringAnimation: {
    animation: `$myEffect 500ms ease-in`,
  },
  exitingAnimation: {
    animation: `$myAniExit 2000ms ease-in`,
  },
  enteringTickAnimation: {
    animation: `$myEffect 500ms ease-in`,
  },
  exitingTickAnimation: {
    animation: `$myEffectExit 1000ms ease-in`,
  },
  "@keyframes myEffect": {
    "0%": {
      opacity: 0,
      transform: "scale(0)",
    },
    "20%": {
      opacity: 1,
      transform: "scale(0.99)",
    },
    "100%": {
      opacity: 1,
      transform: "scale(1)",
    },
  },
  "@keyframes myEffectExit": {
    "0%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "80%": {
      opacity: 1,
      transform: "scale(0.99)",
    },
    "100%": {
      opacity: 0,
      transform: "scale(0)",
    },
  },
  "@keyframes myAniExit": {
    "0%": {
      opacity: 1,
      transform: "scale(1)",
    },
    "85%": {
      opacity: 1,
      transform: "scale(0.99)",
    },
    "100%": {
      opacity: 0,
      transform: "scale(0)",
    },
  },
  eachContentImage: {
    height: "80px",
    width: "80px",
    marginBottom: "10px",
    marginTop: "5px",
    "@media (max-width:1440px)": {
      height: "70px",
      width: "70px",
    },
    "@media (max-width:1280px)": {
      height: "50px",
      width: "50px",
    },
    "@media (max-width:1080px)": {
      height: "35px",
      width: "35px",
    },
    "@media (max-width:768px)": {
      height: "80px",
      width: "80px",
    },
    "@media (max-width:490px)": {
      height: "60px",
      width: "60px",
    },
  },
  eachContentText: {
    textAlign: "center",
    paddingLeft: "30px",
    paddingRight: "30px",
    margin: "1px  ",

    "@media (max-width:1440px)": {
      paddingLeft: "0px",
      paddingRight: "0px",
    },
    "@media (max-width:1280px)": {
      fontSize: "14px",
    },
    "@media (max-width:1080px)": {
      fontSize: "12px",
    },
    "@media (max-width:830px)": {
      fontSize: "11px",
    },
    "@media (max-width:768px)": {
      fontSize: "14px",
      marginBottom: "8px",
    },
    "@media (max-width:490px)": {
      fontSize: "14px",
    },
  },
});

function NotableDeployment({ notableDeployments }) {
  const classes = useStyles();
  const [enterAni, setEnterAni] = useState(false);
  const [exitAni, setExitAni] = useState(false);
  const [enterTickAni, setEnterTickAni] = useState(false);
  const [exitTickAni, setExitTickAni] = useState(false);
  const [current, setCurrent] = useState(1);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrent((prev) => {
        if (prev === 4) {
          return 1;
        } else {
          return prev + 1;
        }
      });
    }, 2500);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    setEnterAni(true);
    setTimeout(() => {
      setEnterAni(false);
      setEnterTickAni(true);
      setTimeout(() => {
        setEnterTickAni(false);
        setExitTickAni(true);
        setTimeout(() => {
          setExitTickAni(false);
          // setExitAni(true);
          // setTimeout(() => {
          //   setExitAni(false);
          // }, 500);
        }, 1000);
      }, 500);
    }, 500);

    setExitAni(false);
  }, [current]);

  return (
    <div className={classes.main}>
      <div className={classes.box}></div>
      <div className={classes.cubebox}>
        <div
          style={{
            background: "rgba(255,255,255,1)",
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "30px",
          }}
        >
          <img
            src={notableDeployments[0].mainLogo}
            className={classes.CubeLogo}
          ></img>
        </div>
      </div>
      <div className={classes.innerContent}>
        <div className={classes.eachContent}>
          <img
            className={classes.eachContentImage}
            src={notableDeployments[1].imageUrl}
          ></img>
          <div className={classes.eachContentText}>
            {notableDeployments[1].text}
          </div>
          {current === 1 ? (
            <div
              className={`${classes.circle} ${
                enterAni ? classes.enteringAnimation : classes.exitingAnimation
              } `}
            ></div>
          ) : null}
          {current === 1 && (enterTickAni || exitTickAni) ? (
            <div
              className={`${classes.tickmark} ${
                enterTickAni
                  ? classes.enteringTickAnimation
                  : classes.exitingTickAnimation
              } `}
            >
              <CheckIcon style={{ fontSize: 25 }}></CheckIcon>
            </div>
          ) : null}
        </div>
        <div className={classes.eachContent}>
          <img
            className={classes.eachContentImage}
            src={notableDeployments[2].imageUrl}
          ></img>
          <div className={classes.eachContentText}>
            {notableDeployments[2].text}
          </div>
          {current === 2 ? (
            <div
              className={`${classes.circle} ${
                enterAni ? classes.enteringAnimation : classes.exitingAnimation
              } `}
            ></div>
          ) : null}
          {current === 2 && (enterTickAni || exitTickAni) ? (
            <div
              className={`${classes.tickmark} ${
                enterTickAni
                  ? classes.enteringTickAnimation
                  : classes.exitingTickAnimation
              } `}
            >
              <CheckIcon style={{ fontSize: 25 }}></CheckIcon>
            </div>
          ) : null}
        </div>
        <div className={classes.eachContent}>
          <img
            className={classes.eachContentImage}
            src={notableDeployments[3].imageUrl}
          ></img>
          <div className={classes.eachContentText}>
            {notableDeployments[3].text}
          </div>
          {current === 3 ? (
            <div
              className={`${classes.circle} ${
                enterAni ? classes.enteringAnimation : classes.exitingAnimation
              } `}
            ></div>
          ) : null}
          {current === 3 && (enterTickAni || exitTickAni) ? (
            <div
              className={`${classes.tickmark} ${
                enterTickAni
                  ? classes.enteringTickAnimation
                  : classes.exitingTickAnimation
              } `}
            >
              <CheckIcon style={{ fontSize: 25 }}></CheckIcon>
            </div>
          ) : null}
        </div>
        <div className={classes.eachContent}>
          <img
            className={classes.eachContentImage}
            src={notableDeployments[4].imageUrl}
          ></img>
          <div className={classes.eachContentText}>
            {notableDeployments[4].text}
          </div>
          {current === 4 ? (
            <div
              className={`${classes.circle} ${
                enterAni ? classes.enteringAnimation : classes.exitingAnimation
              } `}
            ></div>
          ) : null}
          {current === 4 && (enterTickAni || exitTickAni) ? (
            <div
              className={`${classes.tickmark} ${
                enterTickAni
                  ? classes.enteringTickAnimation
                  : classes.exitingTickAnimation
              } `}
            >
              <CheckIcon style={{ fontSize: 25 }}></CheckIcon>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default NotableDeployment;
