import React, { Component } from "react";

import ResourcesPageBrochuresSection from "./ResourcesPageBrochuresSection/ResourcesPageBrochuresSection";
import ResourcesPageHeader from "./ResourcesPageHeader/ResourcesPageHeader";
import ResourcesPageStyles from "./ResourcesPageStyles";
import ResourcesPageVideosSection from "./ResourcesPageVideosSection/ResourcesPageVideosSection";
import withStyles from "@material-ui/core/styles/withStyles";
import { Grid } from "@material-ui/core";
import ResourcePageCaseStudySection from "./ResourcePageCaseStudy/ResourcePageCaseStudySection";

let styles = ResourcesPageStyles;

class ResourcesPage extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <ResourcesPageHeader />
        <ResourcePageCaseStudySection></ResourcePageCaseStudySection>
        <br />
        <ResourcesPageVideosSection />
        <br />
        <br />
        <br />
        <br />
        <ResourcesPageBrochuresSection />
      </div>
    );
  }
}

export default withStyles(styles)(ResourcesPage);
