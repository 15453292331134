let CardSetStyles = {
   root: {
      marginTop: 20,
   },
   thumbnailImage: {
      width: '100%'
   },
   viewContainer: {
      // minHeight:680
   },
   tilesContainer: {}
};

export default CardSetStyles;