import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import BannerStyles from "./BannerStyles";
import { useEffect } from "react";
// import { bannerData } from "../utils/indexThree";

const Banner = (props) => {
  const { classes, bannerData } = props;
  const actualfirstCount = bannerData.metricData[0].count;
  const actualsecondCount = bannerData.metricData[1].count;
  const actualthirdCount = bannerData.metricData[2].count;
  const [firstCount, setfirstCount] = useState(0);
  const [secondCount, setsecondCount] = useState(0);
  const [thirdCount, setthirdCount] = useState(0);

  useEffect(() => {
    const innerInterval = setInterval(() => {
      setfirstCount((prev) => {
        if (prev < actualfirstCount) {
          return prev + 1;
        } else {
          return prev;
        }
      });
    }, 100);

    return () => clearInterval(innerInterval);
  }, []);

  useEffect(() => {
    const innerInterval = setInterval(() => {
      setsecondCount((prev) => {
        if (prev < actualsecondCount) {
          if (actualsecondCount > 10000) {
            return prev + 100;
          } else {
            return prev + 5;
          }
        } else {
          return prev;
        }
      });
    }, 1);

    return () => clearInterval(innerInterval);
  }, []);

  useEffect(() => {
    const innerInterval = setInterval(() => {
      setthirdCount((prev) => {
        if (prev < actualthirdCount) {
          if (actualthirdCount > 10000) {
            return prev + 1000;
          } else {
            return prev + 1;
          }
        } else {
          return prev;
        }
      });
    }, 100);

    return () => clearInterval(innerInterval);
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setfirstCount(0);
      setsecondCount(0);
      setthirdCount(0);
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className={classes.bannerContainer}>
      <div className={classes.heading}>{bannerData.heading}</div>
      <div className={classes.metric}>
        <div className={classes.eachMetric}>
          <div className={classes.count}>{firstCount}+</div>
          <div className={classes.countCategory}>
            {bannerData.metricData[0].title}
          </div>
        </div>
        <div className={classes.eachMetric}>
          <div className={classes.count}>{secondCount}+</div>
          <div className={classes.countCategory}>
            {bannerData.metricData[1].title}
          </div>
        </div>
        <div className={classes.eachMetric}>
          <div className={classes.count}>{thirdCount}% </div>
          <div className={classes.countCategory}>
            {bannerData.metricData[2].title}
          </div>
        </div>
      </div>
      <div className={classes.desktopInfo}>{bannerData.subHeading}</div>
      <div className={classes.mobileInfo}>
        {bannerData.mobileViewSubHeading}
      </div>
      <div className={classes.appStackButton}>
        <a href="/appstack" className={classes.heroButton}>
          <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/hero_apps.svg" />
          VIEW AWIROS APPSTACK
        </a>
      </div>
      <div className={classes.trustedBy}>Trusted by</div>
      {/* <div className={classes.trustedByImage}><img style={{width:"100%",objectFit:'contain'}} src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/sample partner list 1.png"></img></div> */}
      <div className={classes.logos}>
        {bannerData.trustedBy.map((logo, index) => (
          <img key={index} src={logo} className={classes.logo}></img>
        ))}
      </div>

      {/* <div className={classes.appsectionRelated}>
          <div><h1>An App for every Use-case</h1></div>
        </div> */}

      <div className={classes.appSectionRelated}>
        <div className={classes.heroSectionUseCase}>
          <h1>An App for every Use-Case</h1>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(BannerStyles, { withTheme: true })(Banner)
);
