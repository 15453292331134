let getHost = () => {
  if (process.env.NODE_ENV === 'development') {
    return window.AwirosDeploymentConf.getDevelopmentHost();
  } else if (process.env.NODE_ENV === 'production') {
    return window.AwirosDeploymentConf.getProductionHost();
  }
};

let getSocketHost = () => {
  if (process.env.NODE_ENV === 'development') {
    return window.AwirosDeploymentConf.getDevelopmentHost();
  } else if (process.env.NODE_ENV === 'production') {
    return window.AwirosDeploymentConf.getProductionHost();
  }
};
let Globals = {
  connectMessaging: window.AwirosDeploymentConf.connectMessaging,
  offline: window.AwirosDeploymentConf.offline,
  socketURL: getSocketHost(),
  GA_TRACKING_ID: 'UA-137882686-1',
};

export { getHost, getSocketHost, Globals };
