import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section6Styles from "./Section6Styles";
import Grid from "@material-ui/core/Grid";
import Section6Config from "./Section6Config";
import Section6Card from "./Section6Card/Section6Card";

let styles = Section6Styles;

class Section6 extends Component {
   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <Grid container direction={'row'} justify={'center'} spacing={10} style={{marginBottom: 100}}>
               {Section6Config.map((cardData, index) => {
                  return (
                     <Grid key={index} item xs={12} sm={8} md={4}>
                        <Section6Card
                           cardData={cardData}
                           history={this.props.history}
                        /> {/*6*/}
                     </Grid>
                  )
               })}
            </Grid>
         </div>
      );
   }
}

export default withStyles(styles)(Section6);
