import StyleConfig from "../../../../../lib/StyleConfig";


let DataSheetFormStyles = {
   root: {
      margin: '0 auto',
      maxWidth: 1200,
      padding: '0 20px 50px',
      flex: 1,
      backgroundColor: StyleConfig.backgroundColor,
      '@media (min-width: 960px)': {
         padding: '0 20px 50px'
      },
   },
   heading: {
      fontSize: 36,
      fontWeight: 400,
      color: StyleConfig.mainThemeColor,
      textAlign: 'center',
      margin: '100px auto'
   },
   subheadingContainer: {
      marginBottom: 40
   },
   subheading: {
      fontSize: 30,
      fontWeight: 700,
      marginBottom: 100
   },
   submitButton: {
      width: '100%',
      fontSize: 18,
      fontWeight: 500,
      color: StyleConfig.invertedSecondaryThemeColor,
      backgroundColor: StyleConfig.mainThemeColor,
      textTransform: 'capitalize',
      '&:hover': {
         color: StyleConfig.invertedSecondaryThemeColor,
         backgroundColor: StyleConfig.mainThemeColor,
      }
   },
   formContainer: {
      '& .MuiTextField-root': {
         marginBottom: 10
      },
   },
   detailsContainer: {
      marginTop: 100
   },
   detailsHeading: {
      fontSize: 20,
      fontWeight: 500,
      color: StyleConfig.headingColor,
      marginTop: 0,
   },
   detailsTextContent: {
      fontSize: 18,
      fontWeight: 300,
      color: StyleConfig.headingColor,
      lineHeight: '1.6em'
   },
   detailsLink: {
      fontSize: 18,
      fontWeight: 300,
      lineHeight: '1.6em',
      textDecoration: 'none'
   },
   detailsLogo: {
      fontSize: 32,
      color: StyleConfig.mainThemeColor
   }
};

export default DataSheetFormStyles
