import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section1CardStyles from "./Section1CardStyles";
import Grid from "@material-ui/core/Grid";
import RoutePath from "../../../../../lib/RoutePath";
import Button from "@material-ui/core/Button";
import withWidth from "@material-ui/core/withWidth";
import Hidden from "@material-ui/core/Hidden";
import clsx from "clsx";

let styles = Section1CardStyles;

class Section1Card extends Component {
    static defaultProps = {
        headingText: "lorem",
        textContent:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur consectetur possimus quo ratione ullam",
        logo: "../../../../../assets/images/icons/anpr.png",
        buttonName: "App Store",
        buttonLink: RoutePath.homePath,
        callImageLoadedCallback: false
    };

    handleClick = () => {
        RoutePath.navigateTo(this.props, this.props.buttonLink);
    };

    render() {
        let { classes, width } = this.props;
        let thumbnail = (
            <img
                src={this.props.logo}
                onLoad={
                    this.props.callImageLoadedCallback
                        ? this.props.imageLoadedCallback
                        : undefined
                }
                alt="app logo"
                className={classes.image}
            />
        );

        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction={"row"}
                    justify={width === "xs" ? "center" : "space-between"}
                    alignItems={width === "xs" ? null : "center"}
                    spacing={width === "xs" ? 0 : 3}
                >
                    <Hidden smUp>
                        <Grid item xs={9}>
                            {thumbnail}
                        </Grid>
                    </Hidden>

                    <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.textContainer}
                        container
                        justify={width === "xs" ? "center" : null}
                    >
                        <div className={clsx(classes.headingText)}>
                            {this.props.headingText}
                        </div>
                        <div className={classes.textContent}>
                            {this.props.textContent}
                        </div>
                        <Button
                            variant={"contained"}
                            disableElevation
                            onClick={this.handleClick}
                            className={classes.button}
                        >
                            {this.props.buttonName}
                        </Button>
                    </Grid>

                    <Hidden xsDown>
                        <Grid item xs={12} sm={6}>
                            {thumbnail}
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        );
    }
}

export default withWidth()(withStyles(styles)(Section1Card));
