import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import OurSuccessStoriesStyles from "./OurSuccessStoriesStyles";
import { useEffect } from "react";
import { ourSuccessStoriesData } from "../utils/indexFour";
import CarouselComponentWithDots from "../../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";

const OurSuccessStories = (props) => {
  const { classes } = props;

  return (
    <div className={classes.container}>
      <div className={classes.leftContainer}>
        <div className={classes.heading}>
          <div>OUR</div>
          <div>SUCCESS STORIES</div>
        </div>
        <div className={classes.points}>
          <div className={classes.point}>
            <img src="/assets/industries/arrow.svg"></img> Eliminated manual
            attendence
          </div>
          <div className={classes.point}>
            <img src="/assets/industries/arrow.svg"></img> Regulated campus
            entry-exit
          </div>
          <div className={classes.point}>
            <img src="/assets/industries/arrow.svg"></img> Optimized campus
            parking
          </div>
          <div className={classes.point}>
            <img src="/assets/industries/arrow.svg"></img> Reduced facility
            management costs
          </div>
        </div>
      </div>
      <div className={classes.rightContainer}>
        <CarouselComponentWithDots
          cardComponentClasses={classes.cardContainer}
          contentArray={ourSuccessStoriesData.map((card, index) => {
            return (
              <div key={index} className={classes.imageContainer}>
                <img
                  src={card.imageUrl}
                  className={classes.carouselImage}
                ></img>
              </div>
            );
          })}
          showDots={true}
          interval={3000}
        ></CarouselComponentWithDots>
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(OurSuccessStoriesStyles, { withTheme: true })(OurSuccessStories)
);
