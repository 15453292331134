let Section6CardStyles = {
   root: {
      marginBottom: 140
   },
   heading: {
      marginBottom: 20
   },
   textContent: {
      lineHeight: '1.5rem',
      // textAlign:'center',
      fontSize: '18px!important',
      fontWeight: 300,
      // margin:'auto 100px',
      '@media (min-width: 960px)': {
         height: '19.5rem',
         // margin: 'auto 30px',
      },
      // marginBottom: 40,
   },
   thumbnailImage: {
      width: '90%',
      // height:'100%',
      border: '1px solid',
      borderColor: 'transparent',
      margin: '0 auto',
      marginBottom: 10
   },
   buttonType: {
      '@media (max-width: 960px)': {
         marginTop: 25,
         // height: '19.5rem',
         // margin: 'auto 30px',
      },
      // float:'right',
      // marginRight:25
   }
};

export default Section6CardStyles;