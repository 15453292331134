let ChallengeStyles = {
  SaveDates: {
    padding: "60px",
    "@media (max-width: 769px)": {
      padding: "40px",
    },
  },
  SaveDatesImage: {
    width: "100%",
    "@media (max-width: 769px)": {
      gap: "70px",
      display: "none",
    },
  },
  detailedPlan: {
    color: "white",
    textAlign: "center",
    fontSize: "32px",
    marginTop: "48px",
    "@media (max-width: 769px)": {
      fontSize: "20px",
    },
  },
  // saveDatesDesktopContainer: {
  //   position: "relative",
  // },
  saveDatesContainer: {
    display: "block",
  },
  saveDatesHeading: {
    marginTop: "40px",
    color: "#FC0",
    fontSize: "40px",
    fontWeight: "600",
    textAlign: "center",
    marginBottom: "80px",
  },
  imageContainer: {
    backgroundColor: "black",
    width: "max-content",
    aspectRation: "1/1",
    borderRadius: "50%",
  },
  mobileViewContainer: {
    "@media (min-width: 769px)": {
      display: "none",
    },
  },
  cardContainer: {
    color: "white",
    display: "flex",
    flexDirection: "column",
    gap: "64px",
  },
  singleCard: {
    textAlign: "center",
    width: "250px",
    margin: "auto",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    alignItems: "center",
  },
  singleCardHeading: {
    fontSize: "18px",
    fontWeight: "500",
  },
  list: {
    listStyleType: "disc",
    textAlign: "center",

    listStylePosition: "inside",
    width: "400px",
    padding: "auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "@media (max-width: 500px)": {
      maxWidth: "350px",
    },

    // margin: "auto",
    textAlign: "center",
    "& p": {
      textAlign: "center",
      display: "flex",
      alignItems: "center",
      // marginRight: "15%",
    },
  },
};

export default ChallengeStyles;
