let Section1Styles = {
   /*   root: {
         '@media (max-width:960px)': {
            marginTop: 'calc(64px + 8vh)',
         }
      },
      headerContainer: {
         // marginTop: '19vh',
      },
      thumbnail: {
         width: '100%'
      },
      body: {
         paddingTop: `55px!important`
      }*/

   root: {
      // marginBottom:40,
   },
   headerImage: {
      width: '100%',
      margin: 'auto',
      // marginBottom:20,
      textAlign: 'center',
      '@media (max-width: 600px)': {
         width: '100%',
      },
   }
};

export default Section1Styles;
