import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import TestimonialsStyles from "./TestimonialsStyles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import InfiniteCarousel from "../InfiniteCarousel/InfiniteCarousel";
import TestimonialConfig from "./TestimonialConfig";
import TestimonialCard from "./TestimonialCard/TestimonialCard";

let styles = TestimonialsStyles;

class Testimonials extends Component {
   constructor(props) {
      super(props);
      this.testimonialData = TestimonialConfig
   }


   render() {
      let {classes} = this.props;
      return (
         <>
            <Grid container direction={'column'} alignItems={'center'} spacing={4} className={classes.headingContainer}>
               <Grid item>
                  <p className={clsx(classes.heading, 'bottom-dash')}>
                     Testimonials
                  </p>
               </Grid>
            </Grid>
            <InfiniteCarousel Component={TestimonialCard} carouselContent={this.testimonialData}/>
         </>
      );
   }
}

export default withStyles(styles)(Testimonials);