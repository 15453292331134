import React from "react";
import CaseStudyStyles from "./CaseStudyStyles";
import { makeStyles, withStyles } from "@material-ui/styles";
const useStyles = makeStyles(CaseStudyStyles);

function CaseStudy() {
  const classes = useStyles();
  return (
    <div className={classes.caseStudy}>
      <img src="/assets/case-study/caseStudyBanner.svg" width="100%"></img>
      <div className={classes.heading}>
        Leveraging AWS Cloud & Intel® Distribution of OpenVINO™ Toolkit to
        Create Awiros Video AI Marketplace
      </div>

      <div className={classes.description}>
        Video analytics is an ever-evolving field, with enterprises - both big
        and small - constantly leveraging its use cases. Thousands of cameras,
        IoT devices, and sensors are deployed across cities, businesses, and
        factories - generating petabytes of data each day. With such a data
        explosion, it’s critical for businesses to utilize AI to streamline
        video applications. However, when it comes to “video AI” solutions,
        customers often require applications for several use cases which vary
        from one business to the other, even in the same industry vertical.
        Developing an app for each use case will be a painstaking process, which
        will cost time, manpower, and resources.
      </div>

      <div className={classes.description}>
        This is where Awiros is making a difference. A one-of-its-kind video
        intelligence platform, Awiros brings in the power of Computer Vision
        (CV) to a large audience through its marketplace, as well as its core
        technology offering, which lets others build CV applications without the
        overhead of system engineering and data engineering efforts
        ................
      </div>

      <a
        href="/assets/case-study/Awiros Case Study  Designed Version.pdf"
        download={true}
      >
        <button className={classes.readMoreButton}>Read More</button>
      </a>
    </div>
  );
}

export default CaseStudy;
