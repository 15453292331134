import StyleConfig from "../../../../lib/StyleConfig";

let Section4Styles = {
   root: {
      marginTop: 100,
      marginBottom: 64
   },
   heading: {
      color: StyleConfig.headingColor,
      textAlign: 'center',
      fontSize: 30,
      fontWeight: 400,
   },

};

export default Section4Styles;