import RoutePath from "../../../../lib/RoutePath";
import ProductCard from "./ProductCard/ProductCard";
import React from "react";

let Section2Config = [
    {
        tabURI: RoutePath.enterprise,
        name: "Awiros V-Stream",
        content: (props) => {
            return (
                <ProductCard
                    heading={"Awiros V-Stream Appliance - Enterprise"}
                    subheading={
                        "Rack Mountable Appliance for Heavy Video AI Work Loads."
                    }
                    body={
                        "Awiros V-Stream Appliance is pre-installed with Awiros OS and comes bundled with the complete Awiros Software suite. It can handle large deployments with support for up to 150 deep learning based applications  per machine. With a form factor of 2U (800X1000mm rack mountable)  Awiros V-Stream can make your Data Centre Video AI ready with Advanced Video Intelligence. Awiros Enterprise also offers virtually infinite scalability. For a large number of applications in use-cases like smart cities, multiple instances of the rack server can be installed and the Awiros OS seamlessly scales to give the user a unified interface."
                    }
                    showLearnMoreButton={false}
                    link={RoutePath.productDetailed(RoutePath.enterprise)}
                    showDataSheetButton={true}
                    dataSheetLink={RoutePath.vStreamDataSheetPath}
                    image={"/assets/images/resource/vstream.png"}
                    bulletPoints={[
                        "Awiros Software Stack pre-configured and benchmarked. ",
                        "Up to 6 Tesla T-4 GPU Cards in a single appliance",
                        "Up to 40 CPU Cores per appliance ",
                        "Supports up to 150 Deep Learning based analytics",
                        "In built storage for Video AI meta data.",
                        "Unmatched Scale up and Scale out capacity.",
                        "Scales seamlessly in terms of number of channels and servers"
                    ]}
                />
            );
        }
    },

    {
        tabURI: RoutePath.gStream,
        name: "Awiros V-Sigma",
        content: (props) => {
            return (
                <ProductCard
                    heading={"Awiros V-Sigma Appliance"}
                    // subheading={'Rack Mountable Appliance for Heavy Video AI Work Loads.'}
                    body={`Awiros V-Sigma Appliance is yet another hardware offering that comes pre-installed with Awiros OS and and the complete Awiros Software suite. It has been designed for the deployment of upto 48 deep learning based applications per machine. With a form factor of 2U (800X1000mm rack mountable) Awiros V-Sigma can make your Data Centre Video AI ready with Advanced Video Intelligence. The V-Sigma Applicance is perfect for multiple Video AI deployments at multiple sites, with the Awiros OS allowing the user to manage all of these sites simultaneousy through a unified interface.`}
                    showLearnMoreButton={false}
                    link={RoutePath.productDetailed(RoutePath.gStream)}
                    showDataSheetButton={true}
                    dataSheetLink={RoutePath.gStreamDataSheetPath}
                    image={"/assets/images/resource/vsigma.png"}
                    bulletPoints={[
                        "Awiros Software Stack pre-configured and benchmarked. ",
                        "Up to 3 Quadro GPU Cards in a single appliance",
                        "Up to 40 CPU Cores per appliance ",
                        "Supports up to 48 Deep Learning based analytics",
                        "In built storage for Video AI meta data.",
                        "Unmatched Scale up and Scale out capacity.",
                        "Scales seamlessly in terms of number of channels and servers"
                    ]}
                />
            );
        }
    },

    {
        tabURI: RoutePath.onePlus,
        name: "Awiros EDGEplus 7",
        content: (props) => {
            return (
                <ProductCard
                    heading={"Awiros EDGEplus 7"}
                    // subheading={'A standalone intelligence device for all basic needs'}
                    body={
                        "Packaged as a rugged field-unit, Awiros EDGEplus 7 connects to any camera system and brings the power of Awiros Video Intelligence to you. Awiros EDGEplus 7 is perfectly suited for small to medium sized deployments in Homes, Small Offices, Shops and Warehouses. Whether the et is to secure the home with intrusion detection, or count people/vehicles in-and-out of the premises or create detailed analysis of space usage, Awiros EDGEplus 7 handles the workload seamlessly. For different geographical locations, several Awiros EDGEplus 7 devices can be linked to same user account to implement a truly distributed video-intelligencesystem."
                    }
                    link={RoutePath.productDetailed(RoutePath.onePlus)}
                    showLearnMoreButton={false}
                    image={"/assets/images/resource/viu3.png"}
                    showDataSheetButton={true}
                    dataSheetLink={RoutePath.edgePlusDataSheetPath}
                    bulletPoints={[
                        "Compatible with all camera make and models",
                        "Supports online and offline operation",
                        "Supports up to 16 applications simultaneously",
                        "Get real-time notifications on the online dashboard and mobile app"
                    ]}
                />
            );
        }
    },

    {
        tabURI: RoutePath.pulse,
        name: "Awiros Pulse",
        content: (props) => {
            return (
                <ProductCard
                    heading={"Awiros Pulse"}
                    subheading={
                        "A standalone intelligence device for all basic needs"
                    }
                    body={
                        "Awiros Pulse is a compact package with an on-board camera for powering simple applications from among the Awiros AppStack. Pulse can help in Footfall counting, Motion and Tampering alerts and also work a standalone smart camera for home or office. Not more than a few centimeters tall, the Awiros Pulse can be placed at the strategic entry/exit points to automatically monitor key activities in the camera view. Awiros Pulse also has an optional WiFi interface for easy deployment."
                    }
                    image={"/assets/images/resource/pulse-black.png"}
                    link={RoutePath.productDetailed(RoutePath.pulse)}
                    showLearnMoreButton={false}
                    bulletPoints={[
                        "Plug-and-play. Takes just a few minutes to setup",
                        "On board camera for standalone operation",
                        "WiFi interface for zero wiring overhead",
                        "Get real-time notifications on the online dashboard and mobile app",
                        "Ideal for motion and tracking related applications from the AppStack"
                    ]}
                />
            );
        }
    }
];

export default Section2Config;
