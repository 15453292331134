import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section2Styles from "./Section2Styles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import RoutePath from "../../../../lib/RoutePath";

let styles = Section2Styles;

class Section2 extends Component {
   handleClick = () => {
      window.open(RoutePath.spiraGithubLink, '_blank')
   }

   render() {
      let {classes} = this.props;
      return (
         <Grid container direction={'row'} justify={'center'} className={classes.root}>
            <Grid item>
               <Button variant="contained" color="primary" className={classes.button} onClick={this.handleClick}>
                  <i className={`fab fa-github ${classes.fontAwesomeIcon}`}/>&ensp;Go to Spira Github Page
               </Button>
            </Grid>
         </Grid>
      );
   }
}

export default withStyles(styles)(Section2);