import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section5Styles from "./Section5Styles";
import Section5Card from "./Section5Card/Section5Card";
import InfiniteCarousel from "../../../CommonComponents/InfiniteCarousel/InfiniteCarousel";
import Section5Config from "./Section5Config";

let styles = Section5Styles;

class Section5 extends Component {
   constructor(props) {
      super(props);
      this.carouselContent = Section5Config
   }

   render() {
      let {classes} = this.props;

      return (
         <div className={classes.root}>
            <div className={classes.heading}>Our Partners</div>

            <InfiniteCarousel
               carouselContent={this.carouselContent}
               Component={Section5Card}
               visibleSlidesXl={5}
               visibleSlidesLg={5}
               visibleSlidesMd={5}
               visibleSlidesSm={4}
               visibleSlidesXs={2}
               interval={2000}
            />
         </div>
      );
   }
}

export default withStyles(styles)(Section5);