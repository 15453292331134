import StyleConfig from "../../../lib/StyleConfig";

let HorizontalTabsStyles = {
   tabContainer: {
      color: StyleConfig.fadeTabColor,
      backgroundColor: 'transparent',
      boxShadow: 'none',
      borderBottom: '1px solid lightgrey',
      zIndex: 1000
   },
   selectedTabIndicator: {
      backgroundColor: StyleConfig.mainThemeColor
   }
};

export default HorizontalTabsStyles;