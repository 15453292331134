class APIPath {
   static bookDemo = '/api/awiros_website_data';
   // static bookDemo = '/book_demo';

   static appStack = '/api/awiros_app_store';
   static appStackDeveloperList = '/api/app_stack_developer_list';
   static listOfTargetIndustries = '/api/list_of_target_industries';
   static filterAwirosAppStack = '/api/filter_awiros_app_stack';

   static loginPath = '/auth/login';

}

export default APIPath;
