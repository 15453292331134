let ChallengeStyles = {
  awards: {
    color: "white",
    fontSize: "108px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  awardsHeading: {
    textShadow: " -30px 5px 10px #31C2DC,30px 5px 10px #F0557C",
    marginTop: "85px",
    fontWeight: "800",
    "@media (max-width:768px)": {
      fontSize: "56px",
    },
  },
  awardsDescription: {
    fontSize: "24px",
    marginBottom: "120px",
  },
  awardsContainer: {
    display: "flex",
    color: "#FC0",
    justifyContent: "center",
    margin: "auto 100px",
    gap: "120px",
    "@media (max-width:1442px)": {
      display: "none",
    },
  },
  carouselComp: {
    margin: "auto",
    width: "90%",
    "@media (min-width:1442px)": {
      display: "none",
    },
  },
  singleAward: {
    backgroundColor: "white",
    borderRadius: "8px",
    borderBottom: "3px solid #FC0",
    boxShadow: "0px 2px 3px 1px rgba(0, 0, 0, 0.10)",
    width: "420px",
    height: "250px",
    color: "#FC0",
    "@media (max-width:1442px)": {
      margin: "auto",
    },
    "@media (max-width:768px)": {
      width: "300px",
      height: "250px",
    },
  },
  rankHeader: {
    padding: "0px",
    margin: "0px",
    lineHeight: "0.8",
  },
  numberHeading: {
    fontSize: "76px",
    fontWeight: "800",
  },
  text: {
    fontSize: "48px",
    fontWeight: "700",
  },
  priceHeading: {
    fontSize: "24px",
    fontWeight: "700",
  },
  price: {
    fontSize: "48px",
    marginTop: "16px",
    color: "#2C2C2C",
    fontWeight: "500",
  },
  list: {
    fontSize: "32px",
    marginTop: "80px",
    listStyleType: "disc",
    color: "black",
    textAlign: "center",
    listStylePosition: "inside",
    padding: "32px",
    width: "90%",
    margin: "auto",
    borderBottom: "3px solid #FC0",

    "@media (max-width:1442px)": {
      marginTop: "40px",
      fontSize: "24px",
    },
    "@media (max-width:768px)": {
      fontSize: "20px",
      textAlign: "center",
      "& li": {
        textAlign: "center",
      },
    },
  },
  note: {
    color: "#F00",
    fontSize: "16px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: "40px 10px",
  },
};

export default ChallengeStyles;
