import StyleConfig, {ProcessorColors} from "../../../../../../../lib/StyleConfig";

let AppCardStyles = {
   root: {
      padding: 15,
      border: '1px solid',
      borderColor: StyleConfig.mainThemeColor,
   },
   topPartContainer: {
      marginBottom: 10,
   },
   appIcon: {
      width: '100%',
      maxWidth: 60,
      // height: 60
   },
   appName: {
      fontSize: 15,
      fontWeight: 500,
      lineClamp: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'box',
      boxOrient: 'vertical'
   },
   developerName: {
      fontSize: 12
   },
   descriptionText: {
      fontSize: 13,
      lineHeight: '1.5em', /* Sets line height to 1.5 times text size */
      height: '3em', /* Sets the div height to 2x line-height (3 times text size) */
      lineClamp: 2,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      display: 'box',
      boxOrient: 'vertical'
   },
   industryTagContainer: {
      display: 'flex',
      flexDirection: 'row'
   },
   industryTag: {
      textAlign: 'center',
      width: '95px',
      marginLeft: '0',
      borderRadius: '14px',
      backgroundColor: '#bcbdbd',
      fontFamily: 'Roboto',
      fontSize: '9px',
      fontWeight: '300',
      color: '#333',
      padding: '.5% 3.5%',
      margin: '1%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
   },
   industryTagEmpty: {
      textAlign: 'center',
      minWidth: '25px',
      marginLeft: '0',
      borderRadius: '14px',
      backgroundColor: '#bcbdbd',
      fontFamily: 'Roboto',
      fontSize: '9px',
      fontWeight: '300',
      color: '#333',
      padding: '.5% 3.5%',
      margin: '1%',
      // textOverflow: 'ellipsis',
      // whiteSpace: 'nowrap',
      // overflow: 'hidden',
   },
   processorTag: {
      textAlign: 'center',
      width: 55,
      marginLeft: '0',
      borderRadius: '3px',
      backgroundColor: '#bcbdbd',
      fontFamily: 'Roboto',
      fontSize: '9px',
      fontWeight: '300',
      color: ProcessorColors.processorTextColor,
      padding: '.5% 3.5%',
      margin: '1%',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',

      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'

   },
   processorIcon: {
      width: 11,
      marginLeft: 5
   },
   likeButtonIcon: {
      fontSize: 12,
      fontWeight: 300,
      marginRight: 1,
      color: 'grey'
   },
   likeButtonText: {
      fontSize: 12,
      fontWeight: 300,
      marginRight: 5,
      color: 'grey'
   },


   exploreButton: {
      backgroundColor: StyleConfig.appStackButtonColor,
      color: 'white',
      padding: '1px 10px',
      '&:hover': {
         backgroundColor: StyleConfig.appStackButtonHoverColor
      },
      fontSize: '12px'
   }

};

export default AppCardStyles;