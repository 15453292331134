import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import AwirosOSPageStyles from "./AwirosOSPageStyles";
import Section1 from "./Sections/Section1/Section1";
import Section2 from "./Sections/Section2/Section2";
// import Section2_1 from "./Sections/Section2_1/Section2_1";
// import Section3_1 from "./Sections/Section3_1/Section3_1";
// import Section3_2 from "./Sections/Section3_2/Section3_2";
import Section4 from "./Sections/Section4/Section4";
import Section5 from "./Sections/Section5/Section5";
import AwirosOSIntegratingComp from "./AwirosOSIntegratingComp";

let styles = AwirosOSPageStyles;

class AwirosOSPage extends Component {
  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <Section1 />
        <Section2 />
        {/*<Section2_1/>
            <Section3_1/>
            <Section3_2/>*/}
        {/* <Section4 /> */}
        <AwirosOSIntegratingComp heading="Awiros Deployment Architecture"></AwirosOSIntegratingComp>
        <Section5 />
      </div>
    );
  }
}

export default withStyles(styles)(AwirosOSPage);
