import RoutePath from "../../../../lib/RoutePath";

let Section6Config = [
    {
        heading: "Partner With Us",
        image: "../../../../assets/images/resource/partnerships.png",
        content: `The Video Analytics market is likely to grow to a whopping 21 billion USD worldwide by 2025. With its unique app-based ecosystem Awiros is all set to become the default delivery mechanism for all kinds of Video AI applications. Hope to hop on the Video AI bandwagon? Partner with Awiros and deliver to your clients the best of Video AI solutions today!`,
        buttonText: "Partner With Us",
        link: RoutePath.contactUs
    },
    {
        heading: "Develop With Us",
        image: "../../../../assets/images/resource/develop-with-us.png",
        content: `Awiros is an open platform for Computer Vision and AI developers, providing them with all of the neccessary resources for transforming AI algorithms into end-to-end solutions. It is, therefore, used by developers from across the world for testing, training, benchmarking and deploying their applications.`,
        buttonText: "Develop With Us",
        link: RoutePath.developers
    },
    {
        heading: "Join Us",
        image: "../../../../assets/images/resource/join-us.png",
        content: `Want to join us in our journey of creating the future of Video AI? 
                Well, if you eat, sleep and dream Video AI, then we will love to have you on-board. Drop in your resume and we will get back to you!`,
        buttonText: "Join Us",
        link: RoutePath.careers
    }
];

export default Section6Config;
