import StyleConfig from "../../../../lib/StyleConfig";

let Section5Styles = {
   root: {
      textAlign: 'center'

   },
   heading: {
      fontSize: 30,
      fontWeight: 400,
      lineHeight: '1.4em',
      color: StyleConfig.headingColor,
   },
   subtitle: {
      fontSize: 18,
      fontWeight: 400,
   }
};

export default Section5Styles;