export const bannerData = [
  {
    imageUrl: "/banner2/1.png",
    title: "Reduce staff errors and increase productivity using Video AI",
    subTitle:
      "Use Awiros Video AI technology to automatically detect errors in your warehouse and increase operational efficiency",
    //   cameraCount: "1000+",
    //   areaCovered: "200+",
    //   apps: "30+",
    //   livesImpacted: "2 Mn+",
    //   bartnote: "Know more about Awiros’ role in Bengaluru Safe City",
    //   bartnoteUrl: "",
    videoUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/reduceStaffNew.mp4",
  },
  {
    imageUrl: "/banner2/2.png",
    title:
      "Enhance your worker safety and stay compliant using Video analytics",
    subTitle:
      "Have a safe and secure warehouse environment with cutting edge Video AI technology powered by Awiros",
    //   cameraCount: "1000+",
    //   areaCovered: "200+",
    //   apps: "30+",
    //   livesImpacted: "2 Mn+",
    //   bartnote: "Know more about Awiros’ role in Jaipur Smart City",
    //   bartnoteUrl: "",
    videoUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/workersSafetyNew.mp4",
  },
  {
    imageUrl: "/banner2/3.png",
    title: "Automate inventory management and make supply chains smarter ",
    subTitle:
      "Awiros can help you significantly improve the working & safety conditions for workers using intelligent AI apps",
    //   cameraCount: "1000+",
    //   areaCovered: "200+",
    //   apps: "30+",
    //   livesImpacted: "2 Mn+",
    //   bartnote: "Know more about Awiros’ role in Bareilly Smart City",
    //   bartnoteUrl: "",
    videoUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/autoInv.mp4",
  },
];

// export const appSelection = [
//   {
//     title: "Our Top Apps for Warehouse",
//     apps: [
//       {
//         name: "Facial Recognition",
//         iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_frs.svg",
//         imageUrl:
//           "https://i.picsum.photos/id/184/200/300.jpg?hmac=dCgm4a8do6DWvjUWcFvft3Kd1srf1f_TyIZoWGrgu48",
//         tags: ["Smart City", "Safe City", "Face", "FRS", "People", "Identify"],
//       },
//       {
//         name: "Automatic Number Plate Recognition",
//         iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_npr.svg",
//         imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps2/licence-plate-single.JPG",
//         tags: [
//           "Smart City",
//           "Safe City",
//           "crowd",
//           "people",
//           "counting",
//           "crowd counting",
//           "people counting",
//         ],
//       },
//       {
//         name: "Crowd Counting",
//         iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_crowd_counting.svg",
//         imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps2/crowd-estimation.JPG",
//         tags: [
//           "Smart City",
//           "Safe City",
//           "ANPR",
//           "Number Plate",
//           "Vehicle",
//           "Car",
//           "Bike",
//           "4 wheeler",
//           "Registration",
//         ],
//       },
//       {
//         name: "Camera Health Monitoring",
//         iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_tampering.svg",
//         imageUrl:
//           "https://i.picsum.photos/id/184/200/300.jpg?hmac=dCgm4a8do6DWvjUWcFvft3Kd1srf1f_TyIZoWGrgu48",
//         tags: [
//           "Smart City",
//           "Safe City",
//           "tampering",
//           "camera tampering",
//           "camera damage detection",
//           "Warehousing",
//           "Logistics",
//           "intentional",
//           "block camera",
//         ],
//       },
//       {
//         name: "Advanced Intrusion Detection",
//         iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_advanced_intrusion.svg",
//         imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/advanced-intrution.JPG",
//         tags: [
//           "Smart City",
//           "Safe City",
//           "prohibited area",
//           "person intrusion",
//           "intrusion",
//           "detect intruder",
//           "detect unauthorized access",
//         ],
//       },
// {
//   name: "Helmet Violation Detection",
//   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_helmet_violation.svg",
//   imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/helmet-violation.JPG",
//   tags: [
//     "Smart City",
//     "Safe City",
//     "helmet violation",
//     "2 wheeler",
//     "bike",
//     "motorcycle",
//     "helmet detection",
//   ],
// },
//       {
//         name: "Vehicle counting & Classification",
//         iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_vehicle_count.svg",
//         imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/vehicle-count.JPG",
//         tags: [
//           "Smart City",
//           "Safe City",
//           "vehicle count",
//           "car count",
//           "vehicle identification",
//           "car details",
//           "vehicle details",
//         ],
//       },
//       {
//         name: "Crowd Estimation & Management",
//         iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/placeholder.svg",
//         imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/crowd-estimation.JPG",
//         tags: [
//           "Smart City",
//           "Safe City",
//           "large crowd count",
//           "dense crowd count",
//           "big group detection",
//           "big group counting",
//         ],
//       },
//       {
//         name: "Person Collapsing",
//         iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_person_collapsing.svg",
//         imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/Person-collapsing.jpg",
//         tags: [
//           "Smart City",
//           "Safe City",
//           "fall",
//           "fell",
//           "collapse",
//           "man down",
//           "person collapse",
//           "emergency",
//         ],
//       },
//       {
//         name: "Fire detection",
//         iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/placeholder.svg",
//         imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/fire-detection.JPG",
//         tags: [
//           "Smart City",
//           "Safe City",
//           "fire alarm",
//           "fire alert",
//           "fire and smoke",
//         ],
//       },
//       {
//         name: "Pothole detection",
//         iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_pothole_detection.svg",
//         imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/pothole-detection.JPG",
//         tags: ["Smart City", "Safe City", "hole in ground", "pothole detect"],
//       },

//     ],
//   },

// ];
export const appSelection = [
  {
    title: "Our top Apps for Warehouses",
    placeholder: "Search for Warehouse Apps",
    apps: [
      {
        name: "Advanced Intrusion Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Advanced Instrusion Detection.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/advanced-intrution.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "prohibited area",
          "person intrusion",
          "intrusion",
          "detect intruder",
          "detect unauthorized access",
        ],
        description:
          "Monitor activities around a user defined area to detect intrusion",
      },
      {
        name: "Person Collapsing",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Person Collapsing.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/Person-collapsing.jpg",
        tags: [
          "Smart City",
          "Safe City",
          "fall",
          "fell",
          "collapse",
          "man down",
          "person collapse",
          "emergency",
        ],
        description:
          "Identify a person collapsing in any premise and send immediate alerts",
      },
      {
        name: "Fire Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Fire & Smoke detection.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/fire-detection.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "fire alarm",
          "fire alert",
          "fire and smoke",
        ],
        description:
          "Locate fire through cameras and trigger an alert for action",
      },
      {
        name: "PPE Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/PPE detection.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/PPE-labeled.jpg",
        tags: ["warehouse", "logistics", "workers", "safety", "PPE"],
        description:
          "Automatically detect the absence of face masks & protective gear and generate alerts",
      },
      {
        name: "Box Counting",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Box Counting.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/box-counting.jpg",
        tags: [
          "warehouse",
          "logistics",
          "box counting",
          "inventory",
          "manufacturing",
        ],
        description:
          "Keep a count of different number of boxes automatically & number of objects being loaded/unloaded",
      },
      {
        name: "Mobile Phone Usage Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Mobile Phone Detection.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/MobileDetection.png",
        tags: [
          "warehouse",
          "logistics",
          "mobile phone",
          "detection",
          "compliance",
        ],
        description:
          "Get notified if workers are using phones or other gadgets on shop floor",
      },
      {
        name: "Facial Recognition Attendance Management",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/FRAM.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/FacialRecognition.png",
        tags: ["warehouse", "logistics", "FRAM", "attendance"],
        description:
          "Manage the attendance of all your employees using facial recognition",
      },
      {
        name: "Visual Inventory Management",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Visual Inventory.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/vim.png",
        tags: [
          "warehouse",
          "logistics",
          "visual inventory management",
          "inventory",
        ],
        description: "Helps you monitor on-shelf inventory",
      },
      {
        name: "Social Distancing Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Social Distancing Detection.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/Socialdistencing2.jpg",
        tags: ["warehouse", "logistics", "social distancing detection"],
        description: "Generate alerts if social distancing norms are violated",
      },
      {
        name: "Thermal Scanning",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Thermal Scanning.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/thermalScanning.jpg",
        tags: [
          "warehouse",
          "logistics",
          "thermal scanning",
          "temperature",
          "worker safety",
        ],
        description: "Generate alerts if a worker has high body temperature",
      },
      // {
      //   name: "Smoke Detection",
      //   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Smoke detection.png",
      //   imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/SmokeDetection.png",
      //   tags: ["warehouse", "logistics", "smoke detection", "safety"],
      //   description:
      //     "Locate Smoke through cameras and trigger an alert for action",
      // },
    ],
  },
];

export const partners = [
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/hitachi.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/cisco.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/vmware.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/veracity.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/trinity.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/honeywell.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/qognify.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/genetec.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/Milestone.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/intel.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/nvidia.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/dell.jpeg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/ags.jpg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/amnex.jpeg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/hp.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/icd.jpeg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/johnsons-control.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/L&T.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/nec.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/Xilinx.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/QualcommLogo.svg",
];

export const customers = [
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/wani.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/sicm.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrtc.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/upl.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/govt-of-telangana.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrb.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/hindustan-petroleum.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dxc-tech.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/yotta.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/20cube.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/mercedez.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/indiabulls.png",
  // "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/raytheon-tech.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/biocon.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dr-reddy-labs.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bengaluru-safe-city.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bareily-smart-city.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/thane-smart-city.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Jaipur.jpeg",
];

export const customers1 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/20cube.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bareily-smart-city.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bengaluru-safe-city.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/biocon.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/upl.png",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dr-reddy-labs.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dxc-tech.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/govt-of-telangana.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/hindustan-petroleum.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/wani.png",
  ],
};

export const customers2 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/indiabulls.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Jaipur.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/mercedez.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrb.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/yotta.png",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrtc.png",
    // "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/raytheon-tech.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/sicm.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/thane-smart-city.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/maharashtra.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Kdisc.jpeg",
  ],
};

export const partners1 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Cisco.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Dell.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Genetec.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Hitachi.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Honeywell.png",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Intel.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Milestone.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Nvidia.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Qognify.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Trinity.png",
  ],
};

export const partners2 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/AGS Transact Technologies.jpg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Amnex.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Hewlett Packard Enterprsie.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Honeywell.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/ICD Technologies.jpeg",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Johnsons Control.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/L&T.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/NEC.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/Xilinx.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/QualcommLogo.svg",
  ],
};

export const intergrationData = [
  {
    name: "cloud",
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/cloud.png",
    hoverCoordinates: [
      { x: 162, y: 470, text: "near monitor" },
      { x: 271, y: 106, text: "near Awiros Os" },
      { x: 626, y: 88, text: "near Video AI Appstack" },
      { x: 785, y: 106, text: "near Public/ Private Cloud" },
      { x: 1071, y: 542, text: "near camera" },
    ],
  },
  {
    name: "premise",
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/on-premise.png",
    hoverCoordinates: [
      { x: 70, y: 361, text: "near VMS Server" },
      { x: 240, y: 544, text: "near Command Control center" },
      { x: 460, y: 687, text: "near Awiros Os" },
      { x: 526, y: 140, text: "near Intelligence Engyn" },
      { x: 582, y: 275, text: "near on premise server" },
      { x: 889, y: 101, text: "near Video AI Appstack" },
      { x: 1193, y: 466, text: "near camera" },
    ],
  },
  {
    name: "edge",
    imageUrl: "/assets/edge+central.png",
    hoverCoordinates: [
      { x: 1, y: 432, text: "near VMS Server" },
      { x: 141, y: 613, text: "near Command Control center" },
      { x: 281, y: 722, text: "near Awiros Os" },
      { x: 242, y: 123, text: "near Intelligence Engyn" },
      { x: 679, y: 45, text: "near Video AI Appstack" },
      { x: 1056, y: 560, text: "near Awiros Edge Server" },
      { x: 1406, y: 627, text: "near on Site n" },
    ],
  },
];

export const notableDeployments = [
  {
    mainLogo:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/NotableDeployments/20Cube-Logo 1.png",
  },
  {
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/NotableDeployments/increasedControl.png",
    text: "Increased control over supply chain",
  },
  {
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/NotableDeployments/workersSafety.png",
    text: `Increased worker's safety`,
  },
  {
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/NotableDeployments/perimeterSecured.png",
    text: "Increased perimeter security",
  },
  {
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/NotableDeployments/efficiency.png",
    text: "Increased operational efficiency",
  },
];

export const whyBetter = [
  {
    name: "Diverse Marketplace",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps.png",
    subText: "Offers 60+ video AI apps on our Appstack",
  },
  {
    name: "Dynamic Deployment",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/dynamic-deployment.png",
    subText:
      "Deploy video AI apps at any scale with an unmatched level of flexibility",
  },
  {
    name: "Hardware Agnostic",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/hardware.png",
    subText:
      "Awiros apps run smoothly without the need for specialized cameras or hardware",
  },
  {
    name: "Scale on Demand",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/scale.png",
    subText: "Scale up to thousands of camera with ease",
  },
  {
    name: "24*7 Support",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/support.png",
    subText: "A 24*7 team to support and take care of all queries",
  },
  {
    name: "Real-time Data & Alerts",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/real-time.png",
    subText: "Monitor and get instant alerts from multiple apps in real time",
  },
  {
    name: "Easy to Integrate",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/easy-to-integrate.png",
    subText:
      "Easy integration into existing systems by 3 different deployment architecture, quick integration",
  },
];
