import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section3CardStyles from "./Section3CardStyles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
import Modal from "@material-ui/core/Modal";
import { WithStore } from "pure-react-carousel";

let styles = Section3CardStyles;

class Section3Card extends Component {
    static defaultProps = {
        name: "Awiros - The Video Intelligence OS - An Introduction",
        videoID: "f0O8gQgaJq4",
        thumbnail: "./../../../../../assets/images/resource/temp-thumbnail.png"
    };

    constructor(props) {
        super(props);
        this.state = {
            videoOpen: false
        };
    }

    handleVideoOpen = () => {
        this.setState({ videoOpen: true });
    };

    handleVideoClose = () => {
        // this.props.carouselStore.setStoreState({isPlaying: true});
        this.setState({ videoOpen: false });
    };

    render() {
        let { classes, cardData } = this.props;
        let { videoOpen } = this.state;
        return (
            <>
                <div className={classes.root}>
                    <Card>
                        <CardActionArea
                            className={classes.card}
                            onClick={this.handleVideoOpen}
                            onDrag={this.dragStarted}
                        >
                            <img
                                src={cardData.thumbnail}
                                alt="thumbnail"
                                className={classes.thumbnailImage}
                            />
                            <div className={classes.videoTitle}>
                                {cardData.name}
                            </div>
                        </CardActionArea>
                    </Card>
                </div>

                <Modal
                    open={videoOpen}
                    // disablePortal
                    disableEnforceFocus
                    disableAutoFocus
                    onClose={this.handleVideoClose}
                    // className={classes.modal}
                    aria-labelledby="simple-modal-title"
                    aria-describedby="simple-modal-description"
                >
                    <Card className={classes.videoContainer}>
                        <iframe
                            width="100%"
                            height="100%"
                            src={`https://www.youtube.com/embed/${cardData.videoID}`}
                            frameBorder="0"
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </Card>
                </Modal>
            </>
        );
    }
}

export default withStyles(styles)(Section3Card);
