let Section5CardStyles = {
   root: {
      // padding: '45px 32px',
      // border: `1px solid ${StyleConfig.mainThemeColor}`,
      height: '100%',
      position: 'relative',
   },
   card: {
      // width: '85%',
      height: '100%',
      // height: '85%',
      // minHeight: 200,
   },
   cardClickableArea: {
      padding: 10
   },
   title: {
      fontSize: 16,
      padding: '0 10px'
   },
   thumbnailImage: {
      width: '100%'
   },
};

export default Section5CardStyles;