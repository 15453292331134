import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import CreditsPageStyles from "./CreditsPageStyles";

let styles = CreditsPageStyles;

class CreditsPage extends Component {
   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <div>partner with us: <a href="https://www.freepik.com/free-photos-vectors/people">People vector created by
               pch.vector - www.freepik.com</a></div>
            <div>develop with us: <a href="https://www.freepik.com/free-photos-vectors/technology">Technology vector
               created by stories - www.freepik.com</a></div>
            <div>join us: <a href="https://www.freepik.com/free-photos-vectors/background">Background vector created by
               katemangostar - www.freepik.com</a></div>
            <div>developer-tools: <a href="https://www.freepik.com/free-photos-vectors/business">Business vector created
               by freepik - www.freepik.com</a></div>
            <div>integration: <a href="https://www.freepik.com/free-photos-vectors/banner">Banner vector created by
               katemangostar - www.freepik.com</a></div>
            <div>smart-traffic: <a href="https://www.freepik.com/free-photos-vectors/car">Car vector created by
               macrovector - www.freepik.com</a></div>
            <div>smart & safe cities: <a href="https://www.freepik.com/free-photos-vectors/abstract">Abstract vector
               created by macrovector - www.freepik.com</a></div>
            <div>forensics: <a href="https://www.freepik.com/free-photos-vectors/technology">Technology vector created
               by macrovector - www.freepik.com</a></div>
            <div>gears in awiros os motion graphic: <a href="https://www.freepik.com/free-photos-vectors/business">Business
               vector created by rawpixel.com - www.freepik.com</a></div>
            <div>manufacturing new: <a href="https://www.freepik.com/free-photos-vectors/poster">Poster vector created
               by macrovector - www.freepik.com</a></div>
            <div>smart home: <a href="https://www.freepik.com/free-photos-vectors/background">Background vector created
               by freepik - www.freepik.com</a></div>
            <div>developer in banner gif:<a href="https://www.freepik.com/free-photos-vectors/business">Business vector
               created by freepik - www.freepik.com</a></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
         </div>
      );
   }
}

export default withStyles(styles)(CreditsPage);