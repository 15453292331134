import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import {
  CarouselProvider,
  Dot,
  DotGroup,
  Slide,
  Slider,
} from "pure-react-carousel";
import "pure-react-carousel/dist/react-carousel.es.css";
import Grid from "@material-ui/core/Grid";
import CarouselComponentWithDotsStyles from "./Carousel-ComponentWithDotsStyles";
import withWidth from "@material-ui/core/withWidth";
import clsx from "clsx";

let styles = CarouselComponentWithDotsStyles;

class CarouselComponentWithDots extends Component {
  static defaultProps = {
    isPlaying: true,
    interval: 5000,
    step: 1,
    currentSlide: 0,
    visibleSlides: 1,
    aspectRatioWidth: 700,
    aspectRatioHeight: 618,
    hasShadow: false,
    showDots: true,
    contentArray: [
      //just bare components to be sent as an array, ex: <img src="something" alt="something"/>
    ],
    isIntrinsicHeight: true,
  };
  renderDots = (props) => {
    return (
      <Grid
        container
        direction={"row"}
        justify={"center"}
        alignItems={"center"}
      >
        {Array.from(Array(props.totalSlides)).map((element, index) => {
          return (
            <Dot slide={index} key={index}>
              <i className="far fa-circle" />
            </Dot>
          );
        })}
      </Grid>
    );
  };

  render() {
    let { contentArray, width, classes } = this.props;

    return (
      <div className={classes.root}>
        {/*naturalSlideWidth & naturalSlideHeight are not dimensions, these are for aspect ratio only*/}
        <CarouselProvider
          infinite
          isPlaying={this.props.isPlaying}
          interval={this.props.interval}
          isIntrinsicHeight={this.props.isIntrinsicHeight}
          step={this.props.step}
          visibleSlides={this.props.visibleSlides}
          currentSlide={this.props.currentSlide}
          naturalSlideWidth={this.props.aspectRatioWidth}
          naturalSlideHeight={this.props.aspectRatioHeight}
          totalSlides={this.props.contentArray.length}
        >
          <Slider
            isPlaying={this.props.isPlaying}
            className={this.props.hasShadow ? classes.imageContainer : ""}
          >
            {this.props.contentArray.map((Component, index) => {
              return (
                <Slide
                  index={index}
                  key={index}
                  className={clsx(
                    classes.componentCard,
                    this.props.cardComponentClasses
                  )}
                >
                  {Component}
                </Slide>
              );
            })}
          </Slider>
          {this.props.showDots && (
            <DotGroup
              className={classes.dotGroup}
              renderDots={this.renderDots}
            />
          )}
        </CarouselProvider>
      </div>
    );
  }
}

export default withWidth()(withStyles(styles)(CarouselComponentWithDots));
