import React from "react";
import ResourcePageCaseStudySectionStyles from "./ResourcePageCaseStudySectionStyles.js";
import { makeStyles, withStyles } from "@material-ui/styles";
import RoutePath from "../../../lib/RoutePath.js";
const useStyles = makeStyles(ResourcePageCaseStudySectionStyles);

function ResourcePageCaseStudySection() {
  const classes = useStyles();
  return (
    <div className={classes.caseStudy}>
      <div style={{ marginBottom: "50px" }}>
        <div className={"h2heading"}>Case Studies</div>
        <div
          className={classes.card}
          style={{
            boxShadow:
              "0px 2px 1px -1px rgb(0 0 0 / 20%), 0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%)",
          }}
        >
          <a href={RoutePath.caseStudy} className={classes.link}>
            <img
              src="/assets/case-study/caseStudyThumbnail.svg"
              width="100%"
              className={classes.cardImage}
            ></img>
            <div className={classes.cardTitle}>
              Leveraging AWS cloud & Intel Distribution of OpenVINO Toolkit to
              Create Awiros Video AI Marketplace{" "}
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default ResourcePageCaseStudySection;
