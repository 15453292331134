import { makeStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";
import "@splidejs/splide/dist/css/splide.min.css";
import { Link } from "react-router-dom";
import RoutePath from "../../lib/RoutePath";

const useStyles = makeStyles({
  banner: {
    color: "black",
    display: "flex",
    flexDirection: "row",
    margin: "0 auto",
    marginTop: "50px",
    justifyContent: "space-between",
    gap: "0px",
    "@media (max-width:1200px)": {
      marginTop: "5%",
      width: "90%",
      margin: "0 auto",
      //   marginTop: "150px",
    },
    "@media (max-width:768px)": {
      marginTop: "3%",
      //   marginTop: "150px",
    },
    "@media (max-width:600px)": {
      flexDirection: "column-reverse",
      //   marginTop: "150px",
    },
    // border: "1px solid black",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "column",
    width: "calc( 40% - 40px )",
    paddingLeft: "10px",
    "@media (max-width:1200px)": {
      marginTop: "%",
    },
    "@media (max-width:600px)": {
      width: "80%",
      margin: "0 auto",
      textAlign: "center",
      marginTop: "40px",
    },
    "@media (max-width:550px)": {
      width: "100%",
      margin: "0 auto",
      textAlign: "center",
      marginTop: "40px",
    },
    // justifyContent: "center",
    // border: "1px solid black",
  },
  heading: {
    fontSize: "56px",
    fontWeight: "700",
    "@media (max-width:1200px)": {
      fontSize: "40px",
    },
    "@media (max-width:1024px)": {
      fontSize: "28px",
    },
    "@media (max-width:768px)": {
      fontSize: "24px",
    },
  },
  headingEnd: {
    color: "#FFCC00",
  },
  subHeadind: {
    fontSize: "24px",
    fontWeight: "300",
    marginTop: "40px",
    marginBottom: "40px",
    "@media (max-width:1200px)": {
      fontSize: "20px",
      marginBottom: "10%",
      marginTop: "10%",
    },
    "@media (max-width:1024px)": {
      fontSize: "16px",
    },
  },
  button: {
    display: "inline-block",
    fontSize: "24px",
    fontWeight: "400",
    backgroundColor: "#FFCC00",
    borderRadius: "40px",
    border: "none",
    cursor: "pointer",
    width: "250px",
    padding: "12px 24px",
    "@media (max-width:1200px)": {
      padding: "6px 12px",
      width: "180px",
      borderRadius: "15px",
      fontSize: "16px",
    },
    "@media (max-width:768px)": {
      margin: "0 auto",
    },
  },
  rightContainer: {
    display: "flex",
    width: "60%",
    flexDirection: "row",
    // border: "1px solid black",
    // height: "max-content",
    "@media (max-width:600px)": {
      width: "80%",
      textAlign: "center",
      margin: "0 auto",
      //   border: "1px solid black",
      justifyContent: "center",
    },
    "@media (max-width:530px)": {
      width: "100%",
    },
  },
  line1: {
    width: "40px",
    height: "5px",
    marginTop: "15%",
    "@media (max-width:1200px)": {
      width: "30px",
    },
    "@media (max-width:768px)": {
      width: "15px",
      height: "0px",
    },
  },
  line2: {
    width: "20px",
    height: "5px",
    marginTop: "15%",
    "@media (max-width:1200px)": {
      width: "15px",
      height: "3px",
    },
  },
  line3: {
    width: "20px",
    height: "5px",
    marginTop: "50%",
    "@media (max-width:1200px)": {
      width: "15px",
      height: "3px",
    },
  },
  line4: {
    width: "40px",
    height: "5px",
    marginTop: "50%",
    "@media (max-width:1200px)": {
      width: "30px",
    },
    "@media (max-width:768px)": {
      width: "15px",
      height: "0px",
    },
  },
  card: {
    position: "relative",
    cursor: "pointer",
  },
  cardImage: {
    width: "100%",
    objectFit: "contain",
    border: "2px solid #FFCC00",
  },
  cardtitle: {
    position: "absolute",
    bottom: "50px",
    color: "white",
    display: "flex",
    width: "100%",
    justifyContent: "center",
    fontSize: "20px",
    fontWeight: "900",
    "@media (max-width:1200px)": {
      fontSize: "16px",
    },
    "@media (max-width:1024px)": {
      fontSize: "14px",
    },
    "@media (max-width:768px)": {
      fontSize: "14px",
    },
    "@media (max-width:700px)": {
      fontSize: "10px",
    },
    "@media (max-width:600px)": {
      fontSize: "14px",
    },
    "@media (max-width:500px)": {
      fontSize: "12px",
    },
    "@media (max-width:430px)": {
      fontSize: "10px",
    },
  },
});
function IndustriesBanner() {
  const classes = useStyles();

  return (
    <div className={classes.banner}>
      <div className={classes.leftContainer}>
        <div className={classes.heading}>
          LAUNCH OF OUR{" "}
          <span className={classes.headingEnd}>SUCCESS STORIES</span>
        </div>
        <div className={classes.subHeadind}>
          Check out how Awiros is impacting industries through Video AI
          solutions
        </div>
        <Link to={RoutePath.industriesMatching}>
          <button className={classes.button}>Learn More</button>
        </Link>
      </div>
      <div className={classes.rightContainer}>
        <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/homebanner/l1.png" className={classes.line1}></img>
        <Link to={RoutePath.industriesMatching}>
          <div className={classes.card}>
            <img
              src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/homebanner/smartcityNew.png"
              className={classes.cardImage}
            ></img>
            <div className={classes.cardtitle}>Smart Cities</div>
          </div>
        </Link>
        <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/homebanner/l2.png" className={classes.line2}></img>
        <Link to={RoutePath.industriesPageTwo}>
          <div className={classes.card}>
            <img
              src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/homebanner/warehousing.png"
              className={classes.cardImage}
            ></img>
            <div className={classes.cardtitle}>Warehousing</div>
          </div>
        </Link>
        <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/homebanner/l3.png" className={classes.line3}></img>

        <Link to={RoutePath.industriesPageThree}>
          <div className={classes.card}>
            <img
              src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/homebanner/manufacturing.png"
              className={classes.cardImage}
            ></img>
            <div className={classes.cardtitle}>Manufacturing</div>
          </div>
        </Link>
        <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/homebanner/l4.png" className={classes.line4}></img>
      </div>
    </div>
  );
}

export default IndustriesBanner;
