import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import AppStackPageStyles from "./AppStackPageStyles";
import Section1 from "./Sections/Section1/Section1";
import Section5 from "./Sections/Section5/Section5";

let styles = AppStackPageStyles;

class AppStackPage extends Component {
   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <Section1/>

            {/*<Section2/>*/}

            {/*<Section3/>*/}

            {/*<Section4/>*/}

            <Section5/>
         </div>
      );
   }
}

export default withStyles(styles)(AppStackPage)