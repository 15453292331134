import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section2Styles from "./Section2Styles";

let styles = Section2Styles;

class Section2 extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <div className={"h2heading center smallMb"}>
                    A New Age OS for Video AI
                </div>
                <div className={"h3heading center mediumMb"}>
                    See how Awiros is different from traditional Video Analytics
                    systems
                </div>
                <img
                    className={classes.image}
                    src={"/assets/images/resource/comparison-gif.gif"}
                    alt="cycle"
                />
            </div>
        );
    }
}

export default withStyles(styles)(Section2);
