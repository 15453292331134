import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import AboutOrganizationStyles from "./AboutOrganizationStyles";
import { aboutOrganisers } from "../utils/aboutOrganisers";
import RoutePath from "../../../lib/RoutePath";

const AboutOrganization = (props) => {
  const { classes } = props;

  return (
    <div
      className={classes.aboutOrganizationContainer}
      style={{
        background: `url('/assets/appathon/guidelinesBG.png')`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundColor: "#2C2C2C",
      }}
    >
      <div className={classes.aboutOrganizationHeading}>
        ABOUT THE ORGANISERS
      </div>
      <div>
        <div className={classes.aboutOrganizationContainer}>
          <div className={classes.aboutOrganizationContent}>
            <img
              className={classes.aboutOrganizationContentLogo}
              src="/assets/appathon/aboutOrgLogo.png"
            ></img>

            <div className={classes.infoContainer}>
              {aboutOrganisers.map((info, index) => (
                <div key={index} className={classes.info}>
                  <span className={classes.infodot}>. </span>
                  <div className={classes.info}>{info}</div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.line}></div>
        <div className={classes.knowMore}>
          Want to know more about <span style={{ color: "#FC0" }}>AWIROS</span>!
        </div>
        <div className={classes.visitUsContainer}>
          <a href={"https://awiros.com/"} target="_blan">
            <button className={classes.visitUs}>VISIT US</button>
          </a>
        </div>
        <div className={classes.socialLinks}>
          <div className={classes.singleLink}>
            <a
              href={RoutePath.linkedInPath}
              target="_blank"
              className={classes.socialLink}
            >
              <img
                src="assets/intelInnovation/footer/linkedIn.png"
                className={classes.socialLinkLogo}
              ></img>
            </a>
            <span className={classes.singleLinkText}>Awiros</span>
          </div>
          <div className={classes.singleLink}>
            <a
              href={RoutePath.youTubePath}
              target="_blank"
              className={classes.socialLink}
            >
              <img
                src="assets/intelInnovation/footer/youTube.png"
                className={classes.socialLinkLogo}
              ></img>
            </a>
            <span className={classes.singleLinkText}>
              Awiros Video Intelligence
            </span>
          </div>
          <div className={classes.singleLink}>
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=info@awiros.com"
              target="_blank"
              className={classes.socialLink}
            >
              <img
                src="assets/intelInnovation/footer/mail.png"
                className={classes.socialLinkLogo}
              ></img>
            </a>
            <span className={classes.singleLinkText}>info@awiros.com</span>
          </div>
        </div>
        <div className={classes.address}>
          <img
            src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/intelInnovation/footer/map.png"
            className={classes.addressLogo}
          ></img>
          <div>
            11, Vatika City Point, MG Road Gurgaon(122002), (+91) 124 426 3789{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(AboutOrganizationStyles, { withTheme: true })(AboutOrganization)
);
