let Section6Styles = {
   root: {
      marginBottom: 120
   },
   headerImage: {
      width: '70%',
      margin: 'auto',
      marginTop: '20px',
      // marginLeft: '3px',
      textAlign: 'center',
      '@media (max-width: 600px)': {
         width: '100%',
      },
   }
};

export default Section6Styles;