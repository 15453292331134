import React from "react";
import BannerCompStyles from "./BannerCompStyles";
import { makeStyles, withStyles } from "@material-ui/styles";
const useStyles = makeStyles(BannerCompStyles);

function BannerComp({ bannerData }) {
  const classes = useStyles();
  return bannerData.map((banner, index) => {
    const {
      title,
      apps,
      areaCovered,
      cameraCount,
      bartnote,
      bartnoteUrl,
      imageUrl,
      livesImpacted,
      subTitle,
      videoUrl,
    } = banner;
    return (
      <div className={classes.carousel} key={index}>
        <div
          className={classes.carouselDetailsConatiner}
          style={{
            background: `url(${imageUrl}) no-repeat`,
            backgroundSize: "cover",
          }}
        >
          <div className={classes.carouselDetails}>
            <h1 className={classes.bannerTitle}>{title}</h1>
            <p className={classes.bannerSubtitle}>{subTitle}</p>
            <div className={classes.carouselMetrics}>
              <div className={classes.metrics}>
                <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/cctv.svg" />
                <div className={classes.metricData}>
                  <span>{cameraCount}</span>
                  <span>Cameras</span>
                </div>
              </div>
              <div className={classes.metrics}>
                <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/carbon_area.svg" />
                <div className={classes.metricData}>
                  <span>{areaCovered}</span>
                  <span>Area Covered</span>
                </div>
              </div>
              <div className={classes.metrics}>
                <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps.svg" />
                <div className={classes.metricData}>
                  <span>{apps}</span>
                  <span>Apps</span>
                </div>
              </div>
              <div className={classes.metrics}>
                <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/people.svg" />
                <div className={classes.metricData}>
                  <span>{livesImpacted}</span>
                  <span>Lives Impacted</span>
                </div>
              </div>
            </div>
            <div className={classes.contactContainer}>
              <button className={classes.contact}>
                <a href="/contact">
                  <span>Contact Us</span>
                  <img
                    className={classes.carretRight}
                    src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/carret--right.svg"
                    alt="Carret right"
                  />
                </a>
              </button>
            </div>

            <p>
              <a className={classes.bartnote} href={bartnoteUrl}>
                {bartnote}
              </a>
            </p>
          </div>
          <div className={classes.carouselVideo}>
            <video autoPlay loop muted>
              <source src={videoUrl} type="video/mp4" />
            </video>
          </div>
        </div>
      </div>
    );
  });
}

export default BannerComp;
