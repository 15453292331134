import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import HorizontalTabsStyles from "./HorizontalTabsStyles";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import withWidth from "@material-ui/core/withWidth";

let styles = HorizontalTabsStyles;

function TabPanel(props) {
   const {children, value, index, ...other} = props;

   return (
      <Typography
         component="div"
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && <Box p={3}>{children}</Box>}
      </Typography>
   );
}

function a11yProps(index) {
   return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
   };
}

class HorizontalTabs extends Component {
   static defaultProps = {
      tabData: [],
      hideTabsIfSingleTabSupplied: false
   };

   constructor(props) {
      super(props);
      this.state = {
         tabValue: 0
      }
   }

   handleTabChange = (event, newValue) => {
      this.setState({tabValue: newValue});
   };

   render() {
      let {classes, width} = this.props;
      let {tabValue} = this.state;
      let centered;
      if (this.props.tabData.length > 3) {
         if (width === 'xl' || width === 'lg') {
            centered = true
         } else {
            centered = false
         }
      } else {
         if (width === 'xs') {
            centered = false
         } else {
            centered = true
         }
      }
      return (
         <div className={classes.root}>
            <AppBar position="static" className={classes.tabContainer}
                    style={{display: this.props.tabData.length <= 1 && this.props.hideTabsIfSingleTabSupplied ? 'none' : 'inherit'}}
            >
               <Tabs
                  centered={centered}
                  variant={!centered ? "scrollable" : "standard"}
                  scrollButtons="on"
                  value={tabValue} onChange={this.handleTabChange}
                  classes={{indicator: classes.selectedTabIndicator}} aria-label="simple tabs"
               >
                  {this.props.tabData.map((tab, index) => {
                     return <Tab key={index} label={tab.name} {...a11yProps(index)} />
                  })}
               </Tabs>
            </AppBar>
            {this.props.tabData.map((tab, index) => {
               return (
                  <TabPanel value={tabValue} index={index} key={index}>
                     {tab.content}
                  </TabPanel>
               )
            })}
         </div>
      );
   }
}

export default withWidth()(withStyles(styles, {withTheme: true,})(HorizontalTabs));