let Section1Styles = {
   root: {
      marginBottom: 40,
   },
   headerImage: {
      width: '100%',
      margin: 'auto',
      // marginBottom:20,
      textAlign: 'center',
      '@media (max-width: 600px)': {
         width: '100%',
      },
   },
   linkIcons: {
      fontSize: '24px!important',
      color: 'grey'
   },
   clickHereLink: {
      color: 'blue',
      textDecoration: 'underline',
      cursor: 'pointer'
   },
   supportFormCardContainer: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%) !important',
      width: '50%',
      padding: 10,
      maxHeight: '95%',
      // overflowX:'auto',
      border: 0,
      outline: 0,
      overflowY: 'auto',
      '@media (max-width:960px)': {
         width: '75%',
      },
      '@media (max-width:700px)': {
         width: '95%',
      }

   },
};

export default Section1Styles;
