let colors = {
   column1: 'rgb(68, 84, 106)',
   column1Faded: 'rgba(68, 84, 106, 0.8)',
   column2: 'rgb(68, 114, 196)',
   column2Faded: 'rgba(68, 114, 196, 0.8)',
   column3: 'rgb(237, 125, 49)',
   column3Faded: 'rgba(237, 125, 49, 0.8)',
   column4: 'rgb(165, 165, 165)',
   column4Faded: 'rgba(165, 165, 165, 0.8)',
   column5: 'rgb(255, 192, 0)',
   column5Faded: 'rgba(255, 192, 0, 0.8)',
};


let Section5Styles = {
   root: {
      marginBottom: 140,
      textAlign: 'center',
      marginTop: 100
   },
   largeImage: {
      width: '75%',
      margin: 'auto'
   },
   tableContentRow: {},
   tableContentCell: {
      borderLeft: '1px solid lightgrey',
      '&:first-child': {
         borderLeft: '0px'
      }
   },

   columnHeaderCell: {
      width: '20%',
      fontSize: 17,
      fontWeight: 500
   },
   column1HeaderCell: {
      backgroundColor: colors.column1,
      color: 'white',
      borderBottomColor: colors.column1,
   },
   column2HeaderCell: {
      backgroundColor: colors.column2,
      color: 'white',
   },
   column3HeaderCell: {
      backgroundColor: colors.column3,
      color: 'white',
   },
   column4HeaderCell: {
      backgroundColor: colors.column4,
      color: 'white',
   },
   column5HeaderCell: {
      backgroundColor: colors.column5,
      color: 'white',
   },


   column1BodyCell: {
      backgroundColor: colors.column1,
      color: 'white',
      borderBottomColor: colors.column1,
      width: '20%'
   },
   column2BodyCell: {
      // backgroundColor: colors.column2,
      width: '20%'
   },
   column3BodyCell: {
      // backgroundColor: colors.column3,
      width: '20%'
   },
   column4BodyCell: {
      // backgroundColor: colors.column4,
      width: '20%'
   },
   column5BodyCell: {
      // backgroundColor: colors.column5,
      width: '20%'
   },


   column2ContactButton: {
      backgroundColor: colors.column2,
      padding: '9px 30px',
      color: 'white',
      '&:hover': {
         backgroundColor: colors.column2Faded,
      }
   },
   column3ContactButton: {
      backgroundColor: colors.column3,
      padding: '9px 30px',
      color: 'white',
      '&:hover': {
         backgroundColor: colors.column3Faded,
      }
   },
   column4ContactButton: {
      backgroundColor: colors.column4,
      padding: '9px 30px',
      color: 'white',
      '&:hover': {
         backgroundColor: colors.column4Faded,
      }
   },
   column5ContactButton: {
      backgroundColor: colors.column5,
      padding: '9px 30px',
      color: 'white',
      '&:hover': {
         backgroundColor: colors.column5Faded,
      }
   },


};

export default Section5Styles;