import StyleConfig from "../../../../lib/StyleConfig";

let Section3Styles = {
   root: {
      marginBottom: 140,
      border: 0,
      outline: 0
   },
   heading: {
      color: StyleConfig.headingColor,
      textAlign: 'center',
      fontSize: 30,
      fontWeight: 300,
      marginBottom: 20
   },
   textContent: {
      textAlign: 'center',
      fontSize: '18px!important',
      fontWeight: 300,
      margin: 'auto 100px',
      '@media (max-width: 768px)': {
         margin: 'auto 30px',
      },
      marginBottom: 40,
   },
   largeImage: {
      width: '80vw',
      border: '1px solid black'
   },
   ytButtonContainer: {
      padding: '20px 10px'
   },
   ytButton: {
      width: 100,
   }
};

export default Section3Styles;