let ResourcesPageHeaderStyles = {
   root: {
      // marginBottom:40,
   },
   headerImage: {
      width: '100%',
      margin: 'auto',
      // marginBottom:20,
      textAlign: 'center',
      '@media (max-width: 600px)': {
         width: '100%',
      },
   },
};

export default ResourcesPageHeaderStyles;
