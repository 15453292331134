import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import ProductCardStyles from "./ProductCardStyles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import Button from "@material-ui/core/Button";
import RoutePath from "../../../../../lib/RoutePath";
import {withRouter} from "react-router-dom";
import Modal from "@material-ui/core/Modal";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";

let styles = ProductCardStyles;

class ProductCard extends Component {
   static defaultProps = {
      heading: '',
      subheading: '',
      body: '',
      image: '',
      link: '',
      showDataSheetButton: false,
      dataSheetLink: '',
      showLearnMoreButton: false
   }

   handleLearnMoreClick = () => {
      RoutePath.navigateTo(this.props, this.props.link)
   }

   handleDownloadDataSheetClick = () => {
      window.open(this.props.dataSheetLink, '_blank')
   }

   render() {
      let {classes} = this.props;

      return (
         <div className={classes.root}>
            <div className={clsx(classes.heading)}>{this.props.heading}</div>
            <div className={clsx(classes.subheading)}>{this.props.subheading}</div>
            <div className={clsx(classes.body)}>{this.props.body}</div>
            <Grid container direction={'row'} justify={'center'} alignItems={'center'} spacing={3}>
               <Grid item xs={11} sm={6}>
                  <img src={this.props.image} alt={this.props.heading} className={classes.productImage}/>
               </Grid>

               <Grid item xs={12} sm={6}>
                  <div className={clsx(classes.subheading)}>Highlights</div>
                  <ul className={classes.listTextContainer}>
                     {this.props.bulletPoints.map((point, index) => {
                        return (
                           <li key={index}>{point}</li>
                        )
                     })}
                  </ul>
               </Grid>
            </Grid>
            {this.props.link && this.props.showLearnMoreButton ? (
               <div className={classes.learnMoreButtonContainer}>
                  <Button className={classes.learnMoreButton} variant={'outlined'} onClick={this.handleLearnMoreClick}>
                     Learn More
                  </Button>
               </div>
            ) : null}
            {this.props.dataSheetLink && this.props.showDataSheetButton ? (
               <div className={classes.learnMoreButtonContainer}>
                  <Button className={classes.learnMoreButton} variant={'outlined'}
                          onClick={this.handleDownloadDataSheetClick}>
                     Download Data Sheet
                  </Button>
               </div>
            ) : null}
         </div>
      );
   }
}

export default withRouter(withStyles(styles)(ProductCard));
