import StyleConfig from "../../../../lib/StyleConfig";

let Section4Styles = {
   root: {
      marginBottom: 50
   },
   heading: {
      color: StyleConfig.headingColor,
      textAlign: 'center',
      fontSize: 30,
      fontWeight: 300,
   },
   textContent: {
      textAlign: 'center',
      fontSize: '18px!important',
      fontWeight: 300,
      margin: 'auto 100px',
      '@media (max-width: 768px)': {
         margin: 'auto 30px',
      },
      marginBottom: 40,
   },
   carouselImage: {
      width: '100%'
   }
}

export default Section4Styles;
