let Section1Styles = {
   root: {
      marginBottom: 40,
   },
   headerImage: {
      width: '75%',
      margin: 'auto',
      marginBottom: 20,
      textAlign: 'center',
      '@media (max-width: 600px)': {
         width: '100%',
      },
   }
};

export default Section1Styles;