let AboutOrganizationStyles = {
  aboutOrganizationContainer: {
    padding: "60px",
    "@media (max-width: 768px)": {
      padding: "40px 10px",
    },
  },
  aboutOrganizationHeading: {
    fontSize: "48px",
    fontWeight: "600",
    color: "#FC0",
    marginBottom: "30px",
    "@media (max-width: 1024px)": {
      textAlign: "center",
    },
    "@media (max-width: 768px)": {
      marginBottom: "0px",
      fontSize: "32px",
    },
  },
  aboutOrganizationContent: {
    padding: "30px",
    margin: "auto",
    border: "1px solid #FC0",
    borderRadius: "24px",
    boxShadow: "0px 2px 3px 1px rgba(0, 0, 0, 0.10)",

    "@media (max-width: 768px)": {
      border: "none",
      padding: "0px",
      padding: "30px",
    },
    "@media (max-width: 600px)": {
      border: "none",
      padding: "0px",
    },
    "@media (max-width: 500px)": {
      border: "none",
      padding: "0px",
      width: "320px",
    },
  },
  aboutOrganizationContentLogo: {
    width: "500px",
    marginBottom: "50px",
    "@media (max-width: 1440px)": {
      width: "400px",
    },
    "@media (max-width: 768px)": {
      width: "300px",
      margin: "auto",
      marginBottom: "30px",
      marginTop: "30px",
    },
  },
  infoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    color: "white",
  },
  info: {
    display: "flex",
    gap: "10px",
    fontSize: "24px",
    "@media (max-width: 1440px)": {
      fontSize: "20px",
    },
    "@media (max-width: 768px)": {
      fontSize: "16px",
    },
  },
  infodot: {
    display: "flex",
    alignItems: "top",
  },
  line: {
    border: "1px solid white",
    margin: "100px auto",
    "@media (max-width: 1024px)": {
      width: "500px",
    },
    "@media (max-width: 768px)": {
      width: "90%",
      margin: "50px auto",
    },
  },
  knowMore: {
    textAlign: "center",
    color: "white",
    fontSize: "48px",
    "@media (max-width: 768px)": {
      fontSize: "32px",
    },
  },
  visitUsContainer: {
    textAlign: "center",
  },
  visitUs: {
    backgroundColor: "#FC0",
    padding: "12px 16px",
    width: "560px",
    marginTop: "32px",
    border: "none",
    borderRadius: "12px",
    cursor: "pointer",
    fontWeight: "600",
    "@media (max-width: 768px)": {
      width: "300px",
      borderRadius: "4px",
      fontSize: "16px",
    },
  },

  socialLinks: {
    display: "flex",
    justifyContent: "center",
    fontSize: "26px",
    fontWeight: "400",
    gap: "64px",
    color: "white",
    marginTop: "70px",
    marginBottom: "30px",
    "@media (max-width:1024px)": {
      gap: "32px",
      fontSize: "20px",
    },
    "@media (max-width:768px)": {
      flexDirection: "column",
      gap: "20px",
      fontSize: "16px",
    },
  },
  singleLink: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    "@media (max-width:768px)": {
      justifyContent: "center",
    },
  },
  singleLinkText: {
    fontWeight: "200",
  },
  socialLinkLogo: {
    width: "25px",
  },
  address: {
    display: "flex",
    justifyContent: "center",
    fontSize: "24px",
    fontWeight: "200",
    color: "white",
    gap: "16px",
    "@media (max-width:1024px)": {
      gap: "16px",
      fontSize: "16px",
    },
    "@media (max-width:768px)": {
      justifyContent: "center",
      textAlign: "center",
      padding: "10px",
      gap: "5px",
      fontSize: "16px",
    },
  },
  addressLogo: {
    "@media (max-width:1024px)": {
      width: "20px",
    },
    "@media (max-width:1024px)": {
      width: "20px",
      height: "25px",
    },
  },
};

export default AboutOrganizationStyles;
