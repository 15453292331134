import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let NotableDeploymentsFirstStyles = {
  deployments: {
    marginTop: "5%",
    "@media (max-width:768px)": {
      marginTop: "10%",
    },
  },
  headings: {
    textAlign: "center",
    fontSize: 55,
    fontWeight: 600,
    lineHeight: 1.17,
    color: "#919192",

    marginBottom: 126,

    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
      marginBottom: 80,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      marginBottom: 30,
    },
  },
  deploymentImage: {
    width: 300,
    height: 300,
    flexShrink: 0,
    [theme.breakpoints.down("lg")]: {
      width: 240,
      height: 240,
    },
  },
  deploymentBannerContainer: {
    display: "flex",
    padding: 1,
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "max-content",
      margin: "0 auto",
    },
    "@media (max-width:400px)": {
      width: "100%",
    },
  },
  "@keyframes myAni": {
    "0%": {
      opacity: "0",
    },
    "10%": {
      opacity: "1",
    },
    "80%": {
      opacity: "1",
    },
    "90%": {
      opacity: "0",
    },
    "100%": {
      opacity: "0",
    },
  },
  myAnimation: {
    animation: `$myAni 5000ms linear infinite`,
  },
  deploymentMetrics: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(4,1fr)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [theme.breakpoints.up("xl")]: {
      width: "max-content",
    },
  },
  deploymentCells: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    maxWidth: 300,
    "& div": {
      flexShrink: 0,
      width: "50%",
      maxWidth: 150,
      textAlign: "center",
      aspectRatio: "1/1",
    },
  },
  deploymentMetricCells: {
    flexBasis: "50%",
    backgroundColor: " #3C5D94",
    // backgroundImage:
    //   "linear-gradient(90.51deg, #373B44 -3.74%, #4286F4 103.23%)",
  },
  iconImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 24,
    "& img": {
      width: 64,
      height: 64,
    },
    [theme.breakpoints.down("md")]: {
      "& img": {
        width: 50,
        height: 50,
      },
    },
  },
  gradientText: {
    "-webkit-text-fill-color": "transparent",
    background:
      "linear-gradient(to left ,rgba(60, 93, 148, 1),rgba(60, 93, 148, 1))",
    "-webkit-background-clip": "text",
  },
  metricsData: {
    fontSize: 30,
    fontWeight: 700,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#fff",
    "& span": {
      fontSize: 24,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      "& span": {
        fontSize: 18,
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      "& span": {
        fontSize: 16,
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      "& span": {
        fontSize: 14,
      },
    },
  },
  gutterSpace: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  backdrop: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#fff",
    top: 0,
    right: 0,
    // border:'1px solid black',
    //   '@media (max-width:400px)':{
    //     right:"-50px"
    // },
  },
  partnerContainer: {
    padding: "180px 65px",
    [theme.breakpoints.down("md")]: {
      padding: "100px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "60px 16px",
    },
  },
  headings: {
    textAlign: "center",
    fontSize: 55,
    fontWeight: 600,
    lineHeight: 1.17,
    // color: "#919192",
    margin: 0,
    marginBottom: 126,
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
      marginBottom: 80,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      marginBottom: 30,
    },
  },
};

export default NotableDeploymentsFirstStyles;
