import StyleConfig from "../../../../lib/StyleConfig";

let Section1Styles = {
  root: {
    marginBottom: 40,
    marginTop: "50px",
  },
  heading: {},
  videoContainer: {
    display: "flex",
    justifyContent: "center",
  },
  appStackVideo: {
    width: "100%",

    //  "@media (max-width: 1300px)": {
    //    width: "90%",
    //  },
  },
  appStack: {
    width: "100%",
    // padding:'0 20px 20px',
    marginBottom: 20,
    border: "1px solid",
    borderColor: StyleConfig.borderOutline,
    height: 700,
    overflowX: "hidden",
    overflowY: "auto",
  },
};

export default Section1Styles;
