import StyleConfig from "../../lib/StyleConfig";

let AwirosOSPageStyles = {
  root: {
    margin: "0 auto",
    maxWidth: 1200,
    padding: "0 20px 50px",
    flex: 1,
    // width:'100vw',
    backgroundColor: StyleConfig.backgroundColor,
    "@media (min-width: 960px)": {
      padding: "0 20px 50px",
    },
    "@media (min-width: 990px)": {
      padding: "0 10px 50px",
    },
  },
  heading: {
    color: "#3b566e",
    textAlign: "center",
    fontSize: 30,
    fontWeight: 400,
  },
};

export default AwirosOSPageStyles;
