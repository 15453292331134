import Axios from 'axios';
import ServiceResponse from "./ServiceResponse";

class APIServices {
   static async request(axiosConfig) {
      try {
         const response = await Axios.request(axiosConfig);
         if (response.data.success) {
            return ServiceResponse.success(response.data.msg, (response.data.token||response.data.data));
         } else {
            // console.log('api calling error');
            return ServiceResponse.error(response.data.msg);
         }
      } catch (e) {
         if (e.response) {
            // console.log('api services error', e);
            return ServiceResponse.error('Connection timed out');
         } else {
            return ServiceResponse.error('The connection timed out');
         }
      }
   }
}

export default APIServices;
