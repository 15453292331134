export const secondBannerCardsData = [
  {
    heading: "Collaborate & Create",
    description:
      "Developers across diverse sectors are invited to utilize the platform.",
    imageUrl: "/assets/appathon/collaborate.webp",
  },
  {
    heading: "Build Production Ready Apps",
    description:
      "Creating production-ready OpenVino-based computer vision applications ",
    imageUrl: "/assets/appathon/build.webp",
  },
  {
    heading: "Get Recognized",
    description:
      "Selected applications have the opportunity to be featured on Awiros' Appstack marketplace.",
    imageUrl: "/assets/appathon/recognized.webp",
  },
  {
    heading: "Development made Easy",
    description:
      "Building complex Video AI apps can be made easier with our support.",
    imageUrl: "/assets/appathon/development.webp",
  },
];
