import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section7Styles from "./Section7Styles";
import Grid from "@material-ui/core/Grid";
import Section7TwitterFeed from "./Section7TwitterFeed/Section7TwitterFeed";
import NewsCarousel from "./NewsCarousel/NewsCarousel";
import {v4 as uuid} from 'uuid';

let styles = Section7Styles;

class Section7 extends Component {
   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <p className={'h2heading center'}>
               Awiros on News & Social Media
            </p>
            <Grid container direction={'row'} justify={'center'} spacing={3}>
               <Grid item xs={12} sm={9} md={7}>
                  <NewsCarousel history={this.props.history}/> {/*9*/}
               </Grid>

               <Grid item xs={12} sm={9} md={5}>
                  <Section7TwitterFeed key={uuid()} history={this.props.history}/> {/*8*/}
               </Grid>

            </Grid>
         </div>
      );
   }
}

export default withStyles(styles)(Section7);
