import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section4Styles from "./Section4Styles";
import HorizontalTabs from "../../../CommonComponents/HorizontalTabs/HorizontalTabs";
import TabConfig from "./tabsConfig";

let styles = Section4Styles;

class Section4 extends Component {
   constructor(props) {
      super(props);
      this.tabData = TabConfig
   }

   render() {
      let {classes} = this.props;

      return (
         <div className={classes.root}>
            <p className={classes.heading}>
               Video AI Apps for a variety of Industry Segments
            </p>
            <HorizontalTabs hideTabsIfSingleTabSupplied tabData={this.tabData}/>
         </div>
      );
   }
}

export default withStyles(styles)(Section4)