import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section3Styles from "./Section3Styles";
import CarouselComponentWithDots from "../../../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";
import withWidth from "@material-ui/core/withWidth";
import Section3Card from "./Section3Card/Section3Card";

let styles = Section3Styles;

class Section3 extends Component {
    constructor(props) {
        super(props);
        this.contentArray = [
            <Section3Card
                appName={"Your Ownership"}
                textContent={
                    "Your app’s IP, code, copyright and ownership shall stay with you at all times. Awiros only acts as a delivery mechanism for taking your AI application to end customers."
                }
                logo={"/assets/images/resource/ownership.png"}
            />,
            <Section3Card
                appName={"Your Growth"}
                textContent={
                    "With a comprehensive and transparent revenue sharing framework already in place, you can be certain of receiving all of the revenue your app generates without any hassle."
                }
                logo={"/assets/images/resource/growth.png"}
            />,
            <Section3Card
                appName={"Your Visibility"}
                textContent={
                    "Awiros only acts as an enabler for taking your app to the customer, its eventually your app and your brand that the customer interacts with and you alone can control it."
                }
                logo={"/assets/images/resource/visibility.png"}
            />,
            <Section3Card
                appName={"Your Outreach"}
                textContent={
                    "With an ever-growing customer base spread over countries across the globe, your solution’s market outreach would be much more widespread than ever before. "
                }
                logo={"/assets/images/resource/outreach.png"}
            />
        ];
    }

    render() {
        let { classes, width } = this.props;
        let visibleSlides = 3;
        if (width === "lg" || width === "md") {
            visibleSlides = 3;
        } else if (width === "sm") {
            visibleSlides = 2;
        } else if (width === "xs") {
            visibleSlides = 1;
        }
        return (
            <div className={classes.root}>
                <div className={"h2heading center"}>Awiros Assures</div>
                <CarouselComponentWithDots
                    contentArray={this.contentArray}
                    visibleSlides={visibleSlides}
                />
            </div>
        );
    }
}

export default withWidth()(withStyles(styles)(Section3));
