import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section5Styles from "./Section5Styles";
import clsx from "clsx";
import Grid from "@material-ui/core/Grid";

let styles = Section5Styles;

class Section5 extends Component {
   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <p className={clsx(classes.heading, 'bottom-dash')}>Know the Awiros OS</p>
            <p className={classes.subtitle}>Play the video below to get a first-hand experience of the Awiros
               Dashboard.</p>
            <Grid container direction={'row'} justify={'center'} alignItems={'center'}>
               <Grid item xs={12} sm={9} md={6}>
                  <iframe
                     width="100%"
                     height="315"
                     src="https://www.youtube.com/embed/f0O8gQgaJq4"
                     title={"Awiros Video"}
                     frameBorder="0"
                     allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                     allowFullScreen
                  />
               </Grid>
            </Grid>
         </div>
      );
   }
}

export default withStyles(styles)(Section5);