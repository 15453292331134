import VideoPath from "../../../../lib/VideoPath";

let Section3Config = [
    /*{
       name: 'Awiros - The Video Intelligence OS - An Introduction',
       videoID: 'f0O8gQgaJq4',
       thumbnail:'./../../../../assets/images/resource/video-thumbnails/awiros-os-intro.png')
    },*/
    {
        name: "Awiros Automatic Number Plate Recognition",
        videoID: VideoPath.autoNPR,
        thumbnail:
            "./../../../../assets/images/resource/video-thumbnails/anpr.png"
    },
    {
        name: "Awiros FRAM",
        videoID: VideoPath.FRAM,
        thumbnail:
            "./../../../../assets/images/resource/video-thumbnails/awiros-fram.png"
    },
    {
        name: "Awiros Fire and Smoke Detection",
        videoID: VideoPath.fireAndSmokeDetection,
        thumbnail:
            "./../../../../assets/images/resource/video-thumbnails/fire-and-smoke.png"
    },
    {
        name: "Awiros Face Clustering Application",
        videoID: VideoPath.faceClustering,
        thumbnail:
            "./../../../../assets/images/resource/video-thumbnails/face-clustering.png"
    },

    {
        name: "Awiros Vehicle Counting & Classification",
        videoID: VideoPath.vcc,
        thumbnail:
            "./../../../../assets/images/resource/video-thumbnails/vcc.png"
    },

    {
        name: "AI-based Crowd Estimation & Management",
        videoID: VideoPath.crowdDash,
        thumbnail:
            "./../../../../assets/images/resource/video-thumbnails/crowd.png"
    }
];

export default Section3Config;
