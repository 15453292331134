import createMuiTheme from "@material-ui/core/styles/createMuiTheme";
import StyleConfig from "../lib/StyleConfig";

const primaryTheme = createMuiTheme({
   palette: {
      primary: {
         main: StyleConfig.mainThemeColor,
      },
   },
});

export default primaryTheme