import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import SupportPageStyles from "./SupportPageStyles";
import Section1 from "./Sections/Section1/Section1";
import Section2 from "./Sections/Section2/Section2";

let styles = SupportPageStyles;

class SupportPage extends Component {
   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <Section1/>
            <Section2/>
         </div>
      );
   }
}

export default withStyles(styles)(SupportPage);
