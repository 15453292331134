import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section1Styles from "./Section1Styles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";
import withWidth from "@material-ui/core/withWidth";

let styles = Section1Styles;

class Section1 extends Component {
    render() {
        let { classes, width } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.headerImage}>
                    <img
                        src={"/assets/images/resource/appliances-cover.png"}
                        className={classes.headerImage}
                    />
                </div>

                {/*<Grid container direction={'row'} justify={'space-between'} alignItems={'flex-start'} spacing={7} className={classes.headerContainer}>
               

               <Grid item xs={12} sm={8} className={classes.body}>
                  <div className={clsx('h2heading', {
                     ['center']: width === 'xs'
                  })}
                  >
                     Awiros Appliances
                  </div>

                  <div className={clsx('textContent justify')}>
                     For those who wish to leverage the power of the Video AI without having to deal with the hassles of hardware configuration, 
                     Awiros Appliances is a line of processing hardware devices that come pre-loaded with the Awiros OS. Built in partnership 
                     with some of the leading processing hardware vendors in the world, these devices are optimized for running the Awiros OS and its apps. 
                     Depending on your app and channel requirements, you can choose from any of the available options and start using the latest and the best 
                     of Video AI apps in almost no time at all.
                  </div>
               </Grid>
            </Grid>*/}
            </div>
        );
    }
}

export default withWidth()(withStyles(styles)(Section1));
