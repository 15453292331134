import "material-react-toastify/dist/ReactToastify.css";
import "./index.css";
import React, { Component } from "react";
import { Route, Router, Switch } from "react-router-dom";
import AboutUsPage from "./components/AboutUsPage/AboutUsPage";
import AppStackPage from "./components/AppStackPage/AppStackPage";
import AppStyles from "./AppStyles";
import AwirosOSPage from "./components/AwirosOSPage/AwirosOSPage";
import ContactPage from "./components/ContactPage/ContactPage";
import CreditsPage from "./components/CreditsPage/CreditsPage";
import CssBaseline from "@material-ui/core/CssBaseline";
import DevelopersPage from "./components/DevelopersPage/DevelopersPage";
import Footer from "./components/Footer/Footer";
import { Globals } from "./lib/Constants";
import HomePage from "./components/HomePage/HomePage";
import IndustriesPage from "./components/IndustriesPage/IndustriesPage";
import Navbar from "./components/Navbar/Navbar";
import ProductDescriptionPage from "./components/ProductsPage/ProductDescriptionPage/ProductDescriptionPage";
import ProductsPage from "./components/ProductsPage/ProductsPage";
import ReactGA from "react-ga";
import ResourcesPage from "./components/ResourcesPage/ResourcesPage";
import RoutePath from "./lib/RoutePath";
import ScrollToTop from "./util/ScrollToTop";
import SupportPage from "./components/SupportPage/SupportPage";
import { ToastContainer } from "material-react-toastify";
import UserService from "./services/UserService";
import { createBrowserHistory } from "history";
import { withStyles } from "@material-ui/core/styles";
import IndustriesPageTwo from "./components/IndustriesPage/IndustriesPageTwo";
import BannerComp from "./components/IndustriesPage/CommonComponents/BannerComp";
import IndustriesPageThree from "./components/IndustriesPage/IndustriesPageThree";
import IndustriesPageFour from "./components/IndustriesPage/IndustriesPageFour";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import CaseStudy from "./components/ResourcesPage/CaseStudyPage/CaseStudy";
import IndustriesPageFive from "./components/IndustriesPage/IndustriesPageFive";
import PolicyPage from "./components/PolicyPage/Policy.js";
import EULAPage from "./components/PolicyPage/EULA.js";
import AppathonPage from "./components/Appathon/AppathonPage";
import AppathonDashboard from "./components/Appathon/dashboard/dashboard";
import NewContactUsPage from "./components/ContactPage/NewContactUsPage.js";

ReactGA.initialize(Globals.GA_TRACKING_ID);

let history = createBrowserHistory();

history.listen((location) => {
  ReactGA.pageview(location.pathname + location.search);
});

const styles = AppStyles;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      anchor: "left",
      showNav: false,
    };
  }

  componentDidMount = async () => {
    await UserService.login();
    ReactGA.pageview(window.location.pathname);
    window.addEventListener("scroll", () => {
      const scrollButton = document.getElementById("scrollToTop");
      if (window.scrollY > 500) {
        scrollButton.style.display = "block";
      } else {
        if (scrollButton) {
          scrollButton.style.display = "none";
        }
      }
    });

    if (typeof window !== "undefined") {
      if (window.location.pathname === "/intel-innovation-22") {
        this.setState({ ...this.state, showNav: false });
      } else {
        this.setState({ ...this.state, showNav: true });
      }
    }
  };

  render() {
    const { classes } = this.props;

    return (
      <>
        <div className={classes.root}>
          <button
            id="scrollToTop"
            className={`${classes.scrollToTop} ${classes.scrollToTopButton}`}
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <ExpandLessIcon></ExpandLessIcon>
          </button>
          <CssBaseline />
          <Router history={history}>
            <ScrollToTop>
              {
                <Navbar>
                  <Switch>
                    <Route
                      exact
                      path={RoutePath.homePath}
                      component={HomePage}
                    />
                    <Route
                      exact
                      path={RoutePath.awirosOS}
                      component={AwirosOSPage}
                    />
                    {/*<Route exact path={RoutePath.products} component={ProductsPage}/>*/}
                    <Route
                      exact
                      path={RoutePath.products}
                      component={ProductsPage}
                    />
                    <Route
                      exact
                      path={RoutePath.productDetailed()}
                      component={ProductDescriptionPage}
                    />
                    <Route
                      exact
                      path={RoutePath.developers}
                      component={DevelopersPage}
                    />
                    <Route exact path={RoutePath.try} component={BannerComp} />
                    <Route
                      exact
                      path={RoutePath.industriesMatching}
                      component={IndustriesPage}
                    />
                    <Route
                      exact
                      path={RoutePath.industriesPageTwo}
                      component={IndustriesPageTwo}
                    />
                    <Route
                      exact
                      path={RoutePath.industriesPageThree}
                      component={IndustriesPageThree}
                    />
                    <Route
                      exact
                      path={RoutePath.industriesPageFour}
                      component={IndustriesPageFour}
                    />
                    <Route
                      exact
                      path={RoutePath.industriesPageFive}
                      component={IndustriesPageFive}
                    />
                    <Route
                      exact
                      path={RoutePath.aboutUs}
                      component={AboutUsPage}
                    />
                    <Route
                      exact
                      path={RoutePath.contactUs}
                      component={ContactPage}
                    />
                    <Route
                      exact
                      path={RoutePath.support}
                      component={SupportPage}
                    />
                    <Route
                      exact
                      path={RoutePath.appStack}
                      component={AppStackPage}
                    />
                    <Route
                      exact
                      path={RoutePath.resourcesPage}
                      component={ResourcesPage}
                    />
                    <Route
                      exact
                      path={RoutePath.caseStudy}
                      component={CaseStudy}
                    />
                    <Route
                      exact
                      path={RoutePath.credits}
                      component={CreditsPage}
                    />
                    <Route
                      exact
                      path={RoutePath.policy}
                      component={PolicyPage}
                    />
                    <Route
                      exact
                      path={RoutePath.appathon}
                      component={AppathonPage}
                    />
                    <Route
                      exact
                      path={RoutePath.carriers}
                      component={NewContactUsPage}
                    />
                    <Route
                      exact
                      path={RoutePath.appathonDashboard}
                      component={AppathonDashboard}
                    />
                    {/* <Route
                      exact
                      path={RoutePath.legalDisclaimer}
                      component={LegalDisclaimerPage}
                    /> */}
                    <Route exact path={RoutePath.eula} component={EULAPage} />
                  </Switch>
                </Navbar>
              }

              {this.state.showNav && <Footer />}
            </ScrollToTop>
          </Router>
          <ToastContainer />
        </div>
      </>
    );
  }
}

export default withStyles(styles)(App);
