import StyleConfig from "../../../lib/StyleConfig";

let SocialBoxStyles = {
  root: {
    width: "300px",
    // margin:'0 10px 10px',
    // flexGrow: 1,
    "@media (max-width: 768px)": {
      width: "200px",
    },
  },
  cardContainer: {
    padding: 10,
    boxShadow: "0px 0px 20px #f2f3f4",
    // display: 'flex',
    // flexDirection: 'row',
    // justifyContent: 'center'
  },
  thumbnailContainer: {
    width: 170,
    height: 170,
    borderRadius: "50%",
    overflow: "hidden",
    margin: "40px auto",
    position: "relative",
    "@media (max-width: 768px)": {
      width: "120px",
      height: "120px",
    },
    "&:before": {
      position: "absolute",
      content: "''",
      left: "50%",
      top: 0,
      width: "0",
      height: "100%",
      display: "block",
      opacity: 0.5,
      backgroundColor: StyleConfig.mainThemeColor,
      "-webkit-transition": "all 500ms ease",
      "-ms-transition": "all 500ms ease",
      "-o-transition": "all 500ms ease",
      transition: "all 500ms ease",
    },
    "&:hover": {
      "&:before": {
        left: 0,
        width: "100%",
      },
    },
  },
  thumbnailImage: {
    width: "100%",
    margin: "0 auto",
    //  filter: "grayscale(1)",
    // height:170,
    // borderRadius: 50,
    verticalAlign: "middle",
  },
  removeGrayscale: {
    "&:hover": {
      // filter: "grayscale(0)",
    },
  },
  socialLinksContainerHide: {
    position: "absolute",
    top: "-10%",
    left: "50%",
    transition: "all 500ms ease",
    "@media (max-width: 1024px)": {
      top: "-15%",
    },
  },
  socialLinksContainerShow: {
    position: "absolute",
    // top:'calc(50%-5px)',
    top: "44%",
    left: "47%",
    transition: "all 500ms ease",
  },
  linkText: {
    color: StyleConfig.invertedSecondaryThemeColor,
  },
  name: {
    fontSize: 17,
    fontWeight: 400,
    lineHeight: "1.7em",
    color: StyleConfig.headingColor,
    textAlign: "center",
  },
};

export default SocialBoxStyles;
