import StyleConfig from "../../lib/StyleConfig";

let FooterStyles = {
   gridItem:{
      '@media (max-width:960px)':{
         padding:'0 17% 0 17%'
      },
      '@media (max-width:768px)':{
         padding:'0 15% 0 15%'
      },
      '@media (max-width:490px)':{
         padding:'0 10% 0 10%'
      },
   },
   root: {
      backgroundColor: StyleConfig.barterColor,
      padding: 20
      // height: 270
   },
   imageContainer: {
      alignSelf: 'center',
      justifyContent: 'center',
      display: 'flex'
   },
   logoImage: {
      // width:'100%'
   },
   headingText: {
      fontSize: 20,
      fontWeight: 400,
      
   },
   more:{
      '@media (max-width:960px)':{
         display:'flex',
         justifyContent:'center'
      },
   },
   link: {
      display: 'block',
      float: 'left',
      clear: 'both',
      textDecoration: 'none',
      fontSize: 16,
      lineHeight: '35px',
      color: StyleConfig.secondaryThemeColor,
      transition: 'all 300ms ease',
      '&:hover': {
         color: StyleConfig.barterHighlight
      },
      
   },
   socialLinkContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
   },
   socialLink: {
      display: 'inline-block',
      textDecoration: 'none',
      fontSize: 16,
      // margin: '0 10px',
      lineHeight: '35px',
      color: StyleConfig.secondaryThemeColor,
      transition: 'all 300ms ease',
      '&:hover': {
         color: StyleConfig.barterHighlight
      }
   },
   copyrightTextContainer: {
      paddingTop: 20,
      paddingBottom: 10
   },
   copyrightText: {
      fontSize: 12,
      fontWeight: 300,
      textAlign: 'center',
      margin: 0,
   },
   copyrightLinks: {
      textDecoration: 'none',
      color: StyleConfig.barterHighlight,
      whiteSpace: 'nowrap'
   }
};

export default FooterStyles;