import { createMuiTheme } from "@material-ui/core";

const theme = createMuiTheme({
  breakpoints: {
    values: {
      xs: 600,
      sm: 768,
      md: 1024,
      lg: 1440,
      xl: 1536,
    },
  },
});

let IndustriesPageTwoStyles = {
  rootContainer: {
    color: "#606069",
  },
  cardComponent: {
    margin: 0,
  },
  carousel: {
    position: "relative",
    paddingBottom: 50,
  },
  carouselDetailsConatiner: {
    color: "#fff",
    padding: 83,
    width: "100%",
    height: "auto",
    display: "flex",
    boxSizing: "border-box",
    "@media (max-width:1024px)": {
      backgroundBlendMode: "darken",
    },
    justifyContent: "space-between",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      padding: 40,
      margin: "0",
    },
    paddingBottom: 16,
  },
  carouselDetails: {
    maxWidth: "80%",

    [theme.breakpoints.down("lg")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("md")]: {
      maxWidth: "60%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "70%",
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "100%",
    },
  },
  carouselMetrics: {
    display: "flex",
    gap: "44px",
    [theme.breakpoints.down("md")]: {
      gap: "16px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  // bannerBackDrop:{
  //   backgroundColor:'rgba(0,0,0,0.5)',
  //   zIndex:'10',

  // },
  bannerTitle: {
    fontSize: 56,
    lineHeight: 1.17,
    fontWeight: 1200,
    fontFamily: "Roboto",
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 36,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 28,
    },
    "@media (max-width:500px)": {
      fontSize: 24,
    },
  },
  bannerSubtitle: {
    fontSize: 24,
    // fontWeight: 800,
    lineHeight: 1.17,
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 20,
    },
    "@media (max-width:500px)": {
      fontSize: 16,
    },
  },
  metrics: {
    display: "flex",
    alignItems: "center",
    gap: "16px",
    [theme.breakpoints.down("md")]: {
      gap: "8px",
      "& img": {
        width: 30,
        height: 30,
      },
    },
  },
  metricData: {
    display: "flex",
    flexDirection: "column",
    "&:last-child": {
      flexShrink: 0,
      width: "max-content",
    },
  },
  contact: {
    fontSize: 30,
    margin: "60px 0",
    lineHeight: 1.17,
    padding: 0,
    border: "none",
    borderRadius: "10px",

    "& a": {
      textDecoration: "none",
      padding: "16px 24px",
      display: "flex",
      alignItems: "center",
      color: "#000",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      margin: 0,
      marginTop: 16,
      "& a": {
        padding: "8px 12px",
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
    },
  },
  contactContainer: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
    },
  },
  carretRight: {
    width: 40,
    height: 40,
    [theme.breakpoints.down("md")]: {
      width: 28,
      height: 28,
    },
    [theme.breakpoints.down("xs")]: {
      width: 20,
      height: 20,
    },
  },
  bartnote: {
    color: "#fff",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  carouselVideo: {
    maxWidth: "40%",
    width: "100%",
    height: "100%",
    marginTop: "auto",
    position: "relative",
    "&>video": {
      zIndex: 10,
      position: "absolute",
      bottom: 0,
      transform: "translateY(50px)",
      width: "80%",
      aspectRatio: "4/2.2",
      objectFit: "cover",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "60%",
      margin: "0 auto",
      "&>video": {
        position: "relative",
        transform: "translateY(100px)",
        width: "100%",
      },
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "80%",
    },
    "@media (max-width:500px)": {
      maxWidth: "100%",
    },
  },
  heroSection: {
    "& :not(:last-child)": {
      marginBottom: 60,
    },
    "@media (max-width:768px)": {
      "& :not(:last-child)": {
        marginBottom: 0,
      },
      marginTop: "60px",
    },
  },
  smartCities: {
    fontSize: 96,
    lineHeight: 1.17,
    fontWeight: 900,
    fontFamily: "Roboto",
    margin: "0 26px",
    [theme.breakpoints.down("xl")]: {
      fontSize: 56,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 40,
      margin: "0 16px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      margin: "0 8px",
      // fontWeight: 800,
    },
  },
  heroSectionTitle: {
    fontSize: "56px",
    lineHeight: 1.17,
    fontWeight: 900,
    paddingLeft: "2%",
    padddingRight: "2%",
    // fontFamily: "Roboto",
    textAlign: "center",
    // [theme.breakpoints.down("xl")]: {
    //   fontSize: '56px',
    // },
    "@media (max-width:1440px)": {
      fontSize: "36px",
    },

    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      paddingLeft: "32px",
      paddingRight: "32px",
    },
  },
  heroSectionSummary: {
    fontSize: 24,
    // fontWeight: 500,
    padding: "0 148px",
    lineHeight: 1.17,
    textAlign: "center",

    "@media (max-width:1440px)": {
      fontSize: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 14,
      padding: "0 32px",
    },
    "@media (max-width:768px)": {
      paddingTop: "50px",
      paddingBottom: "50px",
    },
  },
  heroButton: {
    display: "flex",
    width: "max-content",
    padding: "16px 28px",
    // background: "linear-gradient(90.63deg, #F1A760 5.27%, #EF4F51 92.53%);",
    backgroundColor: "#3C5D94",
    alignItems: "center",
    color: "#fff",
    justifyContent: "center",
    gap: "16px",
    margin: "0 auto",
    textDecoration: "none",
    fontSize: "20px",
    fontWeight: "1000",
    borderRadius: "10px",
    "@media (max-width:1440px)": {
      padding: "10px 14px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "8px 16px",
      fontSize: 12,
      gap: "8px",
    },
    "@media (max-width:768px)": {
      marginTop: "20px",
    },
  },
  heroSectionUseCase: {
    padding: "0 148px",
    textAlign: "center",
    fontSize: 20,
    fontWeight: 500,
    lineHeight: 1.17,
    marginBottom: 18,
    "& h1": {
      fontSize: 55,
      fontWeight: 600,
    },
    [theme.breakpoints.down("xl")]: {
      "& h1": {
        fontSize: 56,
      },
    },
    "@media (max-width:1440px)": {
      "& h1": {
        fontSize: 36,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 100px",
      "& h1": {
        fontSize: 28,
      },
      fontSize: 16,
    },
    [theme.breakpoints.down("sm")]: {
      padding: "0 60px",
      marginBottom: 0,
      "& h1": {
        fontSize: 24,
      },
      fontSize: 14,
    },
    [theme.breakpoints.down("xs")]: {
      padding: 16,
    },
    "@media (max-width:768px)": {
      padding: "0px 16px",
      paddingTop: "10px",
    },
  },
  appSection: {
    padding: "44px 154px",
    background: "rgba(217, 217, 217, 0.2)",
    [theme.breakpoints.down("xl")]: {
      padding: "36px 128px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "24px 80px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "30px 20px 16px 20px",
      // border:'1px solid black',
      // display:'flex',
      // flexDirection:'column',
      // justifyContent:'center',
      // paddingTop:'20%'
    },
  },
  appSectionHeading: {
    fontSize: "36px",
    "@media (max-width:1280px)": {
      fontSize: "24px",
    },
    "@media (max-width:768px)": {
      fontSize: "20px",
      marginBottom: "30px",
      marginTop: "30px",
    },
  },
  appSearch: {
    width: "60%",
    margin: "0 auto",
    display: "flex",
    alignItems: "center",
    border: "2px solid #606069",
    borderRadius: 8,
    padding: 16,
    gap: "40px",
    "& input": {
      width: "100%",
      fontSize: 20,
      lineHeight: 1.17,
      border: 0,
      background: "transparent",
      "&:focus": {
        outline: "none",
      },
    },

    [theme.breakpoints.down("sm")]: {
      padding: "4px",
      width: "100%",
      gap: "16px",
      "& img": {
        width: 15,
        height: 15,
      },
      "& input": {
        fontSize: 14,
      },
    },
    "@media (max-width:1280px)": {
      width: "80%",
      padding: "4px",
    },
  },
  appHeading: {
    margin: "2%",
    fontSize: "28px",
  },
  appsCarousel: {
    position: "relative",
  },
  appsCarouselButtons: {
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  appsContainer: {
    display: "flex",
    scrollBehavior: "smooth",
    overflowX: "auto",

    gap: "32px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  mainAppCard: {
    height: "375px",
    width: "300px",
    position: "relative",
    borderRadius: "16px",

    "@media (min-width:768px)": {
      // To change flipping speed, change time(Make sure to make changes for desktop as well as mobile) below as well as percentages of animation
      "&:hover": {
        "& $appCard": {
          animation: `$rotatingAni 15000ms ease-in`,
        },
        "& $descriptionCard": {
          animation: `$delayedRotatingAni 15000ms ease-in`,
        },
      },
    },
    "@media (max-width:1280px)": {
      height: "250px",
      width: "200px",
    },
    "@media (max-width:768px)": {
      height: "325px",
      width: "200px",
    },
  },
  // To change flipping speed, change time(Make sure to make changes for desktop as well as mobile) below as well as percentages of animation
  rotateAppCard: {
    animation: `$rotatingAni 15000ms ease-in`,
  },
  rotateDescrCard: {
    animation: `$delayedRotatingAni 15000ms ease-in`,
  },
  // reverseAppCard:{
  //   animation: `$revRotatingAni 10000ms ease-in`,
  // },
  // reverseDescrCard:{
  //   animation: `$revDelayedRotatingAni 10000ms ease-in`,
  // },
  "@keyframes rotatingAni": {
    "0%": {
      transform: "rotateY(0deg)",
    },
    "5%": {
      transform: "rotateY(90deg)",
    },
    "100%": {
      transform: "rotateY(90deg)",
    },
  },
  // "@keyframes revRotatingAni": {
  //   "0%": {
  //     transform:"rotateY(90deg)"
  //   },
  //   "10%": {
  //     transform:"rotateY(0deg)"
  //   },
  //   "100%": {
  //     transform:"rotateY(0deg)"
  //   }
  // },
  "@keyframes delayedRotatingAni": {
    "0%": {
      transform: "rotateY(90deg)",
    },
    "5.5%": {
      transform: "rotateY(90deg)",
    },
    "10%": {
      transform: "rotateY(0deg)",
    },
    "100%": {
      transform: "rotateY(0deg)",
    },
  },
  // "@keyframes revDelayedRotatingAni": {
  //   "0%": {
  //     transform:"rotateY(0deg)"
  //   },
  //   "10%": {
  //     transform:"rotateY(90deg)"
  //   },
  //   "20%": {
  //     transform:"rotateY(90deg)"
  //   },
  //   "100%": {
  //     transform:"rotateY(90deg)"
  //   }
  // },
  appCard: {
    height: "375px",
    width: "300px",
    position: "absolute",
    top: "0",
    left: "0",
    flexShrink: 0,
    display: "flex",
    justifyContent: "flex-end",
    flexDirection: "column",
    borderRadius: "16px",
    "@media (max-width:1280px)": {
      height: "250px",
      width: "200px",
    },
    "@media (max-width:768px)": {
      height: "275px",
      width: "200px",
    },
  },
  descriptionCard: {
    display: "flex",
    flexDirection: "column",
    // justifyContent:'center',
    // alignItems:'center',
    // border:'1px solid black',
    height: "374px",
    width: "300px",
    // backgroundColor:'#a1aab2',
    backgroundColor: "white",
    transform: "rotateY(90deg)",
    // paddingTop:"10%",
    border: "5px solid #3C5D94",
    borderRadius: "16px",
    "@media (max-width:1280px)": {
      height: "250px",
      width: "200px",
    },
    "@media (max-width:768px)": {
      height: "275px",
      width: "200px",
    },
    // fontSize:'24px',
    // padding:'5%',
  },
  descriptionCardTopbar: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    width: "100%",
    padding: "20% 5% 1% 5%",
    color: "white",
    // backgroundColor:'orange',
    height: "23%",
    // justifyContent: 'space-between',
    marginBottom: "20px",
  },
  DesCardIcon: {
    height: "80px",
    aspectRatio: "1/1",
    marginRight: "0px",
    // border:'1px solid white',
    // backgroundColor:'#ffad30',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    "@media (max-width:1280px)": {
      height: "40px",
      aspectRatio: "1/1",
    },
  },
  DesCardTitle: {
    padding: "3% 0% 3% 3%",
    fontSize: "24px",
    color: "black",
    lineHeight: "1",
    fontWeight: "800",

    "@media (max-width:1280px)": {
      fontSize: "16px",
    },
    // textAlign:'center',
    // border:"1px solid black"
  },
  desCardDescription: {
    fontSize: "20px",
    height: "70%",
    width: "100%",
    // border:'1px solid black',
    display: "flex",
    justifyContent: "center",
    // alignItems:'center',
    padding: "5%",
    paddingTop: "20%",
    color: "black",

    "@media (max-width:1280px)": {
      fontSize: "14px",
    },
  },
  horizontalLine: {
    backgroundColor: "#797c85",
    height: "1px",
    border: "none",
  },

  appCardDetails: {
    display: "flex",
    alignItems: "center",
    color: "#fff",
    gap: "8px",
    padding: 4,
    paddingLeft: "4%",

    backgroundColor: "rgba(60, 93, 148,0.8)",
    borderRadius: "0px 0px 16px 16px",
    padding: "3% 0% 3% 0%",
    "@media (max-width:1280px)": {
      height: "53px",
    },
    "@media (max-width:768px)": {
      height: "58px",
    },
  },
  appIcons: {
    width: 60,
    height: 60,
    marginLeft: "5px",
    "@media (max-width:1280px)": {
      width: 30,
      height: 30,
    },
  },
  appTitle: {
    fontSize: "20px",
    "@media (max-width:1280px)": {
      fontSize: "16px",
    },
    "@media (max-width:1280px)": {
      fontSize: "12px",
    },
  },
  emptyState: {
    textAlign: "center",
    fontSize: 24,
    marginTop: 48,
    fontWeight: 600,
  },
  integrationsContainer: {
    padding: "180px 190px",
    textAlign: "center",
    paddingBottom: 18,
    width: "100%",
    [theme.breakpoints.down("sm")]: {
      padding: 60,
    },
    [theme.breakpoints.down("sm")]: {
      padding: 4,
    },
  },
  operationSectionContainer: {
    display: "flex",
    justifyContent: "space-around",
    position: "relative",
    marginBottom: 100,
    "& button": {
      height: "max-content",
      zIndex: 1,
      padding: 8,
      background: "#fff",
      border: "13px solid #F1965D",
      borderRadius: 8,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      margin: 0,
      background: "#fff",
      color: "#F1965D",
      borderRadius: 8,
      padding: "16px 24px",
      fontWeight: 700,
      fontSize: 24,
      lineHeight: 1.17,
      "&:disabled": {
        background: "#F1965D",
        color: "#fff",
        borderColor: "#fff",
      },
    },
    [theme.breakpoints.down("md")]: {
      "& button": { padding: "8px 16px", borderWidth: 5 },
      marginBottom: 60,
    },
    [theme.breakpoints.down("sm")]: {
      "& button": { padding: "6px 8px", fontSize: 16, borderWidth: 4 },
      marginBottom: 30,
    },
  },
  divider: {
    position: "absolute",
    width: "100%",
    height: 2,
    zIndex: 0,
    top: "40%",
  },
  grid: {
    background: "rgba(217, 217, 217, 0.2)",
  },
  betterSection: {
    padding: "100px 150px",
    marginBottom: 120,
    textAlign: "center",
    paddingBottom: 0,
    "& h2": {
      fontSize: 55,
      margin: 0,
    },
    "& p": {
      margin: 0,
      fontSize: 20,
    },
    [theme.breakpoints.down("md")]: {
      padding: "60px 100px",
      paddingBottom: 0,
      "& h2": {
        fontSize: 36,
        margin: 0,
      },
      "& p": {
        margin: 0,
        fontSize: 18,
      },
    },
    [theme.breakpoints.down("sm")]: {
      padding: "100px 150px",
      paddingBottom: 0,
      "& h2": {
        fontSize: 24,
        margin: 0,
      },
      "& p": {
        margin: 0,
        fontSize: 14,
      },
    },
    [theme.breakpoints.down("xs")]: {
      marginBottom: 0,
      padding: "32px 16px",
    },
  },
  imageContainer: {
    padding: "0 24.6%",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      display: "none",
    },
  },
  imageSection: {
    aspectRatio: "970/800",
    position: "relative",
    maxWidth: 970,
  },
  tiles: {
    "& img": {
      width: 52,
      height: 52,
      [theme.breakpoints.down("lg")]: {
        width: 30,
        height: 30,
      },
    },
    flexShrink: 0,
    display: "flex",
    flexDirection: "column",
    position: "relative",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    borderRadius: "50%",
    width: 148,
    height: 148,
    [theme.breakpoints.down("lg")]: {
      width: 120,
      height: 120,
      fontSize: 12,
    },
    [theme.breakpoints.down("md")]: {
      width: 96,
      height: 96,
    },
    padding: 16,
    textAlign: "center",
    border: "1px solid rgba(240, 74, 81, 1)",
  },
  scaleCircle: {
    transform: "scale(1.20)",
  },
  container: {
    position: "absolute",
    width: 250,
    top: 0,
    [theme.breakpoints.down("md")]: {
      width: 200,
    },
    [theme.breakpoints.down("sm")]: {
      width: 150,
    },
  },
  containerLeft: {
    left: 0,
    transform: "translate(-100%,0)",
  },
  containerRight: {
    right: 0,
    transform: "translate(100%,0)",
  },
  diverse: {
    position: "absolute",
    bottom: "10%",
    transform: "translate(-50%,0)",
  },
  dynamicDeployment: {
    position: "absolute",
    bottom: "50%",
    transform: "translate(-50%,25%)",
  },
  hardware: {
    position: "absolute",
    top: "10%",
    left: "10%",
    transform: "translate(-10%,-35%)",
  },
  scale: {
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translate(-50%,-50%)",
  },
  support: {
    position: "absolute",
    top: "10%",
    right: "10%",
    transform: "translate(-10%,-45%)",
  },
  realTime: {
    position: "absolute",
    top: "50%",
    right: 0,
    transform: "translate(50%,-75%)",
  },
  easyIntegrate: {
    position: "absolute",
    bottom: "10%",
    right: 0,
    transform: "translate(50%,0%)",
  },
  deployments: {
    padding: "180px 124px",
    [theme.breakpoints.down("lg")]: {
      padding: "180px 60px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "100px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: 32,
    },
    paddingBottom: 0,
  },
  Notabledeployments: {
    padding: "180px 124px",
    [theme.breakpoints.down("lg")]: {
      padding: "100px 30px 0px 30px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "100px 30px 0px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: 0,
      paddingTop: 32,
    },
    paddingBottom: 0,
  },

  imageContainerMobile: {
    paddingBottom: 30,
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  imageCarousel: {
    "& > div": {
      width: "max-content",
      margin: "0 auto",
    },
  },

  deploymentImage: {
    width: 300,
    height: 300,
    flexShrink: 0,
    [theme.breakpoints.down("lg")]: {
      width: 240,
      height: 240,
    },
  },
  deploymentBannerContainer: {
    display: "flex",
    padding: 1,
    justifyContent: "center",
    [theme.breakpoints.up("xl")]: {
      width: "100%",
    },
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      alignItems: "center",
      width: "max-content",
      margin: "0 auto",
    },
  },
  deploymentMetrics: {
    display: "grid",
    width: "100%",
    gridTemplateColumns: "repeat(4,1fr)",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "repeat(2,1fr)",
    },
    [theme.breakpoints.up("xl")]: {
      width: "max-content",
    },
  },
  deploymentCells: {
    display: "flex",
    width: "100%",
    justifyContent: "flex-start",
    maxWidth: 300,
    "& div": {
      flexShrink: 0,
      width: "50%",
      maxWidth: 150,
      textAlign: "center",
      aspectRatio: "1/1",
    },
  },
  deploymentMetricCells: {
    flexBasis: "50%",
    backgroundImage:
      "linear-gradient(to bottom left,rgba(240, 74, 81, 1),rgba(242, 167, 96, 1))",
  },
  iconImage: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 24,
    "& img": {
      width: 64,
      height: 64,
    },
    [theme.breakpoints.down("md")]: {
      "& img": {
        width: 50,
        height: 50,
      },
    },
  },
  gradientText: {
    "-webkit-text-fill-color": "transparent",
    background:
      "linear-gradient(to left ,rgba(240, 74, 81, 1),rgba(242, 167, 96, 1))",
    "-webkit-background-clip": "text",
  },
  metricsData: {
    fontSize: 30,
    fontWeight: 700,
    display: "inline-flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    color: "#fff",
    "& span": {
      fontSize: 24,
    },
    [theme.breakpoints.down("md")]: {
      fontSize: 24,
      "& span": {
        fontSize: 18,
      },
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 20,
      "& span": {
        fontSize: 16,
      },
    },
    [theme.breakpoints.down("xs")]: {
      fontSize: 18,
      "& span": {
        fontSize: 14,
      },
    },
  },
  gutterSpace: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  backdrop: {
    position: "absolute",
    width: "100%",
    height: "100%",
    background: "#fff",
    top: 0,
    right: 0,
  },
  partnerContainer: {
    padding: "180px 65px",
    [theme.breakpoints.down("md")]: {
      padding: "100px 30px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "60px 16px",
    },
  },
  headings: {
    textAlign: "center",
    fontSize: 55,
    fontWeight: 600,
    lineHeight: 1.17,
    // color: "#919192",
    margin: 0,
    marginBottom: 126,
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
      marginBottom: 80,
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 28,
      marginBottom: "25%",
    },
  },
  NotableHeading: {
    textAlign: "center",
    fontSize: 55,
    fontWeight: 600,
    lineHeight: 1.17,
    // color: "#919192",
    margin: 0,
    marginBottom: 126,
    textTransform: "capitalize",
    [theme.breakpoints.down("md")]: {
      fontSize: 36,
      marginBottom: "%",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: 24,
      marginBottom: "60px",
    },
  },
  partners: {
    display: "flex",
    alignItems: "center",
    overflow: "hidden",
    position: "relative",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      justifyContent: "center",
    },
  },
  partnerImage: {
    flexBasis: "20%",
    maxWidth: 400,
    height: 265,
    padding: 16,
    flexShrink: 0,
    "& img": {
      objectFit: "contain",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
    },
    [theme.breakpoints.down("xs")]: {
      flexBasis: "33%",
    },
  },
  button: {
    border: 0,
    background: "transparent",
    padding: 0,
  },
  carouselLeft: {
    position: "absolute",
    left: 0,
    top: "50%",
    transform: "translate(-50%,-50%)",
    zIndex: "10",
    cursor: "pointer",
    display: "none",
  },
  carouselRight: {
    position: "absolute",
    right: 0,
    top: "50%",
    transform: "translate(50%,-50%)",
    zIndex: "10",
    cursor: "pointer",
  },
};

export default IndustriesPageTwoStyles;
