import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import CarouselComponentWithDots from "../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";
import IndustriesPageTwoStyles from "./IndustriesPageTwoStyles";

import {
  bannerData,
  intergrationData,
  partners,
  customers,
  notableDeployments,
  whyBetter,
  customers1,
  customers2,
  partners1,
  partners2,
  appSelection,
} from "./utils/indexTwo";

import AppSection from "./AppSection/indexTwo";
import { useEffect } from "react";
import NotableDeployment from "./componentsForTwoPage/NotableDeployment";

import CustomersAndPartnersMobileViewComp from "./componentsForTwoPage/CustomersAndPartnersMobileViewComp";
import CustomersAndPartnersDesktopViewComponent from "./componentsForTwoPage/CustomersAndPartnerDesktopViewComponent";
import IntegratingAwirosComp from "./ComponentsForThreePage/IntegratingAwirosComp";
import WhyBetterNew from "./CommonComponents/WhyBetterNew";

const IndustriesPageTwo = (props) => {
  const { classes } = props;

  let dimensions = useWindowDimensions();

  // for current window size
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const renderBannerCards = () => {
    return bannerData.map((banner, index) => {
      const {
        title,
        apps,
        areaCovered,
        cameraCount,
        bartnote,
        bartnoteUrl,
        imageUrl,
        livesImpacted,
        subTitle,
        videoUrl,
      } = banner;
      return (
        <div className={` ${classes.bannerBackDrop}`}>
          <div className={classes.carousel} key={index}>
            <div
              className={`${classes.carouselDetailsConatiner} `}
              style={{
                background: `rgba(0, 0, 0, 0.6) url(${imageUrl}) 100% 100% no-repeat`,
                backgroundSize: "cover",
              }}
            >
              <div className={classes.carouselDetails}>
                <h1 className={classes.bannerTitle}>{title}</h1>
                <p className={classes.bannerSubtitle}>{subTitle}</p>

                <div className={classes.contactContainer}>
                  <button className={classes.contact}>
                    <a href="/contact">
                      <span>Contact Us</span>
                      <img
                        className={classes.carretRight}
                        src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/carret--right.svg"
                        alt="Carret right"
                      />
                    </a>
                  </button>
                </div>
              </div>

              <div className={classes.carouselVideo}>
                <video
                  autoPlay
                  muted
                  loop
                  controls={false}
                  allowsInlineMediaPlayback={true}
                  playsInline={true}
                  src={videoUrl}
                  type="video/mp4"
                ></video>
              </div>
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <section className={classes.rootContainer}>
      {/* Banner Carousel */}

      <CarouselComponentWithDots
        cardComponentClasses={classes.cardComponent}
        contentArray={renderBannerCards()}
        interval={6000}
      />

      {/* Hero section */}

      <div className={classes.heroSection}>
        <div>
          <p className={classes.heroSectionTitle}>
            Transforming Warehouses with Video AI
          </p>
        </div>
        {dimensions.width <= 768 ? (
          <p className={classes.heroSectionSummary}>
            Check out our tailored use-cases that can revolutionize your
            warehouse.
          </p>
        ) : (
          <p className={classes.heroSectionSummary}>
            Awiros with it's diverse marketplace and dynamic deployment
            offerings has become the go-to Video AI solution for warehouses.
            Check out our tailored use-cases that can revolutionize your
            warehouse.
          </p>
        )}

        <a href="/appstack" className={classes.heroButton}>
          <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/hero_apps.svg" />
          VIEW AWIROS APPSTACK
        </a>
        <div
          className={classes.heroSectionUseCase}
          style={{ marginTop: "30px" }}
        >
          <h1>An App for every Use-Case</h1>
          {/* Our solutions for Warehouses through Video AI */}
        </div>
      </div>

      {/* Apps Section */}

      <AppSection>{appSelection}</AppSection>

      {/* Awiros in your city */}

      <div>
        {/* <IntegratingAwiros heading='Integrating Awiros In Your Warehouses'></IntegratingAwiros> */}
        <IntegratingAwirosComp heading="Awiros Deployment Architecture"></IntegratingAwirosComp>
      </div>

      {/* why we are better */}
      {/* <WhyBetter></WhyBetter> */}
      <WhyBetterNew></WhyBetterNew>

      {/* Notable deployments */}

      <div className={classes.Notabledeployments}>
        <h2 className={classes.NotableHeading}>Our Success Stories</h2>
        <NotableDeployment
          notableDeployments={notableDeployments}
        ></NotableDeployment>
      </div>

      <div className={classes.deployments}></div>

      {dimensions.width <= 768 ? (
        <CarouselComponentWithDots
          visibleSlides={1}
          showDots={false}
          contentArray={[
            <CustomersAndPartnersMobileViewComp
              data={customers1}
              heading="Customers"
              subHeading=""
            ></CustomersAndPartnersMobileViewComp>,
            <CustomersAndPartnersMobileViewComp
              data={customers2}
              heading="Customers"
              subHeading=""
            ></CustomersAndPartnersMobileViewComp>,
            <CustomersAndPartnersMobileViewComp
              data={partners1}
              heading="Partners"
              subHeading="OEMs"
            ></CustomersAndPartnersMobileViewComp>,
            <CustomersAndPartnersMobileViewComp
              data={partners2}
              heading="Partners"
              subHeading="System Integrators"
            ></CustomersAndPartnersMobileViewComp>,
          ]}
        />
      ) : (
        <CarouselComponentWithDots
          visibleSlides={1}
          showDots={false}
          contentArray={[
            <CustomersAndPartnersDesktopViewComponent
              data={{
                heading: "Customers",
                allColumns: [
                  { subHeading: "", bothColumns: customers1 },
                  { subHeading: "", bothColumns: customers2 },
                ],
              }}
            ></CustomersAndPartnersDesktopViewComponent>,
            <CustomersAndPartnersDesktopViewComponent
              data={{
                heading: "Partners",
                allColumns: [
                  { subHeading: `OEMs`, bothColumns: partners1 },
                  { subHeading: "System Integrators", bothColumns: partners2 },
                ],
              }}
            ></CustomersAndPartnersDesktopViewComponent>,
          ]}
        />
      )}
      <div className={classes.deployments}></div>
    </section>
  );
};

export default withRouter(
  withStyles(IndustriesPageTwoStyles, { withTheme: true })(IndustriesPageTwo)
);
