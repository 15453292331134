import StyleConfig from "../../lib/StyleConfig";

let PolicyStyles = {
  root: {},
  banner: {
    width: "100%",
    height: "500px",
    color: "white",
    display: "flex",
    alignItems: "center",
    paddingLeft: "10%",
    paddingRight: "10%",

    "@media (max-width:768px)": {
      height: "400px",
      justifyContent: "center",
      alignItems: "center",
      paddingLeft: "5%",
      paddingRight: "5%",
    },
  },
  bannerTitle: {
    color: "#FFCC00",
    fontSize: "54px",

    "@media (max-width:1080px)": {
      fontSize: "36px",
    },
    "@media (max-width:768px)": {
      fontSize: "24px",
      marginBottom: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  bannerDescription: {
    fontSize: "16px",
    display: "inline",
    alignItems: "center",
    color: "white",
    marginTop: "50px",
    "@media (max-width:768px)": {
      display: "inline-block",
      textAlign: "center",
      marginTop: "0px",
    },
  },
  bannerLink: {
    color: "#FFCC00",
  },
  accordions: {
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    margin: "50px 88px 50px 88px",
    padding: "30px 0px 60px 0px",
    "@media (max-width:768px)": {
      margin: "50px 10px 50px 10px",
    },
  },
  accordion: {
    width: "100%",
    boxShadow: "0px 4px 8px rgba(171, 171, 171, 0.12)",
    padding: "0px 20px ",
  },
  accordionHeading: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    color: "#FFCC00",
    fontSize: "24px",
    cursor: "pointer",
    "@media (max-width:768px)": {
      fontSize: "20px",
    },
  },
  accordionDescription: {
    fontSize: "16px",
    whiteSpace: "pre-line",
    paddingBottom: "20px",
    textAlign: "justify",
    "@media (max-width:768px)": {
      fontSize: "12px",
    },
  },

  gridItem: {
    "@media (max-width:960px)": {
      padding: "0 17% 0 17%",
    },
    "@media (max-width:768px)": {
      padding: "0 15% 0 15%",
    },
    "@media (max-width:490px)": {
      padding: "0 10% 0 10%",
    },
  },
};

export default PolicyStyles;
