import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import AboutUsPageStyles from "./AboutUsPageStyles";
import SocialBox from "./SocialBox/SocialBox";
import Grid from "@material-ui/core/Grid";
import profileData from "./profileData";
import Testimonials from "../CommonComponents/Testimonials/Testimonials";
import RoutePath from "../../lib/RoutePath";
import Button from "@material-ui/core/Button";

let styles = AboutUsPageStyles;

class AboutUsPage extends Component {
  constructor(props) {
    super(props);
    this.profileData = profileData;
  }

  render() {
    let { classes } = this.props;
    return (
      <div className={classes.root}>
        <h1 className={classes.heading}>About Us</h1>
        <div className={classes.textContent}>
          Our mission is to bring the power of Video Intelligence to everyone;
          right from developers to the end users. Awiros is the conduit to bring
          the most cutting edge research in the areas of Computer Vision, Deep
          Learning and Artificial Intelligence to the market.
        </div>
        <div className={classes.textContent}>
          Incorporated in 2015, Awiros is making it possible for everyone to
          build as well as utilize the most advanced applications of Computer
          Vision and AI in their everyday lives using its app-oriented open and
          unified Operating System model. With a stellar team of researchers and
          engineers, our constant endeavor is to push the boundaries of
          technology while delivering great value to our customers.
        </div>

        <h2 className={classes.subtitle}>
          Meet our Founder
          {/* <br />
          (we are being modest). */}
        </h2>
        <div className={classes.cardContainer}>
          {this.profileData.map((profile, index) => {
            return <SocialBox {...profile} />;
          })}
        </div>

        <div className={classes.linkedInContainer}>
          <a href="https://www.linkedin.com/company/awiros/" target="_blank">
            <div className={classes.linkedInLogo}>
              <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/linkedIn.png"></img>
            </div>
          </a>
          <a
            href="https://www.linkedin.com/company/awiros/"
            target="_blank"
            className={classes.text}
          >
            <span className={classes.mainText}>Get to know about our team</span>
          </a>
        </div>

        {/* <Grid
          container
          direction={"row"}
          spacing={5}
          className={classes.profilesContainer}
        >
          {this.profileData.map((profile, index) => {
            return (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <SocialBox {...profile} />
              </Grid>
            );
          })}
        </Grid> */}

        <Testimonials />
      </div>
    );
  }
}

export default withStyles(styles)(AboutUsPage);
