import React from "react";
import CustomersAndPartnersFirstStyles from "./CustomersAndPartnersFirstStyles";
import { makeStyles, withStyles } from "@material-ui/styles";
import {
  bannerData,
  partners,
  customers,
  notableDeployments,
} from "../utils/index";
import CarouselComponentWithDots from "../../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";

const useStyles = makeStyles(CustomersAndPartnersFirstStyles);

function CustomersAndPartnersFirst() {
  const classes = useStyles();

  const renderPartners = () => {
    let i = 0,
      j = 0;
    const responseArr = [];
    while (i * 5 < partners.length || j * 5 < customers.length) {
      if ((i + j) % 2 === 0) {
        if (i * 5 < partners.length) {
          const temp = {
            title: "partners",
            logos: [...partners].splice(i * 5, 5),
          };
          responseArr.push(temp);
        }
        i++;
      } else {
        if (j * 5 < customers.length) {
          const temp = {
            title: "customers",
            logos: [...customers].splice(j * 5, 5),
          };

          responseArr.push(temp);
        }
        j++;
      }
    }
    return responseArr;
  };

  return (
    <div className={classes.partnerContainer}>
      <CarouselComponentWithDots
        visibleSlides={1}
        showDots={false}
        contentArray={renderPartners().map((ele, index) => {
          return (
            <React.Fragment key={index}>
              <h2 className={classes.headings}>{ele.title}</h2>
              <div className={classes.partners}>
                {ele.logos.map((logo, index) => (
                  <div key={index} className={classes.partnerImage}>
                    <img
                      src={logo}
                      width="100%"
                      height="100%"
                      alt="partner image"
                    />
                  </div>
                ))}
              </div>
            </React.Fragment>
          );
        })}
      />
    </div>
  );
}

export default CustomersAndPartnersFirst;
