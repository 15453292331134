import React, { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/styles";
import AwirosOSIntegratingCompStyles from "./AwirosOSIntegratingCompStyles";
import { integrationData } from "./IntegratingCompData";

const useStyles = makeStyles(AwirosOSIntegratingCompStyles);

function AwirosOSIntegratingComp(props) {
  const { heading } = props;
  console.log(`headin-${heading}`);
  const classes = useStyles();
  const [integrationSelected, setIntegrationSelected] = useState("cloud");
  const [hoverIndex, setHoverIndex] = useState(null);

  const findPosition = (element) => {
    if (typeof element.offsetParent !== "undefined") {
      for (var posX = 0, posY = 0; element; element = element.offsetParent) {
        posX += element.offsetLeft;
        posY += element.offsetTop;
      }
      return [posX, posY];
    } else {
      return [element.x, element.y];
    }
  };

  const getCoordinates = (e) => {
    var PosX = 0;
    var PosY = 0;
    var ImgPos;
    ImgPos = findPosition(e.target);
    if (!e) var e = window.event;
    if (e.pageX || e.pageY) {
      PosX = e.pageX;
      PosY = e.pageY;
    } else if (e.clientX || e.clientY) {
      PosX =
        e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
      PosY =
        e.clientY +
        document.body.scrollTop +
        document.documentElement.scrollTop;
    }
    PosX = PosX - ImgPos[0];
    PosY = PosY - ImgPos[1];
    return { x: PosX, y: PosY };
  };

  const handleHover = (event) => {
    const { x, y } = getCoordinates(event);
    const selectedInfrastructureData = integrationData.filter(
      (ele) => ele.name === integrationSelected
    );

    (selectedInfrastructureData[0].hoverCoordinates || []).map((coord) => {
      const xRatio = coord.x / 1538;
      const yRatio = coord.y / 800;
      const imageWidth = event.target.clientWidth;
      const imageHeight = event.target.clientHeight;
      if (
        xRatio * imageWidth < x &&
        xRatio * imageWidth > x - 0.05 * imageWidth &&
        yRatio * imageHeight < y &&
        yRatio * imageHeight > y - 0.05 * imageHeight
      ) {
        console.log(coord.text);
        // Todo: add hover text to the x,y coordinates
      }
    });
  };

  return (
    <div className={classes.integrationsContainer}>
      <h2 className={classes.headings}>{heading}</h2>

      <div className={classes.operationSectionContainer}>
        <hr className={classes.divider} />
        <button
          disabled={integrationSelected === "cloud"}
          onClick={() => {
            setIntegrationSelected("cloud");
          }}
          style={{ cursor: "pointer" }}
        >
          Cloud
        </button>
        <button
          disabled={integrationSelected === "premise"}
          onClick={() => {
            setIntegrationSelected("premise");
          }}
          style={{ cursor: "pointer" }}
        >
          On-premise
        </button>
        <button
          disabled={integrationSelected === "edge"}
          onClick={() => {
            setIntegrationSelected("edge");
          }}
          style={{ cursor: "pointer" }}
        >
          Edge+Cloud
        </button>
      </div>

      {/* Todo : Add information when hovered on images */}

      {integrationData.map((singleIntegrationData, index) => {
        if (singleIntegrationData.name === integrationSelected) {
          return (
            <div>
              <div key={index} className={classes.imageContainer}>
                <img
                  // onMouseMove={handleHover}
                  width="100%"
                  height="100%"
                  src={singleIntegrationData.imageUrl}
                  className={classes.image}
                />

                {singleIntegrationData.hoverCoordinates.map(
                  (hoverCoordinate, index) => (
                    <span
                      key={index}
                      className={classes.circleAndText}
                      style={{
                        position: "absolute",
                        left: `${hoverCoordinate.x}%`,
                        top: `${hoverCoordinate.y}%`,
                      }}
                    >
                      <div className={classes.here}>
                        <div className={classes.baseCircle}></div>
                        <div className={classes.pulse}></div>
                        <div className={classes.backgroundOpac}></div>
                        <div className={classes.text}>
                          {hoverCoordinate.text}
                        </div>
                        <img
                          className={classes.cursor}
                          height="15px"
                          width="15px"
                          // src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/pointer.png"
                          src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/CURSOR.svg"
                        ></img>
                      </div>
                    </span>
                  )
                )}
              </div>
              <div className={classes.description}>
                <div className={classes.para}>
                  {singleIntegrationData.content}
                </div>
                <div className={classes.dotContent}>
                  <ul>
                    {singleIntegrationData.dotPoints.map(
                      (dotContent, index) => (
                        <li>{dotContent}</li>
                      )
                    )}
                  </ul>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
}

export default AwirosOSIntegratingComp;
