import StyleConfig from "../../../../lib/StyleConfig";

let Section2Styles = {
   root: {
      width: '100%',
      marginBottom: 50,
   },
   heading: {
      color: StyleConfig.headingColor,
      textAlign: 'center',
      fontSize: 30,
      fontWeight: 300,
   },
   textContent: {
      fontSize: '18px!important',
      fontWeight: 300,
      textAlign: 'center',
   },
   largeImage: {
      margin: '50px auto',
      width: '100%',
   },

}

export default Section2Styles;