export const bannerData = [
  {
    imageUrl: "/banner/sanket-shah-F_3qCaaSadc-unsplash.jpg",
    title: "Helping Namma Bengaluru in improving its Citizen's Safety",
    subTitle:
      "Powered by Awiros Video AI, Namma Bengaluru is now solving for its traffic, safety woes and more",
    cameraCount: "5000+",
    areaCovered: "740+ km²",
    apps: "10+",
    livesImpacted: "4.5+ Mn",
    bartnote: "Know more about Awiros’ role in Bengaluru Safe City",
    bartnoteUrl: "",
    videoUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/bangalore.mp4",
  },
  {
    imageUrl: "/banner/pexels-jhovani-morales-12494914.jpg",
    title: "Improving traffic management in Jaipur Smart City",
    subTitle:
      "Turning normal city cameras to intelligent AI enabled cameras for better traffic management using Awiros ",
    cameraCount: "200+",
    areaCovered: "460+ km²",
    apps: "5+",
    livesImpacted: "1.5+ Mn",
    bartnote: "Know more about Awiros’ role in Jaipur Smart City",
    bartnoteUrl: "",
    videoUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/JaipurNew.mp4",
  },
  {
    imageUrl: "/banner/Bareilly_City_Station_03.jpg",
    title: "Enhancing cleanliness and crowd monitoring in Bareilly ",
    subTitle:
      "Awiros platform and use cases solve for Bareilly Smart City’s loitering problem and ensure public safety",
    cameraCount: "1400+",
    areaCovered: "100+ km²",
    apps: "15+",
    livesImpacted: "0.4+ Mn",
    bartnote: "Know more about Awiros’ role in Bareilly Smart City",
    bartnoteUrl: "",
    videoUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/bareillyNew.mp4",
  },
];

export const appSelection = [
  {
    title: "Our top Apps for Smart City",
    placeholder: "Search for Smart City Apps",
    apps: [
      {
        name: "Facial Recognition",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Facial Recognition.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/FacialRecognition.png",
        tags: ["Smart City", "Safe City", "Face", "FRS", "People", "Identify"],
        description:
          "Identify, search & monitor individuals’ faces from video data, across locations in real time",
      },
      {
        name: "Automatic Number Plate Recognition",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Number Plate Recognition.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/licence-plate-single.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "ANPR",
          "Number Plate",
          "Vehicle",
          "Car",
          "Bike",
          "4 wheeler",
          "Registration",
        ],
        description:
          "Detect number plates without any specialised cameras, and manage traffic with ease",
      },
      {
        name: "Crowd Counting",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Crowd Counting.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/crowd-estimation.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "ANPR",
          "Number Plate",
          "Vehicle",
          "Car",
          "Bike",
          "4 wheeler",
          "Registration",
        ],
        description:
          "Get notified when the number of people in a region exceeds a given threshold",
      },
      {
        name: "Parking Violation",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Parking Violation.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/ParkingViolation.png",
        tags: [
          "Smart City",
          "Safe City",
          "no parking zone",
          "no parking",
          "wrong parking",
        ],
        description:
          "Generate alerts when vehicles are parked in no-parking zones",
      },
      {
        name: "Garbage Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Garbage Detection.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/Garbage-labeled.jpg",
        tags: [
          "Smart City",
          "Safe City",
          "detect garbage",
          "garbage, littering",
        ],
        description:
          "Detect garbage in a premise and send alerts to the designated authority",
      },
      {
        name: "Person Collapsing",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Person Collapsing.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/Person-collapsing.jpg",
        tags: [
          "Smart City",
          "Safe City",
          "fall",
          "fell",
          "collapse",
          "man down",
          "person collapse",
          "emergency",
        ],
        description:
          "Identify a person collapsing in any premise and send immediate alerts",
      },
      {
        name: "Advanced Intrusion Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Advanced Instrusion Detection.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/advanced-intrution.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "prohibited area",
          "person intrusion",
          "intrusion",
          "detect intruder",
          "detect unauthorized access",
        ],
        description:
          "Monitor activities around a user defined area to detect intrusion",
      },
      {
        name: "Helmet Violation Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Helmet Violation Detection.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/helmet-violation.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "helmet violation",
          "2 wheeler",
          "bike",
          "motorcycle",
          "helmet detection",
        ],
        description:
          "Identify riders who violate helmet rules and generate an instant alert",
      },
      {
        name: "Vehicle Counting & Classification",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Vehicle Counting.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/vehicle-count.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "vehicle count",
          "car count",
          "vehicle identification",
          "car details",
          "vehicle details",
        ],
        description:
          "Count vehicles of different types and identify attributes such as type, color and make",
      },
      // {
      //   name: "Smoke Detection",
      //   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Smoke detection.png",
      //   imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/SmokeDetection.png",
      //   tags: ["Smart City", "Safe City", "smoke alert", "smoke detect"],
      //   description:
      //     "Locate smoke through cameras and trigger an alert for action",
      // },

      {
        name: "Fire Detection",
        iconUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Fire detection.png",
        imageUrl:
          "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/fire-detection.JPG",
        tags: [
          "Smart City",
          "Safe City",
          "fire alarm",
          "fire alert",
          "fire and smoke",
        ],
        description:
          "Locate fire through cameras and trigger an alert for action",
      },
      // {
      //   name: "Pothole Detection",
      //   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-1 logos/Pothole Detection.png",
      //   imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/pothole-detection.JPG",
      //   tags: ["Smart City", "Safe City", "hole in ground", "pothole detect"],
      //   description:
      //     "Detect potholes on roads and send alerts to the designated authority",
      // },

      // {
      //   name: "Loitering",
      //   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_pothole_detection.svg",
      //   imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/pothole-detection.JPG",
      //   tags: ['Smart City', 'Safe City', 'suspicious','animal','person'],
      //   description:"Detect loitering of human/animals on prohibited areas and generate alerts"
      // },
      // {
      //   name: "Animal detection",
      //   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/Animal-detection.JPG",
      //   imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/pothole-detection.JPG",
      //   tags: ['Smart City', 'Safe City', 'detect animal','stray animal'],
      //   description:"Detect animals on roads and other prohibited areas and generate alerts"
      // },
      // {
      //   name: "Attribute detection",
      //   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_pothole_detection.svg",
      //   imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/pothole-detection.JPG",
      //   tags: ['Smart City', 'Safe City', 'find person','person find'],
      //   description:"Locate and detect persons with a set a given attributes like dress color/type, height etc."
      // },
    ],
  },
];

export const partners = [
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/hitachi.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/cisco.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/vmware.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/veracity.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/trinity.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/honeywell.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/qognify.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/genetec.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/Milestone.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/intel.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/nvidia.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/dell.jpeg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/ags.jpg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/amnex.jpeg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/hp.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/johnsons-control.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/nec.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/nexgen-integrated-systems.jpeg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/L&T.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/icd.jpeg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/Kinara.svg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/honeywell.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/qognify.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/genetec.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/intel.png",
];

export const customers = [
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/wani.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/sicm.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrtc.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/upl.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/govt-of-telangana.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrb.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/hindustan-petroleum.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dxc-tech.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/yotta.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/20cube.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/mercedez.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/indiabulls.png",
  // "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/raytheon-tech.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/biocon.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dr-reddy-labs.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bengaluru-safe-city.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Jaipur.jpeg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/thane-smart-city.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bareily-smart-city.png",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/maharashtra.jpeg",
  "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Kdisc.jpeg",
];

export const customers1 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/20cube.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bareily-smart-city.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bengaluru-safe-city.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/biocon.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/upl.png",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dr-reddy-labs.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dxc-tech.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/govt-of-telangana.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/hindustan-petroleum.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/wani.png",
  ],
};

export const customers2 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/indiabulls.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Jaipur.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/mercedez.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrb.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/yotta.png",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrtc.png",
    // "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/raytheon-tech.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/sicm.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/thane-smart-city.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/maharashtra.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Kdisc.jpeg",
  ],
};

export const partners1 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Cisco.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Dell.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Genetec.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Hitachi.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Honeywell.png",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Intel.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Milestone.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Nvidia.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Qognify.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Trinity.png",
  ],
};

export const partners2 = {
  column1: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/AGS Transact Technologies.jpg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Amnex.jpeg",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Hewlett Packard Enterprsie.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Honeywell.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/ICD Technologies.jpeg",
  ],
  column2: [
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Johnsons Control.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/L&T.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/NEC.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/Xilinx.png",
    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/QualcommLogo.svg",
  ],
};

export const intergrationData = [
  {
    name: "cloud",
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/cloud.png",
    hoverCoordinates: [
      { x: 162, y: 470, text: "near monitor" },
      { x: 271, y: 106, text: "near Awiros Os" },
      { x: 626, y: 88, text: "near Video AI Appstack" },
      { x: 785, y: 106, text: "near Public/ Private Cloud" },
      { x: 1071, y: 542, text: "near camera" },
    ],
  },
  {
    name: "premise",
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/on-premise.png",
    hoverCoordinates: [
      { x: 70, y: 361, text: "near VMS Server" },
      { x: 240, y: 544, text: "near Command Control center" },
      { x: 460, y: 687, text: "near Awiros Os" },
      { x: 526, y: 140, text: "near Intelligence Engyn" },
      { x: 582, y: 275, text: "near on premise server" },
      { x: 889, y: 101, text: "near Video AI Appstack" },
      { x: 1193, y: 466, text: "near camera" },
    ],
  },
  {
    name: "edge",
    imageUrl: "/assets/edge+central.png",
    hoverCoordinates: [
      { x: 1, y: 432, text: "near VMS Server" },
      { x: 141, y: 613, text: "near Command Control center" },
      { x: 281, y: 722, text: "near Awiros Os" },
      { x: 242, y: 123, text: "near Intelligence Engyn" },
      { x: 679, y: 45, text: "near Video AI Appstack" },
      { x: 1056, y: 560, text: "near Awiros Edge Server" },
      { x: 1406, y: 627, text: "near on Site n" },
    ],
  },
];

export const notableDeployments = [
  {
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/thane-smart-city.png",
    licenses: "320+",
    application_types: [
      "Easy Calibration",
      "Object Tracking",
      "Presence Analytics",
      "Facial Recongnition",
      "Camera Tempering",
      "Unattended Baggage",
      "Enter/Exit Detection",
      "Stopping Detection",
      "License Plate Recognition",
      "Dwell Detection ( Crowd Detection )",
      "Directional Detection",
      "Tailgating Detection",
      " Object Classification Object Counting",
    ],
    areaCovered: "147+ km²",
    livesImpacted: "1+ Mn",
  },
  {
    imageUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/sicm.png",
    licenses: "250+",
    application_types: [
      "Facial Recongnition",
      "Facial Recongnition",
      "Facial Recongnition",
      "Facial Recongnition",
      "Facial Recongnition",
    ],
    areaCovered: "40+ acres",
    livesImpacted: "1.3+ Mn",
  },
];

export const whyBetter = [
  {
    name: "Diverse Marketplace",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps.png",
    subText: "Offers 60+ video AI apps on our Appstack",
  },
  {
    name: "Dynamic Deployment",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/dynamic-deployment.png",
    subText:
      "Deploy video AI apps at any scale with an unmatched level of flexibility",
  },
  {
    name: "Hardware Agnostic",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/hardware.png",
    subText:
      "Awiros apps run smoothly without the need for specialized cameras or hardware",
  },
  {
    name: "Scale on Demand",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/scale.png",
    subText: "Scale up to thousands of camera with ease",
  },
  {
    name: "24*7 Support",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/support.png",
    subText: "A 24*7 team to support and take care of all queries",
  },
  {
    name: "Real-time Data & Alerts",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/real-time.png",
    subText: "Monitor and get instant alerts from multiple apps in real time",
  },
  {
    name: "Easy to Integrate",
    iconUrl:
      "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/easy-to-integrate.png",
    subText:
      "Easy integration into existing systems by 3 different deployment architecture, quick integration",
  },
];
