import { withStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import ContactPageStyles from "./ContactPageStyles";


const NewContactUsPage = (props) => {


    return (
        <div style={{ marginBottom: '200px', padding: '20px' }}>
            <div id='khembedjobs'></div>
        </div>
    );
};

export default withRouter(
    withStyles(ContactPageStyles, { withTheme: true })(NewContactUsPage)
);
