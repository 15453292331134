import StyleConfig from "../../../../../lib/StyleConfig";

let Section1CardStyles = {
   root: {
      // width:'100%',
      // height:'100%',
      // border:'1px solid',
      // borderColor:StyleConfig.mainThemeColor,
      // padding:20,
      // maxHeight:'65vh'
   },
   textContainer: {
      maxWidth: 450,
      margin: '0 auto',
   },
   headingText: {
      fontSize: 30,
      fontWeight: 500,
      color: StyleConfig.carouselHeadings,
      marginBottom: 20,
      '@media (max-width: 600px)': {
         fontSize: 23,
         textAlign: 'center'
      },
   },
   textContent: {
      fontSize: 16,
      fontWeight: 300,
      textAlign: 'justify',
      marginBottom: 30,
      '@media (max-width: 600px)': {
         textAlign: 'center'
      },
   },
   button: {
      '@media (max-width: 600px)': {
         // fontSize: 13,
      },
   },
   image: {
      width: '100%',
      // border:'1px solid lightgrey'
   }
};

export default Section1CardStyles;