export const integrationData = [
    {
        name: "cloud",
        imageUrl:
            "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/cloud.png",
        content: `Awiros Software Platform can be easily deployed and accessed over any public or private cloud. Awiros
    utilizes the orchestration capabilities of the cloud and provides unmatched flexibility to cater to
    your Video Intelligence needs. Ideally suited for enterprises, SME’s and Smart Homes.`,
        dotPoints: [
            "Tailored to work with any cloud service provider",
            "Easy to setup and use",
            "Easy access to data & alerts",
            "Cost-effective",
            "Scales on demand",
            "Platform as a service (PaaS)",
            "On-Demand compute and storage availability"
        ],
        hoverCoordinates: [
            {
                x: 9.16,
                y: 62.25,
                text: "A consolidated UI to view and manage all aspects of deployment at a single place"
            },
            {
                x: 16.67,
                y: 14.03,
                text: "Proprietary Hardware Abstraction Library for scaling Video AI apps"
            },
            {
                x: 43.14,
                y: 11.65,
                text: "60+ Video AI Apps available for a variety of industries"
            },
            // { x: 59.10, y: 15.04, text: "near Public/ Private Cloud" },
            {
                x: 73.81,
                y: 71.79,
                text: "Awiros Apps can be deployed on any standard ONVIF compliant source"
            }
        ]
    },
    {
        name: "premise",
        imageUrl:
            "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/on-premise.png",
        content: `Awiros Software platform and Awiros Enterprise hardware both can be deployed on-premise. The Awiros
    platform capabilities can be utilized by creating a cloud like service layer on-premise, hence
    reducing the cost and increasing the efficiency. Ideal for Smart City, Surveillance, Law Enforcement,
    Municipal, Traffic and deployments where data security and control is primary.`,
        dotPoints: [
            "Data Security and Privacy",
            "100% control over the software and hardware",
            "Low Total Cost of Ownership (TCO)"
        ],
        hoverCoordinates: [
            {
                x: 4.8,
                y: 47.81,
                text: "Easily Integrate with any VMS platform to store and read video feeds instantly"
            },
            {
                x: 16.54,
                y: 72.05,
                text: "Manage and view deployment across servers and sites seamlessly"
            },
            {
                x: 27.7,
                y: 91.0,
                text: "A consolidated UI to view and manage all aspects of deployment at a single place"
            },
            {
                x: 36.25,
                y: 18.54,
                text: "Proprietary Hardware Abstraction Library for scaling Video AI apps"
            },
            // { x: 37.11, y: 36.42, text: "near on premise server" },
            {
                x: 61.26,
                y: 13.38,
                text: "60+ Video AI Apps available for a variety of industries"
            },
            {
                x: 82.22,
                y: 61.72,
                text: "Awiros Apps can be deployed on any standard ONVIF compliant source"
            }
        ]
    },
    {
        name: "edge",
        imageUrl: "/assets/edge+central.png",
        content: `Awiros also supports Edge+Central deployments in a hybrid model. This architecture is best suited for
    Smart City, Surveillance, Law Enforcement, Municipal, Traffic and deployments with
    distributed/scattered geographic locations, where there is an immediate need of edge-based analytics.
    Using a combination of Awiros Platform and Awiros One+, an agile and responsive infrastructure can be
    created to address the diverse needs of the clients.`,
        dotPoints: [],
        hoverCoordinates: [
            {
                x: 0.07,
                y: 57.22,
                text: "Easily Integrate with any VMS platform to store and read video feeds instantly"
            },
            {
                x: 9.71,
                y: 81.19,
                text: "Manage and view deployment across servers and sites seamlessly"
            },
            {
                x: 19.37,
                y: 90.63,
                text: "A consolidated UI to view and manage all aspects of deployment at a single place"
            },
            {
                x: 16.67,
                y: 16.29,
                text: "Proprietary Hardware Abstraction Library for scaling Video AI apps"
            },
            {
                x: 46.79,
                y: 5.96,
                text: "60+ Video AI Apps available for a variety of industries"
            }
            // { x: 72.77, y: 74.17, text: "near Awiros Edge Server" },
            // { x: 92.89, y: 83.04, text: "near on Site n" },
        ]
    }
];
