import React, { Component } from "react";

import ResourcesPageHeaderStyles from "./ResourcesPageHeaderStyles";
import withStyles from "@material-ui/core/styles/withStyles";

let styles = ResourcesPageHeaderStyles;

class ResourcesPageHeader extends Component {
    render() {
        let { classes, width } = this.props;
        return (
            <div className={classes.root}>
                <div className={classes.headerImage}>
                    <img
                        src={"/assets/images/resource/resources-banner.png"}
                        className={classes.headerImage}
                    />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(ResourcesPageHeader);
