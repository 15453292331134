import StyleConfig from "../../../../../lib/StyleConfig";

let Section3CardStyles = {
   root: {
      // padding: '45px 32px',
      // border: `1px solid ${StyleConfig.mainThemeColor}`,
      position: 'relative',
   },
   card: {
      width: '100%',
      height: '100%',
      minHeight: 200,
   },
   thumbnailImage: {
      width: '100%'
   },
   videoTitle: {
      fontSize: 15,
      fontWeight: 500,
      textAlign: 'center',
      fontColor: StyleConfig.videoLabels,
      padding: 5
   },
   modal: {
      border: 0,
      outline: 0
   },
   videoContainer: {
      width: '80%',
      height: '80%',
      margin: 'auto',
      marginTop: '5%',
      border: 0,
      outline: 0
   }
   // backdrop: {
   //    zIndex: 1101,
   //    color: '#fff',
   //    // position:'absolute'
   // },
};


export default Section3CardStyles;