export const mainBannerData = [
    {
        line1: "Fast-track your",
        line2: "Quality Control",
        line3: "Automate your QC process with",
        line4: "Video AI and reduce manual intervention",
        line5: "",
        backUrl: "/banner2/ManufacturingLP3banner.jpg"
    },
    {
        line1: "Minimise",
        line2: "Manual Errors",
        line3: "Get instant alerts on shopfloor errors",
        line4: "and increase efficiency by 20%",
        line5: "",
        backUrl: "/banner2/LP3Banner2.jpg"
    },
    {
        line1: "Reduce",
        line2: "Accidents",
        line3: "Automatically monitor safety",
        line4: "compliance to instantly detect PPE violation",
        line5: "",
        backUrl: "/banner2/2.png"
    }
];

export const bannerData = {
    heading: "Transform Manufacturing with Video AI",
    metricData: [
        { title: "Manufacturing apps", count: "25" },
        { title: "Accidents prevented", count: "3000" },
        { title: "Man-hours saved", count: "20000" }
    ],
    subHeading:
        "Awiros with it's diverse marketplace and dynamic deployment offerings has become the go-to Video AI solution for manufacturing. Check out our tailored use-cases that can revolutionize your manufacturing unit.",
    mobileViewSubHeading:
        "Check out our tailored use-cases that can revolutionize your manufacturing unit.",
    trustedBy: [
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/dell.jpeg",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/honeywell.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/cisco.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/siemens.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/nvidia.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/trustedBy/mercedez.png"
    ]
};

export const whyBetterData = [
    {
        icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/whyBetterIcons/apps.png",
        video: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/1-diverse-marketplace.mp4",
        title: "Diverse Marketplace",
        description: "Offers 60+ Video AI apps on our Appstack"
    },
    {
        icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/whyBetterIcons/dynamic-deployment.png",
        video: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/2-dynamic-deployment.mp4",
        title: "Dynamic Deployment",
        description:
            "Deploy Video AI apps at any scale with an unmatched level of flexibility"
    },
    {
        icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/whyBetterIcons/hardware.png",
        video: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/5-hardware-agnostic.mp4",
        title: "Hardware Agnostic",
        description:
            "Awiros apps run smoothly without the need for specialized cameras or hardware"
    },
    {
        icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/whyBetterIcons/scale.png",
        video: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/3-scale-on-demand.mp4",
        title: "Scale on Demand",
        description: "Scale up to thousands of cameras with ease"
    },
    {
        icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/whyBetterIcons/real-time.png",
        video: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/4-real-time-alerts-and-data.mp4",
        title: "Real-time Data & Alerts",
        description:
            "Monitor and get instant alerts from multiple apps in real time"
    },
    {
        icon: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/whyBetterIcons/easy-to-integrate.png",
        video: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/videos/whyBetterVideos/6-easy-to-integrate.mp4",
        title: "Easy to Integrate",
        description:
            "Quick & easy integration into existing systems by 3 different deployment architectures"
    }
];

export const appSelection = [
    {
        title: "Our top Apps for Manufacturing",
        placeholder: "Search for Manufacturing apps",
        apps: [
            {
                name: "PPE Detection",
                iconUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/PPE detection.png",
                imageUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/PPE-labeled.jpg",
                tags: ["warehouse", "logistics", "workers", "safety", "PPE"],
                description:
                    "Automatically detect the absence of face masks & protective gear and generate alerts"
            },
            {
                name: "Box Counting",
                iconUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Box Counting.png",
                imageUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/box-counting.jpg",
                tags: [
                    "warehouse",
                    "logistics",
                    "box counting",
                    "inventory",
                    "manufacturing"
                ],
                description:
                    "Keep a count of different number of boxes automatically & number of objects being loaded/unloaded"
            },
            {
                name: "Mobile Phone Usage Detection",
                iconUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Mobile Phone Detection.png",
                imageUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/MobileDetection.png",
                tags: [
                    "warehouse",
                    "logistics",
                    "mobile phone",
                    "detection",
                    "compliance"
                ],
                description:
                    "Get notified if workers are using phones or other gadgets on shop floor"
            },
            {
                name: "Advanced Intrusion Detection",
                iconUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Advanced Instrusion Detection.png",
                imageUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/advanced-intrution.JPG",
                tags: [
                    "Smart City",
                    "Safe City",
                    "prohibited area",
                    "person intrusion",
                    "intrusion",
                    "detect intruder",
                    "detect unauthorized access"
                ],
                description:
                    "Monitor activities around a user defined area to detect intrusion"
            },
            {
                name: "Facial Recognition Attendance Management",
                iconUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/FRAM.png",
                imageUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/FacialRecognition.png",
                tags: ["warehouse", "logistics", "FRAM", "attendance"],
                description:
                    "Manage the attendance of all your employees using facial recognition"
            },
            {
                name: "Visual Inventory Management",
                iconUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Visual Inventory.png",
                imageUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/vim.png",
                tags: [
                    "warehouse",
                    "logistics",
                    "visual inventory management",
                    "inventory"
                ],
                description: "Helps you monitor on-shelf inventory"
            },
            // {
            //   name: "Camera Health Monitoring",
            //   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_tampering.svg",
            //   imageUrl:
            //     "https://i.picsum.photos/id/184/200/300.jpg?hmac=dCgm4a8do6DWvjUWcFvft3Kd1srf1f_TyIZoWGrgu48",
            //   tags: [
            //     "Smart City",
            //     "Safe City",
            //     "tampering",
            //     "camera tampering",
            //     "camera damage detection",
            //     "Warehousing",
            //     "Logistics",
            //     "intentional",
            //     "block camera",
            //   ],
            //   description:"Automatically detect problems in the cameras due to power outage, malicious tampering etc and get instant notifications"
            // },
            {
                name: "Social Distancing Detection",
                iconUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Social Distancing Detection.png",
                imageUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/Socialdistencing2.jpg",
                tags: ["warehouse", "logistics", "social distancing detection"],
                description:
                    "Generate alerts if social distancing norms are violated"
            },
            {
                name: "Person Collapsing",
                iconUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Person Collapsing.png",
                imageUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/Person-collapsing.jpg",
                tags: [
                    "Smart City",
                    "Safe City",
                    "fall",
                    "fell",
                    "collapse",
                    "man down",
                    "person collapse",
                    "emergency"
                ],
                description:
                    "Identify a person collapsing in any premise and send immediate alerts"
            },
            {
                name: "Fire Detection",
                iconUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Fire & Smoke detection.png",
                imageUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/fire-detection.JPG",
                tags: [
                    "Smart City",
                    "Safe City",
                    "fire alarm",
                    "fire alert",
                    "fire and smoke"
                ],
                description:
                    "Locate fire through cameras and trigger an alert for action"
            },
            {
                name: "Thermal Scanning",
                iconUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/LP-2 logos/Thermal Scanning.png",
                imageUrl:
                    "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/thermalScanning.jpg",
                tags: [
                    "warehouse",
                    "logistics",
                    "thermal scanning",
                    "temperature",
                    "worker safety"
                ],
                description:
                    "Generate alerts if a worker has high body temperature"
            }

            // {
            //   name: "Loitering",
            //   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_pothole_detection.svg",
            //   imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/pothole-detection.JPG",
            //   tags: ['Smart City', 'Safe City', 'suspicious','animal','person'],
            //   description:"Detect loitering of human/animals on prohibited areas and generate alerts"
            // },
            // {
            //   name: "Animal detection",
            //   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/Animal-detection.JPG",
            //   imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/pothole-detection.JPG",
            //   tags: ['Smart City', 'Safe City', 'detect animal','stray animal'],
            //   description:"Detect animals on roads and other prohibited areas and generate alerts"
            // },
            // {
            //   name: "Attribute detection",
            //   iconUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/app_logo/awi_pothole_detection.svg",
            //   imageUrl: "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/apps/pothole-detection.JPG",
            //   tags: ['Smart City', 'Safe City', 'find person','person find'],
            //   description:"Locate and detect persons with a set a given attributes like dress color/type, height etc."
            // },
        ]
    }
];

// Camera: Awiros Apps can be deployed on any standard ONVIF compliant source.
// AppStack: 60+ Video AI Apps available for a variety of industries
// Engyn: Proprietary Hardware Abstraction Library for scaling Video AI apps
// Awiros OS UI: A consolidated UI to view and manage all aspects of deployment at a single place.
// VMS:  Easily Integrate with any VMS platform to store and read video feeds instantly.
// CCC: A control and command centre can manage and view deployment across servers and sites seamlessly.
export const integrationData = [
    {
        name: "cloud",
        imageUrl:
            "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/cloud.png",
        hoverCoordinates: [
            {
                x: 9.16,
                y: 62.25,
                text: "A consolidated UI to view and manage all aspects of deployment at a single place"
            },
            {
                x: 16.67,
                y: 14.03,
                text: "Proprietary Hardware Abstraction Library for scaling Video AI apps"
            },
            {
                x: 43.14,
                y: 11.65,
                text: "60+ Video AI Apps available for a variety of industries"
            },
            // { x: 59.10, y: 15.04, text: "near Public/ Private Cloud" },
            {
                x: 73.81,
                y: 71.79,
                text: "Awiros Apps can be deployed on any standard ONVIF compliant source"
            }
        ]
    },
    {
        name: "premise",
        imageUrl:
            "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/on-premise.png",
        hoverCoordinates: [
            {
                x: 4.8,
                y: 47.81,
                text: "Easily Integrate with any VMS platform to store and read video feeds instantly"
            },
            {
                x: 16.54,
                y: 72.05,
                text: "Manage and view deployment across servers and sites seamlessly"
            },
            {
                x: 27.7,
                y: 91.0,
                text: "A consolidated UI to view and manage all aspects of deployment at a single place"
            },
            {
                x: 36.25,
                y: 18.54,
                text: "Proprietary Hardware Abstraction Library for scaling Video AI apps"
            },
            // { x: 37.11, y: 36.42, text: "near on premise server" },
            {
                x: 61.26,
                y: 13.38,
                text: "60+ Video AI Apps available for a variety of industries"
            },
            {
                x: 82.22,
                y: 61.72,
                text: "Awiros Apps can be deployed on any standard ONVIF compliant source"
            }
        ]
    },
    {
        name: "edge",
        imageUrl: "/assets/edge+central.png",
        hoverCoordinates: [
            {
                x: 0.07,
                y: 57.22,
                text: "Easily Integrate with any VMS platform to store and read video feeds instantly"
            },
            {
                x: 9.71,
                y: 81.19,
                text: "Manage and view deployment across servers and sites seamlessly"
            },
            {
                x: 19.37,
                y: 90.63,
                text: "A consolidated UI to view and manage all aspects of deployment at a single place"
            },
            {
                x: 16.67,
                y: 16.29,
                text: "Proprietary Hardware Abstraction Library for scaling Video AI apps"
            },
            {
                x: 46.79,
                y: 5.96,
                text: "60+ Video AI Apps available for a variety of industries"
            }
            // { x: 72.77, y: 74.17, text: "near Awiros Edge Server" },
            // { x: 92.89, y: 83.04, text: "near on Site n" },
        ]
    }
];

export const notableDeployments = [
    {
        imageUrl:
            "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/upl1.jpg",
        cardHeading: "Increased Worker Safety",
        cardDetails: `Video AI solutions from Awiros enhanced worker safety and reduced shop floor accidents at UPL`
    },
    {
        imageUrl:
            "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/mer1.jpg",
        cardHeading: "Improved entry-exit Management",
        cardDetails:
            "Awiros Video analytics solutions enhanced safety measures and mitigated risks at the Mercedes R&D facility"
    },
    {
        imageUrl:
            "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/page3/Hindustan Petroleum.png",
        cardHeading: "Enhanced Safety Management",
        cardDetails: `Using Video AI apps powered by Awiros, HPCL increased safety monitoring at its outlets`
    }
];

export const customers1 = {
    column1: [
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/20cube.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bareily-smart-city.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/bengaluru-safe-city.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/biocon.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/upl.png"
    ],
    column2: [
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dr-reddy-labs.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/dxc-tech.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/govt-of-telangana.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/hindustan-petroleum.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/wani.png"
    ]
};

export const customers2 = {
    column1: [
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/indiabulls.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Jaipur.jpeg",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/mercedez.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrb.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/yotta.png"
    ],
    column2: [
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/ncrtc.png",
        // "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/raytheon-tech.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/sicm.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/thane-smart-city.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/maharashtra.jpeg",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/customers/Kdisc.jpeg"
    ]
};

export const partners1 = {
    column1: [
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Cisco.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Dell.jpeg",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Genetec.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Hitachi.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Honeywell.png"
    ],
    column2: [
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Intel.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Milestone.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Nvidia.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Qognify.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/OEM/Trinity.png"
    ]
};

export const partners2 = {
    column1: [
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/AGS Transact Technologies.jpg",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Amnex.jpeg",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Hewlett Packard Enterprsie.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Honeywell.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/ICD Technologies.jpeg"
    ],
    column2: [
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/Johnsons Control.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/L&T.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/SI/NEC.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/Xilinx.png",
        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/partners/QualcommLogo.svg"
    ]
};
