import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import PulseStyles from "./PulseStyles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

let styles = PulseStyles;

class Pulse extends Component {
    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <h2
                    id={"heading"}
                    className={clsx(classes.heading, "scrollMargin")}
                >
                    Awiros Pulse
                </h2>
                <h3
                    id={"subheading"}
                    className={clsx(classes.subtitle, "scrollMargin")}
                >
                    A standalone intelligence device for all basic needs
                </h3>
                <p
                    id={"description"}
                    className={clsx(classes.textContent, "scrollMargin")}
                >
                    Awiros Pulse is a compact package with an on-board camera
                    for powering simple applications from among the Awiros
                    AppStack. Pulse can help in Footfall counting, Motion and
                    Tampering alerts and also work a standalone smart camera for
                    home or office. Not more than a few centimeters tall, the
                    Awiros Pulse can be placed at the strategic entry/exit
                    points to automatically monitor key activities in the camera
                    view. Awiros Pulse also has an optional WiFi interface for
                    easy deployment.
                </p>
                <Grid
                    id={"images"}
                    container
                    direction={"row"}
                    justify={"space-between"}
                    alignItems={"center"}
                    spacing={6}
                    className={clsx("scrollMargin")}
                >
                    <Grid item xs={6}>
                        <img
                            className={classes.thumbnailImage}
                            src={
                                "./../../../../..//assets/images/resource/pulse-black.jpg"
                            }
                            alt="frs"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <img
                            className={classes.thumbnailImage}
                            src={
                                "./../../../../..//assets/images/resource/pulse.png"
                            }
                            alt="anpr"
                        />
                    </Grid>
                </Grid>

                <p className={classes.textContent}>
                    Awiros Pulse is the perfect solution for quick deployment of
                    video intelligence in retail stores, homes and offices for
                    getting data, real-time alerts and deep analytics from your
                    space.
                </p>

                <Grid
                    id={"details"}
                    container
                    direction={"row"}
                    spacing={10}
                    className={clsx("scrollMargin")}
                >
                    <Grid item xs={12} sm={6}>
                        <h4 className={classes.listHeading}>Key Advantages</h4>
                        <ul className={classes.listTextContainer}>
                            <li>
                                Plug-and-play. Takes just a few minutes to setup
                            </li>
                            <li>On board camera for standalone operation</li>
                            <li>WiFi interface for zero wiring overhead</li>
                            <li>
                                Get real-time notifications on the online
                                dashboard and mobile app
                            </li>
                            <li>
                                Ideal for motion and tracking related
                                applications from the AppStack
                            </li>
                        </ul>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <h4 className={classes.listHeading}>Who is it for?</h4>
                        <ul className={classes.listTextContainer}>
                            <li>Smart homes</li>
                            <li>Retail Stores</li>
                            <li>Offices</li>
                            <li>Exhibition Booths</li>
                            <li>Entry/Exit gates</li>
                        </ul>
                    </Grid>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Pulse);
