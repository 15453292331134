import { withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import NewChallengeStyles from "./NewChallengeStyles";
import { problems } from "../utils/problem";

const NewChallenge = (props) => {
  const { classes } = props;
  const [openProblemWithIndex, setOpenProblemWithIndex] = useState(-1);

  return (
    <div className={classes.NewChallengeContainer}>
      <div className={classes.challengeDetails}>
        <div className={classes.challengeDetailsHeading}>THE CHALLENGE</div>
        <div className={classes.challengeDetailsDescription}>
          The App-a-thon will test your ability to make use of Awiros SDK’s and
          tools to bring to life a computer vision model that can solve
          real-world use cases in broad variety of industries as listed below.
        </div>
        <div className={classes.challengeDetailsDescription}>
          Are you ready to embark on this extraordinary journey? Are you ready
          to unveil the unseen and create a future where innovation knows no
          bounds? The "Unveil the Unseen" Computer Vision App-a-thon awaits your
          brilliance.
        </div>
        <div className={classes.challengeDetailsDescription}>
          Participants are encouraged to{" "}
          <span className={classes.challengeDetailsDescriptionHighlight}>
            propose their own problem statements
          </span>{" "}
          , provided they{" "}
          <span className={classes.challengeDetailsDescriptionHighlight}>
            align with the industries specified below
          </span>{" "}
          and adhere to the event's guidelines.
        </div>
        <div className={classes.challengeDetailsDescription}>
          Get ready to redefine possibilities and make an impact.
        </div>
      </div>
      <div className={classes.themes}>
        <span>Themes</span>
        <div className={classes.horizontalLine}></div>
      </div>
      <div className={classes.problemContainer}>
        {problems.map((problem, index) => (
          <div key={index} className={classes.problem}>
            <div className={classes.problemHeader}>
              <div className={classes.problemHeaderMainHeading}>
                <img
                  className={classes.problemIcon}
                  src={`/assets/appathon/problem/${problem.iconUrl}`}
                ></img>
                <span className={classes.problemTitleDesktop}>
                  {problem.title}
                </span>
              </div>
              <div className={classes.problemHeaderHeading}>
                <span className={classes.problemTitleMobile}>
                  {problem.title}
                </span>
                <div className={classes.problemDescription}>
                  {problem.heading}
                </div>
                {openProblemWithIndex === index && (
                  <div className={classes.problemAnswer}>
                    <div>
                      {problem.points.map((point, pointIndex) => (
                        <div key={pointIndex}>
                          {pointIndex + 1}. {point}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>

              {openProblemWithIndex !== index ? (
                <div clalssName={classes.problemUpArrowContainer}>
                  <img
                    className={classes.problemHeaderArrow}
                    src="/assets/appathon/downArrow.png"
                    style={{ cursor: "pointer" }}
                    onClick={() => setOpenProblemWithIndex(index)}
                  ></img>
                </div>
              ) : (
                <div
                  clalssName={classes.problemUpArrowContainer}
                  style={{
                    height: "100%",
                    marginTop: "auto",
                  }}
                >
                  <img
                    className={classes.problemHeaderArrow}
                    style={{ marginTop: "auto", cursor: "pointer" }}
                    src="/assets/appathon/upArrow.png"
                    onClick={() => setOpenProblemWithIndex(-1)}
                  ></img>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(NewChallengeStyles, { withTheme: true })(NewChallenge)
);
