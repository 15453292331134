import { makeStyles } from "@material-ui/core";
import React, { useState } from "react";
import CarouselComponentWithDots from "../../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";
import { whyBetterData } from "../utils/indexThree";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";

const useStyles = makeStyles({
  whyBetter: {
    aspectRatio: "1920/1000",
    // border:'1px solid black',
    display: "flex",
    overflowY: "hidden",
    backgroundColor: "#F7F7F7",
    "@media (max-width:768px)": {
      display: "none",
    },
  },
  videoSection: {
    flex: "0.7",
    // border:'1px solid black',
    display: "flex",
    flexDirection: "column",
    // justifyContent:'center',
    alignItems: "center",
  },
  Heading: {
    fontSize: "55px",
    marginTop: "5%",
    marginBottom: "2%",
    display: "block",
    marginLeft: "40%",
    fontWeight: "600",
    // color:"black",
    "@media (max-width:1440px)": {
      marginBottom: "1%",
      fontSize: "36px",
    },
    "@media (max-width:1024px)": {
      marginBottom: "1%",
      fontSize: "24px",
    },
    // border:"1px solid black"
  },
  videoContainer: {
    width: "80%",
    height: "60%",
    // border:"1px solid black"
  },
  videoTitle: {
    fontSize: "44px",
    fontWeight: "500",
    // color:'black',
    marginBottom: "1%",
    "@media (max-width:1440px)": {
      fontSize: "28px",
    },
    "@media (max-width:1024px)": {
      fontSize: "20px",
    },
  },
  videoDescription: {
    fontSize: "28px",
    // fontWeight:'500',
    // color:'black',
    // marginBottom:'1%',
    "@media (max-width:1440px)": {
      fontSize: "22px",
    },
    "@media (max-width:1024px)": {
      fontSize: "16px",
    },
  },
  smoothAni: {
    animation: "$fadeIn 1s ease-in-out",
  },
  "@keyframes fadeIn": {
    "0%": {
      opacity: 0.0,
    },

    "100%": {
      opacity: 1,
    },
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end",
  },
  circleSection: {
    flex: "0.3",
    // border:'1px solid black',
    display: "flex",
    alignItems: "center",
    position: "relative",
    overflow: "hidden",
  },
  "@keyframes pulsating": {
    "0%": {
      transform: "scale(1)",
      opacity: "0.8",
    },

    "100%": {
      transform: "scale(1.2)",
      opacity: "1",
    },
  },
  blinkAni: {
    animation: `$pulsating 2000ms ease-in`,
    animationIterationCount: "infinite",
  },

  itemCarousel: {
    position: "absolute",
    // top:'0px',
    // left:'0',
    height: "15.08%",
    width: "15.08%",
    // animation: `$pulsating 2000ms ease-in`,
    // animationIterationCount: "infinite",
    border: "1px solid orange",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    transition: "1s",
    cursor: "pointer",
    backgroundColor: "#F7F7F7",
    // "&:nth-child(2)": {
    // position:'absolute',
    // top:'0%',
    // left:'50%%',
    // },
    "&:nth-child(2)": {
      top: "43%",
      left: "-7%",
    },
    "&:nth-child(3)": {
      top: "0%",
      left: "18%",
    },
    "&:nth-child(4)": {
      top: "0%",
      left: "68%",
    },
    "&:nth-child(5)": {
      top: "43%",
      left: "93%",
    },
    "&:nth-child(6)": {
      top: "86.31%",
      left: "68%",
    },
    "&:nth-child(7)": {
      top: "86.31%",
      left: "18%",
    },
  },
  circleImageContainer: {
    height: "80%",
    aspectRatio: "1/1",
    // border:'1px solid black',
    // borderRadius:'50%',
    marginLeft: "35%",
    position: "relative",
    transition: "1s",
  },
  nextButton: {
    position: "absolute",
    top: "28.5%",
    right: "52%",
    zIndex: "5",
    border: "none",
    backgroundColor: "#F7F7F7",
    cursor: "pointer",

    // backgroundColor:'white',
    // borderRadius:"50%"
  },
  prevButton: {
    position: "absolute",
    bottom: "28.5%",
    right: "52.3%",
    zIndex: "5",
    border: "none",
    backgroundColor: "#F7F7F7",
    cursor: "pointer",
    // backgroundColor:'white',
    // borderRadius:"50%"
  },
  arrow: {
    transform: "scale(2)",
  },
  circleImage: {
    height: "100%",
    width: "100%",
    // border:'1px solid black',
  },
  iconImage: {
    width: "80%",
    height: "auto",
  },
  iconContainer: {
    // border: "1px solid black",
    display: "flex",
    justifyContent: "center",
    position: "relative",
  },
  numbers: {
    position: "absolute",
    height: "30px",
    width: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    // border: "1px solid orange",
    color: "orange",
    fontWeight: "900",
    fontSize: "20px",
    left: "-40px",
    top: "60px",
  },
  scaleUpCircle: {
    transform: "scale(1.2)",
    border: "5px solid orange",
  },
  mobileViewContainer: {
    // display:'flex',
    // justifyContent:'center',

    backgroundColor: "#F7F7F7",
    paddingBottom: "5%",
    "@media (min-width:768.9px)": {
      display: "none",
    },
  },
  heading: {
    textAlign: "center",
    fontSize: "32px",
    fontWeight: "600",
    paddingTop: "10%",
    marginBottom: "5%",
    "@media (max-width:768px)": {
      fontSize: "24px",
    },
  },
  separateContainer: {
    textAlign: "center",
  },
  iconTitle: {
    fontSize: "24px",
    color: "black",
    fontWeight: "400",
    padding: "5%",
    "@media (max-width:768px)": {
      fontSize: "20px",
    },
  },
  iconDescription: {
    fontSize: "20px",
    color: "black",
    fontWeight: "300",
    paddingLeft: "3%",
    paddingRight: "3%",
    "@media (max-width:768px)": {
      fontSize: "16px",
    },
    // paddingBottom:'2%'
  },
  dotSection: {
    flex: "0.3",
    // border: "1px solid black",
    height: "100%",
  },
  carouselDotContainer: {
    display: "flex",
    height: "60%",
    flexDirection: "column",
    alignItems: "end",
    justifyContent: "space-between",
    marginTop: "30%",
    paddingRight: "30%",
    gap: "50px",
    "@media (max-width:1600px)": {
      gap: "30px",
      // marginTop: "20%",
    },
    "@media (max-width:1080px)": {
      gap: "20px",
    },
    "@media (max-width:800px)": {
      marginTop: "25%",
    },
    // border: "1px solid black",
  },
  singleDotContainer: {
    position: "relative",
    "&:hover": {
      "& $text": {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        transform: "scale(1)",
      },
    },
  },
  carouselDot: {
    height: "70px",
    width: "70px",
    borderRadius: "50%",
    border: "1px solid #3C5D94",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    filter: "grayscale(1)",
    "@media (max-width:1600px)": {
      height: "50px",
      width: "50px",
    },
    "@media (max-width:1080px)": {
      height: "40px",
      width: "40px",
    },
  },
  icon: {
    height: "40px",
    width: "40px",
    "@media (max-width:1600px)": {
      height: "30px",
      width: "30px",
    },
    "@media (max-width:1080px)": {
      height: "20px",
      width: "20px",
    },
  },
  scaleUp: {
    transform: "scale(1.7)",
    filter: "grayscale(0)",
    border: "3px solid #3C5D94",
  },
  scaleDown: {
    transform: "scale(0.5)",
  },
  text: {
    width: "200px",
    height: "50px",
    position: "absolute",
    bottom: "10px",
    right: "80px",
    display: "none",
    borderRadius: "10px",
    padding: "10px",
    fontSize: "16px",
    cursor: "pointer",
    borderBottom: "1px solid rgba(0,0,0,0.2)",
    boxShadow:
      "rgba(0, 0, 0, 0.1) 0px 1px 10px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px",
    zIndex: "100",
    backgroundColor: "rgba(0,0,0,0.5)",
    color: "white",
    "@media (max-width:1600px)": {
      width: "200px",
      height: "50px",
      fontSize: "14px",
      bottom: "0px",
    },
    "@media (max-width:1080px)": {
      right: "60px",
      height: "50px",
      width: "100px",
      fontSize: "12px",
      bottom: "-5px",
    },
  },
  textPosition: {
    bottom: "10px",
    right: "100px",
    "@media (max-width:1600px)": {
      bottom: "0px",
    },
    "@media (max-width:1080px)": {
      bottom: "-5px",
      right: "60px",
    },
  },
});

function WhyBetterNew() {
  const classes = useStyles();
  const elements = [
    { icon: "first", video: "first video" },
    { icon: "second", video: "second video" },
    { icon: "third", video: "third video" },
    { icon: "fourth", video: "fourth video" },
    { icon: "fifth", video: "fifth video" },
    { icon: "sixth", video: "sixth video" },
  ];
  const [currentElement, setCurrentElement] = useState(0);
  const [rotatingStates, setRotatingStates] = useState({
    carouselDeg: 0,
    itemDeg: 0,
    translate: -50,
  });

  const handleNext = () => {
    setRotatingStates((prev) => ({
      carouselDeg: prev.carouselDeg - 60,
      itemDeg: prev.itemDeg + 60,
      translate: prev.translate,
    }));
    setCurrentElement((prev) => {
      if (prev === elements.length - 1) {
        return 0;
      } else {
        return prev + 1;
      }
    });
  };
  const handleIconClick = (index) => {
    if (index === whyBetterData.length - 1 && currentElement === 0) {
      handlePrevious();
      return;
    }
    if (index === 0 && currentElement === whyBetterData.length - 1) {
      handleNext();
      return;
    }
    if (index - 1 === currentElement) {
      handleNext();
      return;
      // index-1<0 && currentElement!==0) ||
    }
    if (index + 1 === currentElement) {
      handlePrevious();
      return;
    }
  };
  const handlePrevious = () => {
    setRotatingStates((prev) => ({
      carouselDeg: prev.carouselDeg + 60,
      itemDeg: prev.itemDeg - 60,
      translate: prev.translate,
    }));
    setCurrentElement((prev) => {
      if (prev === 0) {
        return elements.length - 1;
      } else {
        return prev - 1;
      }
    });
  };
  const handleDotClick = (index) => {
    setCurrentElement(index);
  };

  return (
    <div>
      <div className={classes.whyBetter}>
        <div className={classes.videoSection}>
          <div className={classes.Heading}>Why Awiros?</div>
          <div className={`${classes.videoContainer} `}>
            <div className={`${classes.videoTitle}`}>
              {whyBetterData[currentElement].title}
            </div>
            <div className={`${classes.videoDescription}`}>
              {whyBetterData[currentElement].description}
            </div>
            <video
              key={whyBetterData[currentElement].video}
              autoPlay
              muted
              loop
              controls={false}
              allowsInlineMediaPlayback={true}
              playsInline={true}
              disablePictureInPicture
              className={`${classes.videoForIcon} ${classes.smoothAni}`}
              height="100%"
              width="100%"
            >
              <source
                src={whyBetterData[currentElement].video}
                type="video/mp4"
              />
            </video>
          </div>
        </div>
        <div className={classes.dotSection}>
          <div className={classes.carouselDotContainer}>
            {whyBetterData.map((element, index) => (
              <div className={classes.singleDotContainer}>
                <div
                  className={`${classes.carouselDot} ${
                    currentElement === index ? classes.scaleUp : ""
                  }`}
                  onClick={() => handleDotClick(index)}
                >
                  <img src={element.icon} className={classes.icon}></img>
                </div>
                <div
                  className={`${classes.text} ${
                    currentElement === index ? classes.textPosition : ""
                  }`}
                  onClick={() => handleDotClick(index)}
                >
                  {element.title}
                </div>
              </div>
            ))}
          </div>
        </div>
        {/* <div className={classes.circleSection}>
          <button
            onClick={handleNext}
            className={classes.nextButton}
            style={{ transform: "rotate(-60deg)" }}
          >
            <ArrowForwardIosIcon
              style={{ color: "orange" }}
              className={classes.arrow}
            ></ArrowForwardIosIcon>
          </button>
          <button
            onClick={handlePrevious}
            className={classes.prevButton}
            style={{ transform: "rotate(60deg)" }}
          >
            <ArrowForwardIosIcon
              style={{ color: "orange" }}
              className={classes.arrow}
            ></ArrowForwardIosIcon>
          </button>
          <div
            className={classes.circleImageContainer}
            style={{ transform: `rotate(${rotatingStates.carouselDeg}deg)` }}
          >
            <img
              src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/bg-circle.png"
              className={classes.circleImage}
            ></img>

            {whyBetterData.map((element, index) => (
              <div
                key={index}
                onClick={() => handleIconClick(index)}
                className={`${classes.itemCarousel} ${
                  index === currentElement ? classes.scaleUpCircle : ""
                } `}
                style={{
                  transform: `rotate(${rotatingStates.itemDeg}deg) ${
                    index === currentElement ? `scale(1.7)` : ""
                  }`,
                }}
              >
                <div className={classes.iconContainer}>
                  <img
                    height="10px"
                    width="10px"
                    src={element.icon}
                    className={classes.iconImage}
                  ></img>
                  
                </div>
              </div>
            ))}
          </div>
        </div> */}
      </div>

      <div className={classes.mobileViewContainer}>
        <div className={classes.heading}>Why Awiros?</div>
        <CarouselComponentWithDots
          cardComponentClasses={classes.imageCarousel}
          showDots={true}
          interval={6000}
          isPlaying={false}
          contentArray={whyBetterData.map((element, index) => (
            <div className={classes.separateContainer}>
              <video
                key={whyBetterData[index].video}
                autoPlay
                muted
                loop
                controls={false}
                allowsInlineMediaPlayback={true}
                playsInline={true}
                disablePictureInPicture
                height="auto"
                width="90%"
              >
                <source src={whyBetterData[index].video} type="video/mp4" />
              </video>

              <div className={classes.iconTitle}>{element.title}</div>
              <div className={classes.iconDescription}>
                {element.description}
              </div>
            </div>
          ))}
        />
      </div>
    </div>
  );
}

export default WhyBetterNew;
