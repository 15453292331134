import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  customersAndpartners: {
    width: "100%",
    aspectRatio: "1920/1800",
    padding: "2% 8% 10% 8%",
    filter: `grayscale(1)`,
  },
  heading: {
    textAlign: "center",
    fontSize: "30px",
    fontWeight: "600",
    marginBottom: "5%",
    "@media (max-width:768px)": {
      fontSize: "24px",
    },
  },
  subHeading: {
    textAlign: "center",
    fontSize: "20px",
    fontWeight: "600",
    marginBottom: "2%",
    "@media (max-width:768px)": {
      fontSize: "16px",
      marginTop: "20px",
      marginBottom: "20px",
    },
  },
  main: {
    width: "100%",
    height: "100%",
  },

  mainContent: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  mainColumnContent: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    height: "100%",
  },
  eachContent: {
    width: "100%",
    height: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "15px",
  },
  image: {
    width: "50%",
    objectFit: "contain",
  },
});

function CustomersAndPartnersMobileViewComp(props) {
  const { data, heading, subHeading } = props;

  const classes = useStyles();

  return (
    <div className={classes.customersAndpartners}>
      <div className={classes.heading}>{heading}</div>
      <div className={classes.subHeading}>{subHeading}</div>
      <div className={classes.main}>
        <div className={classes.mainContent}>
          <div className={classes.mainColumnContent}>
            {data.column1.map((imageUrl) => (
              <div className={classes.eachContent}>
                <img src={imageUrl} className={classes.image}></img>
              </div>
            ))}
          </div>
          <div className={classes.mainColumnContent}>
            {data.column2.map((imageUrl) => (
              <div className={classes.eachContent}>
                <img src={imageUrl} className={classes.image}></img>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomersAndPartnersMobileViewComp;
