export const guidelines = [
  "You can have at most 4 members in your team.",
  "You can either invite your friends to form a team or you can request other teams to add you as a member.",
  "App-A-Thon starts on 15th September 2023 10:00AM and ends on 30th October 2023 9:00PM.",
  "This will be an online and offline hackathon. Offline hackathon will be held in Delhi NCR (venue details will be disclosed later) and participants from outside Delhi NCR can participate via online mode.",
  "The submissions will be judged by the appointed judges, the decision of the administrative team will be final and binding.",
  "You may use open source libraries and other freely available systems / services.",
  "You are expected to come up with new and innovative ideas, any idea that has been copied from somewhere will be disqualified.",
  "The intellectual property of your code belongs only to your team.",
];
