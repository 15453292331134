import { withStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import AppathonPageStyles from "./AppathonPageStyles";
import AppathonBanner from "./AppathonBanner";
import SecondBanner from "./secondBanner/SecondBanner";
import Awards from "./Awards/Awards";
import Guideline from "./Guidelines/Guideline";
import Faqs from "./FAQs/Faqs";
import AboutOrganization from "./aboutOrganization/AboutOrganization";
import RegistrationForm from "./RegistrationForm/RegistrationForm";
import SaveDates from "./saveTheDates/SaveDates";
import NewChallenge from "./NewChallenge/NewChallenge";
import { env } from "../../env";

const AppathonPage = (props) => {
  const { classes } = props;

  const scollToRef = useRef();
  const [redirectToRegister, setRedirectToRegister] = useState(false);

  useEffect(() => {
    if (redirectToRegister) {
      scollToRef.current.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
    setTimeout(() => {
      setRedirectToRegister(false);
    }, 1000);
  }, [redirectToRegister]);
  return (
    <div>
      <AppathonBanner
        setRedirectToRegister={setRedirectToRegister}
      ></AppathonBanner>
      {/* <div className={classes.sessionContainer}>
        <div className={classes.sessionHeading}>
          OUR FIRST TRANING SESSION IS LIVE NOW!
        </div>

        <div
          style={{
            textAlign: "center",
          }}
        >
          <a
            style={{ margin: "auto" }}
            target="_blank"
            href={env.REACT_APP_TRANING_SESSION_LINK}
          >
            <button className={classes.bannerContentRegisterButton}>
              Join Now
            </button>
          </a>
        </div>
      </div> */}
      <div className={classes.sessionContainer}>
        <div className={classes.sessionHeading}>ROUND 1 RESULTS</div>

        <div
          style={{
            textAlign: "center",
          }}
        >
          <a
            style={{ margin: "auto" }}
            target="_blank"
            href={"assets/appathon/appathon_round_one_result.pdf"}
          >
            <button className={classes.bannerContentRegisterButton}>
              Result
            </button>
          </a>
        </div>

        <div className={classes.linkContainer}>
          {[
            {
              heading: "Watch Training Session 1",
              url: env.REACT_APP_TRAINING_SESSION_ONE_LINK,
            },
            {
              heading: "Watch Training Session 2",
              url: env.REACT_APP_TRAINING_SESSION_TWO_LINK,
            },
            {
              heading: "Download Training Plan",
              url: env.REACT_APP_DETAILED_PLAN,
            },
            {
              heading: "Round 1 PPT",
              url: env.REACT_APP_PPT_TEMPLATE,
            },
          ].map((link, index) => (
            <a
              href={link.url}
              target="_blank"
              className={classes.linkContainerLink}
            >
              {link.heading}
            </a>
          ))}
        </div>
      </div>

      <SecondBanner></SecondBanner>
      <NewChallenge></NewChallenge>
      <Awards></Awards>
      <SaveDates></SaveDates>

      <div ref={scollToRef}>
        <RegistrationForm></RegistrationForm>
      </div>
      <Guideline></Guideline>
      <Faqs></Faqs>
      <AboutOrganization></AboutOrganization>
    </div>
  );
};

export default withRouter(
  withStyles(AppathonPageStyles, { withTheme: true })(AppathonPage)
);
