let NewChallengeStyles = {
  NewChallengeContainer: {
    padding: "40px",
    marginTop: "40px",
    "@media (max-width:768px)": {
      padding: "20px",
    },
  },
  challengeDetails: {
    display: "flex",
    flexDirection: "column",
    gap: "40px",
    "@media (max-width:768px)": {
      alignItems: "center",
    },
  },
  challengeDetailsHeading: {
    fontSize: "48px",
    fontWeight: "600",
    "@media (max-width:768px)": {
      fontSize: "32px",
    },
  },
  challengeDetailsDescription: {
    fontSize: "20px",
    "@media (max-width:1024px)": {
      fontSize: "18px",
    },
    "@media (max-width:768px)": {
      textAlign: "center",
      fontSize: "16px",
    },
  },
  challengeDetailsDescriptionHighlight: {
    fontWeight: "700",
  },
  themes: {
    display: "flex",
    alignItems: "center",
    gap: "9px",
    fontSize: "32px",
    fontWeight: "600",
    marginTop: "48px",
    marginBottom: "80px",
    "@media (max-width:768px)": {
      fontSize: "24px",
      marginBottom: "40px",
      marginTop: "32px",
    },
  },
  horizontalLine: {
    width: "100%",
    height: "3px",
    backgroundColor: "#FC0",
  },
  problemContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    marginBottom: "80px",
    "@media (max-width:768px)": {
      marginBottom: "40px",
    },
  },
  problemHeading: {
    fontSize: "48px",
    fontWeight: "600",
    marginBottom: "40px",
    "@media (max-width:1024px)": {
      fontSize: "32px",
      textAlign: "center",
    },
  },
  problem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    borderRadius: "8px",
    padding: "20px",
    gap: "16px",
    bordeRadius: "8px",
    borderBottom: "3px solid #FC0",
    boxShadow: "0px 2px 3px 1px rgba(0, 0, 0, 0.10)",
    "@media (max-width:768px)": {
      padding: "10px",
    },
  },
  faqHeaderArrow: {
    marginLeft: "auto",
    cursor: "pointer",
  },
  problemHeader: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  problemHeaderMainHeading: {
    display: "flex",
    alignItems: "center",
    gap: "32px",
    width: "450px",
    height: "max-content",
    marginBottom: "auto",
    "@media (max-width:768px)": {
      width: "max-content",
    },
  },
  problemIcon: {
    width: "50px",
    "@media (max-width:768px)": {
      width: "40px",
    },
  },
  problemTitleDesktop: {
    fontSize: "32px",
    fontWeight: "600",
    "@media (max-width:1024px)": {
      fontSize: "24px",
    },
    "@media (max-width:768px)": {
      display: "none",
    },
  },
  problemTitleMobile: {
    display: "none",
    fontSize: "24px",
    fontWeight: "600",
    "@media (max-width:768px)": {
      display: "block",
    },
  },
  problemDescription: {
    "@media (max-width:1024px)": {
      fontSize: "20px",
    },
    "@media (max-width:768px)": {
      fontSize: "16px",
    },
  },
  problemHeaderHeading: {
    width: "100%",
    marginLeft: "20px",
    fontSize: "24px",
  },
  problemAnswer: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    fontSize: "24px",
    marginTop: "32px",
    "@media (max-width:1024px)": {
      fontSize: "20px",
    },
    "@media (max-width:768px)": {
      fontSize: "16px",
    },
  },
  problemUpArrowContainer: {
    display: "flex",
    alignItems: "end",
    marginTop: "auto",
    border: "1px solid black",
  },
  // problemHeaderArrow: {
  //   marginLeft: "auto",
  // },
  // hidden: {
  //   display: "none",
  // },
};

export default NewChallengeStyles;
