import StyleConfig from "../../../../../../lib/StyleConfig";

let AppStackStyles = {
   root: {
      // marginTop: vh(9),
      // marginHorizontal:vw(2),
      position: 'sticky'
   },
   topBarContainer: {
      padding: 20,
      position: 'sticky',
      top: 0,
      backgroundColor: 'white',
      zIndex: 1100,
   },
   dropdownIcons: {
      width: 25
   },
   menuItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center'
   },
   checkbox: {
      fill: StyleConfig.mainThemeColor,
   },
   item: {
      backgroundColor: '#4D243D',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      margin: 1,
   },
   itemInvisible: {
      backgroundColor: 'transparent',
   },
   guidedTourButton: {
      backgroundColor: StyleConfig.mainThemeColor,
      color: StyleConfig.invertedSecondaryThemeColor,
      '&:hover': {
         backgroundColor: StyleConfig.mainThemeColor,
         opacity: .8
      }
   },
   headerContainer: {
      position: 'absolute',
      left: 0,
      zIndex: 120000,
   },
   filterInput: {
      flex: 1,
      position: 'relative',
   },
   filterContainer: {
      flex: 1,
   },
   appListContainer: {},
   appList: {
      padding: '20px 20px 20px',
      margin: '0 auto',
   }
};

export default AppStackStyles;