import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section7Styles from "../Section7Styles";
import InfiniteCarousel from "../../../../CommonComponents/InfiniteCarousel/InfiniteCarousel";
import NewsCarouselConfig from "./NewsCarouselConfig";
import NewsCarouselCard from "./NewsCarouselCard/NewsCarouselCard";

let styles = Section7Styles;

class NewsCarousel extends Component {
   constructor(props) {
      super(props);
   }

   render() {
      let {classes} = this.props;

      return (
         <div className={classes.root}>
            <InfiniteCarousel
               carouselContent={NewsCarouselConfig}
               Component={NewsCarouselCard}
               visibleSlidesXl={1}
               visibleSlidesLg={1}
               visibleSlidesMd={1}
               visibleSlidesSm={1}
               visibleSlidesXs={1}
            />
         </div>
      );
   }
}

export default withStyles(styles)(NewsCarousel);