import { withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import FaqsStyles from "./FaqsStyles";
import { faqs } from "../utils/faqs";

const Faqs = (props) => {
  const { classes } = props;
  const [openFaqWithIndex, setOpenFaxWithIndex] = useState(-1);

  return (
    <div className={classes.faqsContainer}>
      <div className={classes.faqsHeading}>
        Frequently Asked Questions (FAQs)
      </div>
      <div className={classes.faqsContent}>
        {faqs.map((faq, index) => (
          <div key={index} className={classes.faqs}>
            <div className={classes.faqHeader}>
              <span className={classes.faqsNumber}>
                {index < 9 ? `0${index + 1}` : `${index + 1}`}
              </span>
              <span className={classes.faqsQuestion}>{faq.question}</span>
              {openFaqWithIndex !== index && (
                <img
                  className={classes.faqHeaderArrow}
                  src="/assets/appathon/downArrow.png"
                  onClick={() => setOpenFaxWithIndex(index)}
                ></img>
              )}
            </div>
            {openFaqWithIndex === index && (
              <div className={classes.faqsAnswer}>
                <span>{faq.answer}</span>
                <img
                  src="/assets/appathon/upArrow.png"
                  onClick={() => setOpenFaxWithIndex(-1)}
                  className={classes.faqHeaderArrow}
                ></img>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default withRouter(withStyles(FaqsStyles, { withTheme: true })(Faqs));
