import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from "@material-ui/core/Button";
import RoutePath from "../../../../../lib/RoutePath";
import Section6CardStyles from "./Section6CardStyles";
import Grid from "@material-ui/core/Grid";

let styles = Section6CardStyles;


class Section6Card extends Component {
   constructor(props) {
      super(props);
   }

   handleClick = () => {
      RoutePath.navigateTo(this.props, RoutePath.developers)
   };

   render() {
      let {classes, cardData} = this.props;

      return (
         <>
            <Grid container direction={'column'} alignItems={'center'}>
               <Grid item>
                  <div className={`h2heading center ${classes.heading}`}>
                     {cardData.heading}
                  </div>
               </Grid>
               <Grid item>
                  <div className={classes.thumbnailImage}>
                     <img src={cardData.image} className={classes.thumbnailImage}/>
                  </div>
               </Grid>
               <Grid item>
                  <div className={`${classes.textContent} justify`}>
                     {cardData.content}
                  </div>
               </Grid>
               <Grid item container direction={'row'} justify={'flex-end'}>
                  <Button
                     className={classes.buttonType}
                     variant={"outlined"}
                     color="primary"
                     onClick={this.handleClick}
                     disableElevation
                  >
                     {cardData.buttonText}
                  </Button>
               </Grid>
            </Grid>
         </>
      );
   }
}

export default withStyles(styles)(Section6Card);