let Section7TwitterFeedStyles = {
   root: {
      marginTop: 10,
      marginBottom: 140,
   },
   tweetsContainer: {
      maxWidth: 600,
      margin: '0 auto'
   },
   followButtonContainer: {
      marginTop: 30,
   },
   followButton: {
      marginRight: 300
   }
}

export default Section7TwitterFeedStyles;
