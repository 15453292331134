import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import NotableDeploymentStyles from "./NotableDeploymentStyles";
import { useEffect } from "react";
import { notableDeployments } from "../utils/indexThree";
import CarouselComponentWithDots from "../../CommonComponents/Carousel-ComponentWithDots/Carousel-ComponentWithDots";

const NotableDeployment = (props) => {
  const { classes } = props;

  // current window dimensions
  let dimensions = useWindowDimensions();

  // for current window size
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const renderCards = () => {
    return notableDeployments.map((card, index) => {
      return (
        <div key={index} className={classes.NDCard}>
          <div className={classes.image}>
            <img
              src={card.imageUrl}
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "2%",
                objectFit: `${index === 0 || index === 2 ? "contain" : ""}`,
              }}
            ></img>
          </div>
          <div className={classes.detailContainer}>
            <div className={classes.detailHeading}>{card.cardHeading}</div>
            <div className={classes.detailDescription}>{card.cardDetails}</div>
          </div>
        </div>
      );
    });
  };

  return (
    <div className={classes.NotableDeploymentContainer}>
      <div className={classes.heading}>Our Success Stories</div>
      <div className={classes.DesktopViewContainer}>
        <div className={classes.cardContainer}>{renderCards()}</div>
      </div>
      <div className={classes.MobileViewContainer}>
        <CarouselComponentWithDots
          cardComponentClasses={classes.cardContainer}
          contentArray={renderCards()}
          showDots={false}
          interval={3000}
        ></CarouselComponentWithDots>
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(NotableDeploymentStyles, { withTheme: true })(NotableDeployment)
);
