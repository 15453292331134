import StyleConfig from "../../../../../../lib/StyleConfig";

let maxWidth = 960;

let AppDescriptionStyles = {
   root: {
      // border:'1px solid lightgrey'
   },
   topBarContainer: {
      position: 'sticky',
      padding: 10,
      top: 0,
      backgroundColor: 'white',
      zIndex: 1000,
   },
   topBar: {
      maxWidth: maxWidth,
      margin: '0 auto',
   },
   guidedTourButton: {
      backgroundColor: StyleConfig.mainThemeColor,
      color: StyleConfig.invertedSecondaryThemeColor,
      '&:hover': {
         backgroundColor: StyleConfig.mainThemeColor,
         opacity: .8
      }
   },
   contentContainer: {
      maxWidth: maxWidth,
      margin: '0 auto',
      [`@media (max-width: ${maxWidth}px)`]: {
         margin: '0 10px',
      },
   },
   headerContainer: {
      marginBottom: 20,
      display: 'flex',
      flexDirection: 'row',
   },
   appIcon: {
      width: 90,
      height: 90
   },
   appNameContainer: {
      marginLeft: 10
   },
   appName: {
      fontSize: 25,
      fontWeight: 400,
   },
   appDescription: {
      fontSize: 16,
      fontWeight: 300,
   },
   screenshotImage: {
      width: '100%'
   },
   section3Container: {
      marginBottom: 20
   },
   detailsSubheading: {
      fontSize: 14,
      fontWeight: 500
   },
   detailsValue: {
      fontSize: 14,
      fontWeight: 400,
   },
};

export default AppDescriptionStyles;