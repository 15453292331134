import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import Section4CardStyles from "./Section4CardStyles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";

let styles = Section4CardStyles;

class Section4Card extends Component {
    static defaultProps = {
        appName: "Advanced Intrusion Detection",
        textContent:
            "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur consectetur possimus quo ratione ullam. Asperiores, cupiditate deleniti ducimus facere facilis ipsa itaque optio quasi quisquam reiciendis rem sequi velit vitae!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur consectetur possimus quo ratione ullam. Asperiores, cupiditate deleniti ducimus facere facilis ipsa itaque optio quasi quisquam reiciendis rem sequi velit vitae!Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur consectetur possimus quo ratione ullam.",
        logo: "/assets/images/icons/anpr.png"
    };

    render() {
        let { classes } = this.props;
        return (
            <div className={classes.root}>
                <Grid
                    container
                    direction={"row"}
                    justify={"center"}
                    alignItems={"center"}
                    spacing={10}
                >
                    <Hidden smUp>
                        <Grid item sm={12} container justify={"center"}>
                            <img
                                src={this.props.logo}
                                alt="app logo"
                                className={classes.appIcon}
                            />
                        </Grid>
                    </Hidden>
                    <Grid
                        container
                        direction={"column"}
                        spacing={2}
                        item
                        sm={7}
                    >
                        <Grid item>
                            <div className={classes.appName}>
                                {this.props.appName}
                            </div>
                        </Grid>
                        <Grid item className={classes.textContent}>
                            {this.props.textContent}
                        </Grid>
                    </Grid>
                    <Hidden xsDown>
                        <Grid item sm={5}>
                            <img
                                src={this.props.logo}
                                alt="app logo"
                                className={classes.appIcon}
                            />
                        </Grid>
                    </Hidden>
                </Grid>
            </div>
        );
    }
}

export default withStyles(styles)(Section4Card);
