import { withStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { withRouter } from "react-router-dom";
import RegistrationFormStyles from "./RegistrationFormStyles";
import { toast } from "material-react-toastify";
import axios from "axios";
import RoutePath from "../../../lib/RoutePath";
import ReCAPTCHA from "react-google-recaptcha";

import { env } from "../../../env";
import { countryArray } from "../utils/countryArray";
import { countryObj } from "../utils/countriesObj";
const RegistrationForm = (props) => {
  const { classes } = props;
  const queryParameters = new URLSearchParams(window.location.search);
  const utmSource = queryParameters.get("utm_source");
  const intialCountry = "IN";
  const intialTeamDetails = {
    teamSize: 1,
    teamName: "",
    aggrement: false,
    utm_source: utmSource || "",
  };
  const participantObject = {
    name: "",
    email: "",
    phoneNo: "",
    organisation: "",
    captain: true,
    city: "",
    country: countryObj[intialCountry],
  };

  const firstStepRequiredFields = [
    "name",
    "email",
    "phoneNo",
    "organisation",
    "city",
    "country",
  ];

  const captchaRef = useRef(null);

  const [stepNumber, setStepNumber] = useState(1);
  const [selectedCountry, setSelectedCountry] = useState(intialCountry);
  const [teamDetails, setTeamDetails] = useState(intialTeamDetails);
  const [participantsDetails, setParticipantsDetails] = useState([
    participantObject,
  ]);
  const [currentParticipant, setCurrentParticipant] = useState(-1);
  const [captchaValidated, setCaptchaValidated] = useState(false);
  const [teamRegistered, setTeamRegistered] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if ((participantsDetails || []).length > 1) {
      setCurrentParticipant(1);
    }
  }, [(participantsDetails || []).length]);

  const handleInputChange = (index, fieldName, value) => {
    if (fieldName === "name") {
      value = value.trimStart();
    }

    const tempParticipantsDetails = [...participantsDetails];
    tempParticipantsDetails[index][fieldName] = value;
    setParticipantsDetails(tempParticipantsDetails);
  };

  const handleCountryChange = (e) => {
    setSelectedCountry(e.target.value);

    const tempParticipantDetails = [...participantsDetails];
    tempParticipantDetails[0].country = countryObj[e.target.value];
    setParticipantsDetails(tempParticipantDetails);
  };

  const handleSubmitClick = async () => {
    if (teamDetails.teamName === "") {
      toast.error("Please fill the team name!!");
      return;
    }

    if (!teamDetails.aggrement) {
      toast.error("You need to agree to the aggrement");
      return;
    }

    if (!captchaValidated) {
      toast.error("You first need to validate captcha");
      return;
    }
    setIsLoading(true);

    await axios
      .post(`${env.REACT_APP_BACKEND_URL}/awiros/website/team/register_team`, {
        participantsDetails,
        teamDetails,
      })
      .then((res) => {
        if (res.data.success) {
          setStepNumber(1);
          setTeamDetails({ ...intialTeamDetails });
          setParticipantsDetails([{ ...participantObject }]);
          setCurrentParticipant(-1);
          setTeamRegistered(true);
          window.lintrk("track", { conversion_id: 14195788 });
        } else {
          toast.error(res.data.msg || "Failed to register team");
        }
      })
      .catch(function (error) {
        if (error?.response?.status === 429) {
          toast.error("Too may request, Please try after some time");
          return;
        }
        console.log(error, "error");
      });

    setIsLoading(false);
  };

  const handleTeamDetailsChange = (e) => {
    let value = e.target.value;
    if (e.target.name === "teamname") {
      value = value.trimStart();
    }
    setTeamDetails({
      ...teamDetails,
      teamName: value,
    });
  };

  const handleCurrentParticipantChange = (index) => {
    if (currentParticipant !== index) {
      setCurrentParticipant(index);
    }
  };

  const handleTeamSizeChange = (e) => {
    const currentNumberOfParticipants = (participantsDetails || []).length;
    const tempParticipantsDetails = [...participantsDetails];
    const newTeamSize = +e.target.value;
    const differenceInNumberOfParticipant = Math.abs(
      currentNumberOfParticipants - newTeamSize
    );
    for (let index = 0; index < differenceInNumberOfParticipant; index++) {
      if (currentNumberOfParticipants < newTeamSize) {
        tempParticipantsDetails.push({
          ...participantObject,
          captain: false,
        });
      } else {
        tempParticipantsDetails.pop();
      }
    }

    setParticipantsDetails(tempParticipantsDetails);
    setTeamDetails({
      ...teamDetails,
      teamSize: +e.target.value,
    });
  };

  // const checkAllFields = (index) => {
  //   return !(
  //     participantsDetails[index].name === "" ||
  //     participantsDetails[index].email === "" ||
  //     participantsDetails[index].organisation === "" ||
  //     participantsDetails[index].phoneNo === "" ||
  //     participantsDetails[index].city === "" ||
  //     participantsDetails[index].country === ""
  //   );
  // };

  const emailCheck = (emailId) => {
    return /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailId);
  };

  const PhoneNoCheck = (phoneNumber) => {
    return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/.test(
      phoneNumber
    );
  };

  const handleCaptchaChange = async (e) => {
    const token = captchaRef.current.getValue();
    // captchaRef.current.reset();

    if (!e) {
      setCaptchaValidated(false);
      return;
    }
    await axios
      .post(
        `${env.REACT_APP_BACKEND_URL}/awiros/website/misc/validate_captcha`,
        { token }
      )
      .then((res) => {
        if (res.data.success) {
          setCaptchaValidated(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const handleContinueClick = async (e) => {
    e.preventDefault();

    // const checkFields = checkAllFields(0);

    if (participantsDetails[0].name === "") {
      toast.error("Please fill the name!!");
      return;
    } else if (participantsDetails[0].email === "") {
      toast.error("Please fill the email!!");
      return;
    } else if (participantsDetails[0].phoneNo === "") {
      toast.error("Please fill the phone number!!");
      return;
    } else if (participantsDetails[0].organisation === "") {
      toast.error("Please fill the organisation!!");
      return;
    } else if (participantsDetails[0].city === "") {
      toast.error("Please fill the city!!");
      return;
    } else if (participantsDetails[0].country === "") {
      toast.error("Please fill the country!!");
      return;
    }
    // if (!checkFields) {
    //   toast.error("Please fill all the details!!");
    //   return;
    // }
    if (!emailCheck(participantsDetails[0].email)) {
      toast.error("enter valid email");
      return;
    }
    if (!PhoneNoCheck(+participantsDetails[0].phoneNo)) {
      toast.error("enter valid phone no.");
      return;
    }

    setStepNumber(2);
    window.lintrk("track", { conversion_id: 14100908 });
    await axios.post(
      `${env.REACT_APP_BACKEND_URL}/awiros/website/team/team_registration_initial_data`,
      {
        ...participantsDetails[0],
        utm_source: utmSource || "",
      }
    );
  };

  return (
    <div className={classes.form}>
      <div className={classes.formProgress}>
        <div className={classes.formProgressHeading}>Registration</div>
        <div className={classes.formProgressContent}>
          <div className={classes.stepSection}>
            <div className={classes.stepContainer}>
              <span className={classes.stepLabel}>Step-1</span>
              <span className={classes.stepDescription}>
                Personal Information
              </span>
            </div>
            <span
              className={classes.circle}
              style={{ backgroundColor: "white" }}
            ></span>
          </div>
          <div className={classes.stepSection}>
            <div className={classes.stepContainer}>
              <span className={classes.stepLabel}>Step-2</span>
              <span className={classes.stepDescription}>Team Information</span>
            </div>
            <span
              className={classes.circle}
              style={{ backgroundColor: stepNumber === 2 ? "white" : "" }}
            ></span>
          </div>
        </div>
      </div>
      <div className={classes.details}>
        {teamRegistered && (
          <div className={classes.successfulTeamRegistrationMessage}>
            Registration completed successfully! Please check your e-mail for
            confirmation.
          </div>
        )}

        <div
          className={classes.detailsHeading}
          style={{
            color: "red",
            marginBottom: "32px",
          }}
        >
          REGISTRATION CLOSED!!
        </div>

        <div className={classes.detailsHeading}>
          {stepNumber === 1 ? "Personal Information" : "Team Information"}
        </div>
        <div className={classes.detailsNote}>
          Required fields <span style={{ color: "red" }}>*</span>
        </div>

        {stepNumber === 1 && (
          <div className={classes.oneStepContainer}>
            <form onSubmit={handleContinueClick}>
              <div className={classes.detailsRow}>
                <input
                  type="text"
                  placeholder="Name*"
                  className={classes.inputField}
                  value={participantsDetails[0].name}
                  name="name"
                  disabled={true}
                  // required={true}
                  onChange={(e) =>
                    handleInputChange(0, e.target.name, e.target.value)
                  }
                ></input>
                <input
                  type="text"
                  placeholder="E-mail*"
                  className={classes.inputField}
                  value={participantsDetails[0].email}
                  name="email"
                  disabled={true}
                  // required={true}
                  onChange={(e) =>
                    handleInputChange(0, e.target.name, e.target.value)
                  }
                ></input>
              </div>
              <div className={classes.detailsRow}>
                <input
                  type="text"
                  placeholder="Phone No.*"
                  className={classes.inputField}
                  value={participantsDetails[0].phoneNo}
                  name="phoneNo"
                  disabled={true}
                  // required={true}
                  onChange={(e) =>
                    handleInputChange(0, e.target.name, e.target.value)
                  }
                ></input>
                <input
                  type="text"
                  placeholder="Organization/Institute*"
                  className={classes.inputField}
                  value={participantsDetails[0].organisation}
                  name="organisation"
                  disabled={true}
                  // required={true}
                  onChange={(e) =>
                    handleInputChange(0, e.target.name, e.target.value)
                  }
                ></input>
              </div>
              <div className={classes.detailsRow}>
                <input
                  type="text"
                  placeholder="City*"
                  className={classes.inputField}
                  value={participantsDetails[0].city}
                  name="city"
                  disabled={true}
                  onChange={(e) =>
                    handleInputChange(0, e.target.name, e.target.value)
                  }
                ></input>
                <select
                  value={selectedCountry}
                  className={classes.inputField}
                  onChange={(e) => handleCountryChange(e)}
                  disabled={true}
                >
                  {countryArray.map((ele, index) => (
                    <option key={index} value={ele.value}>
                      {ele.label}
                    </option>
                  ))}
                </select>
              </div>

              <div className={classes.detailsContinueButtonContainer}>
                <button
                  className={classes.detailsContinueButton}
                  disabled={true}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        )}
        {stepNumber === 2 && (
          <div className={classes.secondStepContainer}>
            <div className={classes.teamInfo}>
              <div className={classes.teamInfoField}>
                <div className={classes.teamName}>Name of Team*</div>
                <div>
                  {" "}
                  <input
                    type="text"
                    placeholder="Name of your team"
                    className={classes.inputField}
                    value={teamDetails.teamName}
                    name="teamname"
                    onChange={(e) => handleTeamDetailsChange(e)}
                  ></input>
                </div>
              </div>
              <div className={classes.teamInfoField}>
                <div className={classes.teamName}>
                  Number of Members (including you) *
                </div>
                <div>
                  {" "}
                  <select
                    value={teamDetails.teamSize}
                    className={classes.inputField}
                    onChange={(e) => handleTeamSizeChange(e)}
                  >
                    <option value="1">One Participant</option>
                    <option value="2">Two Participants</option>

                    <option value="3">Three Participants</option>

                    <option value="4">Four Participants</option>
                  </select>
                </div>
              </div>
            </div>
            <div></div>
            {
              <div style={{ color: "green" }}>
                You can add participants' details later through a link in the
                confirmation email.
              </div>
            }
            <div className={classes.tabSection}>
              {(participantsDetails || []).map((participant, index) => {
                if (index === 0) {
                  return;
                }
                return (
                  <div className={classes.participantDetails} key={index}>
                    <div className={classes.detailsVerticalRowHeading}>
                      <span
                        style={{
                          borderBottom:
                            currentParticipant === index
                              ? "3px solid #FFCC00"
                              : "",
                          cursor: "pointer",
                        }}
                        onClick={() => handleCurrentParticipantChange(index)}
                      >
                        Participant: {`0${index + 1}`}
                      </span>
                    </div>
                  </div>
                );
              })}
            </div>
            <div className={classes.participantsDetails}>
              {(participantsDetails || []).map((participant, index) => {
                if (
                  index === 0 ||
                  (currentParticipant !== -1 && currentParticipant !== index)
                ) {
                  return;
                }
                return (
                  <div className={classes.participantDetails} key={index}>
                    {currentParticipant === -1 && (
                      <div className={classes.detailsVerticalRowHeading}>
                        Participant: {`0${index + 1}`}
                      </div>
                    )}
                    <div className={classes.detailsVerticalRow}>
                      <input
                        type="text"
                        placeholder="Name"
                        className={classes.inputField}
                        value={participantsDetails[index].name}
                        name="name"
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      ></input>
                      <input
                        type="text"
                        placeholder="E-mail"
                        className={classes.inputField}
                        value={participantsDetails[index].email}
                        name="email"
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      ></input>

                      <input
                        type="text"
                        placeholder="Phone No."
                        className={classes.inputField}
                        value={participantsDetails[index].phoneNo}
                        name="phoneNo"
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      ></input>
                      <input
                        type="text"
                        placeholder="Organization/Institute"
                        className={classes.inputField}
                        value={participantsDetails[index].organisation}
                        name="organisation"
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      ></input>
                      <input
                        type="text"
                        placeholder="City"
                        className={classes.inputField}
                        value={participantsDetails[index].city}
                        name="city"
                        onChange={(e) =>
                          handleInputChange(
                            index,
                            e.target.name,
                            e.target.value
                          )
                        }
                      ></input>
                    </div>
                  </div>
                );
              })}
            </div>

            <div className={classes.detailsContactNote}>
              <input
                type="checkbox"
                checked={teamDetails.aggrement}
                onChange={() =>
                  setTeamDetails({
                    ...teamDetails,
                    aggrement: !teamDetails.aggrement,
                  })
                }
              ></input>{" "}
              I have read and agree to{" "}
              <a
                className={classes.policies}
                target="_blank"
                href={RoutePath.appathonTermsOfUse}
              >
                Terms
              </a>{" "}
              and{" "}
              <a
                className={classes.policies}
                target="_blank"
                href={RoutePath.appathonPrivacyPolicy}
              >
                Privacy Policy
              </a>{" "}
            </div>
            <div className={classes.captchaContainer}>
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_SITE_KEY}
                ref={captchaRef}
                onChange={(e) => handleCaptchaChange(e)}
              />
            </div>
            <div className={classes.detailsActions}>
              <button
                className={classes.detailsPreviousButton}
                onClick={() => setStepNumber(1)}
              >
                Previous
              </button>
              <button
                className={classes.detailsSubmitButton}
                onClick={handleSubmitClick}
                disabled={isLoading}
              >
                Submit
              </button>
            </div>
          </div>
        )}
        <div className={classes.detailsContactNote}>
          For any queries or support contact us at{" "}
          <a
            style={{ fontWeight: "700", color: "black" }}
            href="https://mail.google.com/mail/?view=cm&fs=1&to=appathon@awiros.com"
            target="_blank"
          >
            appathon@awiros.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(RegistrationFormStyles, { withTheme: true })(RegistrationForm)
);
