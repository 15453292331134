import React, { Component } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import AppCardStyles from "./AppCardStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import withWidth from "@material-ui/core/withWidth";
import clsx from "clsx";
import { ProcessorColors } from "../../../../../../../lib/StyleConfig";
import { ReplaceUrlHostname } from "../../../../../../../util/commonUtils";

let styles = AppCardStyles;

class AppCard extends Component {
    static defaultProps = {
        cardData: {}
    };

    handleExploreClicked = () => {
        this.props.switchScreen(this.props.cardData, false);
    };

    render() {
        let { classes, cardData, width } = this.props;
        let extraCount = 0;
        if (cardData) {
            return (
                <div className={clsx(classes.root)}>
                    <Grid
                        container
                        direction={"row"}
                        spacing={1}
                        className={classes.topPartContainer}
                        justify={"center"}
                    >
                        <Grid item sm={3}>
                            <img
                                src={ReplaceUrlHostname(cardData.icon)}
                                alt={cardData.awi_name}
                                className={classes.appIcon}
                            />
                        </Grid>

                        <Grid item xs={12} sm={9}>
                            <div className={classes.appName}>
                                {cardData.awi_name}
                            </div>
                            <div className={classes.developerName}>
                                {cardData.awi_developer}
                            </div>
                            <div className={classes.descriptionText}>
                                {cardData.description}
                            </div>

                            <div className={classes.industryTagContainer}>
                                {cardData.industries.map((industry, index) => {
                                    if (index === 0 || index === 1) {
                                        return (
                                            <div
                                                key={index}
                                                className={classes.industryTag}
                                            >
                                                {industry.awi_name}
                                            </div>
                                        );
                                    } else if (index > 1) {
                                        extraCount++;
                                    }
                                })}
                                {extraCount ? (
                                    <div className={classes.industryTagEmpty}>
                                        +{extraCount}
                                    </div>
                                ) : null}
                            </div>

                            <div
                                className={classes.processorTag}
                                style={{
                                    backgroundColor:
                                        ProcessorColors[cardData.processor]
                                }}
                            >
                                {cardData.processor}
                                <img
                                    className={classes.processorIcon}
                                    src={
                                        "https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/images/icons/hardware-platforms/processor-w.png"
                                    }
                                    alt=""
                                />
                            </div>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction={"row"}
                        justify={"space-between"}
                        alignItems={"center"}
                    >
                        <Grid item>
                            <i
                                className={`fas fa-thumbs-up ${classes.likeButtonIcon}`}
                            />{" "}
                            <span className={classes.likeButtonText}>
                                {cardData.likes}
                            </span>
                            <i
                                className={`fas fa-thumbs-down ${classes.likeButtonIcon}`}
                            />{" "}
                            <span className={classes.likeButtonText}>
                                {cardData.dislikes}
                            </span>
                        </Grid>

                        <Grid item>
                            <Button
                                variant={"contained"}
                                onClick={this.handleExploreClicked}
                                className={classes.exploreButton}
                            >
                                EXPLORE
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            );
        } else {
            return null;
        }
    }
}

export default withWidth()(withStyles(styles)(AppCard));
