import StyleConfig from "../../lib/StyleConfig";

let ResourcesPageStyles = {
  root: {
    margin: "0 auto",
    // marginTop: 'calc(64px + 8vh)',
    maxWidth: 1200,
    padding: "0 20px 50px",
    flex: 1,
    // width:'100vw',
    backgroundColor: StyleConfig.backgroundColor,
    "@media (min-width: 960px)": {
      padding: "0 20px 50px",
      paddingTop: 25,
    },
    "@media (min-width: 990px)": {
      padding: "0 10px 50px",
      paddingTop: 25,
    },
    // overflow:'auto',
    // overflowX:'hidden',
  },
};

export default ResourcesPageStyles;
