import React, {useState} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";
import Zoom from "@material-ui/core/Zoom";
import ScrollIntoView from "react-scroll-into-view";
import Section2Styles from "./Section2Styles";
import {withRouter} from "react-router-dom";
import clsx from "clsx";
import Section2Config from "./Section2Config";
import withWidth from "@material-ui/core/withWidth";

let styles = Section2Styles;

function ScrollTop(props) {
   const {children, window} = props;
   const {classes} = props;
   // Note that you normally won't need to set the window ref as useScrollTrigger
   // will default to window.
   // This is only being set here because the demo is in an iframe.
   const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 10
   });

   const handleClick = event => {
      const anchor = (event.target.ownerDocument || document).querySelector(
         "#back-to-top-anchor"
      );

      if (anchor) {
         anchor.scrollIntoView({behavior: "smooth", block: "center"});
      }
   };

   return (
      <Zoom in={trigger}>
         <div onClick={handleClick} role="presentation" className={classes.root}>
            {children}
         </div>
      </Zoom>
   );
}

function Section2(props) {
   let {classes, window, width} = props;

   let tabData = Section2Config;

   let defaultSelectedTab = tabData.length ? tabData[0].tabURI : '';

   let [selectedTab, setSelectedTab] = useState(defaultSelectedTab)

   const trigger = useScrollTrigger({
      target: window ? window() : undefined,
      disableHysteresis: true,
      threshold: 10
   });

   let handleChangeSelectedTab = (tabURI) => () => {
      setSelectedTab(tabURI)
   }

   return (
      <div className={classes.root}>
         <Grid container direction={'row'} justify={width === 'xs' ? 'center' : 'space-between'}>
            <Grid item xs={12} sm={3}>
               <Grid container direction={"row"} spacing={2} justify={width === 'xs' ? 'center' : 'flex-start'}
                     className={classes.navButtonsContainer}>
                  {tabData.map((tab, index) => {
                     return (
                        <Grid item xs={11} key={index}>
                           <ScrollIntoView selector={`#${tab.tabURI}`}>
                              <Button
                                 variant={'contained'}
                                 className={clsx(classes.navButton,
                                    (selectedTab === tab.tabURI) ? classes.selectedNavButton : null
                                 )}
                                 onClick={handleChangeSelectedTab(tab.tabURI)}
                                 classes={{label: classes.navButtonLabel}}
                              >
                                 {tab.name}
                              </Button>
                           </ScrollIntoView>
                        </Grid>
                     )
                  })}
               </Grid>
            </Grid>

            <Grid item xs={12} sm={9} container direction={'column'} spacing={4}
                  style={
                     width === 'xs' ? {
                        marginTop: 30,
                     } : null
                  }
            >
               {tabData.map((tab, index) => {
                  let ContentComponent = tab.content;
                  return (
                     <Grid item id={tab.tabURI} key={index} className={classes.contentContainer}
                           style={{scrollMarginTop: !trigger ? '22vh' : '13vh'}}>
                        <ContentComponent/>
                     </Grid>

                  )
               })}
            </Grid>
         </Grid>

      </div>
   );
}

export default withWidth()(withRouter(withStyles(styles)(Section2)));
