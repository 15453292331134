import GenUtil from '../util/GenUtil';
import APIServices from './serviceHelpers/APIServices';
import APIPath from '../lib/APIPath';
import { getHost } from '../lib/Constants';

class AppStackService {
  static getGridHeaders = async (data = {}) => {
    const axiosConfig = {
      url: `${getHost()}${APIPath.appStack}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getAPIHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log('headers', await GenUtil.getAPIHeaders());
    const sr = await APIServices.request(axiosConfig);
    return sr;
  };

  static getAppStackDeveloperList = async (data = {}) => {
    const axiosConfig = {
      url: `${getHost()}${APIPath.appStackDeveloperList}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getAPIHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log('headers', await GenUtil.getAPIHeaders());
    const sr = await APIServices.request(axiosConfig);
    return sr;
  };

  static getListOfTargetIndustries = async (data = {}) => {
    const axiosConfig = {
      url: `${getHost()}${APIPath.listOfTargetIndustries}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getAPIHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log('headers', await GenUtil.getAPIHeaders());
    const sr = await APIServices.request(axiosConfig);
    return sr;
  };

  static getFilterAwirosAppStack = async (data = {}) => {
    const axiosConfig = {
      url: `${getHost()}${APIPath.filterAwirosAppStack}`,
      method: 'POST',
      crossDomain: true,
      dataType: 'json',
      headers: await GenUtil.getAPIHeaders(),
      cache: false,
      processData: false,
      data: JSON.stringify(data),
    };
    // console.log('headers', await GenUtil.getAPIHeaders());
    const sr = await APIServices.request(axiosConfig);
    return sr;
  };
}

export default AppStackService;
