import { withStyles } from "@material-ui/styles";
import React from "react";
import { withRouter } from "react-router-dom";
import SecondBannerStyles from "./SecondBannerStyles";
import { secondBannerCardsData } from "../utils/SecondBannerData";

const SecondBanner = (props) => {
  const { classes } = props;

  return (
    <div
      style={{
        background: `url('/assets/appathon/secondBannerBG.png')`,
        backgroundColor: "#2C2C2C",
        border: "1px solid black",
        backgroundSize: "cover",
      }}
    >
      <div className={classes.banner}>
        <div className={classes.heading}>
          <div className={classes.firstHeading}>
            <span>THE 2ND </span>
            <span className={classes.compVis}>COMPUTER VISION</span>
          </div>
          <div className={classes.secondHeading}>APP-A-THON</div>
        </div>
        <div className={classes.cardContainer}>
          {secondBannerCardsData.map((card, index) => (
            <div key={index}>
              {index % 2 === 0 ? (
                <div
                  className={classes.card}
                  style={{
                    width: "100%",
                    marginRight: "auto",
                  }}
                >
                  <img src={card.imageUrl} className={classes.cardImage}></img>
                  <div className={classes.cardDetails}>
                    <div className={classes.cardDetailsHeading}>
                      {card.heading}
                    </div>
                    <div className={classes.cardDetailsDescription}>
                      {card.description}
                    </div>
                  </div>
                </div>
              ) : (
                <div className={classes.card}>
                  <div
                    className={classes.cardDetails}
                    style={{ textAlign: "right" }}
                  >
                    <div className={classes.cardDetailsHeading}>
                      {card.heading}
                    </div>
                    <div className={classes.cardDetailsDescription}>
                      {card.description}
                      {index === 1 ? (
                        <>
                          <span>with</span>{" "}
                          <span style={{ color: "#FC0" }}>C++/Python.</span>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <img src={card.imageUrl} className={classes.cardImage}></img>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(SecondBannerStyles, { withTheme: true })(SecondBanner)
);
