let Section5Styles = {
   root: {
      marginBottom: 120
   },
   subheading: {
      maxWidth: 800,
      margin: '0 auto'
   },
   headerImage: {
      width: '90%',
      margin: 'auto',
      marginTop: '20px',
      textAlign: 'center',
      '@media (max-width: 600px)': {
         width: '100%',
      },
   }
};

export default Section5Styles;