import StyleConfig from "../../../../lib/StyleConfig";

let Section6Styles = {
   root: {
      marginBottom: 140
   },
   heading: {
      marginBottom: 20
   },
   textContent: {
      height: '12rem',
      lineHeight: '1.5rem',
      // textAlign:'center',
      fontSize: '18px!important',
      fontWeight: 300,
      // margin:'auto 100px',
      '@media (max-width: 768px)': {
         margin: 'auto 30px',
      },
      marginBottom: 40,
   },
   thumbnailImage: {
      width: '90%',
      // height:'100%',
      border: '1px solid',
      borderColor: 'transparent',
      marginBottom: 10
   },
   buttonType: {
      marginTop: 25,
      float: 'right',
      // marginRight:25
   }
};

export default Section6Styles;