import { withStyles } from "@material-ui/styles";
import PolicyStyles from "./PolicyStyles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

const PolicyPage = (props) => {
  const { data, classes } = props;
  console.log(data, "eula");
  const [selectedIndex, setSelectedIndex] = useState([]);

  const handleClick = (index) => {
    const tempIndex = selectedIndex.indexOf(index);
    const arr = selectedIndex;
    if (tempIndex === -1) {
      setSelectedIndex([...selectedIndex, index]);
    } else {
      arr.splice(tempIndex, 1);
      setSelectedIndex([...arr]);
    }
  };

  return (
    <div className={classes.root}>
      <div
        className={classes.banner}
        style={{
          backgroundImage: `url(${data.backgroundImageUrl})`,
          backgroundSize: "cover",
        }}
      >
        <div className={classes.bannerContent}>
          <div className={classes.bannerTitle}>{data.title}</div>
          <div className={classes.bannerDescription}>
            EULA for all software and services associated with
            <a
              style={{
                color: "#FFCC00",
                marginLeft: "5px",
                marginRight: "5px",
                textDecoration: "none",
              }}
              href="https://awiros.com/"
              target="_blank"
            >
              Awiros
            </a>{" "}
          </div>
        </div>
      </div>

      <div>
        <div className={classes.accordions}>
          <div>
            If you require any more information or have any questions about our
            EULA, please feel free to contact us by email at{" "}
            <a
              href="https://mail.google.com/mail/?view=cm&fs=1&to=legal@awiros.com"
              target="_blank"
            >
              {" "}
              legal@awiros.com
            </a>{" "}
          </div>
          {data.content.map((accordion, index) => (
            <div className={classes.accordion}>
              <div
                className={classes.accordionHeading}
                onClick={() => handleClick(index)}
              >
                <p>{accordion.heading}</p>
                <img
                  style={{
                    transform: `rotate(${
                      selectedIndex.includes(index) ? "180deg" : "0deg"
                    })`,
                  }}
                  src="/assets/policy/downArrow.png"
                ></img>
              </div>
              {selectedIndex.includes(index) && (
                <div className={classes.accordionDescription}>
                  {accordion.description}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default withRouter(
  withStyles(PolicyStyles, { withTheme: true })(PolicyPage)
);
