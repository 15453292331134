import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  customersAndpartnersTwo: {
    width: "100%",
    aspectRatio: "1920/900",
    padding: "2% 10% 2% 10%",
    "@media (max-width:768px)": {
      aspectRatio: "1920/900",
      padding: "2% 5% 2% 5%",
    },
    filter: `grayscale(1)`,
  },
  heading: {
    textAlign: "center",
    fontSize: "55px",
    fontWeight: "600",

    "@media (max-width:1440px)": {
      fontSize: "36px",
    },
    "@media (max-width:1024px)": {
      fontSize: "24px",
    },

    "@media (max-width:768px)": {
      fontSize: "20px",
    },
  },
  subHeading: {
    textAlign: "center",
    fontSize: "35px",
    fontWeight: "600",
    margin: "5% 0 5% 0",
    "@media (max-width:1440px)": {
      fontSize: "24px",
    },
    "@media (max-width:1080px)": {
      fontSize: "16px",
    },

    "@media (max-width:768px)": {
      fontSize: "16px",
    },
  },
  main: {
    width: "100%",
    height: "100%",
  },

  mainContent: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  subMainContent: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "50%",
    paddingBottom: "10px",
  },
  mainSubColumn: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  mainColumnContent: {
    display: "flex",
    flexDirection: "column",
    flex: "1",
    height: "100%",
  },
  divisionLine: {
    borderRight: "1px solid black",
  },
  eachContent: {
    width: "100%",
    height: "20%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: "10px",
  },
  image: {
    width: "40%",
    objectFit: "contain",
    "@media (max-width:768px)": {
      width: "45%",
    },
  },
});

function CustomersAndPartnersDesktopViewComponent({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.customersAndpartnersTwo}>
      <div className={classes.heading}>{data.heading}</div>
      <div className={classes.main}>
        <div className={classes.mainContent}>
          <div className={classes.subMainContent}>
            <div className={classes.subHeading}>
              {data.allColumns[0].subHeading}
            </div>
            <div className={classes.mainSubColumn}>
              <div className={classes.mainColumnContent}>
                {data.allColumns[0].bothColumns.column1.map(
                  (imageUrl, index) => (
                    <div key={index} className={classes.eachContent}>
                      <img src={imageUrl} className={classes.image}></img>
                    </div>
                  )
                )}
              </div>
              <div
                className={`${classes.mainColumnContent} ${
                  data.heading === "Partners" ? classes.divisionLine : ""
                }`}
              >
                {data.allColumns[0].bothColumns.column2.map(
                  (imageUrl, index) => (
                    <div key={index} className={classes.eachContent}>
                      <img src={imageUrl} className={classes.image}></img>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
          <div className={classes.subMainContent}>
            <div className={classes.subHeading}>
              {data.allColumns[1].subHeading}
            </div>
            <div className={classes.mainSubColumn}>
              <div className={classes.mainColumnContent}>
                {data.allColumns[1].bothColumns.column1.map(
                  (imageUrl, index) => (
                    <div key={index} className={classes.eachContent}>
                      <img src={imageUrl} className={classes.image}></img>
                    </div>
                  )
                )}
              </div>
              <div className={classes.mainColumnContent}>
                {data.allColumns[1].bothColumns.column2.map(
                  (imageUrl, index) => (
                    <div key={index} className={classes.eachContent}>
                      <img src={imageUrl} className={classes.image}></img>
                    </div>
                  )
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CustomersAndPartnersDesktopViewComponent;
