import { withStyles } from "@material-ui/styles";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
// import { appSelection } from "../utils/indexTwo";
import IndustriesPageTwoStyles from "../IndustriesPageTwoStyles";

const AppSection = (props) => {
  const appSelection = props.children;
  const { classes } = props;
  const [searchString, setSearchString] = useState("");
  const [searchedApps, setSearchedApps] = useState([]);
  const [currentApp, setCurrentApp] = useState(null);

  // for current window size
  function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }

  let dimensions = useWindowDimensions();
  function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(
      getWindowDimensions()
    );

    useEffect(() => {
      function handleResize() {
        setWindowDimensions(getWindowDimensions());
      }

      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
    }, []);

    return windowDimensions;
  }

  const searchApps = (searchString) => {
    // const response = appSelection.reduce((acc, { title, apps }) => {
    //   const selectedApps = apps.filter((app) =>{
    //     const appThroughTags=()=>{
    //       for (let index = 0; index < app.tags.length; index++) {
    //         if (app.tags[index].toLowerCase().includes(searchString.toLowerCase())) {
    //           return true
    //         }

    //       }
    //       return false
    //     }
    //     return app.name.toLowerCase().includes(searchString.toLowerCase()) || appThroughTags()
    //   }
    //   );
    //   if (selectedApps.length > 0) {
    //     return [...acc, ...selectedApps];
    //   } else {
    //     return [...acc];
    //   }
    // }, []);

    let appByName = [];
    let appByTags = [];
    for (let index = 0; index < appSelection[0].apps.length; index++) {
      if (
        appSelection[0].apps[index].name
          .toLowerCase()
          .includes(searchString.toLowerCase())
      ) {
        appByName.push(appSelection[0].apps[index]);
      } else {
        for (let j = 0; j < appSelection[0].apps[index].tags.length; j++) {
          if (
            appSelection[0].apps[index].tags[j]
              .toLowerCase()
              .includes(searchString.toLowerCase())
          ) {
            appByTags.push(appSelection[0].apps[index]);
            break;
          }
        }
      }
    }

    // setSearchedApps([
    //   { title: `Search Results for ${searchString}`, apps: [...response] },
    // ]);
    setSearchedApps([
      {
        title: `Search Results for ${searchString}`,
        apps: [...appByName, ...appByTags],
      },
    ]);
  };

  const handleInputChange = (event) => {
    setSearchString(event.target.value);
    searchApps(event.target.value);
  };

  const handleScrollLeft = (event) => {
    const apps = event.target.parentElement.nextSibling;
    if (apps.scrollWidth - apps.cl > 0) {
      console.log(`display right button`);
    }
    if (apps.scrollWidth - apps.scrollLeft >= apps.clientWidth) {
      // console.log(`display left button`);
      event.target.nextSibling.style.display = "block";
    }
    if (apps.scrollLeft <= 0) {
      event.target.style.display = "none";
    }
    if (apps.scrollLeft > 0) {
      apps.scrollLeft -= 300;
    }
  };
  const handleScrollRight = (event) => {
    const apps = event.target.parentElement.nextSibling;
    console.log(apps.scrollLeft, apps.scrollWidth);
    console.dir(apps);

    if (apps.scrollLeft > 0) {
      // console.log(`display left button`);
      event.target.previousSibling.style.display = "block";
    }
    if (apps.scrollWidth - apps.scrollLeft <= apps.clientWidth + 300) {
      // console.log(`display left button`);
      event.target.style.display = "none";
    }
    console.log(apps.scrollWidth, apps.scrollLeft, apps.clientWidth);
    if (apps.scrollWidth - apps.scrollLeft > 50) {
      apps.scrollLeft += 300;
    }
  };
  const handleMainCardClick = (index) => {
    if (currentApp === null) {
      setCurrentApp(index);
    } else {
      // setCurrentApp(null)
      if (currentApp === index) {
        setCurrentApp(null);
      } else {
        setCurrentApp(index);
      }
    }
  };

  return (
    <div className={classes.appSection}>
      <div className={classes.appSearch}>
        <img src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/search_icon.svg" alt="search_icon" />
        <input
          value={searchString}
          onChange={handleInputChange}
          placeholder={appSelection[0].placeholder}
          type="text"
        />
      </div>
      <div>
        {(searchString ? searchedApps : appSelection).map((apps, index) => {
          return (
            <div key={index}>
              <h2 className={classes.appSectionHeading}>{apps.title}</h2>
              {apps.apps.length > 0 ? (
                <div className={classes.appsCarousel}>
                  <div className={classes.appsCarouselButtons}>
                    <img
                      className={classes.carouselLeft}
                      src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/carousel-left.svg"
                      onClick={handleScrollLeft}
                    />
                    <img
                      className={classes.carouselRight}
                      src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/carousel-right.svg"
                      onClick={handleScrollRight}
                    />
                  </div>
                  <div id="apps" className={classes.appsContainer}>
                    {apps.apps.map((app, index) => (
                      <div
                        key={index}
                        className={classes.mainAppCard}
                        onClick={() => handleMainCardClick(index)}
                      >
                        <div
                          className={`${classes.appCard} ${
                            currentApp === index && dimensions.width <= 768
                              ? classes.rotateAppCard
                              : ""
                          }`}
                          style={{
                            backgroundImage: `url(${app.imageUrl})`,
                            backgroundRepeat: "no-repeat",
                            backgroundSize: "100% 80%",
                          }}
                        >
                          <div className={classes.appCardDetails}>
                            <div className={classes.appIcons}>
                              <img
                                src={app.iconUrl}
                                width="100%"
                                height="100%"
                                alt={app.name}
                              />
                            </div>
                            <span className={classes.appTitle}>{app.name}</span>
                          </div>
                        </div>
                        <div
                          className={`${classes.descriptionCard} ${
                            currentApp === index && dimensions.width <= 768
                              ? classes.rotateDescrCard
                              : ""
                          }`}
                        >
                          <div className={classes.descriptionCardTopbar}>
                            <div className={classes.DesCardIcon}>
                              <img
                                src={app.iconUrl}
                                width="100%"
                                height="100%"
                                alt={app.name}
                              />
                            </div>
                            <div className={classes.DesCardTitle}>
                              {app.name}
                            </div>
                          </div>

                          <div className={classes.desCardDescription}>
                            {app.description}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              ) : (
                <div className={classes.emptyState}>Nothing to display</div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default withRouter(withStyles(IndustriesPageTwoStyles)(AppSection));
