import { makeStyles, withStyles } from "@material-ui/styles";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import MainBannerStyles from "./MainBannerStyles";
import { useEffect } from "react";
// import { mainBannerData } from "../utils/indexThree";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import RoutePath from "../../../lib/RoutePath";
import { Link } from "react-router-dom";

const MainBanner = (props) => {
  const { classes, mainBannerData, increaseLeft } = props;
  const [currentBanner, setCurrentBanner] = useState(0);
  const [lineNumber, setLineNumber] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setLineNumber(0);
      setCurrentBanner((prev) => {
        if (prev === mainBannerData.length - 1) {
          return 0;
        } else {
          return prev + 1;
        }
      });
    }, 6000);

    return () => clearInterval(interval);
  }, []);

  // useEffect(() => {
  //   const innerInterval = setInterval(() => {
  //       setLineNumber(prev=>prev+1)
  //   }, 1000);

  //   return () => clearInterval(innerInterval);
  // }, []);

  return (
    <div>
      {(mainBannerData || []).map((bannerData, index) => {
        if (currentBanner === index) {
          return (
            <div key={index}>
              {
                <div>
                  <div className={`${classes.MainBanner} ${classes.smoothAni}`}>
                    <div
                      className={`${classes.leftSide} ${
                        increaseLeft ? classes.increaseLeft : ""
                      }`}
                    >
                      <div className={`${classes.leftHeading} `}>
                        <div
                          className={`${classes.firstLine} ${classes.bringLine1Attop}`}
                        >
                          {bannerData.line1}
                        </div>
                        <div
                          className={`${classes.secondLine} ${classes.bringLine2Attop}`}
                        >
                          {bannerData.line2}
                        </div>
                      </div>
                      <div className={classes.contactContainer}>
                        <button className={classes.contact}>
                          <a href="/contact">
                            <span className={classes.contactUSButton}>
                              Contact Us
                            </span>
                            <img
                              className={classes.carretRight}
                              src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/carret--right.png"
                              alt="Carret right"
                              height="10px"
                              width="10px"
                            />
                          </a>
                        </button>
                      </div>
                    </div>
                    <div
                      className={`${classes.rightSide} ${
                        increaseLeft ? classes.decreaseRight : ""
                      }`}
                      style={{
                        background: `rgba(0, 0, 0,0.4) url(${bannerData.backUrl}) 100% 100% no-repeat`,
                        backgroundSize: "cover",
                      }}
                    >
                      <div className={classes.rightHeading}>
                        <div
                          className={`${classes.firstLine} ${classes.bringLine3Attop}`}
                        >
                          {bannerData.line3}
                        </div>
                        <div
                          className={`${classes.secondLine} ${classes.bringLine4Attop}`}
                        >
                          {bannerData.line4}
                        </div>
                        <div
                          className={`${classes.thirdLine} ${classes.bringLine5Attop}`}
                        >
                          {bannerData.line5}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className={`${classes.mobileViewMainBanner} ${classes.smoothAni}`}
                    style={{
                      background: `rgba(0, 0, 0,0.4) url(${bannerData.backUrl}) 100% 100% no-repeat`,
                      backgroundSize: "cover",
                    }}
                  >
                    <div className={classes.MVHeading}>
                      {bannerData.line1} {bannerData.line2}
                    </div>
                    <div className={classes.MVDetail}>
                      {bannerData.line3} {bannerData.line4} {bannerData.line5}
                    </div>
                    <div className={classes.MVnote}>
                      <div className={classes.contactContainer}>
                        <button className={classes.contact}>
                          <a href="/contact">
                            <span className={classes.contactUSButton}>
                              Contact Us
                            </span>
                            <img
                              className={classes.carretRight}
                              src="https://awi-website-assets.s3.ap-southeast-1.amazonaws.com/assets/carret--right.svg"
                              alt="Carret right"
                            />
                          </a>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          );
        }
      })}
    </div>
  );
};

export default withRouter(
  withStyles(MainBannerStyles, { withTheme: true })(MainBanner)
);
