import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import Section2Styles from "./Section2Styles";
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Section2Config from "./Section2Config";

let styles = Section2Styles;

class Section2 extends Component {
   constructor(props) {
      super(props);
      this.section2Config = Section2Config
   }

   render() {
      let {classes} = this.props;
      return (
         <div className={classes.root}>
            <div className={'h2heading'}>FAQs</div>
            {this.section2Config.map((summaryObject, index) => {
               return (
                  <>
                     <Accordion>
                        <AccordionSummary
                           expandIcon={<ExpandMoreIcon/>}
                           aria-controls="panel2a-content"
                           id="panel2a-header"
                        >
                           <Typography className={classes.heading}>{summaryObject.heading}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                           {summaryObject.content}
                        </AccordionDetails>
                     </Accordion>
                  </>
               )
            })}
         </div>
      );
   }
}

export default withStyles(styles)(Section2);
