let InfiniteCarouselStyles = {
   root: {
      border: 0,
      outline: 0
   },
   carouselProvider: {
      border: 0,
      outline: 0
   },
   testimonialCard: {
      margin: 10,
      // height: 548,

   }
};

export default InfiniteCarouselStyles;