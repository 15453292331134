import EmailValidator from 'email-validator';
import ValidatorResponse from './ValidatorResponse';

class UserValidator {
  static async isValidEmail(email) {
    if (!email || (email && email.trim().length === 0))
      return ValidatorResponse.error('Email is Empty');
    // todo, check email
    else if (!EmailValidator.validate(email)) {
      return ValidatorResponse.error('Email is invalid');
    }

    return ValidatorResponse.success();
  }
}

export default UserValidator;
