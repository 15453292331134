import React, {Component} from 'react';
import withStyles from "@material-ui/core/styles/withStyles";
import IndustryPageCardStyles from "./IndustryPageCardStyles";
import Grid from "@material-ui/core/Grid";
import clsx from "clsx";

let styles = IndustryPageCardStyles;

class IndustryPageCard extends Component {
   static defaultProps = {
      cardData: {
         awi_name: '',
         icon: '',
         awi_developer: '',
         description: ''
      }
   };

   render() {
      let {classes, cardData} = this.props;
      return (
         <div>
            <Grid container direction={'row'} justify={'center'} alignItems={'center'} spacing={2}
                  className={classes.root}>
               <Grid item xs={12} sm={3} container direction={'row'} justify={'center'} alignItems={'center'}>
                  <Grid item>
                     <img src={cardData.icon} alt={cardData.awi_name} className={classes.appIcon}/>
                  </Grid>
               </Grid>

               <Grid item xs={12} sm={9}>
                  <div className={clsx(classes.appName)}>{cardData.awi_name}</div>
                  <div className={clsx(classes.developerName)}>{cardData.awi_developer}</div>
                  <div className={clsx(classes.descriptionText)}>{cardData.description}</div>
               </Grid>
            </Grid>
         </div>
      );
   }
}

export default withStyles(styles)(IndustryPageCard);