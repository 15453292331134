export const saveTheDates = [
  {
    imageUrl: "registration.png",
    heading: "Registration",
    points: ["Registration of teams (1-4 members)"],
    range: "18 SEPTEMBER 10:00AM -06 OCTOBER 11:59AM",
    date: "",
  },
  {
    imageUrl: "problem.png",
    heading: "Problem Statement Release",
    points: [],
    range: "",
    date: "25 SEPTEMBER",
  },
  {
    imageUrl: "trainingSession1.png",
    heading: "Training Session-1",
    points: ["Overview of Awiros Platform", "Training for OpenVino"],
    range: "",
    date: "03 OCTOBER",
  },
  {
    imageUrl: "traning2.png",
    heading: "Training Session-2",
    points: [
      "Anatomy of Video AI Applications",
      "How to create App using the Awiros SDK",
      "About Intel® Processors (IceLake & Rapid Saphires)",
    ],
    range: "",
    date: "06 OCTOBER",
  },
  {
    imageUrl: "buildandsubmit.png",
    heading: "Build & Submit Applications",
    points: [
      "2 rounds will be conducted. Selected teams will move further.",
      "Round 2 - Access of Awiros SDK is provided to the teams to develop and port their application for further evaluation.",
    ],
    range: "06 OCTOBER - 17 OCTOBER",
    date: "",
  },
  {
    imageUrl: "shortlisted.png",
    heading: "Shortlisted Teams Declared",
    points: [],
    range: "",
    date: "20 OCTOBER",
  },
  {
    imageUrl: "finale.png",
    heading: "Finale",
    points: [
      "At the venue in Bangalore",
      "Presentation of shortlisted applications by the teams.",
      "Demo of the shortlisted Applications",
      "Prize Distribution",
    ],
    range: "",
    date: "29 OCTOBER",
  },
];
