import React, { Component } from 'react';

import BrochureForm from './BrochureForm/BrochureForm';
import { Grid } from '@material-ui/core';
import ResourceCard from '../ResourceCard/ResourceCard';
import ResourcePageBrochuresConfig from './ResourcesPageBrochuresConfig';
import ResourcesPageBrochuresSectionStyles from './ResourcesPageBrochuresSectionStyles';
import withStyles from '@material-ui/core/styles/withStyles';

let styles = ResourcesPageBrochuresSectionStyles;

class ResourcesPageBrochuresSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      brochureList: ResourcePageBrochuresConfig,
      brochureFormModalOpenFlag: false,
      selectedBrochureFormData: undefined,
    };
  }

  handleBrochureFormModalOpen = async () => {
    this.setState({ brochureFormModalOpenFlag: true });
  };

  handleBrochureFormModalClose = async () => {
    this.setState({
      brochureFormModalOpenFlag: false,
      selectedBrochureFormData: undefined,
    });
  };

  setSelectedBrochureData = (selectedBrochureFormData) => {
    this.setState(
      { selectedBrochureFormData },
      this.handleBrochureFormModalOpen
    );
  };

  render() {
    let { classes } = this.props;
    let {
      brochureList,
      brochureFormModalOpenFlag,
      selectedBrochureFormData,
    } = this.state;
    return (
      <div className={classes.root}>
        {brochureList.length ? (
          <>
            <div className={'h2heading'}>Product Brochures</div>
            <Grid container direction="row" spacing={3}>
              {brochureList.map((brochureData,index) => {
                return (
                  <Grid key={index} item xs={12} sm={6} md={4} lg={3}>
                    <ResourceCard
                      cardData={brochureData}
                      setSelectedBrochureData={this.setSelectedBrochureData}
                    />
                  </Grid>
                );
              })}
            </Grid>
          </>
        ) : null}

        <BrochureForm
          open={brochureFormModalOpenFlag}
          onClose={this.handleBrochureFormModalClose}
          brochureFormData={selectedBrochureFormData}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ResourcesPageBrochuresSection);
